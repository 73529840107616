.EditorDialog_importdata {
  .ModalWindow__main {
    width: 600px;
  }

  .ModalWindow__body {
    padding: 1px 18px;
    width: 600px !important;
    height: 350px;
    min-height: 150px !important;
    overflow-y: visible; // added because gray scrollbar was appearing inside Add Issue window, when description was filled with long text;
    // as a result, Discard button was shifted to bottom
  }

  .ModalWindow__footer {
    padding-top: 5px;
    width: 600px;
  }
}

.ImportDataDialog {
  &__label {
    display: block;
    float: left;
    padding-right: 10px;
    line-height: 26px;
    width: 15%;
    font-weight: bold;
    font-size: 16px;
  }

  &__block {
    margin-bottom: 5px;
  }

  &__block_description {
    margin-bottom: 1px;
  }

  &__block_file {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  &__block_type {
    margin-bottom: 16px;
  }
  .col-xs-2 {
    padding-top: 1px;
    flex-basis: 55%;
    min-width: 55%;
  }

  .col-xs-6 {
    padding-top: 1px;
    flex-basis: 20%;
    min-width: 20%;
  }

  &__parameters {
    padding: 0px 50px;
    margin-bottom: 15px;
    font-weight: 600;
    font-size: 16px;
  }

  .Input_radio:not(checked) + label {
    position: relative;
    padding: 0px 24px 0 24px;
  }

  .value_right {
    width: calc(60% + 20px);
    float: left;
    line-height: 26px;

    input[type='text'],
    textarea {
      width: 100%;
    }
  }
}

.ImportDialog__paramsDialog {
  .row {
    padding-left: 80px;
  }

  .value_right {
    width: 150px;
  }
  label {
    width: 400px;
    float: left;
  }

  .btn_big {
    width: 200px;
    margin-top: 7px;
  }
}
