@import '../../styles/utils/variables';

.List {
  margin: 6px 0;

  &__title {
    color: $blue-dark;
    font-size: 18px;

    &_active {
      font-size: 18px;
      cursor: pointer;
      color: $blue-dark;
      &:hover {
        //font-size: 16px;
        color: $blue-sea-dark;
      }
    }
  }
}
