@import "../../styles/utils/variables";

.LayoutView {
  margin-top: 30px;
  margin-left: 0px;

  .tabs-navigation {
    box-sizing: border-box;
    padding-left: 20px;
    background-color: $gray-light;
    border-bottom: 2px solid $blue-sea-dark;
  }

  .tabs-menu-item {
    margin-bottom: -4px;

    &.is-active {
      border: 2px solid $blue-sea-dark;
      border-bottom-color: #fff;
    }
  }

  &__buttons {
    .btn {
      margin-right: 20px;
    }
  }

  &__block {
    margin-bottom: 20px;
  }
}


.LayoutViewList {
  text-align: left;

  .ObjectSearch, .ObjectSearch__result {
    text-align: left;
  }

  .SimpleTable__headerCell {
    background-color: $blue-deep;
    color: $light;
    font-weight: normal;
    padding: 5px;
  }

  .LinkedKeyTable .col-xs-2 {
    width: 100px;
    min-width: 0px;
  }

  .LinkedKeyTable .col-xs-5 {
    padding-left: 0px;
    padding-right: 0px;
  }

  &__label {
    display: block;
    float: left;
    padding-right: 10px;
    line-height: 26px;
    width: 15%;
    font-weight: bold;
    font-size: 16px;

    a {
      font-weight: normal;
    }
  }

  &__block {
    margin-bottom: 5px;
  }

  &__warning {
    margin-left: 125px;
    color: $red;
  }

  &__block_description {
    margin-bottom: 1px;
  }

  &__block_filter {
    margin-bottom: 0px;
  }

  .value_right_filter {
    width: 80%;
    float: left;
    line-height: 26px;

    .TextArea {
      min-height: 60px;
    }
  }


  &__block_text {
    margin-top: 7px;
    margin-bottom: 1px;
  }

  &__block_value {
    margin-top: 1px;
    margin-bottom: 1px;
  }

  .value_right_order {
    width: 15%;
    float: left;
    line-height: 26px;

    input[type=text], textarea {
      width: 100%;
    }
  }

  .KeySettings__divButtons {
    margin-left: 0px;

    .KeySettings__arrowButton1 {
      margin-left: 20px;

      &__Text {
        position: absolute;
        top: 10px;
        left: 10px;
      }
    }

    .KeySettings__arrowButton2 {
      margin-left: 5px;

      &__Text {
        position: absolute;
        top: 10px;
        left: 19px;
      }
    }
  }

  .EditableEntity_string {
    max-width: 100%;
    box-sizing: border-box;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    //height: 31px;
  }

  .EditableEntity .StringData {
    display: inline-block;
  }
}



