.SimpleTooltip {
  &__root {
    display: inline-block;
  }
}

.SimpleTooltip__tooltip {
  position: fixed;
  background: #f0f0f0;
  border: 1px solid #cccccc;
  z-index: 1000;
  padding: 4px 8px;
  border-radius: 6px;
  text-align: left;
  color: #6c6c6c;
  line-height: 20px;
  text-transform: none;
  text-overflow: ellipsis;
  overflow: hidden;
  font-weight: normal;
}

.SimpleTooltip__parent.ToolTipWidth300 .SimpleTooltip__tooltip {
  min-width: 300px;
}

.SimpleTooltip__tooltip:after,
.SimpleTooltip__tooltip:before {
  bottom: 100%;
  left: 18px;
  border: solid transparent;
  content: ' ';
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.SimpleTooltip__tooltip:after {
  border-color: rgba(240, 240, 240, 0);
  border-bottom-color: #f0f0f0;
  border-width: 6px;
  margin-left: -6px;
}

.SimpleTooltip__tooltip:before {
  border-color: rgba(204, 204, 204, 0);
  border-bottom-color: #cccccc;
  border-width: 7px;
  margin-left: -7px;
}

.SimpleTooltip__parent .SimpleTooltip__tooltip {
  display: none;
}

.SimpleTooltip__parent:hover .SimpleTooltip__tooltip {
  display: block;
}

.SimpleTooltip__parent {
  //  display: inline-block;
}
