@import '../../styles/utils/variables';

.EditorDialog_example {
  & > .ModalWindow__background > .ModalWindow__main {
    width: 800px !important;
  }

  & > .ModalWindow__background > .ModalWindow__main > .ModalWindow__body {
    width: 800px !important;
    height: 850px;
    min-height: 150px !important;
    overflow-y: visible; // added because gray scrollbar was appearing inside Add Issue window, when description was filled with long text;
    // as a result, Discard button was shifted to bottom
  }

  & > .ModalWindow__background > .ModalWindow__main > .ModalWindow__footer {
    padding-top: 5px;
    width: 800px;
  }

  .EditorDialog {
    .ModalWindow__main {
      width: 1100px !important;
    }
    .scrollarea {
      width: 1120px !important;
    }
    .ModalWindow__body,
    .ModalWindow__upper {
      width: 1100px !important;
      height: auto !important;
      //min-height: none !important;
    }
    .ModalWindow__footer {
      padding-top: 5px;
      width: 1100px;
    }
  }
}

.ExampleDialog {
  &__label {
    display: block;
    float: left;
    padding-right: 10px;
    line-height: 26px;
    width: 15%;
    font-weight: bold;
    font-size: 16px;
  }

  &__name {
    display: block;
    float: left;
    padding-right: 10px;
    line-height: 26px;
    width: 75%;
    font-size: 16px;
  }

  &__type {
    display: block;
    float: left;
    padding-right: 10px;
    line-height: 26px;
    font-size: 16px;
  }

  &__block {
    margin-bottom: 5px;
  }

  &__block_text {
    width: 100%;
    padding-right: 3px;
    box-sizing: border-box;
  }

  &__block_description {
    margin-bottom: 1px;
  }

  &__block_platform {
    margin-bottom: 10px;
  }

  &__block_unit {
    margin-bottom: 16px;
  }

  .value_right {
    width: calc(80% + 20px);
    float: left;
    line-height: 26px;

    input[type='text'],
    textarea {
      width: 100%;
    }
  }

  .LocaleSelect_classic {
    margin-top: 4px;
    height: 19px;
    padding: 4px;
    border: 1px solid;
    border-radius: 4px;

    .LocaleSelect__value {
      line-height: 19px;
    }

    .LocaleSelect__toggle {
      top: 2px;
      right: 4px;

      &_rotated {
        top: 12px;
      }
    }
  }

  &__button {
    margin-left: 0 !important;
  }

  .EditButton {
    float: right;
  }

  &__text {
    height: 400px;
    max-height: 100%;
  }

  &__error {
    color: $red;
  }

  .JsonObjectEditor {
    textarea {
      height: 80px;
    }
  }
}
