@import '../../styles/utils/variables';

.EditorDialog_dkey {
  .ModalWindow__main {
    width: 800px;
    height: 600px !important;
  }

  .ModalWindow__body {
    padding: 1px 18px;
    // width: 800px !important;
    height: 433px;
    min-height: 150px;
    overflow-y: visible; // added because gray scrollbar was appearing inside Add Issue window, when description was filled with long text;
    // as a result, Discard button was shifted to bottom
  }

  .ModalWindow__footer {
    padding-top: 5px;
    width: 800px;
  }
}

.KeyDialog {
  .DataEditor__block {
    &_name,
    &_primary,
    &_fields,
    &_cardinality,
    &_constraint {
      margin-top: 5px;
      margin-bottom: 5px;

      &__value_right {
        width: calc(82% - 20px);
        float: left;

        .TextArea,
        .TextInput .StringData,
        .KascodeSelect_input,
        .KascodeSelect__placeholder {
          padding-top: 2px;
          height: 28px;
          line-height: 28px;
          font: 16px 'PT Sans', Arial, sans-serif !important;
        }

        &__warning {
          color: red;
          margin-bottom: 5px;
          font: 14px 'PT Sans', Arial, sans-serif !important;
        }
      }
    }

    &_description {
      margin-top: 5px;
      margin-bottom: 5px;

      &__value_right {
        width: calc(82% - 20px);
        float: left;
        line-height: 26px;

        .TextArea,
        .MultilineText__text {
          min-height: 80px;
          font: 16px 'PT Sans', Arial, sans-serif !important;
        }
      }
    }

    &_order {
      margin-top: 0px;
      margin-bottom: 1px;

      &__value_right {
        width: calc(10% + 10px);
        float: left;
        line-height: 26px;
      }
    }

    &__label {
      display: block;
      float: left;
      padding-right: 10px;
      line-height: 23px !important;
      width: calc(15% + 20px);
      font-weight: bold;
      font-size: 16px;
    }
  }

  .SimpleTooltip__parent:hover .SimpleTooltip__tooltip {
    display: block;
    width: 250px;
    font-weight: normal;
  }
}
