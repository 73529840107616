.EditorDialog_environment_chart {
  .ModalWindow__main,
  .ModalWindow__body,
  .ModalWindow__upper,
  .ModalWindow__footer {
    width: 1000px;
  }

  .scrollarea {
    width: 1200px;
  }

  .ScrollArea {
    width: 1020px;

    &__container {
      max-width: 1000px;
      width: calc(100% - 20px);
    }
  }
}
