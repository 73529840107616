@import '../../styles/utils/variables';

.EditorListBase {
  margin: 0;

  .tabs-navigation {
    box-sizing: border-box;
    padding-left: 20px;
    background-color: $gray-light;
    border-bottom: 2px solid $blue-sea-dark;
  }

  .tabs-menu-item {
    margin-bottom: -4px;

    &.is-active {
      border: 2px solid $blue-sea-dark;
      border-bottom-color: #fff;
    }
  }

  &__buttons {
    .btn {
      margin-right: 20px;
    }
  }

  &__block {
    margin-bottom: 20px;
  }

  &__icon {
    display: inline-block;
    background-repeat: no-repeat;
    background-position: center;
    width: 24px;
    height: 24px;

    &_included {
      background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAgCAYAAABpRpp6AAABaElEQVR4Xs3UwU3DQBCFYTPiwinQQTqADvCJG2I7iGkApQToAFEAMR1YooHQgdMBnLnAKUfzRrJkxAOUx2rQrvRrDmOtvs0he8MwVKWd8+5qjtGj5WO6az/vrEDsIUaHZmgFfEPgwrBrdDwtJrRnZWMZvV80ltGVFY3lbq1oLLe0by458f7J3ArYS/+Ls6/Y8cWtvz7413XshYL1hTG2mo2vXjs6ELtQsZ4RdjyELgDrGUBzxjK6BKxnqCOsgA7C3kxYBve0ENAB2Adgr39aGpaNf7QjuvsDthGxzW8I8yGgTwFoRewqE8tgEb0gdCyWwXnoeCyDGf0koMOxDOYS2khoHbuZsJlgXPSGUStolBQsqmkhgLPR6F7B+v154Hz0EdpGYhmcjz5wtIjVwbFoxsaBGZ3Qu4h+IWwYmNHPGLWAfkWJsKFgRvc7on1/Nn5fRYN1NGNrwkaDdTRjiwEzmrEFgRlN2KDzAccc9L+YsGq8AAAAAElFTkSuQmCC');
      background-size: 22px 16px;
    }

    &_excluded {
      background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACYAAAAnCAYAAABjYToLAAADqUlEQVR4Xu3YXWhURxjHYWd2s3GTXc2HX2kgggmKJBdqG2yINkuQXlgEFVQsSBG80qYivVDbEqGgFLQFv3ITkAi2KKgXFpGIGi30Ayt6Idre2BbaBhuN0dhESXbP9HfgFYbhHGzWc/BCX3h4k4ud+efNnOxslDFmUrE1/H5j+Isx5Ztbqti1NWKtVz7Y62DJgAM9k1ZpBRjgED+IMgB7VDh7P2SPfOjECFVP68RB8RXeI2xVxKE+Q49lOWGTgRMjVJZ2AM1WgCGsxwB6EUXtxCI0WPvvRwongya2FUsxRWRRhdmIsrISKoNKMQudDKfWnpg/rbm0TUhbYQvI4D6irjTKnHPWgB3osCe2GTVIQIkENEYR5eEftoaioEUKq2VIkzRfVNNX2umFQh5neGJ+jjDYIdyD7AEKGtPwwbNv2jEDyuKXh+vYh8iKH/Iv2h48kUAGypriWoaV9oO9ixTcGsduFhqhR10n8RMM3HoDb/vB3oSCW/24iMiLqT2l7cW4FU6JFNr9YLVQTjiDSyzAC2Or7zAItzRa/GDlcMvgMqRim9o12KWk12taMiTYVcRdP8IEhJuqrZSuvxF33XSDiZR+yVeioYDBGCSSVmJXHW7HdacXWbil4Gl5ZN1SaELc1RhyvsfkvTDwAC5D3LUk5Iw/1rQ/raR25bh1pOJKJGsvdoIZ6b9p2nU3lKjFQsRV7aiGBAIFD1f9YOcw7oRTKMWHMU5rK0oCbhlj6PODXbGuIW64FSzSEkO25Wh1f43iLi5r+QTUCw92KWRwmHAzIpxWnVx7yqx9jHVrPk6mJ1pCdOERjFBCoxFHCZeJ6BPS12iAdkJ5GMQxsDFFwhsyNTlroEQJcjhFuOkvEKqadhrNSITc/74lyy/u204n/kHBOW8KKQl3lnAtSEww1Fu0PrQiBe3skcfv+AIUmxpj7AU6aJ8j645aeHiEM/gSt5m2FxKmhDYf27AKGXsQ1toFWXMLa50I+xdBF+ZiI9IBi2hUYgPW4A8C9NG/x69IYB7eQRvqUAplsZ9AD6PoxilQ7sSE/JqOYDUmIxEQ0C4jKLoVQKiQ1xbwFD3YxrTybrCwt4sDWIdyJJ1NimVEAf+im0Db//edi/RjtI+wC/0Yg7EUFUjkMYBPg0OFT8ydXpM8EDnnoXjeBI3TPQzjAj4h1B06VUQwJ2Az7WO0oQIlAQENlBMqj0H04qD8zZSKIJgVcI48dTksQA3KkbAO9Qj6cQ3n8QOBJvQZ4j8rrDnlZDgwjwAAAABJRU5ErkJggg==');
      background-size: 19px 19.5px;
    }

    &_not_included {
      background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAkCAYAAADhAJiYAAADGklEQVR4Xs2YT0hUURSHe9cZNaGBFkElOWa5aBO1aWFlkwlBLdKNhQhjixZJoEnQKlq2GpmBxFWhQbpLDdslTi3cRS0MykIdWoTQwgj64zi+PuEgg7zz7ryZt/DA5xk8Z+75ce59dzjPcV13T6k2NDRUg2uBq9AKR6EOIpJSgD+wDFmYhvnBwcFCqTVEkFXIflwfJKEBqsGR8M4Fiv+/Dt8gAyMirHxBCKnD9cADOARGCgYxFzZhFe4haqIsQYhpxg1DAqJQqbmQhznoQ9iSV5JRxLTiZqENIhCGObLVl+Cl1LALIrEdNwH1UCULhWkROAHPqHXaVxAJW4kjcFBimk1BLzSCs4NGiZGjmoEjMEXNw56C5JEehiYfMVkp2AljkPPIyUmsU3KzPltYD8PUrvXqUDec8xEzABcVERo5+c6AIsjAFbi8LaioO498DnAvZKBcy0CvIioKKTTEijt0Fw6Ao3RmDCq1MZ9OxeF6saBbipisdCYsyyhnqgru0KVqw582OVyOslXhmr51zXAyIj+U1cqj7XuAuW29LlXrQZe1O3YEauCaIXBBu2ssYjSRSsy6tgPnDYG4en4sFliwfe3jBhfTWmsvZifg2vsMDnaNRY1s164xA5uwW6xgcGtKMC4+6KOt59jXXjO4ZSWYgHDNvvYng5tVtq3D0oFgMfvaLrx2UqnU2a0PUOuR1Ag5CNPisOIR+A1nDIF3kuA1foxC2DaqdGdxC8Ml95fAE2XbEtAPYVm/cn4KkEZL3kjgMXxXupSGJFRqSUgr3VmB8e2pQ7r0EPLa1lXYqX6frVqH+2jIbwsSUU9xb30uyjTMQTzgAZ6DtBIvwAy1X2hz2U1Y9BGVkPZOQlIRF5fYpOQmfMQswG11lJY7pAX3HBpC+eHVZ/3P0EN33vtOriTMy0y1JC11wxYjnekSMfbZnsQPMqO9Ug56uWL+wTS0U+OjOtsrolZlNOmCZdgoU5gr3/0K3XADMT8qfmEl4vrgGNSUcL42pSNfYATGEfLLVivoK729uFNFr/SaIAZRSdmAnyLiDczAgtxzJdl/9uUHRwU6g2oAAAAASUVORK5CYII=');
      background-size: 18px 18px;
    }
  }
}

.EditorListList {
  text-align: left;

  .ObjectSearch,
  .ObjectSearch__result {
    text-align: left;
  }

  .SimpleTable__headerCell {
    background-color: $blue-deep;
    color: $light;
    font-weight: normal;
    padding: 5px;
  }

  .LinkedKeyTable .col-xs-2 {
    width: 100px;
    min-width: 0px;
  }

  .LinkedKeyTable .col-xs-5 {
    padding-left: 0px;
    padding-right: 0px;
  }

  &__label {
    display: block;
    float: left;
    padding-right: 10px;
    line-height: 26px;
    width: 15%;
    font-weight: bold;
    font-size: 16px;

    a {
      font-weight: normal;
    }
  }

  &__block {
    margin-bottom: 5px;
  }

  &__warning {
    margin-left: 125px;
    color: $red;
  }

  &__block_description {
    margin-bottom: 1px;
  }

  &__block_filter {
    margin-bottom: 0px;
  }

  .value_right_filter {
    width: 80%;
    float: left;
    line-height: 26px;

    .TextArea {
      min-height: 60px;
    }
  }

  &__block_text {
    margin-top: 7px;
    margin-bottom: 1px;
  }

  &__block_value {
    margin-top: 1px;
    margin-bottom: 1px;
  }

  .value_right_order {
    width: 15%;
    float: left;
    line-height: 26px;

    input[type='text'],
    textarea {
      width: 100%;
    }
  }

  .KeySettings__divButtons {
    margin-left: 0px;

    .KeySettings__arrowButton1 {
      margin-left: 20px;

      &__Text {
        position: absolute;
        top: 10px;
        left: 10px;
      }
    }

    .KeySettings__arrowButton2 {
      margin-left: 5px;

      &__Text {
        position: absolute;
        top: 10px;
        left: 19px;
      }
    }
  }

  .EditableEntity_string {
    max-width: 100%;
    box-sizing: border-box;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    //height: 31px;
  }

  .EditableEntity .StringData {
    display: inline-block;
  }
}
