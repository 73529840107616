@import '../utils/variables.scss';

.ModalWindow__root {
  display: none;
  &__open {
    display: block;
  }
}

.ModalWindow__main {
  //position: fixed;
  width: 800px;
  min-height: 500px;
  /*
  top: 100px;
  height: 400px;
  */
  max-height: 95%;
  //top: 50%;
  //transform: translate(-50%, -50%);
  //margin-left: -400px;
  //left: 50%;
  z-index: 1000;
  color: $gray-dark;
  display: flex;
  flex-direction: column;
  max-width: 85%;
}

.ModalWindow__windowHead {
  box-sizing: border-box;
  border-radius: 8px;
  background-color: white;
  margin-bottom: 10px;
  font-size: 20px;
  color: #6c6c6c;
  //color: $blue;
  padding: 14px 50px 14px 18px;
  position: relative;
  flex: 1;
  flex-shrink: 0;
  display: flex;
  width: 100%;
}

.ModalWindow__title {
  line-height: 30px;
}

.ModalWindow__upper {
  box-sizing: border-box;
  width: 800px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background-color: white;
  min-height: 20px;
  padding: 18px;
  //flex: 0 0;
  max-width: 100%;

  &_empty {
    //margin-bottom: -20px;         // fix rounded corners
    padding: 0;
    //overflow: hidden;             // fix rounded corners
  }
}

.ModalWindow__body {
  box-sizing: border-box;
  width: 800px;
  //border-radius: 8px;
  background-color: white;
  min-height: 400px;
  padding: 18px;
  max-height: 100%;
  overflow-y: auto;
  flex: 1 1;
  display: flex;
  flex-direction: column;
  position: relative;

  .Table__container {
    flex: 1;
  }

  button {
    //font-size: 14px;
    //font-weight: bold;
  }
}

.scrollarea {
  width: 820px;
  flex: 1 1 auto;
  max-width: calc(100% + 20px);
}

.scrollarea .scrollbar-container,
.scrollarea .scrollbar-container.active {
  border-radius: 4px;
  //background-color: #EBEBEB;
  background-color: rgba(255, 255, 255, 0.5);
  //opacity: 1 !important;
  opacity: 0.8 !important;
  transition: none;
}
.scrollarea .scrollbar-container:hover {
  border-radius: 7px;
  background-color: #ebebeb;
  opacity: 1 !important;
}

.scrollarea .scrollbar-container.vertical {
  width: 10px;
  //margin-right: 2px;
  padding-top: 1px;
  margin-left: 0px;
  right: 2px;
}
.scrollarea .scrollbar-container.vertical:hover {
  width: 14px;
  right: 0px;

  //margin-left: 2px;
}

.scrollarea .scrollbar-container.vertical {
  //padding-top: 2px;
  //padding-bottom: 2px;
  //overflow: hidden;
  //position: relative;
}
.scrollarea .scrollbar-container.vertical:after {
  /*
  position: absolute;
  width: 5px;
  height: 5px;
  background-color: red;
  content: '';
  */
}

.scrollarea .scrollbar-container.vertical .scrollbar {
  //margin-left: 2px;

  min-height: 30px;
  background-color: #6cd2dc !important;
  border-radius: 4px;
  width: 8px;
  //outline: 2px solid white;
  //box-shadow: 0 -2px 0 #EBEBEB inset, 0 2px 0 #EBEBEB inset;
}

.scrollarea .scrollbar-container.vertical:hover .scrollbar {
  margin-left: 1px;
  background-color: #3dc2d1 !important;
  border-radius: 7px;
  width: 12px;
  //outline: 2px solid white;
  box-shadow: 0 -2px 0 #ffffff inset, 0 2px 0 #ffffff inset;
}

.ModalWindow__header {
  //flex: 1;
  flex: 0 0;
}

.ModalWindowHeader__content {
  flex: 1 0;
  padding-left: 40px;
  font-size: 14px;
}

.ModalWindow__footer {
  width: 800px;
  box-sizing: border-box;
  max-width: 100%;
  background-color: white;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  //flex: 0 0;
  min-height: 20px;
  text-align: center;
  padding-bottom: 15px;
  padding-top: 15px;
  border-top: 1px solid $gray;

  .btn_edit {
    &:hover {
      font-weight: bold;
    }
  }
}

.ModalWindow__content {
  flex: 1 1;
  //max-height: 100%;         // this broke KeySettings window in edit mode. why was this property needed?
  overflow-y: auto;
}

.ModalWindow__background {
  content: '';
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  //background-color: #000;
  //opacity: 0.33;
  background-color: rgba(0, 0, 0, 0.33);
  z-index: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  //backdrop-filter: blur(5px);
  //-webkit-filter: blur(5px);
  //-moz-filter: blur(5px);
}

.ModalWindow__root__open .ModalWindow__background {
  //display: block;
}

.ModalWindow__closeButton {
  position: absolute;
  right: 15px;
  top: 15px;
  z-index: 200;
  width: 28px;
  height: 28px;
  cursor: pointer;
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAABfUlEQVR4Xs3X0WnDMBSF4eQskAxiSCYoZIJmED9kg2YEj9ARPEKhCwS8SEeoLrgcE/UhYH5dCw7iGj/ow1hw9n3f7xbrXHItGUp+dttcx5LPknvJ4++hnhBfJR/zftwoIs72Pu/nJWSJOMzzyZjNIU7zfFhiVCEcYzaGcIwJyLBEABgAUWGGgFz90wAYCuE8wqD5drpgGB5xCYNiYjE8wrcWiuERhpAYHmEIiuERhqAYHmEIiuERhqAYHmEIiuERhqAYHmEIiuERhqAYHmEIiuERhtAYGGEIjPmOgAhDYEwXARGG0BgYYQiAARA0pMZMLyCmFQgY4uzXvJMPcZ/oXoB0rgAAhOsTfDkTgcjAqAFiitAYNWh2bxG6aapNPeVrs3CEF4pRGwSPEY1ohRGJaIlRAgLBiEBkYAQgUjACECkYAYgUjABECkYAIgUjAJGCEYBIwQRkxBE8ZgzILfQAgscYe1N1WADBYYxUdWgAAWCqWf98gftqBI8Zn7/QL5I7B7WSrMgcAAAAAElFTkSuQmCC');
  background-size: cover;
}
.ModalWindow__closeButton:hover {
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADgAAAA3CAYAAABZ0InLAAAB6ElEQVR4XtXaQUpCURSHcT3TgtxCK1BXYI2dNItG6g7cgbaDWoE2ikYtwbeEtwSXIETjOtCJv3QH98HtfXAvfBzO7P3Ay0NxOJ/PB2dn4t15T97Jq+nsveZn6tgfXONtYo4qwy28nbdMgcJdxT4WshKcjpCeJTglZDW4FGmhFa4HJIpTs1/gWhcTQAK48Kx0B32pBDnymq44AWtACjfriBOwAEnjxl1wCRBB8jgBESSPExBB8jgBe0ASuLVwOWAeueWQeVzAnmMpAKpHb5VBHr0Jg9PVKQd2/7xfeY2QGC4PRJA8TkAEyeMERJA8TkAEyeMEpJGxH3vGCcgi9QtCzzgBQeQ9iBMQRL6COAFB5BDECUghaVwK5JEf3lS4GoCq9T4zwKG+hdQE1KvgIgO89A7esiZg+p7Lt+sLaTDui0YaiDt5DzExpIG4W+8tZgGSB950xLWxtwVIHLj0Dl1xikNaIW6ntQuORxqL45HG4niksTgeaf+IO+ZxPNKKcXq4aR7HI60Al3moIuS112aQ2wIggyu8yxtvXwAswHHIRQ5pLI5HGovjkUbheGQK3AI4EilgyDcADkdaLAsGxyPNazgcj7SQrhAcj2wsFiEBHIEMzz6AQsZMcFUh5RjoX/f1N/LevZfzV8U3BhL4uyHd0T4AAAAASUVORK5CYII=');
  right: 12.5px;
  top: 12.5px;
  width: 33px;
  height: 33px;
}
.ModalWindow__closeButton.ModalWindow__closeButtonInText {
  //right: -28px;
}
.ModalWindow__closeButton img {
  height: 28px;
}
.ModalWindow__blur {
  //background-color: red;
  //-webkit-filter: blur(1.5px);
}

.ModalWindow__level {
  //flex-shrink: 0.1;

  //flex: 0 0;
}

.ModalWindow__flex00 {
  flex: none;
}

.ModalWindow__headerEditButton {
  float: right;
  margin-top: 2px;
  margin-right: 20px;

  .EditButton__save {
    //color: $blue-dark;
  }
}

.ModalWindow__statusMessage {
  margin: 10px 0;
  padding: 10px 0;

  &_success {
    background-color: $green;
    color: white;
  }
  &_error {
    background-color: $red-dark;
    color: white;
  }
}
