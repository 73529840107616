.FormulaEditor {
  margin-top: 6px;
  margin-left: -6px;
  height: 20px;

  .ace_variable {
    color: #6c757d;
  }
  .ace_keyword {
    color: #007bff;
  }
  .ace_entity {
    color: #28a745;
  }
}
