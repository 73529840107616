.MainLayout {
  display: flex;

  &__container {
    padding-left: 220px;
    width: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    transition: all 0.4s;

    &.collapse {
      padding-left: 56px;
      transition: all 0.4s;

      .SelectView__upper,
      .OrganizationList__upper,
      .ObjectHeader__objectName {
        left: 56px;
        transition: all 0.4s;
      }
    }

    * > {
      width: 100%;
      max-width: 1920px;
    }

    .HeaderPath {
      top: 10px;
      left: 220px;
      max-width: 600px;
      width: 100%;
    }

    .SelectView__upper,
    .OrganizationList__upper,
    .ObjectHeader__objectName {
      top: 40px;
    }

    .SelectView__upper,
    .OrganizationList__upper,
    .ObjectHeader__objectName {
      left: 220px;
      right: 0;
      width: unset;
      transition: all 0.4s;
    }
  }
}
