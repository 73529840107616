@import '../../styles/utils/variables';

.ConfirmDialog {
  div.ModalWindow__background {
    div.ModalWindow__main {
      width: 600px !important;
      border: none;
    }

    div.ModalWindow__body {
      width: 600px !important;
      padding-top: 0;
      min-height: 120px;
      height: auto !important;
      text-align: center;
      border: none;
    }

    div.ModalWindow__footer,
    div.ModalWindow__upper,
    div.ModalWindow__windowHead {
      width: 600px !important;
      border: none;
    }

    div.ModalWindow__windowHead {
      background-color: $orange-red;
      color: white;
      text-align: center;
      width: 100%;
      border: none;
    }

    .ScrollArea {
      width: 620px;

      &__container {
        max-width: 600px;
        width: calc(100% - 20px);
      }
    }

    img {
      width: 78px;
      display: block;
      margin: 0 auto;
      margin-bottom: 15px;
    }

    font-size: 16px;
  }
}
