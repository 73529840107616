@import "../../styles/utils/variables";

.IssueMessage {
  //border-right: 1px solid $gray;
  //border-left: 1px solid $gray;
  //height: 48px;
  width: 881px;

  &__notActive {
    height: 48px;

    .IssueMessage__log {
      height: 48px;
      overflow: hidden;
    }
  }

  &__photoOuter {
  }

  &__photo {
    margin-top: 8px;
    margin-left: 8px;
    width: 32px;
    height: 32px;
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: cover;
    border-radius: 100%;
  }

  &__col-picture {
    width: 42px;
  }

  .Issue__assignedPicture {
    margin-top: 8px;
    margin-left: 8px;
    width: 40px;
    height: 40px;
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: cover;
    border-radius: 100%;
  }

  &__topCol.col-xs-12 {
    padding: 0;
  }

  &__iconBlock {
    display: inline-block;
    float: right;
    margin-left: 15px;
    padding-top: 2px;
    //margin: 3px;
  }

  &__icon {
    height: 16px;
    margin-right: 6px;
    margin-bottom: 4px;

    &__hidden {
      opacity: 0;
    }
  }

  &__active {
    padding-bottom: 20px;
    height: auto;
    word-wrap: break-word;

    .IssueMessage__text {
      font-weight: normal;
      height: auto;
    }
  }

  &__urlRow {
    .col {
      margin: 0;
      height: 32px;
      //padding: 0;
    }

    &.row {
      padding-top: 3px;
      border-bottom: 1px solid #218A96;
    }
  }

  &__lastMessage, &__sent {
    font-size: 12px;
  }

  &__sent {
    padding-top: 0;
    padding-right: 0;

    &.col-xs-2 {
      max-width: 170px;
      flex-basis: 170px;
    }

    text-align: right;

    &_active {
      padding-top: 12px;

      &.col-xs-3 {
        max-width: 244px;
        flex-basis: 244px;
      }
    }
  }

  .IssueMessage__sender {
    //word-wrap: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-top: 5px;
    padding-left: 20px;

    .Person__outer {
      display: inline;
    }

    .Person {
      display: inline;
    }

    &__active {
      color: $blue-dark;
      padding-top: 12px;
      padding-left: 20px;
    }
  }

  &__url {
    a {
      color: #218A96;
    }

    padding-top: 3px;
    padding-left: 5px;
    //border-bottom: 1px solid #218A96;
    &.col-xs-9 {
      max-width: 75%;
      flex-basis: 75%;
    }
  }

  &__tabs {
    &Outer {
      min-height: 30px;
      position: relative;
    }

    position: absolute;
    bottom: 0;
  }

  &__tab {
    color: #218A96;
    //border-bottom: 1px solid #218A96;
    padding: 5px;
    padding-bottom: 1px;
    cursor: pointer;
    float: right;
    margin-bottom: -1px;
    width: 90px;
    text-align: center;

    &__active {
      color: #3a3a3a;
      border-bottom: 2px solid white;
      border-left: 1px solid #218A96;
      border-right: 1px solid #218A96;
      border-top: 1px solid #218A96;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
    }

    &.col-xs-7 {
      max-width: 15%;
      flex-basis: 15%;
    }
  }

  &__text {
    margin: 0;
    padding-top: 5px;
    height: 37px;
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    display: inline;
    //font-weight: bold;
  }

  &__textOuter {
    overflow: hidden;
    max-height: 100%;
  }

  &__buttonRow {
    margin-top: 15px;

    button {
      margin-right: 25px;
      font-size: 14px;
      font-weight: bold;
    }
  }

  .row {
    max-height: 100%;
  }

  .IssueMessage__row {
    border-bottom: 0;
  }

  &__log {
  }

  &__logRecord {
    height: 18px;
    font-weight: 500;
    font-size: 16px;

    img {
      height: 14px;
    }

    &__opr {
      width: 15%;
      float: left;
      box-sizing: border-box;
      padding-right: 4px;
    }

    &__atr {
      width: 14%;
      margin-top: 1px;
      float: left;
      font-weight: 600;
    }

    &__str {
      width: 71%;
      margin-top: 1px;
      float: left;
    }
  }

  &__index {
    position: absolute;
    left: 2px;
    top: 1px;
    font-size: 10px;
  }
}


.IssueMessage.row, .IssueMessage__row {
  //padding-top: 4px;
  border-bottom: 1px solid #cbcbcb;
  position: relative;
}

.NewIssueMessage .IssueMessage__urlRow {
  width: 100%;
}
.NewIssueMessage__secondRow {
  padding-top: 8px;
}

.IssueMessage__notActive  {
  .IssueMessage__logRecord {
    font-size: 14px;
  }
}
















