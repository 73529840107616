.EditableEntity {
  vertical-align: inherit;
  .StringData {
    display: block;
    min-height: 15px;
    min-width: 30px;
  }
  .translationTopicName{
    font-weight: bold;
  }
}
