@import '../../styles/utils/variables';

.MainLayout {
  .HeaderPath {
    top: 41px;
    width: 100px;
    display: flex;
    padding: 0 15px;
    align-items: center;
    background: white;

    &__outer {
      position: relative;

      .row {
        max-width: unset !important;
        margin: unset !important;
      }
    }
  }
}

.HeaderPath {
  z-index: 250;
  position: fixed;
  top: 80px;
  max-width: 550px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: $blue-sea-dark;

  &_hasBack {
    // margin-left: -10px;
  }

  &__back,
  &__uri {
    display: inline-block;
  }
  &__back {
    margin-right: 5px;

    a {
      padding-right: 5px;
      padding-left: 5px;
    }

    img {
      position: relative;
      top: 2px;
      height: 14px;
    }
  }

  &__show {
    white-space: normal;
    overflow: auto;
    max-width: none;
    background-color: white;
  }

  a {
    color: $blue-sea-dark;
    font-weight: bold;
  }

  & .dot {
    display: inline-block;
    vertical-align: middle;
    height: 100%;
    margin-top: -2px;
    margin-left: 5px;
    margin-right: 5px;
  }
}
