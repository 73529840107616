@import '../../styles/utils/variables';

.EditorDialog_inputs {
  .ModalWindow__main {
    width: 800px;
    height: 650px !important;
  }

  .ModalWindow__body {
    padding: 1px 18px;
    width: 800px !important;
    height: 850px;
    min-height: 150px !important;
    overflow-y: visible; // added because gray scrollbar was appearing inside Add Issue window, when description was filled with long text;
    // as a result, Discard button was shifted to bottom
  }

  .ModalWindow__footer {
    padding-top: 5px;
    width: 800px;
  }
}

.InputsDialog {
  text-align: left;

  .ObjectSearch,
  .ObjectSearch__result {
    text-align: left;
  }

  .SimpleTable__headerCell {
    background-color: rgb(114, 137, 163);
    color: $light;
    font-weight: normal;
    padding: 5px;
  }

  .LinkedKeyTable .col-xs-2 {
    width: 100px;
    min-width: 0px;
  }

  .LinkedKeyTable .col-xs-5 {
    padding-left: 0px;
    padding-right: 0px;
  }

  &__label {
    display: block;
    float: left;
    padding-right: 10px;
    line-height: 26px;
    width: 15%;
    font-weight: bold;
    font-size: 16px;

    a {
      font-weight: normal;
    }
  }

  &__block {
    margin-bottom: 5px;
  }

  &__warning {
    margin-left: 125px;
    color: $red;
  }

  &__block_description {
    margin-bottom: 1px;
  }

  &__block_filter {
    margin-bottom: 0px;
  }

  .value_right_filter {
    width: 80%;
    float: left;
    line-height: 26px;

    .TextArea {
      min-height: 60px;
    }
  }

  &__block_text {
    margin-top: 7px;
    margin-bottom: 1px;
  }

  &__block_value {
    margin-top: 1px;
    margin-bottom: 1px;
  }

  .value_right_order {
    width: 15%;
    float: left;
    line-height: 26px;

    input[type='text'],
    textarea {
      width: 100%;
    }
  }

  .KeySettings__divButtons {
    margin-left: 0px;

    .KeySettings__arrowButton1 {
      margin-left: 20px;

      &__Text {
        position: absolute;
        top: 10px;
        left: 10px;
      }
    }

    .KeySettings__arrowButton2 {
      margin-left: 5px;

      &__Text {
        position: absolute;
        top: 10px;
        left: 19px;
      }
    }
  }
  .EditableEntity_string {
    max-width: 100%;
    box-sizing: border-box;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    //height: 31px;
  }
  .EditableEntity .StringData {
    display: inline-block;
  }
}

// does this really need to be defined globally for all EditableEntities in project?
.EditableEntity {
  text-align: left;

  &_color_label,
  &_style_label,
  &_width_label,
  &_font_label {
    display: block;
    float: left;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 3px;
    line-height: 26px;
    width: 25%;
    font-weight: 500;
    font-size: 16px;
  }

  .col-xs-2 {
    padding-top: 1px;
    flex-basis: 30%;
    min-width: 30%;
  }

  .col-xs-12 {
    padding-bottom: 7px;
    padding-right: 10px;
  }

  .col-xs-5 {
    padding-top: 1px;
    flex-basis: 50%;
    min-width: 50%;
  }

  &_color_value_right {
    width: 50%;
    float: left;
    line-height: 26px;

    input {
      width: 60px;
      margin-right: 5px;
    }
  }

  &_style_value_right,
  &_width_value_right,
  &_font_value_right {
    width: 50%;
    float: left;
    line-height: 26px;
  }

  &_style_value_size,
  &_width_value_size {
    margin-left: 7px;
    width: 5%;
    float: left;
    line-height: 26px;
  }
}

.EditorDialog__editing .InputsDialog__icon {
  background-size: 14px 14px !important;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAcCAYAAAByDd+UAAABv0lEQVR4XrWWMU7DQBBFl5X7cIMEiT6+geMTRD5B4pIKp6Am1DRORZkcwZyAzQ02PRI+QjhB+EGDZllhe3ZRRnraavzyHc/YV6fTSUnq9vklxVGBCcgU1wFYYEDz/nB3VD3FQpl0i2PZ0/AJ6jNdYg3EhYuUOHagpUTWE47AI7D4cTNxQiSpcewgsMLkGSX305dIuutNCNk5wT0wEKfC5HtKfwMaR7BF0mWnEIJrHFO+PSwViltQQFB50vRPIf3RObBRUhZvcDw50sYTDkobSh8iXTuiMforFg5Lx8CwVMxKcVWDc0iCN5A6Qz7rG+6Oh3BBgoITdictwY9gGpG0dgSzwcGnWcxjpd4spwka10pWezD3pHR7Rb3Zt5BWUUzJpcxIq//VGExCGhJgAgTuQj6CXLZvuSdBQy5+vONlGZ0HHTJLMTJvLVodIiMKkYzwFrnRgbISMhOwZSZOf4veRstl/DKN3DIboPSlZLRQ5k66moQXkVXeQincOfRrGiXj/6wGc+8atk+Ygw+wksroC20JFkMfUfw+lKdwv87cbyCXtuuJTiJk277VBWqw4YUeI+S3fwYs+LU9CANefZHPFz3s7xO/V/HIAAAAAElFTkSuQmCC');
}
.EditorDialog__editing .EditorDialogItems__button:hover .InputsDialog__icon {
  background-size: 16px 16px !important;
  background-repeat: no-repeat;
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAB/klEQVR4Xr2XMU7jUBRFb6z08Q5AmukxK8BZQcIKSEoqTDE10M4UmAbKhB04K+CzArwApDErwKzAXKQvPekiy/7xjI90myh+9/o9+en/SdM0COHnn4clAAoJdQTjjSopRxWvv84r9CBCOBW1EHOCA//7LfWXQR2VogPrQFgXEgBPVIxudtSKHam7O6AmLbBYCWBOpSasqJzS1i+oSutpB9ScxbChLmmWIxDf+isqhfFBZay3bQkg5sbaHgoOcgEglxCp72DrCEqqhrHxoYKh0R2AY6uHGVWwXqwBoPMdGkLrydeSS4BRQlzCOGOtxAKMEyIH4GBkFmCkEORGuhBbgPAQ6R5dcLInllHADE/FoPBzDGUHI40C069hzCi3RwgH43Dy4/e9QxiJN4cul4At+QTPV4AGQwkP0cgeGMyMWiGAfx1gyxFk/UdgTPngBD3xO9xRR2K+Rn9iGM/RCOZKCqOKRjBXljBcNIK5zv8ARhGNYm5cw3hknbqrA9sB5trJDMAJjLzrUEpzLGCUA8wTALfy9qUGUPMzOabNB5g7uVdk8ExbTsTfzO1cH9Z2efOaOvW1LIBQUBdUEmiu2+5aZl77Y37Z514Q+xB3Yq7/SWAc+tBL+dS8OeZqrgEwYCsq2tF1Wxen/9HcUTf+ICNogPBd/kK961636zl2fa/nn6tLEfUS8kxmAAAAAElFTkSuQmCC');
}

.EditorDialog__viewing .InputsDialog__icon {
  background-size: 14px 14px !important;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAcCAYAAAByDd+UAAABu0lEQVR4XrWW0W3CMBCGi5V3ugFsAA99TzIBzQSQDcIEpRMQJiAjpBPU7+2DmaAZASagf6Sr7mopydkSlj4dL+dPd7aPzO73+5Nm1fvvNUIFliAVCRfggAVtdXy5ju3DQp30jLAbSbiBumdIbIB6YZMSoQEdVeQ84Ry8AYeOZOoKUUmN0EDglJWnVLlffYk9mlEhZA3CltqTsVQlXiIcweuQ1HiyZ4SVaI+ly6JteQeK/qcQnnkPT0gHnQMXJWXxCeFdSFtPOCltqfoQ6UGIFsivWDgtXQDLUjV7ue3kOyTBJ1iLR55NPO6hS9ivgiscrrQEf4JVRKW1EGRGcRYOIY+Ves9qPTtWXwdNIrV1wwJ9e9ERS/P3ltAoCl9caciZzqml0SzAMiQhATZAIAfyFeR8RiquCRJy9fWOl6UULybkLcXIvLHoTIiMKALbKAe5NYGyEjIb9HfF+R1yWxMoo3OMmjKnPphHybDHAWEjqqtJ+BBZ5Q2UQr5Df62iZHxmNdh4e7gxYQ5+wF4roy+0HdjqP6L0VcivM/kNJOmGbnQSITuPjS5q6YkHeoyQ//1T4MC/6UFY8OGLfH4BfczwXHhNYOIAAAAASUVORK5CYII=');
}
.EditorDialog__viewing .EditorDialogItems__button:hover .InputsDialog__icon {
  background-size: 16px 16px !important;
  background-repeat: no-repeat;
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAABw0lEQVR4Xr2X4W3CMBSEi8X/ZgPYgHQCwgSkEwATNJmgMAFhAugGYYKGCcoI6QQ1E9D34xDWqcR9pvVJp8j+4fv8zLNF73w+PyiVw6l45Mx/io/iRlyLW7FXagAEv4sTT8BBvATQTRltOnY5EVsPwBigNWDVAOkvIDLHc3H1Q+mnmEs1RzAXb8UlFlUJQK/4XnQSF+JddwUQjsEaY60aVKhw5h6xmdQHcKTz3QIiRBvxE9YDBH4THoDJ3RC83lUDcUUAUSBKZzzDUQAgDkRFd0JBAFEgVlSFBABBEFlId9A9kRvFGT7TXI1z1GrvDDKjpF9QXzdaCHobhn1MaHRCuAuRoUpe0VGO+3g0wqWHaBSvoQJC0Rn/AbBDX3vF3dMX9xRBCUo4ovCFao2rDiZyOFegNTHDodxtSRM5PBMPnHFtYoVDS2fwJrY+gN0fhhd051Row87wKb0HoeGpeE27PzIAh8/4RQwNp9uvFRfuPcCa3wi3IWWnnVu8qrbrJqwRek94hl1zeHlZu6sCFqEv4k1HeEJP8RDj/NJqvCaHMwBDrBTtyeKKLmgjXgAoPByAK3xJDKA/3w/xF9/rzt/zPcZefQOILYviSmR1tgAAAABJRU5ErkJggg==');
}
