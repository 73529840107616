@import '../../styles/utils/variables';

.EditorVersionButton {
  display: inline-block;
  height: 22px;
  font-size: 16px;
  padding: 2px 5px;
  color: #218a96;
  cursor: pointer;

  &:hover {
    font-weight: bold;
    color: $blue-dark;
  }

  &__icon {
    position: relative;
    top: 3px;
    margin-right: 4px;
    width: 18px;
    height: 18px;
    //background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: inline-block;
  }

  &__text {
    display: inline-block;
    position: relative;
    top: 0px;
  }

  &.EditorVersionButton__Approve {
    .EditorVersionButton__icon {
      background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACIAAAAaCAYAAADSbo4CAAAA6ElEQVR4Xs3WvQ3CMBAFYGOlhyHok42CSypoMgVNOsp4lIzAAOxirjj0EFfYVvQwTzq5O32yzj+7lJL7dY63+0mW4Tmdr07jGyEWqYuAIiAtEMj4xnStEF8Y55sikNX/ASLI0EZPRgwlCAwrD7GWIADhIfY5BCBNEUj30eCgDWZo6Qi7I4ropRaddDbCQvR262EChowARBEjDAbDRABij5jFEBGAqDbkMEQEZqQUQ0Fo8DEqfBekHhlEFERwlfEwle0MAQFIDYaAAKQGQ0AAksGwEYBswADBguQxQJAhFkNCAFKJmS1ie15WDqg/tS9JgAAAAABJRU5ErkJggg==');
      background-size: 17px 13px;
    }

    &:hover {
      .EditorVersionButton__icon {
        background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAeCAYAAABe3VzdAAABFUlEQVR4Xs3WwQnCMBgF4Bq86xAO4Ah1At2genQA79699ObROInZwA7gwRF0gvoCyhP+Q/kpSf7A4xEo5eMvCZ30fV9ZWIvTeY7yyPFx2Hc/gDOCW6KeyBoJcU+gDVxAZl8Amkh+4tI4ud5I7UziOMnWmcMxHbJxhnErnOaXs4zjKTaKI9AgjkCjOALlC2srOAKJ86hbbAs4AolrvtuGyDI4AiUOIbIQjkCJk8gyOE4wSIBEZsYRiIc9eqdA5sLxkGiQGXEEapHJcYz8YQVgi7oMQK9ImxAnJ6idJHJPjCNQh2QS4whUIHPiCFQgc+IIVCBz4ghUIHPiCByJDAqcOtNYGiTuyervnvSACXSaCeon2RKXLh8olt3SEaDfSwAAAABJRU5ErkJggg==');
        background-size: 20px 15px;
      }
    }
  }

  &.EditorVersionButton__Approve.EditorVersionButton__disabled {
    .EditorVersionButton__icon {
      background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACIAAAAaCAYAAADSbo4CAAAA4klEQVR4Xs3Wyw3DIBBFUYKyTzqxO3JcQSIKQe7ALsUlpCOHxUR3wQKQ9UKeNGI3OkLD53Ich/t1YoyPtIwhhJez+E6INdUzgTYgPRBk+mKuHRAEjPNdEWT3f4CYQwibFyPGGgTDqkPsNQggOsSthADSFUGuNIh3a7CglSPyHTHEkGq1SVcjcojdbgMmMGIEEENMGDKMEgGkcMTAaBBATDuXMEIEM1KLkSAsfIwq34VU7wJiS4jZNcZjqtsZAQJIC0aAANKCESCAFDBqBJATGBAqSBkDQgzJMSIEkEbMkiPO5wNYtKcT+DBdJQAAAABJRU5ErkJggg==');
      background-size: 17px 13px;
    }
  }

  &.EditorVersionButton__Create_draft {
    .EditorVersionButton__icon {
      background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAZCAYAAAArK+5dAAAA2ElEQVR4XrXPsa3CQBCE4WNFbtMB0ivAdAAd4A6gBHeASV8ECSlQgXEHdgfQybkDRrBogsu83pM+aaN/dLO//0uY4N1gBwOcVIQgE8YDZHCADnIOWOPpK34jMmk8HanEJU6VuMQpE5c43cUzDnvxjEMQzzgHPOJKfOIkPnESnziJT5zEJ54O1BpvYbDH04ENnKGEJfS2eDqwhofeUQdbW5wDK71z4PuGhvFxDmz0bhj4iNCNi9OcP/i4QlQlbIFx0wA1evRwhBqCZaCAFzxVpzefcWABEVzeG7l+Ppy8sRb/AAAAAElFTkSuQmCC');
      background-size: 12px 12.5px;
    }

    &:hover {
      .EditorVersionButton__icon {
        background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACIAAAAiCAYAAAA6RwvCAAABN0lEQVR4Xs3XMU7DQBSEYRjROzcAJPr4BuxR0lLhhhqoaaCiRNzAN8C5gdNT7BHwCcys9NBE3lDuPv/SyI1lf9pEkXI+z/NZyW5e3je8DNyWm7jXtO+Hu5/j+1ARkWq4Ry4SGDJIBYQS6IuYnSA1EXkffycDN4TqExyuCH1MHVwRqoMrQjVwRagD1oDgAtaASL+yWAMiAeCOsOCLUPBFKPgiFHwRCr4IBV+Egi9CwReh4ItQcEcIYgnxzH3WQSjYaVwZ4o0PeuJ2BFxz+woIQVjQqTBGTOSCTqcEIoe0dh2XUjudfTlEDpm4/x6YMFMBRAa55Rpu5PelPXEqkZe+CMLC4sWX3GCYZbEIwrrgwok/PL1hNlxr99yXQQjSckonEw1lZYjCEDUebbDviSoA2XKHxUt5rdsvW3zMqqMyo1wAAAAASUVORK5CYII=');
        background-size: 17px 17px;
      }
    }
  }

  &.EditorVersionButton__Create_draft.EditorVersionButton__disabled {
    .EditorVersionButton__icon {
      background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACIAAAAaCAYAAADSbo4CAAAA4klEQVR4Xs3Wyw3DIBBFUYKyTzqxO3JcQSIKQe7ALsUlpCOHxUR3wQKQ9UKeNGI3OkLD53Ich/t1YoyPtIwhhJez+E6INdUzgTYgPRBk+mKuHRAEjPNdEWT3f4CYQwibFyPGGgTDqkPsNQggOsSthADSFUGuNIh3a7CglSPyHTHEkGq1SVcjcojdbgMmMGIEEENMGDKMEgGkcMTAaBBATDuXMEIEM1KLkSAsfIwq34VU7wJiS4jZNcZjqtsZAQJIC0aAANKCESCAFDBqBJATGBAqSBkDQgzJMSIEkEbMkiPO5wNYtKcT+DBdJQAAAABJRU5ErkJggg==');
      background-size: 17px 13px;
    }
  }

  &.EditorVersionButton__Finalize {
    .EditorVersionButton__icon {
      background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABsAAAAaCAYAAABGiCfwAAAAh0lEQVR4Xu2VUQqAIBBER/EmdbkO0CXqvy7XNfp1i1gYCr8UJXDfn8zHMs4s64ZlE9wc8+RQmQBlXPeIupwBhM7SbynUY0AC/VY80HmmTs2jDe2HvTL7WBcQtjVDV6SVs44yE9a3OCPqrH60zGzPflh9VpQktAyd6KV+kPrHkwH7fvbMhtmwC3IHRX/hxmhrAAAAAElFTkSuQmCC');
      background-size: 13.5px 13px;
    }

    &:hover {
      .EditorVersionButton__icon {
        background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB8AAAAeCAYAAADU8sWcAAAAjElEQVR4Xu2WsQ2AMBADP1E6xoDlGIAloIflWIM2DyJCFko6XkmBr3u5sGLnpXf9vKpc7NPopDLhGYZli1KXIwjAy8uzGutdkBKphjQgGSMdeGlDe/NX53dUiAkdYRu+66ggNo+dnWvqwrTjXAf6x9i554wdcYBcM9XTJZPQ+scEPoTnnltBc5rTnOYn54NN2S3vDccAAAAASUVORK5CYII=');
        background-size: 15.5px 15px;
      }
    }
  }

  &.EditorVersionButton__Finalize.EditorVersionButton__disabled {
    .EditorVersionButton__icon {
      background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABsAAAAaCAYAAABGiCfwAAAACXBIWXMAAAsSAAALEgHS3X78AAAAIGNIUk0AAHolAACAgwAA+f8AAIDoAABSCAABFVgAADqXAAAXb9daH5AAAABcSURBVHjaYuzt7f3PAAGMDDQGLEjsfzS26yuyZeg+Q+f/p1D+HxMDHcHwtYyFhnGELv9/NM6oHmf/R/PZaD4bzWd0rzzRw5xSeZTKk2k0zkYtGzmWAQAAAP//AwBt8hrGWsLTEgAAAABJRU5ErkJggg==');
      background-size: 13.5px 13px;
    }
  }
}
