@import '../../styles/utils/variables';

.EditorDialog_element {
  .ModalWindow__main {
    width: 800px;
  }

  .ModalWindow__body {
    padding: 1px 18px;
    width: 800px !important;
    height: 850px;
    min-height: 150px !important;
    overflow-y: visible; // added because gray scrollbar was appearing inside Add Issue window, when description was filled with long text;
    // as a result, Discard button was shifted to bottom
  }

  .ModalWindow__footer {
    padding-top: 5px;
    width: 800px;
  }

  .SettingsDialog .SimpleTable__headerCell {
    background-color: rgb(146, 75, 158);
  }
}

.ElementDialog {
  .DataEditor__block {
    &_name,
    &_description,
    &_visibility,
    &_text,
    &_align,
    &_control {
      margin-top: 5px;
      margin-bottom: 5px;

      &__value_right {
        width: calc(80% + 20px);
        float: left;
        line-height: 26px;
      }
    }

    &_order {
      margin-top: 0px;
      margin-bottom: 1px;

      &__value_right {
        width: calc(10% + 20px);
        float: left;
        line-height: 26px;
      }
    }

    &__label {
      display: block;
      float: left;
      padding-right: 10px;
      line-height: 26px;
      width: 16%;
      font-weight: bold;
      font-size: 16px;
    }
  }

  &__label {
    display: block;
    float: left;
    padding-right: 10px;
    line-height: 26px;
    width: 15%;
    font-weight: bold;
    font-size: 16px;

    a {
      font-weight: normal;
    }
  }

  &__block {
    margin-bottom: 5px;
  }

  &__block_description {
    margin-bottom: 1px;
  }

  &__block_text {
    margin-top: 7px;
    margin-bottom: 1px;
  }

  &__block_value {
    margin-top: 1px;
    margin-bottom: 1px;
  }

  .value_right_order {
    width: 15%;
    float: left;
    line-height: 26px;

    input[type='text'],
    textarea {
      width: 100%;
    }
  }

  .Input_radio:not(checked) + label {
    position: relative;
    padding: 3px 10px 1px 20px;
  }

  &__image {
    max-height: 400px;
    max-width: 400px;
  }

  &__element {
    background-color: white;
    width: 20px;
    height: 20px;
    border: 2px solid black;
  }

  &__innerRow {
    .col-xs-6 {
      padding: 0;
    }
  }

  &__advancedOptions {
    margin-bottom: 20px;
    padding: 10px 0;
    border-top: 1px solid $gray-light;
    border-bottom: 1px solid $gray-light;

    .col-xs-6 {
      text-align: center;
      flex-basis: 33%;
      max-width: 33%;

      .ObjectAdvancedSettings {
        float: none;
        width: 90%;
      }
    }
  }

  &__DropZone {
    height: 1px;
  }

  &__loadIcon {
    color: #238a96;
    font-weight: bold;
    font-size: 14px;
    cursor: pointer;

    &Dark {
      color: #6cd2dc;
    }

    img {
      height: 14px;
      margin-right: 3px;
    }
  }

  .TypedTable__bottom {
    text-align: left;
  }
}
