@import '../../styles/utils/variables';

.EditorDialog_settings {
  .ModalWindow__main {
    width: 800px;
    height: 650px !important;
  }

  .ModalWindow__body {
    padding: 1px 18px;
    width: 800px !important;
    height: 850px;
    min-height: 150px !important;
    overflow-y: visible; // added because gray scrollbar was appearing inside Add Issue window, when description was filled with long text;
    // as a result, Discard button was shifted to bottom
  }

  .ModalWindow__windowHead {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .ModalWindow__footer {
    padding-top: 5px;
    width: 800px;
  }

  &.EditorDialog_fullscreen {
    .ModalWindow__main {
      width: 100vw !important;
    }

    .ModalWindow__body {
      width: 100vw !important;
    }

    .ModalWindow__footer {
      width: 100vw;
    }
  }
}

.SettingsDialog {
  text-align: left;

  .ObjectSearch,
  .ObjectSearch__result {
    text-align: left;
  }

  .SimpleTable__headerCell {
    background-color: $blue-deep;
    color: $light;
    font-weight: normal;
    padding: 5px;
  }

  .LinkedKeyTable .col-xs-2 {
    width: 100px;
    min-width: 0px;
  }

  .LinkedKeyTable .col-xs-5 {
    padding-left: 0px;
    padding-right: 0px;
  }

  &__label {
    display: block;
    float: left;
    padding-right: 10px;
    line-height: 26px;
    width: 15%;
    font-weight: bold;
    font-size: 16px;

    a {
      font-weight: normal;
    }
  }

  &__block {
    margin-bottom: 5px;
  }

  &__warning {
    margin-left: 125px;
    color: $red;
  }

  &__block_description {
    margin-bottom: 1px;
  }

  &__block_filter {
    margin-bottom: 0px;
  }

  .value_right_filter {
    width: 80%;
    float: left;
    line-height: 26px;

    .TextArea {
      min-height: 60px;
    }
  }

  &__block_text {
    margin-top: 7px;
    margin-bottom: 1px;
  }

  &__block_value {
    margin-top: 1px;
    margin-bottom: 1px;
  }

  .value_right_order {
    width: 15%;
    float: left;
    line-height: 26px;

    input[type='text'],
    textarea {
      width: 100%;
    }
  }

  .KeySettings__divButtons {
    margin-left: 0px;

    .KeySettings__arrowButton1 {
      margin-left: 20px;

      &__Text {
        position: absolute;
        top: 10px;
        left: 10px;
      }
    }

    .KeySettings__arrowButton2 {
      margin-left: 5px;

      &__Text {
        position: absolute;
        top: 10px;
        left: 19px;
      }
    }

    &__advancedOptions {
      margin-bottom: 20px;
      padding: 40px 0;
      border-top: 1px solid $gray-light;
      border-bottom: 1px solid $gray-light;

      .col-xs-6 {
        text-align: center;
        flex-basis: 33%;
        max-width: 33%;

        .ObjectAdvancedSettings {
          float: none;
          width: 90%;
        }
      }
    }
  }

  .EditableEntity_string {
    max-width: 100%;
    box-sizing: border-box;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    //height: 31px;
  }

  .EditableEntity .StringData {
    display: inline-block;
  }

  &__buttonIcon {
    background-size: 21px 18px;
    background-repeat: no-repeat;
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACoAAAAkCAYAAAD/yagrAAACt0lEQVR4XrWYMbLaMBCGHQ89vsFzkd6+QbgBzglsylTPr0gNfYqYKuXDJ3i+QfxuAH0K3g3MCcgq8xca4V9GG7wzOxoYI31aafdf/Ol6vUah9vnHr0SGWrwSfyKPteLNn+/fjnfMl8pQiCfy/G4MYKEBhC9HAC4GTvwggOeA+bbW5iI1KCALA+ABrAXuoJ4Pc+hAsWsArgnAXnwnkEMAZCXDqw3C5gBDvpiAXMnQkSga2/Ao8vs4AvkhkA1h2MmwXXggzQ+fPQAtIENtNcKzY5HE/Y1iDSRMIFWW8u/4GjHd9bRV0eNsi3vrHnlGkwn3MrsDoJRnOzn+7kGwr4DtEeHyJutxwSvxhBw5s4PZGCvqitP6AnftsnAyO9SWWPgYIBi5Yp3eRPQ3gfiAagwGhqhRS8oKs0YZkGYB2SpHQAvrSHtzH3F/lm4NhcqkPmgkS6mA3AtHHwuMmeDdpQSk+3mFSH+1IA3cm/hPgam9ShRgWOdFOGo76wtEK7NkMSLwqSWtjR0lwA7YxD2QLaTzbCW18bPb1Jg2z+1kjm7J8UkbATAR7wBqoEuV/LqgGoO89Z5u6l/ZAuzROq0L7n8fsl4cKQ0QBStbSMDcUrqTtQFAzg0Kw4IbBgtBSARsACwRhxmOXpHVJwCSflURUa0hKfYEICOd1sygHLb2/OdZI/vnBuWZL96JJ4CtAMu6rWZ+UF6e1hgjC/ZEAJ4hADOD8hqaOUeLcsT7zplB0YAAkh0tMtwH22Cz84AiEm9UjZDdFmyF76kgaED1tRKKQzsuDotEVIBqCroLSWDH7AmRTf4bFEkSAElhNx5B6NWgpEXTSiPUi8NOCUI8GySHfaGCwGH5mxI15DRsM6FeeUhEU8/7phyQSuNSi+owhIBCBm8giaKoYd9HTup8FyhRFkA+3ApEtp2qHn8BkBaA84/NWx4AAAAASUVORK5CYII=');
    &:hover {
    }
  }
}
.EditorDialog__viewing
  .FieldCreator__advancedOptions
  .EditorDialogItems__button:hover,
.EditorDialog_activity.EditorDialog__viewing .EditorDialogItems__button:hover {
  .SettingsDialog__buttonIcon {
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACoAAAAkCAYAAAD/yagrAAACZ0lEQVR4XsWY0XHaQBBALx7/QwdWB6iDKBWgDixXYKUCp4McFfioIOkgTgfQAXQgVUAUz/u40e2stDoY3swOH9Ic75a9XYkvl8vFLWA9RDtEM8STk9kP4Yc4uGmKIWrW/eEEHpcIEiuX0iMXhjgZ1nuLNudyRWsEREHkw+L1WCNHdI3A1sns+Lk6NxNK5t3F6GuUU6LVEL/ZtcQLm7BQCJJnSgaSDbxpon6IV0Vgj6SVSpSRKalf92CWhGWSZDSl0L5DE61m1dmVIGuNkOWNJlpxwxTPnNyrQN1+IBiQT059wY7W1OVcAhs7mGtU5isxpn9MTraNlVF0zQGx8vFf9I+TOdOAO2SYRsKYnI9fmBD/oIyt+jPT1A2y/aiHNtG9rdNpqGsru8+MskCR1EZad4eoTFo+pVbmlUmkIw8BHx+mmsxtol1AIltE9eZHWfpJqYSZkntO+QmPgjgRgOioDg9ka4oWSanNdNEalWH8JoJSH+3YHZI6iPRK2yqjjB5HT1rfDJMN0eUclIa/opTKKKtHJCuuwS1FAZkXRTZQy5SW2HNvLwpBkd2wGWSRzBLNl90pssHBXUWhVYbHFtm7iZac/nV0wvfK05a/h2hJ/W35BNqRzCvX7aKZkiu5DmlHMu/I3ly0dkgqP22nyXJfeTNRMvFLebePs9pxf68NhGxR0xMQE0d54pJlOYgG0SzJYyIpyco8RQMhWzRYJWVZfXpliSL5PCHZXWnUBqtovqQu+10ZCMEq6g2SVvzE9Cot/+YVyqtD4/JpHGJC9+gMGWUMWiXtsn+FcjoZRKPJYpW0T7k9oXaPf4VRtyQjUekaAAAAAElFTkSuQmCC');
  }
}

// does this really need to be defined globally for all EditableEntities in project?
.EditableEntity {
  text-align: left;

  &_color_label,
  &_style_label,
  &_width_label,
  &_font_label {
    display: block;
    float: left;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 3px;
    line-height: 26px;
    width: 25%;
    font-weight: 500;
    font-size: 16px;
  }

  .col-xs-2 {
    padding-top: 1px;
    flex-basis: 30%;
    min-width: 30%;
  }

  .col-xs-12 {
    padding-bottom: 7px;
    padding-right: 10px;
  }

  .col-xs-5 {
    padding-top: 1px;
    flex-basis: 50%;
    min-width: 50%;
  }

  &_color_value_right {
    width: 50%;
    float: left;
    line-height: 26px;

    input {
      width: 60px;
      margin-right: 5px;
    }
  }

  &_style_value_right,
  &_width_value_right,
  &_font_value_right {
    width: 50%;
    float: left;
    line-height: 26px;
  }

  &_style_value_size,
  &_width_value_size {
    margin-left: 7px;
    width: 5%;
    float: left;
    line-height: 26px;
  }
}
