@import '../../styles/utils/variables';

.Badge {
  box-sizing: border-box;
  margin-right: 4px;
  padding: 4px 8px;
  background-color: $gray;
  border-radius: 3px;
  color: #212121;

  &_green {
    background-color: $green;
    color: #fff;
  }
  &_green_outline {
    background-color: $green-light;
    color: #fff;
  }

  &_red {
    background-color: $red;
    color: #fff;
  }

  &_blue {
    background-color: $blue-dark;
    color: #fff;
  }
  &_yellow {
    background-color: $yellow;
  }

  &_gray {
    &_outline {
      padding-top: 2px;
      padding-bottom: 2px;
      background-color: $light;
      border: 1px solid $gray;
    }
  }

  &_clear {
    border: 0;
    padding: 0 4px;
    background-color: transparent;
  }

  &__cross {
    display: inline-block;
    margin-left: 8px;
    padding: 0 3px 2px 3px;
    background-color: #fff;
    border-radius: 2px;
    height: 12px;
    line-height: 12px;
  }

  &_add {
    width: 14px;
    text-align: center;
    font-size: 100%;
  }
}
