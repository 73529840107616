@import '../../styles/utils/variables';

.ObjectSelector {
  &_closed {
    .TextInput {
      margin-left: 0px;
      width: 88%;
      height: 26px;
      float: inherit;
    }
    .ObjectPathEditor__results {
      width: 85%;
    }
    .ObjectItemSearch__results {
      width: 85%;
    }
  }
  &_opened {
    .TextInput {
      margin-left: 0px;
      width: 100%;
      height: 26px;
    }
  }

  &_collapsable {
    position: relative;
  }
  &_title {
    position: relative;
    padding-bottom: 1px;
    border-bottom: 2px solid $blue;
    color: $blue-dark;
    font-size: 16px;
  }
  &_collapse {
    cursor: pointer;
    box-sizing: border-box;
    position: absolute;
    right: 9px;
    bottom: -32px;
    width: 70px;
    height: 22px;
    line-height: 22px;
    text-align: center;

    &_opened {
      transform: rotateZ(180deg);
      background-color: $blue;
      right: 5%;
      bottom: 0;
      margin-right: -33px;
    }

    &_closed {
      transform: rotateZ(0deg);
      background-color: $blue;
      right: 5%;
      bottom: 0;
      margin-right: -33px;
    }
  }
}
