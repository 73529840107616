.MuiListItemIcon-root {
  min-width: 24px !important;
}

.Side {
  z-index: 100;
  position: fixed;
  top: 0;
  bottom: 0;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  width: 56px;
  height: 100vh;

  box-sizing: border-box;
  background: #6e49cb;

  &__logo {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 55px;
    height: 55px;
  }

  &__user-photo {
    display: block;
    margin: 7px;
    width: 42px;
    height: 42px;
    background-color: #f5f6f9;
    border-radius: 100%;
    overflow: hidden;
    position: relative;
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: cover;
  }
}
