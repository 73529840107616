@import '../../styles/utils/variables';

.JSONTreeCopy {
  &__jsonContainer {
    position: relative;
    min-height: 30px;
  }
  &__copyJsonButton {
    position: absolute;
    right: 10px;
    top: 4px;
    border: 0;
    z-index: 100;
  }

  &__copyJsonTextarea {
    //opacity: 0;
    width: 0;
    height: 0;
    border: 0;
    resize: none;
    top: -5px;
    outline: none !important;
    position: absolute;
    &:focus {
      outline: none !important;
    }
    //display: none;
  }
}
