@import '../../styles/utils/variables';

.ApplicationView {
  //margin-top: 40px;
  overflow-x: hidden;
  width: 1200px;
  margin: 0 auto;

  padding-top: 40px;

  .SimpleTable__headerCell {
    background-color: $yellow;
    color: $gray-dark;
    font-weight: normal;
    padding: 5px;
  }
}

.ApplicationView__root .SelectView__upper {
  background-color: $yellow-dark;
  text-align: center;
  color: white;
}
