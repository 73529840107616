@import '../utils/variables';

.MajorObjectBlock {
  position: relative;
  box-sizing: border-box;
  margin-bottom: 10px;
  width: 100%;
  color: $gray-dark;
  line-height: 32px;

  &__linked {
    width: 66px;
    height: 34px;
    border-right: 1px solid $violet;

    &_one {
      width: 33px;
    }
  }

  &__rating {
    width: 34px;
  }

  &__info {
    flex-grow: 0;
  }

  &__icon {
    flex-grow: 0;
    display: inline-block;
    margin: 4px;
    width: 24px;
    max-height: 24px;
  }

  .ObjectRating__star {
    display: inline-block;
    margin: 6px 4px;
  }

  &__label {
    padding: 0 5px;
    vertical-align: middle;
    cursor: pointer;
  }

  &__more {
    position: relative;
    background-color: $violet;
    color: $light;
    z-index: 10;

    span {
      position: absolute;
      top: 25%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 42px;
    }
  }

  & .ObjectPicture{
    margin-top: 2px !important;
    width: 32px !important;
    height: 32px !important;
    line-height: 26px !important;
  }

  &__childCount {
    width: 20px;
    height: 20px;
    right: -10px;
    top: -10px;
    position: absolute;
    background-color: #92C850;
    border-radius: 100%;
    border: 1px solid white;
    div {
      color: white;
      //padding-left: 5px;
      text-align: center;
      margin-top: -5px;

    }
  }

  .row {
    //height: 35px;       // why was this needed? I removed it to fix SystemsView, some blocks are higher
  }

  &__description {
    &Right {
      margin-left: 2px;
      margin-bottom: 4px;
      padding: 4px;
      border: 1px solid $gray;
      display: inline-block;
      width: 370px;
      vertical-align: top;
      box-sizing: border-box;
    }
    &Bottom {
      margin-bottom: 4px;
      padding: 4px;
      border: 1px solid $gray;
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
    }

  }
}

.PublicationBlock {
  height: 40px;
  border: 3px solid $violet;
}

.DatasetBlock, .InterfaceBlock, .PipelineBlock, .ViewBlock {
  height: 40px;
  border: 3px solid $blue-deep;
}

.Table .MajorObjectBlock {
  margin-bottom: 0;
}

.MajorObjectBlock .MajorObjectLink {
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: calc( 100% - 50px );
  white-space: nowrap;
}

.TypedTable .MajorObjectBlock {
  min-height: 37px;
}

