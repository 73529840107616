@import '../utils/variables';

.Matrix {
  width: 100%;
  position: relative;
  &__label {
    margin: 0 0 0 5px;
    flex: 1;
    font-size: 16px;
    line-height: 30px;
  }

  &__controls {
    flex: 1;
    height: 30px;
    text-align: right;

    &.small {
      flex: 0.2;
    }


    & > * {
      display: inline-block;
      margin-left: 20px;
      vertical-align: middle;
      line-height: 20px;
    }
  }

  &__control-buttons {
    & > * {
      margin-left: 20px;
    }
  }

  &__item {
    width: 100%;
    display:flex;
    position: relative;
  }

  &__footer {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    text-align: center;
  }

  &__more {
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    padding: 0 15px;
    height: 21px;
    background-color: $green;
    border: 0;
  }
  &__body {
    display:flex;
    flex-wrap: wrap;
  }

  .loader_center {
    position: relative;
    margin: 10px 0;
    width: 100%;
    height: 34px;

    .Loader {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}


.MatrixSystem {
  margin-bottom: 40px;
  //min-width: 1200px;
  border: 3px solid $green;

  & > .Matrix__header {
    box-sizing: border-box;
    padding: 5px;
    height: 45px;
    background-color: $green;
    color: $light;
    line-height: 30px;

    .InfoIcon {
      height: 30px;
    }
  }

  & > .Matrix__body {
    padding: 20px 15px 0;
  }
}

.MatrixApplication {
  margin-bottom: 26px;
  //width: 100%;
  width: 368px;
  border: 3px solid $yellow;

  & > .Matrix__header {
    box-sizing: border-box;
    padding: 5px;
    height: 40px;
    background-color: $yellow;
    line-height: 30px;
    text-align: center;
  }

  & > .Matrix__body {
    padding: 12px 9px 0 9px;
  }

  .Matrix__more {
    background-color: $yellow;
  }
}

.Matrix__more img {
  margin-top: 4px;
}
