@import '../../styles/utils/variables';

.EditorDialog_issue {
  .ModalWindow__main {
    width: 800px;
    height: 100%;
  }

  .ModalWindow__body {
    padding: 1px 18px;
    width: 800px;
    height: 850px;
    min-height: 150px;
    overflow-y: visible; // added because gray scrollbar was appearing inside Add Issue window, when description was filled with long text;
    // as a result, Discard button was shifted to bottom
  }

  .ModalWindow__footer {
    padding-top: 5px;
    width: 800px;
  }

  .scrollarea {
    width: 950px;
  }
}

.IssueDialog {
  .scrollarea {
    width: 950px;
  }

  &__label {
    padding-top: 4px;
    font-weight: bold;
    line-height: 22px;
    font-size: 16px;
  }

  &__name {
    float: left;
    width: 85%;
    line-height: 26px;

    input[type='text'],
    textarea {
      margin-left: 0px;
      width: 99%;
    }
  }

  &__value {
    float: left;
    width: 85%;
    line-height: 26px;

    input[type='text'],
    textarea {
      width: 99%;
    }

    &__multiLine {
      margin-top: 1px;
    }

    .TextArea {
      margin-top: 2px;
      width: 99%;
      min-height: 80px;
      overflow-x: hidden;
    }
  }

  &__comment {
    font-size: 14px;
  }

  .StringData {
    min-width: 10px;
    width: 100%;
    padding-left: 5px;
  }

  .CheckBox {
    width: 18px;
    height: 18px;
    padding: 5px 0px 0 5px;
  }

  .CheckBox:not(checked) + label {
    padding-left: 10px;
    padding-top: 5px;
  }

  .CheckBox:not(checked) + label:before,
  .CheckBox:not(checked) + label:after {
    width: 18px;
    height: 18px;
    top: 0px;
  }

  .CheckBox:not(checked) + label:after {
    top: 0px;
  }

  &__tableTasks {
    .NewIssueSettingsBlock__right {
      margin-left: 75px;
    }

    &_edit {
      .Table__floatingButtons {
        min-width: 90px;
      }

      .Table__container {
        padding-left: 54px;
        padding-right: 5px;
      }
    }

    &_create {
      .Table__container {
        padding-left: 84px;
        padding-right: 5px;
      }
    }

    td {
      padding-top: 3px;
      padding-bottom: 2px;
      padding-right: 0;
    }

    th {
      display: none;
    }

    .Table__row {
      height: auto;
      overflow: hidden;
    }

    .TextInput {
      display: block;
      float: left;
      margin-left: 5px;
      width: 99%;
      height: 26px;
    }
  }

  &__columnLabel {
    margin-right: 100px;
    padding-top: 4px;
    line-height: 22px;
    font-weight: bold;
    font-size: 16px;

    &Type {
      padding-left: 9px;
    }

    &Priority {
      padding-left: 0px;
    }

    &Status {
      padding-left: 22px;
    }

    &Resolution {
      padding-left: 9px;
    }
  }

  &__collapse {
    cursor: pointer;
    box-sizing: border-box;
    position: absolute;
    right: 9px;
    bottom: -32px;
    width: 70px;
    height: 22px;
    line-height: 22px;
    text-align: center;
    background-color: $blue-dark;
    transform: rotateZ(180deg);

    &_title {
      position: relative;
      padding-bottom: 3px;
      border-bottom: 2px solid $blue;
      color: $blue-dark;
      font-size: 16px;
    }

    &_opened {
      transform: rotateZ(180deg);
      background-color: $blue;
      right: 5%;
      bottom: 0;
      margin-right: -38px;
    }

    &_closed {
      transform: rotateZ(0deg);
      background-color: $blue;
      right: 5%;
      bottom: 0;
      margin-right: -38px;
    }
  }

  .NewIssueSettingsBlock {
    overflow-y: auto;
    overflow-x: hidden;
    padding-bottom: 0px;

    &_compact {
      padding-bottom: 0px;
    }

    &__left {
      float: left;
      width: 15%;

      &__name {
        margin-top: 4px;
      }
    }

    &__borderBlue {
      padding-bottom: 3px;
      border-bottom: 2px solid $blue;
    }

    &__borderGray {
      padding-top: 3px;
      border-bottom: 1px solid $gray;
    }

    &__objectSearch {
      width: 85%;
      float: left;

      .ObjectSearchMultistep {
        margin-top: 0px;

        &__labelItem {
          margin-top: 5px;
        }

        &__link:last-of-type {
          margin-bottom: 0px;
        }
      }
    }

    &__userSearch {
      width: 85%;
      float: left;
      margin-top: 4px;
    }

    &__track {
      margin-top: 2px;
      height: 24px;
      display: block;

      .check {
        margin-left: 8px;
      }
    }

    &__setting {
      display: inline-block;
      margin-top: 2px;
      margin-right: 10px;
      margin-bottom: 3px;
      cursor: pointer;

      .check {
        box-sizing: border-box;
        display: inline-block;
        margin-right: 5px;
        width: 20px;
        height: 20px;
        border: 4px solid #fff;
        border-radius: 4px;
        box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.2);
        vertical-align: bottom;
      }

      .CheckBox::before {
        height: 0px;
      }

      .CheckBox::after {
        height: 0px;
      }

      input {
        visibility: hidden;
        width: 0;
        height: 0;

        &:checked ~ .check {
          background-color: $blue;
        }
      }

      img {
        vertical-align: bottom;
      }
    }

    &__hiddenFileUpload {
      display: none;
    }

    &__typeCol {
      width: 191px;
      padding-left: 40px;
    }

    &__priorityCol {
      width: 101px;
      margin-right: 35px;
    }

    &__statusCol {
      width: 81px;
      margin-right: 35px;
    }

    &__resolutionCol {
      width: 191px;
      padding-left: 50px;
    }

    &_collapsable {
      position: relative;
    }

    &__title {
      position: relative;
      padding-top: 3px;
      color: $blue-dark;
      font-size: 16px;
    }

    &__content {
      padding-top: 5px;

      .col-xs-12 {
        padding-right: 0px;
        padding-left: 0px;
      }

      .col-xs-3 {
        padding-top: 10px;
        padding-right: 0px;
        padding-left: 0px;
      }
    }

    .Table__add {
      margin-top: 5px;
      margin-bottom: 10px;
    }

    .TagsList {
      margin-bottom: 3px;

      &__title {
        color: $blue-dark;

        &_pointer {
          cursor: pointer;
        }
      }
    }
  }

  &_log {
    margin-top: 5px;

    &__label {
      font-weight: bold;
      line-height: 26px;
      font-size: 16px;

      &_wide {
        width: 100%;
        font-weight: normal;
      }
    }

    &__log {
      font-size: 16px;
      padding-left: 50px;
      padding-top: 27px;
    }
  }

  .NewIssueMessage__sendToInput {
    width: 500px;
  }

  _unset {
    /* unset all attributes */
    all: unset;
  }

  .EditorDialogItems {
    .ItemList {
      &_left {
        width: calc(10% + 40px);
      }

      &_right {
        width: calc(80% - 5px);
      }
    }
  }
}
