/* Colors */
$light: #ffffff;
$gray-light: #F0F0F0;
$gray-lighter: #F7F7F7;
$gray: #CBCBCB;
$gray-dark: #6C6C6C;
$red: #EF5074;
$orange-red: #F1592A;
$red-dark: #df1745;
$red-darker: #B63455;
$red-darkest: #922944;
$yellow: #FFC107;
$yellow-dark: #926f01;
$yellow-light-dark: #C97A03;
$green: #4CAF6D;
$green-dark: #307045;
$green-light: #92C850;
$blue: #6CD2DC;
$blue-dark: #218A96;
$blue-sea-dark: #238A96;
$blue-sea-darker: #1F8A96;
$blue-light: #CDEFF3;
$blue-deep: #5A6DC5;
$violet: #8F70CB;
$violet-dark: #754EBE;
$turquoise: #6CD2DC;
$turquoise-dark: #3FC2D1;
$blue-hover: #3DC2D1;
$blue-link-hover: #A6E3EA;

$blue-light-light: #E8FCFF;

$red-light: #FBE5DE;
$red-middle: #F8BAA7;

$blue-lighter: #E7F7F8;
$blue-middle: #C2EBF0;
$gray-light2: #EBEBEB;

@mixin image-2x($image, $width, $height) {
    @media (min--moz-device-pixel-ratio: 1.3),
           (-o-min-device-pixel-ratio: 2.6/2),
           (-webkit-min-device-pixel-ratio: 1.3),
           (min-device-pixel-ratio: 1.3),
           (min-resolution: 1.3dppx) {
      /* on retina, use image that's scaled by 2 */
      background-image: url($image);
      background-size: $width $height;
    }
  }