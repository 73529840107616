@import '../../styles/utils/variables';

.EditorDialog_tags {
  .ModalWindow__main {
    width: 700px;
    height: 650px !important;
  }

  .ModalWindow__body {
    padding: 1px 18px;
    width: 700px;
    height: 300px;
    min-height: 150px;
    overflow-y: visible; // added because gray scrollbar was appearing inside Add Issue window, when description was filled with long text;
    // as a result, Discard button was shifted to bottom
  }

  .ModalWindow__footer {
    padding-top: 5px;
    width: 700px;
  }
}

.TagsDialog {
  .EditorDialogItems {
    &__more {
      &__visible {
        color: $blue-dark;
        cursor: pointer;
        position: absolute;
        margin-left: calc(90% - 10px);
        margin-top: 20px;
      }

      img {
        height: 4px;
      }
    }
  }
}
