@import '../../styles/utils/variables';

.ObjectItemSearch {
  position: relative;

  input {
    background-color: #e0f5f8;
    padding: 0 5px;
    width: calc(100% - 90px);
    height: 24px;
    line-height: 24px;
    font-size: 16px;
    border-radius: 3px;
    border: 1px solid #cbcbcb;
    outline: none;
    margin-bottom: 2px;
  }

  &__Close {
    position: absolute;
    margin-top: -25px;
    right: 20px;
    color: $blue-dark;
    cursor: pointer;

    &:hover {
      font-weight: bold;
    }
  }

  &__results {
    margin-top: -4px;
    background-color: white;
    border: 1px solid #6dd2dc;
  }

  &__result {
    padding: 5px;
    border-bottom: 1px solid #6dd2dc;
    color: #218a96;
    cursor: pointer;

    &Path {
      margin-bottom: 2px;
    }

    &:hover {
      background-color: $gray-lighter;
      font-weight: 700;
    }
  }

  &__resultHeader {
    font-size: 16px;
    font-weight: bold;
    color: $blue-dark;
  }
}
