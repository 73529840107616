@import "../utils/variables";

.ObjectPicture {
  box-sizing: border-box;
  margin-left: 4px;
  width: 24px;
  height: 24px;
  color: $gray-dark;
  text-align: center;
  border: 1px solid $gray;
  border-radius: 100%;
  line-height: 22px;
  font-weight: bold;
  font-size: 16px;
  //background-color: #F0F0F0;
  background-color: $light;
  &__inline {
    display: inline-block;
    margin-right: 4px;
  }
  img {
    max-width: 24px;
    max-height: 24px;
  }
  &__picture {
    display: inline-block;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border-radius: 50%;
    width: 34px;
    height: 34px;
  }
  &__nameUpper {

  }
}

