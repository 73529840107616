.EditorDialog_exportParams {
  .ModalWindow__main {
    width: 800px;
    height: 650px !important;
  }

  .ModalWindow__body {
    padding: 1px 18px;
    width: 800px !important;
    height: 483px !important;
    min-height: 150px !important;
    overflow-y: visible; // added because gray scrollbar was appearing inside Add Issue window, when description was filled with long text;
    // as a result, Discard button was shifted to bottom
  }

  .ModalWindow__footer {
    padding-top: 5px;
    width: 800px;

    .btn_big {
      width: 190px;
    }
  }
}

.ExportSchemaDialog {
  margin-left: 70px;
  margin-top: 30px;

  .DataEditor__block {
    &_useApprovedVersion,
    &_hideFutureFields,
    &_flattenInheritedFields,
    &_alignNames,
    &_alignEnums,
    &_minimizeSchemaSize {
      margin-top: 5px;
      margin-bottom: 5px;
      height: 35px;

      &__value_right {
        width: calc(20% + 10px);
        float: left;
        line-height: 26px;
      }
    }

    &_schemaType,
    &_hideOptionalFields {
      margin-top: 5px;
      margin-bottom: 5px;
      height: 30px;

      &__value_right {
        width: calc(20% + 10px);
        float: left;
        line-height: 26px;
      }
    }

    &__label {
      display: block;
      float: left;
      padding-right: 10px;
      line-height: 22px;
      width: calc(70% + 10px) !important;
      font-weight: 500 !important;
      font-size: 16px !important;
    }
  }

  .SimpleTooltip__parent:hover .SimpleTooltip__tooltip {
    display: block;
    width: 210px !important;
  }
}
