@import '../../styles/utils/variables';

.ObjectSearchMultistep {
  position: relative;
  margin-top: 10px;

  &__select {
    position: relative;
    margin-bottom: 1px;
    font-size: 16px;
  }

  &__label {
    display: block;
    padding-bottom: 3px;
    height: 18px;
  }
  &__labelType {
    text-transform: capitalize;

    width: 100px;
    float: left;
    font-weight: 600;
    line-height: 20px;
  }

  &__input {
    width: 100%;
  }
  &__input .ObjectSearch__textInput .TextInput {
    height: 26px;
    line-height: 18px;
  }

  &__item {
    //display: inline-block;
    position: relative;
    margin-bottom: 2px;
    font-size: 16px;
  }

  &__labelItem {
    display: inline-block;
  }
  &__itemName {
    color: $blue-dark;

    float: left;
    font-weight: 500;
    line-height: 20px;
    margin-left: 20px;
    cursor: pointer;

    &:hover {
      background-color: $gray-lighter;
      font-weight: 700;
    }
  }
  &__itemValue {
    float: left;
    font-weight: 500;
    line-height: 20px;
    margin-left: 20px;
  }

  &__clearItem {
    display: inline-block;
    img {
      height: 12px;
      margin-left: 4px;
    }
  }

  &__version {
    display: inline-block;
    float: right;
  }

  .ObjectSearch__textInput .TextInput {
    background-color: white;
  }

  &__clear {
    position: absolute;
    top: 32px;
    right: 12px;
    width: 14px;
    height: 14px;
    cursor: pointer;

    img {
      width: 100%;
    }
  }
}
