@import '../../styles/utils/variables';

.EditorDialog_outputs {
  .ModalWindow__main {
    width: 800px;
    height: 650px !important;
  }

  .ModalWindow__body {
    padding: 1px 18px;
    width: 800px !important;
    height: 850px;
    min-height: 150px !important;
    overflow-y: visible; // added because gray scrollbar was appearing inside Add Issue window, when description was filled with long text;
    // as a result, Discard button was shifted to bottom
  }

  .ModalWindow__footer {
    padding-top: 5px;
    width: 800px;
  }
}

.OutputsDialog {
  text-align: left;

  .ObjectSearch,
  .ObjectSearch__result {
    text-align: left;
  }

  .SimpleTable__headerCell {
    background-color: rgb(114, 137, 163);
    color: $light;
    font-weight: normal;
    padding: 5px;
  }

  .LinkedKeyTable .col-xs-2 {
    width: 100px;
    min-width: 0;
  }

  .LinkedKeyTable .col-xs-5 {
    padding-left: 0px;
    padding-right: 0px;
  }

  &__label {
    display: block;
    float: left;
    padding-right: 10px;
    line-height: 26px;
    width: 15%;
    font-weight: bold;
    font-size: 16px;

    a {
      font-weight: normal;
    }
  }

  &__block {
    margin-bottom: 5px;
  }

  &__warning {
    margin-left: 125px;
    color: $red;
  }

  &__block_description {
    margin-bottom: 1px;
  }

  &__block_text {
    margin-top: 7px;
    margin-bottom: 1px;
  }

  &__block_value {
    margin-top: 1px;
    margin-bottom: 1px;
  }

  .value_right_order {
    width: 15%;
    float: left;
    line-height: 26px;

    input[type='text'],
    textarea {
      width: 100%;
    }
  }

  .KeySettings__divButtons {
    margin-left: 0px;

    .KeySettings__arrowButton1 {
      margin-left: 20px;

      &__Text {
        position: absolute;
        top: 10px;
        left: 10px;
      }
    }

    .KeySettings__arrowButton2 {
      margin-left: 5px;

      &__Text {
        position: absolute;
        top: 10px;
        left: 19px;
      }
    }
  }
  .EditableEntity_string {
    max-width: 100%;
    box-sizing: border-box;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    //height: 31px;
  }
  .EditableEntity .StringData {
    display: inline-block;
  }
}

.EditableEntity {
  text-align: left;

  &_color_label,
  &_style_label,
  &_width_label,
  &_font_label {
    display: block;
    float: left;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 3px;
    line-height: 26px;
    width: 25%;
    font-weight: 500;
    font-size: 16px;
  }

  .col-xs-2 {
    padding-top: 1px;
    flex-basis: 30%;
    min-width: 30%;
  }

  .col-xs-12 {
    padding-bottom: 7px;
    padding-right: 10px;
  }

  .col-xs-5 {
    padding-top: 1px;
    flex-basis: 50%;
    min-width: 50%;
  }

  &_color_value_right {
    width: 50%;
    float: left;
    line-height: 26px;
    input {
      width: 60px;
      margin-right: 5px;
    }
  }

  &_style_value_right,
  &_width_value_right,
  &_font_value_right {
    width: 50%;
    float: left;
    line-height: 26px;
  }

  &_style_value_size,
  &_width_value_size {
    margin-left: 7px;
    width: 5%;
    float: left;
    line-height: 26px;
  }
}

.EditorDialog__editing .OutputsDialog__icon {
  background-size: 17px 14px !important;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACIAAAAcCAYAAAAEN20fAAAB4UlEQVR4XsWXQU4CMRSG3zSzd24ARPfgCUZOIJ5AWLoSFq4Zt7qBjS7FE4gnsNyAA5g43mBugK/J31hLQl9hHP7kTwmL9mvnTf83yWazoZDOHp/bPAzZF2zzu0W/WrFL9uzz7mZNeyoNAJiFC3a+gzNnfwkh5CAAyHhY7gDQGNtszRAjgmoBAUQPC50QZI+e/c6LlhQQNrJgF9KTSgMQFXvMk70SJITQ7C77wjxeCYxyJ/Ag1uwOIMRyixlzaWwwDAItPYg+Q1QUKey+z65iYBROY+gUZhWAkMKcs9dSGIWxcOAmgDhIKOq+FEaB0j7TkiEWVJOwIRGMwo1pNSeoURiA9LyCpQZhBgQlpw9PlaEEfUKRQgywRcrYtx7nyJRDCgjcnvECxJT21wtv5s89ktDxlKe4NzJEe7RwkjryBF0tTGiaGtFOynYQarUL9WSC8NqHsFnjBtLgGBAWxL3Axo1DQAq5UAKghdxpDMLPmnsHboaW4D8gxg7ENgjyRXt5kNUJgYtrLulHrpweoiuDkUOEwlT5eeDBlLE1g0+PSzEEtPVdg1T8QC5YfaN5XgqbZzvHxELEgPgN8NuOMFs5/WkRWkwKEkrWqSBdR4fCqEAfodmmbjpoJ7Wf0vgvP/Qt+wF93u/d8iB5kwAAAABJRU5ErkJggg==');
}
.EditorDialog__editing .EditorDialogItems__button:hover .OutputsDialog__icon {
  background-size: 19px 16px !important;
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACYAAAAgCAYAAAB+ZAqzAAAB6klEQVR4Xs2YMW4CMRBFHYsejkCk9OQGCycIOcGyZTooUgN1GqhSxjlBlhMEbkD6FBxhcwLykQb91QDCu17JfOnLsoStp5lhvPbdfr83Pnp4e+9g6MNDuAsnasEG3sLu9/UFY5haHkAHiJkAtS+BC2hbfhus1hWoA9DU+GkLDxCtomkwHaUc7sFauUCsFcAPoZoEI9QjhrVKWwEv4KV3RLifw5DCGcBddTBGSkM5eEKgWlAHfWBufOGsTpOCygCUBaSI6SbcyB+Mhd5TUM4ESNZndeBsKYVTQ7kQqHA4RmymCn3CaRw4Kx19SAD+82LC2TMdfUGAGHAE6xtqxWjFgiNYVx0rVES4FpyovlNLsilcSX/HMtJNGGDNiJ9CISKcNbep5LbA2NyzFiabUgr6AXXm4G/jr46s6WioY/HvSmCPAW1gJ3v5fqavz0KJrIrQkywKkDdU7zwUwXK4MNQ4HhRlpdOvymCyQSSoy18XbXgRC4pgLNy5oVLp5DGgCCZwM56VPLtiQBGMeoYLBecCai6vDqXBmNKBgkvhHeBmNQDHcOEPRfHt4vQa93Wh4a70hVdKgfPTe2oCqKWB6oJpwGmFt4hPwI1MQ7JXjpk5hnvYMSUXlcoFtxFVfYZKSs9QfXWz2oo/m3iG+gf9HgLg0j22AgAAAABJRU5ErkJggg==');
}

.EditorDialog__viewing .OutputsDialog__icon {
  background-size: 17px 14px !important;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACIAAAAcCAYAAAAEN20fAAAB00lEQVR4XsWX0U3DMBRFX6z8kw3aTlCQ+G86AWUCmglIJ2iYoOkEhAkoE+B+w0c2wGyQDcqt9KQXmaq2mxCOdOU/59iycu3ocDiQi3L1OcawRFJkjIxI2CMGKfPNbU0XEjsEUgwFMjvjOUO+RKJHEQgkGHZnBDSPY0RDIqOOxCckrvlDVyQYpETe8FFDDnghFVL47lTskGiQHJO9EOMpoZEpkkIq9ZFR7QksiRqZsEQAcph5Ls0LdIgIO0tiDomGAuHVz5EmREbxbixbB7NxSPjK3CC1r4zisSBhxRKd4EM995VRbDliCQOJinqCF+Qlo5AlCVtihpZRyLV1YGlAmQUx0Sb/aI6WbB9RCFIDiBcJ8mh5ZhCtYpbgv2c4LLGmy3nGYkiRENH/MYv5v5FItQdjEB24g22qY2kez4hutexESq1/UCMVhgdbgruGahIWw0tI11Qk5MNKCIp7wbDAiHtnMAm7a55IKCGT/JFELhInRLhftNUHSc8SGSS2PveR+9YdYioy/Ui4ylTZfWDJmKAzI0+POz8J4de7hlvxHUlI+EZKZOd1eZY5ViLhL2JfgF/PlNm+dT8tXB/zFXE169qjXbOuMiFPzgd5ciKCRkzXK+YPaGjx3GPIzY8AAAAASUVORK5CYII=');
}
.EditorDialog__viewing .EditorDialogItems__button:hover .OutputsDialog__icon {
  background-size: 19px 16px !important;
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACYAAAAgCAYAAAB+ZAqzAAABvUlEQVR4Xs2Y3W3iQBSFHeR3XIJTAXRgUsGyFRh3ABXE6QAq2EkFMRXE6YB04A7iVEDOw5GOdGXkn4l3ONInpGuEPt2ZMdd+uF6v0cAkYAO2IAWZuf4BLsDx0ytxnw0lSgotwa1kvF6yMJ8YhZ7BkFzAE2jnFEtBBVbApqJEbeqfkppHbA1qs2wtOILThI44kIMCuKliaYeUAwcJjZdi4Z9q/VnYZTJSBZm6RFpuye3Gdqw0e4qt94oz3RrYOYml5vQ5wvxvOYmVZqMfJBBAjmIJ2KqkkxdSLu64ox8lEEJOYptIOatboeQklpq/FSasXGymhNpDYEfG5Bssu+Qg9lvRKOQF5RbgHpPdo5gDRczJM2NxA2qPH3wfIZBQIrFS3PxRIzGMPNPTkKFSdacUszAd+qMvz4SkVt1SEqvMWLMPKCUxSp2NWBJOSmJ2uliCYygpK9aAl0jJwS6UlMTUtYsdg0NISUz8Ba2Rcx57ruqRGizWdDy05qyXEwT3oB0uJW69u0jB240b7tk88PZNJmuQgRMLHmLiecS7iNffOzD908ULeARuwGSbAze/mGhAQcEtJe2ytax9afn9+AEifYTws4PMvQAAAABJRU5ErkJggg==');
}
