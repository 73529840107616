@import '../../styles/utils/variables.scss';
@import '../../styles/utils/utils';

.Issue {
  position: relative;
  background-color: #ebebeb;
  border: 1px solid $gray;
  border-bottom: 2px solid $gray;
  box-sizing: border-box;
  height: 155px;
  overflow: hidden;
  display: flex;

  &_opened {
    height: auto;

    .Issue__descriptionValue {
      height: auto;
    }
  }

  .MajorObjectView__collapse {
    right: 0;
    bottom: 0;
    z-index: 50;
    background-color: $blue;
  }

  &__main {
    display: block;
    max-height: 100%;
  }

  &__identity {
    position: relative;
    box-sizing: border-box;
    width: 196px;
    padding: 5px 10px;
    overflow: hidden;
    .Person__outer {
      display: inline;
      word-wrap: break-word;
    }
  }

  &__name {
    margin-top: 0;
    margin-bottom: 6px;
    font-size: 16px;
    font-weight: bold;
  }

  &__property {
    margin-bottom: 2px;
  }

  .IssueCard__priorityIcon,
  .IssueCard__resolutionIcon {
    float: right;
  }
  .IssueCard__resolutionIcon {
    margin-right: 2px;
  }

  &__dateCreated {
    position: absolute;
    right: 8px;
    top: 5px;
  }

  &__data.row {
    box-sizing: border-box;
    width: 698px;
    padding: 8px 0 8px 10px;
    border-left: 1px solid $blue-sea-dark;
  }

  &__edit {
    float: right;
  }

  &__subject {
    &Label {
      font-weight: bold;
      font-size: 16px;
      margin-right: 6px;
      display: inline;
    }
    &Value {
      display: inline;
    }
    margin-bottom: 5px;
  }

  &__description {
    &Label {
      font-weight: bold;
      font-size: 16px;
      margin-right: 6px;
      display: inline-block;
      width: 90px;
      vertical-align: top;
    }
    &Value {
      margin-top: 2px;
      width: 565px;
      height: 36px;
      display: inline-block;
      overflow: hidden;

      &_tall {
        height: 76px;
      }
    }
  }

  &__notes {
    margin-bottom: 6px;
    height: 36px;
    overflow: hidden;
  }

  .ObjectDescription {
    margin-top: 0;

    h2 {
      display: inline-block;
    }

    &__title {
      margin-top: 0;
      margin-bottom: 0;
      //margin-bottom: 18px;
    }
    &__text {
      font-size: 14px;
    }
  }

  .TagsList {
    margin-bottom: 10px;
    &__title {
      color: $blue-dark;

      &_pointer {
        cursor: pointer;
      }
    }
  }

  &__checklistHeader {
    //color: $blue-dark;
    font-weight: bold;
    font-size: 16px;
    margin-right: 15px;
  }
  &__checklistItems {
    margin-left: 45px;
  }

  &__checklistItem {
    position: relative;
    padding-left: 24px;

    &:before {
      content: '';
      position: absolute;
      left: 0;
      width: 16px;
      height: 16px;
      border: 1px solid #cdd1da;
      border-radius: 2px;
      background: #fff;
    }
    &:after {
      content: '';
      position: absolute;
      top: 2px;
      left: 0;
      width: 16px;
      height: 16px;
      border-radius: 2px;
      //background: #6CD2DC;
      background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAD4AAAA+CAYAAABzwahEAAAEPElEQVR4Xu3bb2hVdRjA8Z1z7r27DU2ZwlqigbXRSkOQAnuhqP15IfMfSJBvhP0pszkykkhFrN1pBNm62mSucGz1RhOG2gtfmZEVRhTKQmvWC4VCxxrb2r3n3nNP3xcPeHjgwszd7e63c+HDDwaiX579zt+r5ft+yUz82AjDDReGh+GmCcPD8DA8DI+YEtJ0pX8uy4FAwEhy6aN7jJ440ZUs57FJbMAyfp4wduLElbF0YEkgII0nMWZsOHZiFWIS4Ms629g9zrSXsDSjDHYgPIqUkeFEx1i6MV+irUCABQ8fG3Vwk+h21ASi/cDEXXRxVL9oVDi2YjOiatI+PHyP/UZdwDDtxSz7MAuWOqB5uIOdTHvUmHCiHZbjWAAHJSo+jQTRV425ZJXo/ViBCPS0MzhD9FHTrtVfwKuIwVLT9tCH1426VpdL0gTmwlaTzmEQbzHtAWPuzoi2WD5EDSLQ+9pFBy4Yc1sq0fWoRQx6X2dxGe8xbc+YcDyFdxHPs69vooHotDH340x7Dksb5ul9LeswWoi+btoTmL14Wu9r4eIUPjfqCQzTXs9Sh3ieff0bdjNtt+Dh/GNKWV4JBLjsrWMFiH6E5SPMznPHNYQ6ov8p+MNGid6BF7Edr6GZn/cgOoHRD8ip6+HgvhY5/Is2oi9P1lNWB2tRhQosxCJsRHIC4xvxPKKw1MSz+Bptk/2U1cZ8xNXt4Ev4A+/f57SfYXlbP01Rp65mpj08Jc/VA9G2mIVdTL32PqLnsXyKcthqX+cwgu1E35jsFwpp9EL/OQsOynGEgOX/IzrCchCPqYOtL9EpnCD6/KS/SZHLwW5cRBa+etzjoBKfyVH5Xj6bsQUxWCJ46voZLVP2CkmeaDTgJ0i8TF3iUY124svGOe1qltbAqUvfdd3Bm3LXNTXhEn9b4v+UeL1tYliNTqKccZy6OrEQNiwVn8I7RP9QFC8Nie9j2YVBePDVDUQM69Gq4rV9WJ7nzOKil+iuonpbSvw5lj0YRg6++tWPox4v55n2SjlnlwanLLL4HbuL8jUx8Z0sn2AUxEs0YONBfEDksyr6IbkknQMbJSp+UH7FbxVluGjBWWSCt4zqNNdDbJVEOxL9BBxYwhcukjhX1F8MYCopljp8F4i31KXuAhyXi5RGrEMU+pPFN2hl2rlCh0/IVzqJqmD5Cksl1lJXXmlcwuOogKMCPNzEc0T3T5uvgjChv+WWdQCe2rc2SrEalXleBIwgoaKLP1zif2TZETjSl6g9bwt9FM+gF13T9ss/xJ9mOYRRePrqLkC/CGhi2plpGy4O4yRc+Cpa34AMoFHdak7PcCKyLG/gEjLI6WgxhqRsETO+5yYT3Ipf4Um8r/b1tzhs3Bf85Ei/DdcwJLEuhtCHbUx7zLhwif+FZSNO4kucxhdYQ/RfEjAlwv+TYq4wPAwPw00ThofhYXgY/h8utX6K3T3RaAAAAABJRU5ErkJggg==);
      background-size: cover;
      //box-shadow: inset 0 1px 1px rgba(0,0,0,.5);
      opacity: 0;
      transition: all 0.2s;
    }
    &_checked:after {
      opacity: 1;
    }
  }

  .ObjectProperties,
  .ObjectAdvancedSettings {
    &__button {
      margin-bottom: 10px;
      background-color: transparent;
      color: $blue-dark;
      &:hover {
        color: $blue-dark;
        font-weight: bold;
        background-color: transparent;
      }
    }
  }

  .Table .TableHeaderCell {
    background-color: $turquoise;
  }
  .EditableTable__header {
    background-color: $turquoise;
  }

  &__newMessage {
    background-color: #fff;
  }

  &__assigned {
    .Loader {
      //position: relative;
      //top: 10px;
      transform: scale(0.5);
      .Loader__picture {
        width: 240px;
      }
    }
  }
  &__assignedTo {
    word-wrap: break-word;
    min-height: 40px;
    margin-bottom: 24px;
  }
  &__status {
    min-height: 80px;
  }
  &__statusLabel {
    display: block;
    float: left;
    width: 45px;
    height: 38px;
  }
  &__assignedPicture {
    float: left;
    width: 40px;
    height: 40px;
    margin-right: 8px;
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: cover;
    border-radius: 100%;
  }
  &__assignedName {
    color: $blue-dark;
    min-height: 40px;
  }

  &__documentAddButton {
    display: inline-block;
    float: left;
  }
  .ObjectCreator__addIcon__icon {
    cursor: pointer;
  }
}

.NewIssueMessage {
  @extend .clearfix;
  padding: 2px 0px 8px 0;
  //border-top: 1px solid $gray;
  border-bottom: 1px solid $gray;
  border-right: 1px solid $gray;
  border-left: 1px solid $gray;
  display: block;
  width: 100%;
  //width: 881px;
  margin: 0;
  &.row {
    margin: 0;
  }
  .row {
    width: 100%;
  }
  > div {
    width: 100%;
  }

  &__secondRow {
    width: 100%;
  }
  &__meta {
    box-sizing: border-box;
    //float: left;
    padding-left: 8px;
    padding-bottom: 4px;
    //width: 185px;
    width: 100%;
    min-height: 36px;

    &_inactive {
      width: 185px;
      float: left;
    }
  }
  &__text {
    //float: left;
    //width: 683px;
    width: 100%;

    &_inactive {
      float: left;
      padding-left: 11px;
      width: 468px;
      font-weight: bold;
    }
  }

  &__send.btn_blue {
    width: 135px;
    min-width: 135px;
    font-size: 16px;
    border: 0;
    float: left;
  }
  &__sendToInput {
    font-size: 16px;
    color: $blue-dark;
    margin-left: 10px;
    //padding: 5px;
    //border-radius: 3px;
    //border: 1px solid $gray;
    width: 610px;
    display: inline-block;
    textarea {
      padding: 5px;
      min-height: 28px;
      border-radius: 3px;
      border: 1px solid $gray;
    }
  }
  &__cancel.btn {
    margin-left: -4px;
    margin-top: 5px;
    font-size: 16px;
    border: 0;
    color: $blue-dark;
    float: right;
  }
  &__settings.btn {
    float: right;
    margin-left: 10px;
    //margin-top: 15px;
    font-size: 16px;
    border: 0;
    color: $blue-dark;
    img {
      height: 16px;
      margin-right: 3px;
    }
  }
  &__new.btn_blue {
    width: 190px;
    min-width: 95px;
    font-size: 16px;
    border: 0;
    color: #fff;
    background-color: #6cd2dc;
    font: 14px 'PT Sans Regular', Arial, sans-serif;
    font-weight: 700;
  }
  &__newDiv {
    padding-top: 4px;
    padding-left: 4px;
    width: 100%;
  }
  &__addFile {
    display: inline-block;
    margin-top: 20px;

    img {
      margin-right: 5px;
      vertical-align: text-top;
      width: 16px;
    }
  }

  &__btnImage {
    height: 14px;
    margin-right: 12px;
    position: relative;
    top: 2px;
  }

  &__expand {
    color: $blue-dark;
    float: right !important;
    margin-right: 10px;
    margin-top: 4px;
    &:hover {
      font-weight: bold;
    }
  }
}

.NewIssueSettingsBlock {
  &__left {
    float: left;
    width: 15%;
  }
  &__setting {
    display: inline-block;
    margin-right: 10px;
    margin-bottom: 6px;
    cursor: pointer;

    .check {
      box-sizing: border-box;
      display: inline-block;
      margin-right: 5px;
      width: 20px;
      height: 20px;
      border: 4px solid #fff;
      border-radius: 4px;
      box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.2);
      vertical-align: bottom;
    }

    input {
      visibility: hidden;
      width: 0;
      height: 0;

      &:checked ~ .check {
        background-color: $blue;
      }
    }

    img {
      vertical-align: bottom;
    }
  }

  &__hiddenFileUpload {
    display: none;
  }

  &__typeCol {
    width: 191px;
    padding-left: 40px;
  }
  &__priorityCol {
    width: 101px;
    margin-right: 35px;
  }
  &__statusCol {
    width: 81px;
    margin-right: 35px;
  }
  &__resolutionCol {
    width: 191px;
    padding-left: 50px;
  }
}
