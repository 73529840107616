@import '../../styles/utils/variables';

.EditorDialog_tag {
  .ModalWindow__main {
    width: 700px;
    height: 600px !important;
  }

  .ModalWindow__body {
    padding: 1px 18px;
    width: 700px;
    height: 300px;
    min-height: 150px;
    overflow-y: visible; // added because gray scrollbar was appearing inside Add Issue window, when description was filled with long text;
    // as a result, Discard button was shifted to bottom
  }

  .ModalWindow__footer {
    padding-top: 5px;
    width: 700px;
  }
}

.TagDialog {
  .DataEditor__block {
    &_name,
    &_description {
      margin-top: 5px;
      margin-bottom: 5px;

      &__value_right {
        width: calc(80% + 10px);
        float: left;
        line-height: 26px;
      }
    }

    &__label {
      display: block;
      float: left;
      padding-right: 10px;
      line-height: 26px;
      width: calc(15% + 10px);
      font-weight: bold;
      font-size: 16px;
    }
  }

  &__label {
    display: block;
    float: left;
    padding-right: 10px;
    line-height: 26px;
    width: 15%;
    font-weight: bold;
    font-size: 16px;

    a {
      font-weight: normal;
    }
  }

  &__block {
    margin-bottom: 5px;
  }

  &__block_description {
    margin-bottom: 1px;
  }

  &__block_text {
    margin-top: 7px;
    margin-bottom: 1px;
  }

  &__block_value {
    margin-top: 7px;
    margin-bottom: 1px;
  }

  .value_right_order {
    width: 15%;
    float: left;
    line-height: 26px;

    input[type='text'],
    textarea {
      width: 100%;
    }
  }

  &__block_type {
    margin-top: 8px;
    margin-bottom: 2px;
  }

  &__block_ref {
    margin-top: 6px;
    margin-bottom: 2px;
  }

  &__block_unit {
    margin-top: 8px;
    margin-bottom: 2px;
  }

  .Input_radio:not(checked) + label {
    position: relative;
    padding: 3px 10px 1px 20px;
  }

  &__image {
    max-height: 400px;
    max-width: 400px;
  }

  &__element {
    background-color: white;
    width: 20px;
    height: 20px;
    border: 2px solid black;
  }

  &__innerRow {
    .col-xs-6 {
      padding: 0;
    }
  }

  &__advancedOptions {
    margin-bottom: 20px;
    padding: 40px 0;
    border-top: 1px solid $gray-light;
    border-bottom: 1px solid $gray-light;

    .col-xs-6 {
      text-align: center;
      flex-basis: 33%;
      max-width: 33%;

      .ObjectAdvancedSettings {
        float: none;
        width: 90%;
      }
    }
  }

  &__map {
    padding: 10px 0;
    border-top: 1px solid $gray-light;
    border-bottom: 1px solid $gray-light;

    .col-xs-6 {
      text-align: center;
      flex-basis: 33%;
      max-width: 33%;

      .ObjectAdvancedSettings {
        float: none;
        width: 90%;
      }
    }

    .col-xs-8 {
      margin-left: -5px;
      text-align: center;
      flex-basis: 100%;
      max-width: 100%;

      .ObjectAdvancedSettings {
        float: none;
        width: 95%;
      }
    }

    &__button {
      text-align: center;
      margin-bottom: 10px;
      align-content: center;

      img {
        margin-right: 4px;
        height: 18px;
        vertical-align: sub;
      }
    }

    &__name {
      float: left;
      text-align: left;
      width: 40%;
      font-weight: bold;
      font-size: 16px;
    }

    &__value {
      position: relative;
      top: 1px;
      float: left;
      text-align: left;
      width: 60%;
    }
  }

  .ObjectHeader__DropZone {
    height: 1px;
  }

  .TypedTable__bottom {
    text-align: left;
  }

  &__list {
    text-align: left;
  }

  &__editBlock {
    margin-top: 0px;
    margin-bottom: 0px;
    margin-left: calc(50% - 85px);
  }

  &__editActivityButton {
    right: 10px;
    top: 4px;
    border: 0;
    z-index: 100;
  }
}

.EditorDialog__editing .EditorDialogItems__button:hover .OutputsDialog__icon {
  background-size: 19px 16px !important;
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACYAAAAgCAYAAAB+ZAqzAAAB6klEQVR4Xs2YMW4CMRBFHYsejkCk9OQGCycIOcGyZTooUgN1GqhSxjlBlhMEbkD6FBxhcwLykQb91QDCu17JfOnLsoStp5lhvPbdfr83Pnp4e+9g6MNDuAsnasEG3sLu9/UFY5haHkAHiJkAtS+BC2hbfhus1hWoA9DU+GkLDxCtomkwHaUc7sFauUCsFcAPoZoEI9QjhrVKWwEv4KV3RLifw5DCGcBddTBGSkM5eEKgWlAHfWBufOGsTpOCygCUBaSI6SbcyB+Mhd5TUM4ESNZndeBsKYVTQ7kQqHA4RmymCn3CaRw4Kx19SAD+82LC2TMdfUGAGHAE6xtqxWjFgiNYVx0rVES4FpyovlNLsilcSX/HMtJNGGDNiJ9CISKcNbep5LbA2NyzFiabUgr6AXXm4G/jr46s6WioY/HvSmCPAW1gJ3v5fqavz0KJrIrQkywKkDdU7zwUwXK4MNQ4HhRlpdOvymCyQSSoy18XbXgRC4pgLNy5oVLp5DGgCCZwM56VPLtiQBGMeoYLBecCai6vDqXBmNKBgkvhHeBmNQDHcOEPRfHt4vQa93Wh4a70hVdKgfPTe2oCqKWB6oJpwGmFt4hPwI1MQ7JXjpk5hnvYMSUXlcoFtxFVfYZKSs9QfXWz2oo/m3iG+gf9HgLg0j22AgAAAABJRU5ErkJggg==');
}

.EditorDialog__viewing .EditorDialogItems__button:hover .OutputsDialog__icon {
  background-size: 19px 16px !important;
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACYAAAAgCAYAAAB+ZAqzAAABvUlEQVR4Xs2Y3W3iQBSFHeR3XIJTAXRgUsGyFRh3ABXE6QAq2EkFMRXE6YB04A7iVEDOw5GOdGXkn4l3ONInpGuEPt2ZMdd+uF6v0cAkYAO2IAWZuf4BLsDx0ytxnw0lSgotwa1kvF6yMJ8YhZ7BkFzAE2jnFEtBBVbApqJEbeqfkppHbA1qs2wtOILThI44kIMCuKliaYeUAwcJjZdi4Z9q/VnYZTJSBZm6RFpuye3Gdqw0e4qt94oz3RrYOYml5vQ5wvxvOYmVZqMfJBBAjmIJ2KqkkxdSLu64ox8lEEJOYptIOatboeQklpq/FSasXGymhNpDYEfG5Bssu+Qg9lvRKOQF5RbgHpPdo5gDRczJM2NxA2qPH3wfIZBQIrFS3PxRIzGMPNPTkKFSdacUszAd+qMvz4SkVt1SEqvMWLMPKCUxSp2NWBJOSmJ2uliCYygpK9aAl0jJwS6UlMTUtYsdg0NISUz8Ba2Rcx57ruqRGizWdDy05qyXEwT3oB0uJW69u0jB240b7tk88PZNJmuQgRMLHmLiecS7iNffOzD908ULeARuwGSbAze/mGhAQcEtJe2ytax9afn9+AEifYTws4PMvQAAAABJRU5ErkJggg==');
}
