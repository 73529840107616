.MajorObject__pasteDialog {
  .row {
    width: 100%;
    max-width: none !important;
    margin-bottom: 20px !important;
  }
  .col-xs-12 {
    width: 100%;
  }
  input.ObjectCreator__input {
    width: 330px;
    margin-left: 20px;
  }
  .LinkedKeyTable .scrollarea {
    width: 350px;
  }
  .KeyTableInner {
    width: 280px;
  }
}
