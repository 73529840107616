@import '../../styles/utils/variables';

.RoleDialog {
  .ModalWindow__main {
    width: 700px;
    height: 480px;
  }

  .ModalWindow__body {
    height: 314px;
    min-height: 150px !important;
  }

  .ModalWindow__footer {
    padding-top: 5px;
  }

  .btn_big {
    width: 170px;
    line-height: 48px;
    font-size: 26px;
    font-weight: 300;
  }

  .row {
    margin-left: 100px !important;
    margin-top: 10px !important;
  }

  &__label {
    margin-top: 2px;
    margin-bottom: 20px;
    padding: 6px 18px;
    font-size: 18px;
    font-weight: 600;
  }

  &__role {
    margin-bottom: 25px;

    .Input_radio:not(checked) + label {
      position: relative;
      padding: 3px 0px 0 34px;
    }
  }

  .DataDialog__propertyName {
    display: inline-block;

    .SimpleTooltip__parent:hover .SimpleTooltip__tooltip {
      display: block;
      width: 250px;
    }
  }

  .DataDialog__propertyNameText {
    display: inline-block;
    width: 170px;

    .MultilineText__text {
      margin-right: 0;
      font-size: 18px;
    }
  }

  .SimpleTooltip__root {
    opacity: 1 !important;
    position: absolute;
    left: 125px;
  }

  .SimpleTooltip__parent:hover .SimpleTooltip__tooltip {
    display: block;
    width: 250px;
    max-height: 230px !important;
    max-width: 230px !important;
  }
}
