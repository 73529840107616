@import '../../styles/utils/variables';

.HeaderOld {
  position: fixed;
  width: 100%;
  min-width: 1200px;
  background-color: white;
  z-index: 200;

  &__after {
    width: 100%;
    height: 113px;
  }

  &__title {
    padding-top: 20px;
  }

  &__logo {
    margin: 0;
    color: $blue;
    img {
      width: 156px;
      height: 32px;
    }
    &BetaText {
      position: absolute;
      margin-top: -39px;
      margin-left: 156px;
      color: $blue-dark;
    }
  }

  &__user {
    text-align: center;

    & .HeaderOld__userInner {
      display: inline-block;
    }

    & .HeaderOld__userName {
      padding-top: 28px;
      padding-left: 10px;
      font-size: 18px;
      position: absolute;
    }

    & .HeaderOld__userRoleInHeader {
      padding-top: 48px;
      padding-left: 10px;
      font-size: 18px;
      position: absolute;
    }

    & img {
      margin-top: 10px;
      max-height: 88px;
      border: 1px solid #cccccc;
      border-radius: 50%;
    }
  }

  &__userPhoto {
    margin-top: 8px;
    width: 86px;
    height: 86px;
    background-color: $blue;
    border-radius: 100%;
    overflow: hidden;
    position: relative;
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: cover;
  }
  &__topRow {
    height: 70px;
    border-bottom: 1px solid #cbcbcb;
  }
  &__bottomRow {
    height: 30px;
    padding-top: 4px;
    margin-bottom: 5px;
  }
  &__search {
    text-align: right;
    padding-top: 3px;
  }

  &__menuButtons {
    position: fixed;
    top: 30px;
    margin-left: 300px;
    //left: 20px;
    .HeaderOld__menuButton {
      display: inline-block;
      margin-right: 22px;
      float: left;
      .HeaderOld__menuButtonLabel {
        position: absolute;
        display: none;
        width: 300px;
        margin-left: -140px;
        text-align: center;
        margin-top: -23px;
        color: $blue-dark;
      }
      img {
        height: 20px;
        cursor: pointer;
      }
    }
    .HeaderOld__menuButton:hover {
      .HeaderOld__menuButtonLabel {
        display: block;
      }
    }
  }
}

.Header__settings {
  height: 18px;
  display: inline-block;
  position: relative;
  top: 5px;
  cursor: pointer;
  margin-right: 10px;
}
.Header__home {
  height: 18px;
  display: inline-block;
  position: relative;
  top: 5px;
  cursor: pointer;
  margin-right: 10px;
}
.LoginBlock {
  padding-top: 23px;
  text-align: right;

  & a {
    color: #238a96;
  }
}
