@import '../../styles/utils/variables';

.FieldCreator {
  &__inputBlock {
    display: block;
    margin-top: 5px;
    width: 100%;

    &__multiLine {
      margin-top: 5px;
    }
  }

  &__label {
    display: block;
    font-weight: bold;

    &_left {
      float: left;
      padding-right: 10px;
      line-height: 26px;
      width: 90px;
    }
  }

  &__value {
    line-height: 26px;
  }

  &__input.TextInput {
    display: block;
    float: left;
    width: 83%;
    height: 32px;
  }

  &__textArea {
    width: 100%;
  }

  .TextArea {
    width: 83%;
    min-height: 40px;
    max-height: 65px;
  }

  ::-webkit-input-placeholder {
    font-size: 16px;
  }

  :-moz-placeholder {
    /* Firefox 18- */
    font-size: 16px;
  }

  ::-moz-placeholder {
    /* Firefox 19+ */
    font-size: 16px;
  }

  :-ms-input-placeholder {
    font-size: 16px;
  }

  &__identity {
    padding: 0px 5px 7px 5px;
    font-size: 16px;
    line-height: 24px;
  }

  &__title {
    padding-top: 5px;
    padding-left: 5px;
    padding-right: 5px;
    font-size: 16px;
    height: 20px;
  }

  .ModalWindow__upper {
    padding-top: 10px;
    padding-bottom: 5px;
    padding-left: 10px;
    padding-right: 10px;
    border-bottom: 1px solid #cbcbcb;
  }

  .MultilineText__text {
    min-height: 40px;
    max-height: 60px;
  }

  .Loader {
    margin-left: 180px;
    margin-top: -5px;
    margin-bottom: 5px;
    transform: scale(0.5);

    .Loader__picture {
      width: 50px;
    }
  }

  &__advancedOptions {
    margin-bottom: 20px;
    padding: 10px 0;
    border-top: 1px solid $gray-light;
    border-bottom: 1px solid $gray-light;

    .col-xs-6 {
      text-align: center;
      flex-basis: 50%;
      max-width: 50%;
      padding: 5px;

      .EditorDialogSettings {
        float: none;
        width: 100%;
      }
    }
  }

  &__rules {
  }
}

.EditorDialog_field {
  .ModalWindow__body {
    padding: 1px 18px;
  }

  & .TextInput {
    display: block;
    float: left;
    width: 84%;
    height: 32px;
  }

  .TextArea {
    //margin-left: 5px;
    margin-top: 3px;

    //width: 84%;
    min-height: 65px;
  }
}
