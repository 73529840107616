@import '../../styles/utils/utils';
@import '../../styles/utils/variables';

.ObjectHeader {
  box-sizing: border-box;
  margin-top: 12px;
  padding: 0;
  height: 56px;
  background-color: #ebebeb;
  //border-top: 25px solid rgba(0,0,0,.3);
  font-size: 16px;

  &_application {
    //border-top: 25px solid #C97A03;
  }
  // if you add new object type here, please also add it to __objectName selector with darken
  .TagsList__title {
    font-size: 14px;
  }

  & > .row > div {
    height: 50px;
  }

  &__selectVersion {
    & > div {
      @extend .clearfix;
      width: 100%;
      margin-top: -5px;
    }

    /*
    .EditButton {
      float: left;
      height: 18px;
      overflow: hidden;

      &__btn {
        padding-right: 0;
      }
    }

    .SelectVersion__content {
      strong {
        display: inline-block;
        width: 60px;
      }

      .LocaleSelect {
        width: 150px;
      }
    }
  */
  }

  &__row {
    position: relative;
  }

  &__objectName {
    position: fixed;
    width: 100%;
    left: 0;
    //margin-top: -43px;
    top: 105px;
    height: 20px !important;
    text-align: center;
    text-transform: uppercase;
    //top: -32px;
    font-size: 16px;
    color: white;
    letter-spacing: 6px;
    z-index: 100;

    &After {
      width: 100%;
      height: 9px !important;
    }
  }
}

.select_transparent.material.root-node {
  display: inline-block;
  width: 100px;
  max-height: 20px;
  line-height: 20px;

  .react-selectize-control {
    height: 24px;
    padding: 0;
    border-bottom: 0;

    &:after {
      background-color: $blue;
    }
  }

  .react-selectize-control .react-selectize-placeholder {
    height: 20px;
    line-height: 20px;
  }

  .react-selectize-control .react-selectize-search-field-and-selected-values {
    min-height: 20px;
    line-height: 20px;
  }
}

.ObjectTitle {
  margin-top: -8px;

  &__title {
    margin: 0;
    font-size: 22px;
    font-weight: normal;
    max-width: 300px;
    overflow: hidden;
    height: 22px;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__control {
    //margin-bottom: 4px;
  }

  &__input {
    width: 70%;
    font-size: 18px;
  }
}

.ObjectHeader__ObjectPicture {
  width: 36px;
  height: 36px;
  font-size: 21px;
  line-height: 36px;
  margin-right: 6px;
  margin-top: 8px;
  margin-left: 0px;
}

.ObjectHeader__DropZone {
  width: 45px;
  height: 45px;
  border: 0;
  margin-top: 10px;
}

.ObjectHeader__dropZoneOuter {
  //  position: relative;
}

.ObjectHeader__loadIcon {
  color: #238a96;
  font-weight: bold;
  font-size: 10px;
  cursor: pointer;

  &Dark {
    color: #6cd2dc;
  }

  img {
    height: 10px;
    margin-right: 3px;
  }
}
