@import '../utils/variables';

.ObjectSearch {
  position: relative;

  &__textInput,
  &__results {
    box-sizing: border-box;
    position: relative;
    width: 100%;
  }

  &__results {
    position: absolute;
  }

  &__textInput {
    .TextInput {
      width: 100%;
      background-color: #e0f5f8;
    }
  }

  &__results {
    margin-top: -3px;
    background-color: white;
    border: 1px solid #6dd2dc;
    z-index: 100;
  }

  &__result {
    padding: 10px;
    border-bottom: 1px solid #6dd2dc;

    &:hover {
      background-color: $gray-lighter;
    }
  }

  &__resultHeader {
    font-size: 16px;
    font-weight: bold;
    color: $blue-dark;
  }

  &__close {
    float: right;

    img {
      height: 20px;
    }
  }
}

.ObjectSearch__localeId {
  font-weight: normal;
}

.ObjectSearch__localeCode {
  font-weight: normal;
  margin-left: 40px;
}
