
.ObjectRating {
  display: inline-block;
  width: 24px;
  box-sizing: border-box;

  &__star {
    display: inline-block;
    margin: 0 4px;
    max-height: 24px;
  }

  &__outer {
    text-align: center;
  }

  &__plus {
    color: #06974B;
  }

  &__minus {
    color: #EE5073;
  }
}
/*
.tooltip {
  color: black !important;
  background-color: #F0F0F0 !important;
  //box-shadow: 0px 0px 5px 24px rgba(255,255,255,1);
  border: 1px solid #CCCCCC;
  padding: 1px 12px !important;
  border-radius: 8px !important

}
.tooltip.__react_component_tooltip.place-top:after {
  border-top-color: #F0F0F0 !important;
  //background-color: red !important;
}
*/
