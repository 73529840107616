@import '../../styles/utils/utils';
@import '../../styles/utils/variables';

.Footer {
  color: #cbcbcb;
  font-family: 'PT Sans';

  padding-top: 20px;
  padding-bottom: 50px;

  background-color: #3b3b3b;

  border-top: 7px solid $blue-sea-dark;

  &__caption {
    color: $blue-sea-dark;
    font-size: 1.1em;
    margin-bottom: 5px;
  }

  a {
    color: $blue-sea-dark;
  }
}
