@import '../../styles/utils/variables';

.InviteDialog {
  .ModalWindow__body {
    min-height: 150px !important;
  }

  .ModalWindow__footer {
    padding-top: 5px;
  }

  .btn_big {
    width: 170px;
  }

  .col-xs-3 {
    padding-left: 10px;
    padding-top: 4px;
    flex-basis: 18%;
    font-weight: bold;
    font-size: 16px;
    min-width: 18%;
  }

  .col-xs-9 {
    flex-basis: 82%;
    min-width: 82%;
  }

  &__subject {
    &Row {
      button {
        font-size: 16px;
        font-weight: bold;
      }
    }

    &Label {
      font-weight: bold;
      font-size: 16px;
      margin-right: 6px;
      display: inline;
      width: 170px;
    }
    &Value {
      display: inline;
      font-size: 16px;
    }
    margin-bottom: 5px;
  }

  &__label {
    margin-top: 20px;
    padding: 6px 18px;
    font-size: 18px;
  }

  &__text {
    padding: 6px 18px;
    margin-bottom: 4px;

    .TextArea {
      font-size: 16px;

      &::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        color: $gray;
        font-weight: 500;
        font-size: 14px;
      }
      &::-moz-placeholder {
        /* Firefox 19+ */
        color: $gray;
        font-weight: 500;
        font-size: 14px;
      }
      &:-ms-input-placeholder {
        /* IE 10+ */
        color: $gray;
        font-weight: 500;
        font-size: 14px;
      }
      &:-moz-placeholder {
        /* Firefox 18- */
        color: $gray;
        font-weight: 500;
        font-size: 14px;
      }
      input:-ms-input-placeholder {
        color: gray;
        font-weight: 500;
        font-size: 14px;
      }
    }
  }

  &__select {
    .MuiInputBase-input {
      padding: 6px;
    }
  }

  &__infoRow.row {
    margin-top: 10px;
  }

  .Loader {
    margin-left: 180px;
    margin-top: -5px;
    margin-bottom: 5px;
    transform: scale(0.5);

    .Loader__picture {
      width: 240px;
    }
  }
}
