.OrgCard {
  padding: 10px;
  background: #fff;

  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 8px;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__title {
    margin: 0;
    color: #000000;
    font-weight: 500;

    &:hover {
     color: #6e49cb;
    }
  }

  &__p {
    font-size: 1rem;
  }

  &__role {
    background-color: rgba(76, 175, 80, 0.08) !important;
    color: #4caf50 !important;
    border: 1px solid #4caf50 !important;

    &--pending {
      background-color: rgba(244, 67, 54, 0.08) !important;
      color: #f44336 !important;
      border: 1px solid #f44336 !important;
    }
  }

  &__group {
    & > * {
      margin-right: 10px !important;

      &:last-child {
        margin-right: 0 !important;
      }
    }
  }

  &__bottom {
    display: flex;
    align-items: center;

    & > * {
      margin-right: 10px;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}
