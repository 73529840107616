@import '../../styles/utils/variables';

.IssuesView {
  background-color: white;
  position: relative;
  max-height: 100%;

  .scrollbar-container.vertical {
    height: calc(100% - 2px);
    border: 1px solid #cccccc;
    background-color: #ebebeb;
  }

  .btn_blue.AddIssue {
    float: right;
    margin-right: 18px;
    min-width: 80px;
    border-color: $blue;
    font-size: 13px;
    font-weight: 700;
  }

  .IssuesList {
    .ScrollArea {
      width: calc(100% - 4px);

      &__container {
        width: calc(100% - 16px);
      }

      .IssuesView__cards {
        width: 100%;
      }

      &--editing {
        &::-webkit-scrollbar-thumb {
          background: #6cd2dc;
        }

        &::-webkit-scrollbar-thumb:hover {
          background: #3dc2d1;
        }
      }
    }
  }

  &__filters.row {
    border-bottom: 1px solid #cbcbcb;
    margin: 8px auto;
    padding: 8px 0;
  }

  &__assignedFilter {
    cursor: pointer;
    color: #6c6c6c;
    background-color: $blue-light;
    border: 1px solid $blue;
    border-radius: 2px;
    display: inline-block;
    padding: 4px 0px;
    //height: 20px;
    width: 82px;
    float: left;
    text-align: center;
    vertical-align: middle;
    font-weight: bold;
    &:first-child {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
    &:last-child {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
    &__selected {
      font-weight: bold;
      color: white;
      background-color: $blue;
    }
  }
  &__filterLabel {
    font-weight: bold;
    display: inline-block;
    margin-right: 20px;
    margin-top: 5px;
  }
  &__clearFilter {
    margin-top: 5px;
  }
  &__clearFilterButton {
    color: #238a96;
  }
  &__sortByLabel {
    margin-top: 5px;
    text-align: right;
  }
  .KascodeSelect__input {
    background-color: white;
  }
  &__search.TextInput {
    height: 28px;
    //border-radius: 2px;
    border-color: #cbcbcb;
    background-color: $light;
    margin-top: 1px;
    &.selectedFilter {
      border: 2px solid $blue;
    }
  }
  .SearchInput__pic {
    top: 6px;
    right: 8px;
  }
  &__priority {
    cursor: pointer;
  }

  &__cards {
    // height: 100vh;
    // overflow-y: auto;
  }
  &__messages {
    //border: 1px solid #cbcbcb;
    //border-right: none;
    .scrollbar-container {
      //width: 15px !important
    }
    .scrollbar {
      //width: 12px !important;
    }
  }

  &__fullScreen {
    position: fixed;
    left: 0;
    width: 100%;
    top: 125px;
    height: calc(100% - 125px);
    z-index: 1000;
    background: white;
  }

  &__relative {
    position: relative;
  }
  &__leftFilters {
    cursor: pointer;
    position: absolute;
    width: 120px;
    left: -80px;
    top: 60px;
    //border: 1px solid red;
  }
  &__priority {
    img {
      filter: grayscale(1);
    }
  }
  &__activeLeftFilter {
    img {
      //border: 2px solid $blue-deep;
      filter: grayscale(0);
    }
    &.IssuesView__priority:hover {
      img {
        opacity: 0.9;
      }
    }
  }
  &__statusFilter {
    cursor: pointer;
    background-color: $gray;
    color: white;
    padding: 3px 4px;
    border-radius: 3px;
    display: inline-block;
    margin: 3px 14px 3px 0;
    margin-bottom: 1px;
    font-weight: bold;
    min-width: 102px;
    text-align: center;
    &__active {
      background-color: $blue;
      &:hover {
        background-color: #3dc2d1;
      }
    }
    img {
      float: left;
    }
    &__Triaged {
      img {
        margin-top: 2px;
      }
    }
  }

  &__resolutionFilter {
    position: relative;
    padding-left: 27px;
    height: 25px;

    &:before {
      content: '';
      position: absolute;
      left: 0;
      width: 20px;
      height: 20px;
      border: 1px solid #cdd1da;
      border-radius: 3px;
      background: #fff;
    }
    &:after {
      content: '';
      position: absolute;
      top: 2px;
      left: 0;
      width: 20px;
      height: 20px;
      border-radius: 3px;
      //background: #6CD2DC;
      background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAD4AAAA+CAYAAABzwahEAAAEPElEQVR4Xu3bb2hVdRjA8Z1z7r27DU2ZwlqigbXRSkOQAnuhqP15IfMfSJBvhP0pszkykkhFrN1pBNm62mSucGz1RhOG2gtfmZEVRhTKQmvWC4VCxxrb2r3n3nNP3xcPeHjgwszd7e63c+HDDwaiX579zt+r5ft+yUz82AjDDReGh+GmCcPD8DA8DI+YEtJ0pX8uy4FAwEhy6aN7jJ440ZUs57FJbMAyfp4wduLElbF0YEkgII0nMWZsOHZiFWIS4Ms629g9zrSXsDSjDHYgPIqUkeFEx1i6MV+irUCABQ8fG3Vwk+h21ASi/cDEXXRxVL9oVDi2YjOiatI+PHyP/UZdwDDtxSz7MAuWOqB5uIOdTHvUmHCiHZbjWAAHJSo+jQTRV425ZJXo/ViBCPS0MzhD9FHTrtVfwKuIwVLT9tCH1426VpdL0gTmwlaTzmEQbzHtAWPuzoi2WD5EDSLQ+9pFBy4Yc1sq0fWoRQx6X2dxGe8xbc+YcDyFdxHPs69vooHotDH340x7Dksb5ul9LeswWoi+btoTmL14Wu9r4eIUPjfqCQzTXs9Sh3ieff0bdjNtt+Dh/GNKWV4JBLjsrWMFiH6E5SPMznPHNYQ6ov8p+MNGid6BF7Edr6GZn/cgOoHRD8ip6+HgvhY5/Is2oi9P1lNWB2tRhQosxCJsRHIC4xvxPKKw1MSz+Bptk/2U1cZ8xNXt4Ev4A+/f57SfYXlbP01Rp65mpj08Jc/VA9G2mIVdTL32PqLnsXyKcthqX+cwgu1E35jsFwpp9EL/OQsOynGEgOX/IzrCchCPqYOtL9EpnCD6/KS/SZHLwW5cRBa+etzjoBKfyVH5Xj6bsQUxWCJ46voZLVP2CkmeaDTgJ0i8TF3iUY124svGOe1qltbAqUvfdd3Bm3LXNTXhEn9b4v+UeL1tYliNTqKccZy6OrEQNiwVn8I7RP9QFC8Nie9j2YVBePDVDUQM69Gq4rV9WJ7nzOKil+iuonpbSvw5lj0YRg6++tWPox4v55n2SjlnlwanLLL4HbuL8jUx8Z0sn2AUxEs0YONBfEDksyr6IbkknQMbJSp+UH7FbxVluGjBWWSCt4zqNNdDbJVEOxL9BBxYwhcukjhX1F8MYCopljp8F4i31KXuAhyXi5RGrEMU+pPFN2hl2rlCh0/IVzqJqmD5Cksl1lJXXmlcwuOogKMCPNzEc0T3T5uvgjChv+WWdQCe2rc2SrEalXleBIwgoaKLP1zif2TZETjSl6g9bwt9FM+gF13T9ss/xJ9mOYRRePrqLkC/CGhi2plpGy4O4yRc+Cpa34AMoFHdak7PcCKyLG/gEjLI6WgxhqRsETO+5yYT3Ipf4Um8r/b1tzhs3Bf85Ei/DdcwJLEuhtCHbUx7zLhwif+FZSNO4kucxhdYQ/RfEjAlwv+TYq4wPAwPw00ThofhYXgY/h8utX6K3T3RaAAAAABJRU5ErkJggg==);
      background-size: cover;
      //box-shadow: inset 0 1px 1px rgba(0,0,0,.5);
      opacity: 0;
      transition: all 0.2s;
    }
    &_checked:after {
      opacity: 1;
    }
  }
  &__deadlineFilter {
    border: 2px solid white;
    img {
      height: 20px;
      margin-right: 5px;
      //margin-top: 2px;
      position: relative;
      cursor: pointer;
      filter: brightness(100%);
    }
    &:hover {
      //border: 2px solid $blue-deep;
    }
    &__on:hover {
      img {
        filter: brightness(90%);
      }
    }
  }
  &__deadlineLabel {
    display: inline-block;
    margin-top: 2px;
    cursor: pointer;
  }
  &__loaderDiv.alt-col-xs-9 {
    flex: 2;
    padding-top: 50px;
    margin-right: 300px;
    text-align: center;
    .Loader__picture {
      margin: 0 auto;
    }
  }
  &__loader {
    width: 100px;
    margin: 20px auto;
    clear: both;
  }

  &__btnMore.btn {
    min-width: 80px;
    font: 14px 'PT Sans Regular', Arial, sans-serif;
    font-weight: 700;

    img {
      height: 11px;
      margin-left: 6px;
      margin-top: 2px;
    }
  }

  &__filterPopup {
    position: absolute;
    background-color: white;
    border: 1px solid $blue;
    width: 390px;
    z-index: 200;
    padding: 6px 2px;
    &:focus {
      outline: none;
    }
  }
  &__filterColTitle {
    margin-bottom: 4px;
  }

  &__notFound.alt-col-xs-9 {
    flex: 2;
    color: black;
    text-align: center;
    padding-top: 60px !important;
    margin-left: 12.5%;
    &Header {
      display: block;
      text-transform: uppercase;
    }
  }
  &__linkButton {
    color: $blue-dark;
    cursor: pointer;
    display: inline;
  }

  &__invisible {
    background-color: $blue-light;
    padding: 4px;
    font-weight: bold;
  }
}

.alt-col-xs-1 {
  width: 8.333%;
  float: left;
  padding: 2px;
  box-sizing: border-box;
}
.alt-col-xs-2 {
  width: 16.667%;
  float: left;
  padding: 2px;
  box-sizing: border-box;
}
.alt-col-xs-3 {
  min-width: 25%;
  max-width: 25%;

  float: left;
  padding: 2px;
  box-sizing: border-box;
}
.alt-col-xs-4 {
  width: 33.333%;
  float: left;
  padding: 2px;
  box-sizing: border-box;
}
.alt-col-xs-6 {
  width: 50%;
  float: left;
  padding: 2px;
  box-sizing: border-box;
}
.alt-col-xs-8 {
  width: 66.667%;
  float: left;
  padding: 2px;
  box-sizing: border-box;
}
.alt-col-xs-9 {
  width: 75%;
  float: left;
  padding: 2px;
  box-sizing: border-box;
}
.alt-col-xs-12 {
  width: 100%;
  float: left;
  padding: 2px;
  box-sizing: border-box;
}

.IssuesView__scrollAreaInner {
  border: 1px solid #cbcbcb;
  border-top: none;
  border-bottom: none;
  width: 881px;
  &__noScroll {
    width: 100%;

    .IssueMessage {
      width: 100%;
    }
  }
}

.IssuesList {
  float: none;
}
