@import '../utils/variables';

.TreeElement .TreeElementsList {
  //margin-top: -50px;
  //margin-bottom: 50px;
  min-height: 50px;

  &__empty {
    margin-top: 0;
    margin-bottom: 0px;
  }
}

.TreeElementsList {

  &__control {
    //padding: 10px 0;
    //height: 50px;
	display: none;

    .btn {
      float: right;
    }

    select {
      width: 100%;
      height: 24px;
      background-color: transparent;
      border: 1px solid $gray;
      border-radius: 3px;
      line-height: 20px;
      text-align: center;
    }
  }

  &__empty {
    height: 34px;

    .loader_center {
      position: relative;
      width: 100%;
      margin-top: 15px;

      .Loader {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
}

/*
.TreeCol_double.TreeElement {
  background-color: red;
  .TreeCol_double .CollapsableList .TreeElementsList {
    margin-bottom: 0;
  }
}
*/
