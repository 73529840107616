@import '../../styles/utils/variables';

.SelectView {
  &__upper {
    background-color: $blue;
    color: white;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 6px;
    font-size: 16px;
    height: 20px !important;
    position: fixed;
    width: 100%;
    left: 0;
    top: 105px;
    z-index: 110;
  }

  &__content {
    padding: 58px 0;
  }

  &__title {
    margin-bottom: 40px;
    font-size: 22px;
    text-align: center;
  }

  h1 {
    .ObjectPicture {
      span {
        font-size: 22px;
      }
    }
  }

  .loader_center {
    position: relative;
    width: 100%;

    .Loader {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

.SelectView__tabs-navigation .tabs-navigation {
  background-color: white !important;
}
.ViewButton {
  box-sizing: border-box;
  position: relative;
  width: 170px;
  height: 179px;
  margin-right: 30px;
  margin-bottom: 30px;
  padding: 30px 20px 10px;
  border: 0;
  color: $light;
  font-size: 22px;
  text-align: center;
  border-radius: 8px;

  &:last-child {
    margin-right: 0;
  }

  &_red {
    background-color: $red-darker;
  }
  &_green {
    background-color: $green;
  }
  &_blue {
    background-color: $blue;
  }
  &_yellow {
    background-color: $yellow;
  }
  &_violet {
    background-color: $violet;
  }

  &__icon {
    margin: 5px auto 40px auto;
    max-width: 80px;
  }

  &__label {
    position: absolute;
    bottom: 15px;
    left: 50%;
    transform: translateX(-50%);
  }
}
