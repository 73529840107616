@import '../../styles/utils/variables';

.MajorObjectView {
  &__info {
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid $yellow;

    &_collapsed {
      border-bottom: 1px solid #fff;
      padding: 0;

      & > .row:first-child {
        overflow: hidden;
        height: 0;
      }
    }

    .row {
      position: relative;
    }
  }

  &__collapse {
    cursor: pointer;
    box-sizing: border-box;
    position: absolute;
    right: 9px;
    bottom: -32px;
    width: 70px;
    height: 22px;
    line-height: 22px;
    text-align: center;
    background-color: $yellow;
    transform: rotateZ(180deg);

    &_closed {
      bottom: -22px;
      transform: rotateZ(0deg);
    }
  }

  &__showJson {
    display: inline-block;
    //    float: right;
    right: 0;
    position: absolute;
    margin-right: 20px;

    & > span {
      //position: absolute;
      //right: 18px;
      //top: 38px;
      cursor: pointer;
      color: $blue-dark;

      &:before,
      &:after {
        position: relative;
        top: -1px;
        font-weight: bold;
      }

      &:before {
        content: '{';
      }

      &:after {
        content: '}';
      }

      &:hover {
        color: $blue-dark;
        font-weight: bold;
        background-color: transparent;
      }
    }
  }

  &__showChart {
    display: inline-block;
    //    float: right;
    right: 0;
    position: absolute;
    margin-right: 20px;

    span {
      //position: absolute;
      //right: 18px;
      //top: 38px;
      cursor: pointer;
      color: $blue-dark;

      span,
      a {
        color: $blue-dark;
      }

      &:before,
      &:after {
        position: relative;
        top: -1px;
        font-weight: bold;
      }

      &:before {
        content: '{';
      }

      &:after {
        content: '}';
      }

      &:hover {
        color: $blue-dark;
        font-weight: bold;
        background-color: transparent;
      }
    }
  }
  // default - for Application
  .MajorObjectView__connections .SimpleTable__headerCell,
  .Old_ObjectUserList__table .SimpleTable__headerCell,
  .DataDialog .SimpleTable__headerCell,
  .SettingsDialog .SimpleTable__headerCell,
  .TagsDialog .SimpleTable__headerCell {
    //background-color: $yellow;
    //color: $gray-dark;
    color: $light;
  }

  &_application {
    .MajorObjectView__connections .SimpleTable__headerCell,
    .Old_ObjectUserList__table .SimpleTable__headerCell,
    .DataDialog .SimpleTable__headerCell,
    .SettingsDialog .SimpleTable__headerCell,
    .TagsDialog .SimpleTable__headerCell {
      background-color: $yellow;
      color: $gray-dark;
      //color: $light;
    }
  }

  .MajorObjectJson {
    position: fixed;
    top: 200px;
    width: 1200px;
    left: calc((100% - 1200px) / 2);
    padding: 20px;
    background-color: white;
    border: 2px solid $blue-dark;
    z-index: 1000;
  }

  &_dataset,
  &_layout,
  &_interface,
  &_view,
  &_pipeline {
    .MajorObjectView {
      &__info {
        border-color: $blue-deep;
      }

      &__collapse {
        background-color: $blue-deep;
        color: $light;
      }
    }

    .EditableTable__header {
      background-color: $blue-deep;
    }

    .MajorObjectView__connections .SimpleTable__headerCell,
    .Old_ObjectUserList__table .SimpleTable__headerCell,
    .DataDialog .SimpleTable__headerCell,
    .SettingsDialog .SimpleTable__headerCell,
    .TagsDialog .SimpleTable__headerCell {
      background-color: $blue-deep;
      color: $light;
    }
  }

  .MajorObjectView__connections {
    thead tr {
      //height: 32px;
    }
  }

  .MajorObjectView__connections .SimpleTable__headerCell,
  .Old_ObjectUserList__table .SimpleTable__headerCell,
  .DataDialog .SimpleTable__headerCell,
  .SettingsDialog .SimpleTable__headerCell,
  .TagsDialog .SimpleTable__headerCell {
    font-weight: normal;
    padding: 5px;
  }

  &_organization {
    .MajorObjectView {
      &__info {
        border-color: $turquoise;
      }

      &__collapse {
        background-color: $turquoise;
        color: $light;
      }
    }

    .EditableTable__header {
      background-color: $turquoise;
    }

    .MajorObjectView__connections .SimpleTable__headerCell,
    .Old_ObjectUserList__table .SimpleTable__headerCell,
    .DataDialog .SimpleTable__headerCell,
    .SettingsDialog .SimpleTable__headerCell,
    .TagsDialog .SimpleTable__headerCell {
      background-color: $turquoise;
      color: $light;
    }
  }

  &_system {
    .MajorObjectView {
      &__info {
        border-color: $green;
      }

      &__collapse {
        background-color: $green;
        color: $light;
      }
    }

    .EditableTable__header {
      background-color: $green;
    }

    .MajorObjectView__connections .SimpleTable__headerCell,
    .Old_ObjectUserList__table .SimpleTable__headerCell,
    .DataDialog .SimpleTable__headerCell,
    .SettingsDialog .SimpleTable__headerCell,
    .TagsDialog .SimpleTable__headerCell {
      background-color: $green;
      color: $light;
    }
  }

  &_field {
    .MajorObjectView {
      &__info {
        border-color: $blue-deep;
      }

      &__collapse {
        background-color: $blue-deep;
        color: $light;
      }
    }

    .EditableTable__header {
      background-color: $blue-deep;
    }

    .MajorObjectView__connections .SimpleTable__headerCell,
    .Old_ObjectUserList__table .SimpleTable__headerCell,
    .DataDialog .SimpleTable__headerCell,
    .SettingsDialog .SimpleTable__headerCell,
    .TagsDialog .SimpleTable__headerCell {
      background-color: $blue-deep;
      color: $light;
    }
  }

  &_publication {
    .MajorObjectView {
      &__info {
        border-color: $violet;
      }

      &__collapse {
        background-color: $violet;
        color: $light;
      }
    }

    .EditableTable__header {
      background-color: $violet;
    }

    .MajorObjectView__connections .SimpleTable__headerCell,
    .Old_ObjectUserList__table .SimpleTable__headerCell,
    .DataDialog .SimpleTable__headerCell,
    .SettingsDialog .SimpleTable__headerCell,
    .TagsDialog .SimpleTable__headerCell {
      background-color: $violet;
      color: $light;
    }
  }

  &_subscription {
    .MajorObjectView {
      &__info {
        border-color: $red-darker;
      }

      &__collapse {
        background-color: $red-darker;
        color: $light;
      }
    }

    .EditableTable__header {
      background-color: $red-darker;
    }

    .MajorObjectView__connections .SimpleTable__headerCell,
    .Old_ObjectUserList__table .SimpleTable__headerCell,
    .DataDialog .SimpleTable__headerCell,
    .SettingsDialog .SimpleTable__headerCell,
    .TagsDialog .SimpleTable__headerCell {
      background-color: $red-darker;
      color: $light;
    }
  }

  &__connections {
    background: linear-gradient(to bottom, $gray-light 50px, $light 50px, $light 100%);

    .tabs-list {
      .tab-panel {
        padding-top: 0;
      }
    }
  }

  .TableHeaderCell_rating,
  .TableHeaderCell_subscriptions {
    text-align: center;
  }

  .TableHeaderCell_pubsub {
    padding-left: 10px;
  }

  .TableCell_subscriptions {
    padding-right: 5px;
  }

  .TableCell_pubsub {
    padding-left: 30px;
  }

  .tabs-menu-item.is-active {
    a {
    }

    div.MajorObjectView__tabInfo {
      display: inline-block;
      width: 28px;
      height: 28px;
      position: relative;
      top: 7px;
      margin-left: 13px;
      background-size: 28px 28px;
      background-repeat: no-repeat;
      border: 1px solid #6cd2dc;
      border-radius: 100px;
      background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADgAAAA4CAYAAACohjseAAABtklEQVR4Xu2aPU6EUBSFdWI/s4PRxB5cgbgCZwfSWomFtVjbYGWLOxhWIO4AeothB7oCPJOc4mWC/CQiOeSSnLxMbjF8OZf73gkc13V9NOdrIQBggAaoLgMUlgGe/DfA+fPrKZYEunYKJRR9Ptzmsg4SzsdSuHCUB70TXrpFt9CypR5LAtK9DZZ1B4C0g2hPm6KFMuCuo/4NJcqAW6j6rchtYicLiJv/whJAmVvg7wvU0zH+1wKvmAzQAC1NdCeJiNPUYyHDBN0QQG+KEizgQfqyqc5topBzkPEoIVjbtZJpUYKt6Njd7IYM27Eg3HyGDOFiLI8jpIhpHSRc2gD30QOu3J9TFVo0cGLPE3SGGw96AOYqLRpSER3hFJ0JIKDyBjd6ASqniWCE508KMJdNE9zs1+KA3e4JAE71/OkD5uqB1xsCqJYmBj1/HEjJXn+dCxcTtWflnHZCpo8bKBVwsBfgiskjPNhKChVAv6O+bEgeb3A1VBkyy4EG3BNOItH7A8Aq6Apwicw2MWAKvkA+4GTyoKuq5RyaQbG7HSgChgcfHJRMDonzDnBk2eszAzRAAzRAAzTAH2HOjflcr9icAAAAAElFTkSuQmCC);
    }

    div.MajorObjectView__tabInfo:hover {
      background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAsCAYAAAAehFoBAAACfUlEQVR4XtWZ0Y2bQBCGOeR3OxXEUp4jSAXeEnAFJu+JjlQQu4ON0gCuwFfCUkEgeU0kroNzBc6P9EtYyKvgncE5j/SJB0vDp2XwzC4Pp9MpCo3Pv/6kuBiyBIknQQNa4Dq+v39XR4FB4askF7jkoABvQUg0oOyA/MskwhQtyBxoxBHYjrHi8UjZDJcafNWSJXPmrHkPmTBlLS6H/vHrw9wH3iusJFgCDiTgllGBzFcisVhWnxVwdBhdEk8Ksg3YkepK6QS4USXBOnoUyq7xSJ8GeUtcNleKf0OewrvCfFOlsruhLLEBuR7pNBRm3TKpkNIj8BKYz9KNwgxP59IkF/zlFb3weRfTie2FxmOE+Qs6RjMK5IodbAPB5dlbbsBK2hHpaHth3VgRzSiAffj083cKgR/gHuJDzEd2L2GmFK7Y5faawjPuFKLJ5lvuTJTmkuWMiaYexCkvJpkpPfocom10g4iFsl8gakDL5uAfGZVCssJ7UEJ0e9bFFp4uF70G4Q3I+g7pnXmz11MSlCXOI2C0hRulXM6z1UoUfZtOWOXtxovnplxd0nbCTqmrRTcQdjrC/hyZujAP5p6FwvWF+l0OdjBHaf12rjEFrPYKD2aUHUiF0uV5pysFyRrP/FCDj+ANft9y6zQXzCq9MG9o5eVAmBOULA/HRhMaHKooTGxgLbf/OPVsJbMEneyg03GVw3a25oKo4aoeFDa3Bd1Uj6r2XMkFyATnG96jKq8wpWtxS5VTQdaMHX6MaMaQ0/iaTux7wyld/SdZQ4dRJ/DCmpbXrGgeZoJ13771Ye41ZeUDPM97U7bYo+qxANs27zHdh0WSSJqB4MPi/Xy6/QsYfBdp7FSspgAAAABJRU5ErkJggg==);
    }

    .SimpleTooltip__tooltip {
      font-size: 14px;
      background-color: $blue-light;
      color: black;
      z-index: 2500;

      &:after {
        border-bottom-color: $blue-light;
      }
    }
  }

  &__loading {
    width: 100px;
    margin: 0 auto;

    h1 {
      text-align: center;
      margin: 0 auto;
    }

    .Loader {
      margin-top: 40px;
    }
  }

  &__userRoleInHeader {
    position: fixed;
    top: 45px;
    left: 50%;
    z-index: 200;
    margin-left: 53px;
  }

  .SimpleTable__headerCell_rating {
    text-align: center;
  }
}

.MajorObjectView__PublicationHeader {
  display: flex;
  flex-direction: row;
}
.MajorObjectView__PublicationLayoutsHeader {
  display: inline-block;
  flex: 0 0 auto;
  flex-basis: 16.667%;
  max-width: 16.667%;
}
.MajorObjectView__PublicationFieldsHeader {
  display: inline-block;
  flex: 0 0 auto;
  flex-basis: 83.333%;
  max-width: 83.333%;
}

.MajorObjectView_dataset {
  .ModalWindow__body {
    //min-height: 600px;
  }
}
.Organization__datasetEditButton {
  text-align: right;
  position: absolute;
  width: 1182px;
  margin-top: 10px;
  z-index: 20;
}

.MajorObjectView_application {
  .ObjectProperties {
    float: left;
    display: inline-block;
  }
}
.MajorObjectView__sqlDesignerButton.btn.btn_blue {
  min-width: 100px;
  margin-left: 10px;
}

.MajorObject__backArrowTabs {
  position: absolute;
  margin-left: -38px;
  margin-top: 15px;
  height: 28px;
  width: 28px;
  border-radius: 50%;
  background-color: white;
  border: 2px solid #cccccc;
  cursor: pointer;
  .MajorObject__backArrowTabsHover {
    margin-left: -2px;
    margin-top: -2px;
    position: absolute;
    background-color: transparent;
    border-radius: 50%;
    border-width: 2px;
    border-style: solid;
    border-color: #cccccc;
    display: none;
    height: 28px;
    width: 28px;
  }
  &:hover {
    .MajorObject__backArrowTabsHover {
      display: block;
    }
  }

  img {
    position: relative;
    top: 0px;
    left: 0px;
    height: 24px;
  }
  .MajorObject__backArrowHover {
    display: none;
  }
  &:hover {
    .MajorObject__backArrowNormal {
      display: none;
    }
    .MajorObject__backArrowHover {
      display: block;
    }
  }
}

.MajorObject__forwardArrowTabsOuter {
  position: absolute;
  margin-left: -38px;
  margin-top: 41px;
  height: 30px;
  width: 30px;
}
.MajorObject__forwardArrowTabs {
  height: 28px;
  width: 28px;
  border-radius: 50%;
  background-color: white;
  border: 2px solid #cccccc;
  z-index: 10;
  position: absolute;
  //margin-left: 10px;
  margin-left: 0;
  cursor: pointer;
  .MajorObject__forwardArrowTabsHover {
    margin-left: -2px;
    margin-top: -2px;
    position: absolute;
    background-color: transparent;
    border-radius: 50%;
    border-width: 2px;
    border-style: solid;
    border-color: #cccccc;
    display: none;
    height: 28px;
    width: 28px;
    z-index: 15;
  }
  .MajorObject__forwardArrowImageHover {
    display: none;
    //margin-left: 1px;
  }
  &:hover {
    .MajorObject__forwardArrowTabsHover {
      display: block;
    }
    .MajorObject__forwardArrowImageNormal {
      display: none;
    }
    .MajorObject__forwardArrowImageHover {
      display: block;
    }
  }
  &White {
    display: none;
    height: 31px;
    margin-top: -3px;
    margin-left: 7px;
    width: 31px;
    border-radius: 50%;
    border: 4px solid white;
    z-index: 5;
    position: absolute;
  }

  &Background {
    width: 30px;
    height: 30px;
    position: absolute;
    z-index: 0;
    margin-left: 20px;
  }

  img {
    position: relative;
    top: 0px;
    left: 1px;
    height: 24px;
    z-index: 10;
  }
}

.MajorObject__pasteDialog {
  button {
    max-width: 200px;
  }
}

.SimpleTable {
  a {
    margin-right: 1px;
  }
  .SimpleTable__headerCell:nth-child(2) {
    padding-left: 11px;
  }
}

.MajorObject {
  &__expandButton {
    float: right;
    margin-top: 3px;
    //margin-bottom: 5px;
    box-sizing: border-box;
    width: 16px;
    height: 16px !important;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAAZElEQVR4Xu2UMQ5AUBAFn42bqN2MM3x7Bjej5SyEDhHlU8x0Pz+bnWwxVTctg6QiDxnnch8lZMYugECtF8a2ubz7eX38f3Cf/+cFEDhKuNEBtwAdoAN0gA7QATpAB+gAAikfuQO8SSEol2adWQAAAABJRU5ErkJggg==);
    background-size: 16px 16px;
    //background-color: $gray-light;
    //color: $blue !important;
    line-height: 16px !important;
    font-weight: bold;
    text-align: center;
    //border-radius: 3px;
    //border: 1px solid;
    cursor: pointer;
    &__active {
      background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAAXUlEQVR4Xu2UwQmAQBDE4mInvi1NWzi3BUvTr/ZiBT6Eg/WRVDCQIcNyXBvQqCEDaNTRgmKCYhww8sI+T/RkPe+fKvADX52pwA7YATugAjvQ+zMqsAMqcEAASR35AP6cFUGVYyrbAAAAAElFTkSuQmCC);
    }
    &__dark {
      background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAAY0lEQVR4Xu2UwQ1AUBAFn416dCB09m1nQgU0RLgh4vgcZm4/P5ud7GGqbloGSUUeMs7lPkrIjF0AgVovjG1zeffz+vj/4D7/zwsgcJRwowNuATpAB+gAHaADdIAO0AEEUj5yB3abIAicMJuvAAAAAElFTkSuQmCC);
    }
    &__dark.MajorObject__expandButton__active {
      background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAAW0lEQVR4Xu2UwQmAQBDE4mI9diDa2bmdiVagFVmBD+FgfSQVDGTIsBzXBjRqyAAadbSgmKAYB4y8sM8TPVnP+6cK/MBXZyqwA3bADqjADvT+jArsgAocEEBSRz6tgBQdm6O+pwAAAABJRU5ErkJggg==);
    }
  }
}

.MajorObject {
  &__expandStructureButton {
    float: right;
    margin-top: 3px;
    //margin-bottom: 5px;
    box-sizing: border-box;
    width: 16px;
    height: 16px !important;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAAY0lEQVR4Xu2VsQmAMBQFn8FNrB1NV4h/BUfTNu5i+bFUhAfhrkuVg3skw3K0TVKVhyh5uYVaZMYugAACCIz6yD5Pj/N6Xr0mYAPZ/O0m+krABrIp7wB/QTYnwW8CCCCAQMhH3Ft1FAEprkubAAAAAElFTkSuQmCC);
    background-size: 16px 16px;
    //background-color: $gray-light;
    //color: $blue !important;
    line-height: 16px !important;
    font-weight: bold;
    text-align: center;
    //border-radius: 3px;
    //border: 1px solid;
    cursor: pointer;

    &__active {
      background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAAWklEQVR4Xu3SwQmAMBBE0THYiWc70xri1pDO9Bp7Ebx4jYcwEP5vYB/MTttZD0lZniK9x33lJHN2AAAAAAAAmNVYWRf9ab/uUSbgB75NmaAbAAAAAAAAhHzFA2MICvuPh5raAAAAAElFTkSuQmCC);
    }

    &__dark {
      background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAAYklEQVR4Xu2VwQmAMBAE12A9diDaWbzORCuIFfk8fCrCQpj55ZWBWZJhOdomqcpDlLzcQi0yYxdAAAEERn1kn6fHeT2vXhOwgWz+dhN9JWAD2ZR3gL8gm5PgNwEEEEAg5CNuA9oTb29aMC0AAAAASUVORK5CYII=);
    }

    &__dark.MajorObject__expandStructureButton__active {
      background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAAWUlEQVR4Xu3SwQmAMBBE0TFYjx2Idha3M9EKYkWCF6/JIQyE/xvYB7PTdpVDUpanSN9xXznJnB0AAAAAAABmVXaui1ra72eUCfiBf1Mm6AYAAAAAAAAhX/EC90sKa5clz2MAAAAASUVORK5CYII=);
    }
  }

  &__locale {
    .col-xs-6 {
      margin-top: -4px;
      padding-left: 0;
      padding-right: 0;
      flex-basis: 64%;
      max-width: 64%;

      &:first-child {
        padding-top: 5px;
      }
    }

    a {
      color: $blue-dark;
    }

    .KascodeSelect:hover {
      outline: none;
    }

    .KascodeSelect__placeholder:hover {
      background-color: transparent;
    }

    .KascodeSelect__input {
      //background-color: $light;
      //color: $gray-dark;
      border: none;
    }
    .KascodeSelect__toggle {
      background-color: transparent;
    }

    .KascodeSelect__options {
      background-color: $light;
      //color: $gray-dark;
    }

    .KascodeSelect__label:hover {
      font-weight: bold;
    }
  }
}

.MajorObjectView_publication {
  .TailButton_Link {
    margin-right: 10px;
  }
}

.MajorObject__emptyOuter {
  text-align: center;
}

.MajorObjectVersioned__finalizeMessage {
  margin-top: 6px;
  &Action {
    display: inline;
    cursor: pointer;
    color: $blue-dark;
    &:hover {
      font-weight: bold;
    }
  }
}

.MajorObjectView_dataset .MajorObjectView__connections .SimpleTable.structure th.SimpleTable__headerCell_index_0 {
  background-color: transparent !important;
}

.MajorObject__PropertiesCol,
.MajorObject__AdvancedSettingsCol {
  flex-basis: 20% !important;
  max-width: 20% !important;
}
