@import '../../styles/utils/variables';

.EditorDialog_package {
  .ModalWindow__main {
    height: 750px !important;
  }

  .ModalWindow__body {
    padding: 1px 18px;
    height: 850px;
    min-height: 150px !important;
    overflow-y: visible; // added because gray scrollbar was appearing inside Add Issue window, when description was filled with long text;
    // as a result, Discard button was shifted to bottom
  }

  .ModalWindow__footer {
    padding-top: 5px;
  }

  .ModalWindow__body,
  .ModalWindow__main,
  .ModalWindow__upper,
  .ModalWindow__footer {
    width: 900px;
    max-width: 100%;
  }

  .scrollarea {
    width: 920px;
  }
}

.PackageDialog {
  .DataEditor__block {
    &_name,
    &_description,
    &_source,
    &_metadata {
      margin-top: 5px;
      margin-bottom: 5px;

      &__value_right {
        width: calc(82% - 20px);
        float: left;

        .TextArea,
        .TextInput,
        .StringData,
        .MultilineText__text,
        .KascodeSelect_input,
        .KascodeSelect__placeholder {
          padding-top: 2px;
          height: 28px;
          line-height: 28px;
          font: 16px 'PT Sans', Arial, sans-serif !important;
        }

        &__warning {
          color: red;
          margin-bottom: 5px;
          font: 14px 'PT Sans', Arial, sans-serif !important;
        }
      }
    }

    &_order {
      margin-top: 0px;
      margin-bottom: 1px;

      &__value_right {
        width: calc(10% + 20px);
        float: left;

        .TextArea,
        .TextInput,
        .StringData {
          padding-top: 2px;
          height: 28px;
          line-height: 28px;
          font: 16px 'PT Sans', Arial, sans-serif !important;
        }
      }
    }

    &_applications {
      margin-top: 12px;
      margin-bottom: 0px;

      &__value_right {
        width: calc(82% - 20px);
        float: left;

        .StringData {
          padding-top: 2px;
          height: 28px;
          line-height: 28px;
          font: 16px 'PT Sans', Arial, sans-serif !important;
        }
      }
    }

    &__label {
      display: block;
      float: left;
      padding-left: 8px;
      padding-right: 10px;
      line-height: 26px;
      width: calc(15% + 20px);
      font-weight: bold;
      font-size: 16px;
    }
  }

  &_borderGray {
    margin-top: -5px;
    padding-bottom: 0px;
    border-bottom: 1px solid $gray;
  }

  .EditorListList .SimpleTable__headerCell {
    background-color: rgb(62, 137, 193);
  }
}
