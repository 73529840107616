@import '../../styles/utils/variables';

.DocumentList {
  &__listHeader {
    div {
      box-sizing: border-box;
      float: left;
      padding: 4px;
      background-color: $blue;
      color: $light;
      font-weight: bold;
    }
  }

  &__header {
    &Name {
      width: 30%;
    }
    &Type {
      width: 20%;
    }
    &Description {
      width: 50%;
    }
  }

  &__item {
    & > div {
      padding: 4px;
    }

    &:hover {
      background-color: $blue-light;

      .DocumentList__itemControls {
        display: block;
      }
    }
  }

  &__itemName,
  &__itemType,
  &__itemDescription {
    box-sizing: border-box;
    position: relative;
    float: left;
  }
  &__itemName {
    width: 30%;
  }
  &__itemType {
    width: 20%;
  }
  &__itemDescription {
    width: 50%;
  }

  &__controls {
    margin-top: 10px;
  }

  &__itemControls {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    display: none;
    padding-left: 10px;

    &:before {
      content: '';
      display: block;
      position: absolute;
      top: -4px;
      left: 0;
      height: calc(100% + 8px);
      width: 0;
      border-right: 1px solid $blue;
    }

    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 50%;
      left: -1px;
      height: 6px;
      width: 6px;
      border-top: 1px solid $blue;
      border-left: 1px solid $blue;
      background-color: $blue-light;
      transform: rotate(-45deg) translateY(-60%);
    }

    div {
      display: inline-block;
      margin-right: 8px;
      cursor: pointer;
      color: $blue-sea-dark;

      &:hover {
        font-weight: bold;
      }
    }

    img {
      position: relative;
      top: -2px;
      height: 14px;
      vertical-align: text-bottom;
    }
  }

  &__listItem {
    cursor: pointer;
    img {
      position: relative;
      top: -1px;
      margin-left: 4px;
      height: 14px;
      vertical-align: middle;
    }
  }

  &__button.btn_blue {
    width: 150px;
    margin: 10px;
    display: inline-block;
    float: left;
  }
  &__editUriRow {
    width: 600px;
    padding-bottom: 10px;
  }

  .EditButton {
    display: none;
  }
  &:hover .EditButton {
    display: inline-block;
  }
  &:hover {
    .List__title {
      font-weight: bold;
    }
  }
  .btn_edit:hover {
    font-weight: bold;
  }
  .SimpleTable__headerCell {
    color: $light;
    background-color: $blue;
    padding-bottom: 4px;
  }
}
