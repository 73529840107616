@import '../../styles/utils/variables';

.SQLDesigner__modal {
  .ModalWindow__body,
  .ModalWindow__main,
  .ModalWindow__upper,
  .ModalWindow__footer {
    width: 1150px;
  }
  .scrollarea {
    width: 1170px;
  }

  .ScrollArea {
    width: 1170px;

    &__container {
      max-width: 1150px;
      width: calc(100% - 20px);
    }
  }
}
.SQLDesigner__fullSize {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 2000;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  //overflow-x: hidden;
  background-color: white;

  .SQLDesigner__root #area {
    height: calc(100% - 100px);
  }

  #bottom_area {
    position: fixed;
    left: 0;
    bottom: 20px;
    z-index: 3000;
    width: 100%;
  }
  .SQLDesigner__topRow {
    margin-top: 5px;
    max-width: 800px;
  }
}
.SQLDesigner__headerViews {
  float: left;
  display: inline-block;
  min-width: 300px;
  .KascodeSelect {
    width: 200px;
    min-width: auto;
  }
}
.SQLDesigner__controlRow.row {
  float: left;
  min-width: 300px;
  margin-top: 5px;
  .SQLDesigner__link {
    font-size: 16px;
  }
  .SQLDesigner__closeButton {
    margin-top: 6px;
  }
}
.SQLDesigner__controlRowRight.row {
  float: right;
  min-width: 252px;
  margin-top: 5px;
  .SQLDesigner__link {
    font-size: 16px;
  }
  .ModalWindow__headerEditButton {
    float: left;
  }
  .SQLDesigner__closeButton {
    margin-top: 6px;
    padding-left: 0;
    //margin-right: 2px;

    .SQLDesigner__link {
      margin-left: 0;
    }
  }
}
.SQLDesigner__fullSize {
  .SQLDesigner__hint {
    position: fixed;
    right: 20px;
  }
  #bottom_area .SQLDesigner__hint {
    right: 0px;
  }
}
.SQLDesigner__collapseButton {
  background-color: $blue;
  transform: rotateZ(0deg);
  position: relative;
  width: 50px;
  right: 40px;
  top: 5px;
  outline: 10px solid white;
  z-index: 50;
  margin-left: -30px;
  float: right;
  display: inline-block;
  cursor: pointer;

  &__collapse {
    transform: rotateZ(180deg);
  }
}
.SQLDesigner__bottomArea__collapse {
  height: 36px;
  overflow: hidden;
  .table {
    //display: none !important;
  }
}
