@import '../../styles/utils/variables';

.EditorDialog {
  &__header {
    flex: 0 0;
  }

  &__headerControl {
    flex: 1 0;
    padding-top: 3px;
    padding-right: 40px;
    float: right;
    display: inline-block;

    .EditorButton div {
      white-space: nowrap;
    }
  }

  &__headerVersion {
    flex: 1 0;
    margin-top: -4px;
    padding-top: 0px;
    padding-right: 0px;
    float: right;
    display: inline-block;
    width: 100%;
  }

  .__row {
    min-height: 20px;
  }

  .col-xs-3 {
    padding-left: 50px;
    padding-top: 0px;
    flex-basis: 20%;
    min-width: 60%;
    max-width: 70%;
  }

  .col-xs-4 {
    padding-left: 0px;
    padding-top: 0px;
    flex-basis: 20%;
    min-width: 40%;
    max-width: 60%;
  }

  &__headerButton {
    flex: 1 0;
    padding-top: 9px;
    padding-right: 30px;
    float: right;
    display: inline-block;
  }

  &__restore {
    display: inline-block;

    a {
      padding-right: 10px;
      color: $red;
      display: inline-block;
      cursor: pointer;
      font-size: 18px;

      &:hover {
        font-weight: bold;
        background-color: transparent;
      }
    }
  }

  .EditorButton__cancel {
    min-width: 80px;
  }

  .EditButton__btn {
    display: inline-block;
    cursor: pointer;
    //line-height: 20px;
    //color: $blue-dark;
    //color: #3C3C3C;
    //font-size: 14px;
    &:hover {
      font-weight: bold;
      background-color: transparent;
    }
  }

  .EditButton__edit {
    margin-right: 5px;

    &:hover {
      font-weight: bold;
    }
  }

  .EditButton__save {
    //color: $orange-red;
    color: #3c3c3c;
  }

  &__bgBlock {
    background-color: $blue-deep;
    color: #fff;
  }

  &__block {
    margin-bottom: 12px;
  }

  p {
    margin: 0;
  }

  &__statusMessage {
    margin-bottom: 10px;
  }

  .btn_big {
    width: 170px;
  }

  &__error {
    margin-bottom: 10px;
    color: red;
    text-align: center;
  }

  &__success {
    margin-bottom: 10px;
    color: green;
    text-align: center;
  }

  &__message {
    color: green;
    text-align: center;
  }

  &__loader {
    margin-bottom: 10px;
    text-align: center;

    .Loader {
      display: inline-block;
      transform: scale(1);
    }
  }

  .label_left {
    display: block;
    float: left;
    padding-right: 10px;
    line-height: 26px;
    width: 15%;
  }

  .value_right {
    width: 80%;
    float: left;
    line-height: 26px;

    input[type='text'],
    textarea {
      width: 100%;
    }
  }

  &__value_right {
    width: calc(80% + 20px);
    float: left;
    line-height: 26px;

    input[type='text'],
    textarea {
      width: 100%;
    }
  }

  &__items_left {
    width: calc(5% + 20px);
    float: left;
    line-height: 26px;

    input[type='text'],
    textarea {
      width: 100%;
    }
  }

  .value_right_order {
    width: 15%;
    float: left;
    line-height: 26px;

    input[type='text'],
    textarea {
      width: 100%;
    }
  }

  &__value_right_order {
    width: 15%;
    float: left;
    line-height: 26px;

    input[type='text'],
    textarea {
      width: 100%;
    }
  }

  &__label {
    display: block;
    float: left;
    padding-right: 10px;
    line-height: 26px;
    width: 15%;
    font-weight: bold;
    font-size: 16px;

    a {
      font-weight: normal;
    }
  }

  &__block_name {
    margin-bottom: 5px;
  }

  &__block_description {
    margin-bottom: 1px;
  }

  &__block_order {
    margin-bottom: 5px;
  }

  .ModalWindow__windowHead {
    padding-bottom: 10px;
    background-color: #cdeff3;
    border: 1px solid #6dd2db;
  }

  .ModalWindow__body {
    //border-left: 1px solid #6DD2DB;
    //border-right: 1px solid #6DD2DB;
  }

  .ModalWindow__footer {
    /*
    border-bottom: 1px solid #6DD2DB;
    border-left: 1px solid #6DD2DB;
    border-right: 1px solid #6DD2DB;
    */
    border-top: 1px solid $gray;
  }

  .ModalWindow__upper {
    /*
    border-top: 1px solid #6DD2DB;
    border-left: 1px solid #6DD2DB;
    border-right: 1px solid #6DD2DB;
    */
  }

  .EditButton__icon {
    width: 16px;
    height: 16px;
    top: 4px;
  }

  .EditButton_noedit {
    &:hover {
      font-weight: normal;
    }
  }

  .DataDialog__propertyNameText {
    display: inline-block;
    width: calc(95% - 20px);
  }

  .DataDialog__propertyName .SimpleTooltip__root {
    float: right;
    opacity: 0;
  }

  .DataDialog__propertyName:hover .SimpleTooltip__root {
    opacity: 1;
  }
}

.EditorDialogItems {
  display: inline-block;
  position: relative;
  margin-bottom: 2px;
  overflow: hidden;
  text-align: left;
  width: 100%;

  .EditorDialogItem.row {
    border-left: 1px solid #cdeff3;
    border-right: 1px solid #cdeff3;
    border-bottom: 1px solid #cdeff3;
  }

  .EditorDialogItem__name.col {
    font-weight: bold;
    min-width: 100px;
  }

  &__button {
    background-color: $blue-light;
    width: 100%;
    padding: 4px 0;
    text-align: center;
    font-size: 16px;
    color: #218a96;
    cursor: pointer;

    &:hover {
      font-weight: bold;
      background-color: #a8e3ea;
    }

    &__viewing {
      background-color: #d9cded;
      border-color: #8f70cb;

      &:hover {
        background-color: #a270cb99;
      }
    }
  }

  .EditButton {
    display: none;
  }

  &:hover .EditButton {
    display: inline-block;
  }

  .ItemList {
    margin-bottom: 10px;

    &__title {
      color: $blue-dark;
      font-size: 18px;

      &_active {
        font-size: 18px;
        cursor: pointer;
        color: $blue-dark;

        &:hover {
          //font-size: 16px;
          color: $blue-sea-dark;
        }
      }

      &_pointer {
        cursor: pointer;
      }
    }

    &_left {
      display: block;
      width: calc(5% + 20px);
      float: left;
      line-height: 26px;

      input[type='text'],
      textarea {
        width: 100%;
      }
    }

    &_right {
      margin-top: 4px;
      width: calc(90% + 5px);
      float: left;
      font-size: 16px;
      line-height: 20px;

      input[type='text'],
      textarea {
        width: 100%;
      }

      .MultilineText__text {
        text-align: left;
        text-align-last: left !important;
      }
    }
  }

  &:hover {
    .ItemList {
      &__title {
        font-weight: bold;
      }

      &__editButton {
        position: absolute;
        margin-left: calc(90% + 5px);
        z-index: 2;
        //position: relative;
        top: 2px;
        display: block;
        float: right;
        background-color: $light;
      }

      &_right {
        margin-top: 4px;
        width: calc(90% + 5px);
        float: left;
        font-size: 16px;
        line-height: 20px;

        input[type='text'],
        textarea {
          width: 100%;
        }
      }
    }
  }

  &__list {
    text-align: left;
  }

  &__name {
    float: left;
    width: 50%;
    font-weight: bold;
    font-size: 16px;
  }

  &__value {
    position: relative;
    top: 1px;
    float: left;
    width: 50%;
  }

  &__name_base {
    float: left;
    width: 50%;
    color: darkgrey;
    font-weight: bold;
    font-size: 16px;
  }

  &__value_base {
    position: relative;
    top: 1px;
    float: left;
    width: 50%;
    color: darkgrey;
  }

  &__more {
    text-decoration: underline;
    color: red;
    cursor: pointer;
    position: absolute;
    right: 0px;
    bottom: -1px;
    display: none;

    &__visible {
      display: inline;
    }

    img {
      height: 4px;
    }
  }
}

.EditorDialogItems,
.EditorDialogItems__button {
  &:hover > {
    .EditorDialogItem__buttonImg {
      background-size: 23px 20px;
      width: 23px;
      height: 20px;
      margin-top: -5px;
      margin-right: 4px;
    }
  }

  .EditorDialogItem__buttonImg {
    display: inline-block;
    width: 21px;
    height: 18px;
    vertical-align: middle;
    margin-right: 6px;
    margin-top: -4px;
    background-size: 21px 18px;
  }
}

.EditorDialog__viewing,
.EditorDialog__editing .EditorDialog__viewing {
  & > .ModalWindow__background > .ModalWindow__main > .ModalWindow__windowHead {
    background-color: #d9cded;
    border: 1px solid #8f70cb;
  }

  .ModalWindow__body,
  .ModalWindow__footer,
  .ModalWindow__upper {
    //border-color: #8F70CB;
    //border: 1px solid #8F70CB;
  }

  .ModalWindow__footer {
    //border-top: 1px solid $gray;
  }

  .btn_big,
  .btn_blue,
  .ObjectSelector_collapse_closed,
  .IssueDialog__collapse_closed {
    background-color: #a270cbc2; //#A270CB;
    border-color: #8f70cb;

    &:hover {
      background-color: #8f70cb;
    }

    &.disabled {
      background-color: #cbcbcb;
    }
  }

  .scrollarea .scrollbar-container.vertical .scrollbar {
    background-color: #8f70cb !important;

    &:hover {
      background-color: #8f70cb !important;
    }
  }

  .ScrollArea {
    &::-webkit-scrollbar-thumb {
      background: #8f70cb;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #8f70cb;
    }
  }

  .EditorDialogItems {
    &__button {
      background-color: #d9cded;
      border-color: #8f70cb;
      width: 100%;
      padding: 4px 0;
      text-align: center;
      font-size: 17px;
      font-weight: bold;
      color: #218a96;
      cursor: pointer;

      &:hover {
        font-weight: 400;
        color: white;
        background-color: #a270cb99;
      }
    }
  }
}

.EditorDialog__editing,
.EditorDialog__viewing .EditorDialog__editing {
  & > .ModalWindow__background > .ModalWindow__main > .ModalWindow__windowHead {
    background-color: #cdeff3;
    border: 1px solid #6dd2db;
  }

  .ModalWindow__body,
  .ModalWindow__footer,
  .ModalWindow__upper {
    //border-color: #8F70CB;
    //border: 1px solid #8F70CB;
  }

  .ModalWindow__footer {
    //border-top: 1px solid $gray;
  }

  .btn_big,
  .btn_blue,
  .ObjectSelector_collapse_closed,
  .IssueDialog__collapse_closed {
    background-color: #6cd2dc;
    border-color: #6dd2db;

    &.disabled {
      background-color: #cbcbcb;
    }

    &:hover {
      background-color: #3dc2d1;
    }
  }

  .scrollarea .scrollbar-container.vertical .scrollbar {
    background-color: #6cd2dc !important;

    &:hover {
      background-color: #3dc2d1 !important;
    }
  }

  .ScrollArea {
    &::-webkit-scrollbar-thumb {
      background: #6cd2dc;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #3dc2d1;
    }
  }

  .EditorDialogItems {
    &__button {
      background-color: #cdeff3;
      width: 100%;
      padding: 4px 0;
      text-align: center;
      font-size: 17px;
      font-weight: bold;
      color: #218a96;
      cursor: pointer;

      &:hover {
        font-weight: 400;
        //color: white;
        background-color: #a8e3ea;
      }
    }
  }
}

.EditorDialog__buttonViewOnly {
  .EditorDialogItems__button {
    background-color: #d9cded;
    &:hover {
      background-color: #e0d8f1;
    }
  }
}

.EditorDialog__headerControlAdditional {
  position: absolute;
  right: 45px;
  top: 20px;
  cursor: pointer;

  .Button_FullScreen,
  .Button_Window {
    margin-top: -6px;
  }

  img {
    margin-right: 4px;
    margin-top: -4px;
    vertical-align: middle;
    width: 16px;
    height: 17px;
  }
}

.EditorDialog_fullscreen {
  .ModalWindow__body,
  .ModalWindow__main,
  .ModalWindow__upper,
  .ModalWindow__footer {
    //    width: 100vw !important;
    min-width: 100vw !important;
    max-width: none;
  }

  .ModalWindow__main {
    min-height: 100% !important;
    //    max-height: none;
    height: 100% !important;
  }

  .scrollarea {
    background-color: white;
  }

  .ModalWindow__body {
    //padding-left: 0;
    //padding-right: 0;
    padding-top: 0;
    padding-bottom: 0;
    box-sizing: content-box;
  }

  .ModalWindow__windowHead {
    margin-bottom: 0;
    border-radius: 0;
    border-bottom: 1px solid $gray;
  }

  .ModalWindow__upper {
    border-radius: 0;
  }

  .scrollarea {
    width: 100vw !important;
    max-width: none;
  }

  .ModalWindow__upper.ModalWindow__flex00.ModalWindow__upper_empty {
    display: none;
  }
}
