@import '../../styles/utils/variables';

.ObjectEditor {
  &__controlButton {
    cursor: pointer;
    background-color: $gray;
    color: white;
    padding: 1px 4px;
    border-radius: 5px;
    display: inline-block;
    margin: 3px 14px 3px 0;
    font-weight: bold;
    height: 20px;
    line-height: 20px;
    min-width: 82px;
    text-align: center;

    &__active {
      background-color: $blue;

      &:hover {
        background-color: #3dc2d1;
      }
    }
  }

  &__label {
    display: block;
    float: left;
    padding-right: 10px;
    line-height: 26px;
    width: 15%;
    font-weight: bold;
    font-size: 16px;
  }

  &__name {
    display: block;
    float: left;
    padding-right: 10px;
    line-height: 26px;
    width: 75%;
    font-size: 16px;
  }

  &__type {
    display: block;
    float: left;
    padding-right: 10px;
    line-height: 26px;
    font-size: 16px;
  }

  &__error {
    color: $red;
  }

  &__block {
    margin-bottom: 5px;
  }

  &__block_text {
    width: 100%;
    padding-right: 0px;
    box-sizing: border-box;
  }

  .EditButton {
    float: right;
  }

  .EditButton__edit {
    margin-right: 5px;

    &:hover {
      font-weight: bold;
    }
  }

  EditableEntity_text_updatable {
    padding-right: 0px;
  }
}
