@import '../../styles/utils/variables';

.FieldView {
  display: block;
  margin: 0px 0 20px;
  width: 764px;
  max-width: 100%;

  &__block {
    box-sizing: border-box;
    display: block;
    border: 1px solid $gray;
    padding: 6px 9px;
    background-color: white;
    margin: 7px 1%;

    width: 31%;
    height: 70px;
    color: $gray-dark;
    position: relative;
  }

  &__inlineBlock {
    display: inline-block;
    float: left;
    font-size: 16px;
    color: black;
  }

  .FieldView__blockWithDescription {
  }

  &__info {
    position: absolute;
    right: 9px;
    top: 6px;
    img {
      height: 22px;
    }
  }

  .SimpleTooltip__parent:hover .SimpleTooltip__tooltip {
    width: 300px;
    max-height: none;
  }

  &__blockHeader {
    color: gray;
    margin-bottom: 5px;
    font-size: 16px;
    font-weight: bold;
    text-transform: capitalize;
  }

  &__blockBody {
    //width: 220px;
    padding-top: 10px;
  }

  &__edit {
    text-align: right;
  }

  &__clear {
  }

  &__gray {
    span {
      color: black;
      font-weight: 600;
      font-size: 18px;
    }
  }

  .EditableEntity {
    font: 16px PT Sans, Arial, sans-serif;
    font-weight: 600;
    margin-top: -1px;
    margin-left: -2px;
    position: absolute;
    width: 93%;
    color: black;

    .KascodeSelect {
      margin-top: -4px;
      margin-left: -2px;

      &__placeholder {
        color: gray;
      }
    }

    .StringData {
      margin-top: -4px;
      border: 1px solid #cbcbcb;
      border-radius: 3px;
      height: 25px;
      padding-top: 2px;
      padding-left: 5px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    .TextInput {
      border: 1px solid #cbcbcb;
      border-radius: 3px;
      height: 29px;
      padding-left: 5px;
      width: 100%;
    }
  }

  .EditableEntity.KascodeSelect__tooltip {
    position: relative;
  }
  .KascodeSelect .SimpleTooltip__root {
    top: 0px;
  }

  .TextInput {
    font: 16px PT Sans, Arial, sans-serif;
    font-weight: 600;
    margin-top: -4px;
  }

  :checked.CheckBox + label {
    position: absolute;
    margin: -10px 1px;
    padding: 5px 0 0 45px;
  }

  :not(checked).CheckBox + label {
    position: absolute;
    margin: -10px 1px;
    padding: 6px 0 0 45px;
  }

  .ObjectSearch__results {
    z-index: 400;
    position: absolute;
  }
}
