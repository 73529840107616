.Person {
  &__outer {
    display: inline-block;

    .ModalWindow__root {
      display: inline;
    }

  }
  display: inline-block;

  &__clickable {
    cursor: pointer;
  }


  &__fieldName {
    font-weight: bold;
    display: inline;
  }

  &__picture {
    width: 100px;
    height: 100px;
    border-radius: 100%;
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: cover;

  }

  &__role {
    margin-bottom: 15px;
  }


  &__modal {
    .row {
      width: 100%;
    }


  }


}
