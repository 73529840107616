@import '../../styles/utils/variables';

.ReferenceSelector {
  position: relative;
  width: 100%;
  user-select: none;
  outline: none;

  input {
    padding: 0 5px;
    width: 100%;
    box-sizing: border-box;
    border-radius: 3px;
    border: 1px solid #cbcbcb;
    outline: none;
  }

  .row {
    width: 100%;
    flex: none;
    margin: 0px;
    max-width: 100% !important;
  }

  &__subjectRow {
    button {
      width: 100%;
      min-width: 50px;
      height: 28px;
      border: 0px;
      float: left;
      font-size: 15px;
      font-weight: bold;
    }
  }

  .col-xs-3 {
    margin-left: 5px;
    font-weight: bold;
    flex-basis: 95px;
    min-width: 95px;
    padding: 0 !important;
  }

  .col-xs-9 {
    flex-basis: calc(100% - 100px);
    min-width: calc(100% - 100px);
    padding: 0 !important;
  }

  &__toggle {
    position: absolute;
    //top: 1.2px;           // was top: 1px and height: 27px, in some scales border was not visible
    top: 2px;
    right: 103px;
    width: 23px;
    height: 23px;
    line-height: 28px;
    text-align: center;
    background-color: transparent;

    img {
      margin-top: 9px;
      width: 18px;
      height: 9px;
    }

    &_rotated {
      transform: rotateZ(180deg);
    }
  }

  &__vtoggle {
    position: absolute;
    //top: 1.2px;           // was top: 1px and height: 27px, in some scales border was not visible
    top: 2px;
    right: 2px;
    width: 23px;
    height: 23px;
    line-height: 28px;
    text-align: center;
    background-color: transparent;

    img {
      margin-top: 9px;
      width: 18px;
      height: 9px;
    }

    &_rotated {
      transform: rotateZ(180deg);
    }
  }

  .DataSelectorTable {
    margin-top: 3px;
    width: calc(100% - 3px);

    &Inner {
      display: table;
      width: 100%;
      //width: calc(100% - 20px);
    }

    &__Row {
      line-height: 22px;

      &:hover {
        & .DataSelectorTable__Cell {
          background-color: $blue-light;
        }

        .DataSelectorTable__RowTip .SimpleTooltip__root {
          opacity: 1;
        }
      }

      &__Selected {
        & .DataSelectorTable__Cell {
          background-color: $gray-light;
        }
      }

      &__Highlighted {
        & .DataSelectorTable__Cell {
          color: $red;
        }
      }
    }
  }
}
