@import '../../styles/utils/variables';

.ContentLoader {
  &__block_text {
    margin-top: 1px;
    margin-bottom: 1px;
  }

  __value_right {
    width: 100%;
    float: left;
    line-height: 26px;
  }

  &__image {
    max-height: 400px;
    max-width: 400px;
  }

  &__DropZone {
    height: 1px;
  }

  &__loadIcon {
    margin-top: 2px;
    margin-bottom: 8px;
    color: #238a96;
    font-weight: bold;
    font-size: 14px;
    cursor: pointer;

    &Dark {
      color: #6cd2dc;
    }

    img {
      height: 14px;
      margin-right: 3px;
    }
  }

  &__emptyFrame {
    width: 200px;
    height: 200px;
    background-color: lightblue;
  }

  &__previewFrame {
    width: 200px;
    height: 200px;
  }
}
