
.MultilineText {

  position: relative;

  &__text {
    text-align: justify;
    text-justify: inter-word;
    //word-break: break-all;
    word-wrap: break-word;
    margin-right: 19px;
  }

  &__full {
    //max-height: 100%;
    overflow-y: auto;
    margin-left: 30px;
    margin-right: 30px;
    word-wrap: break-word;
  }

  &__hidden {
  }

  &__more {
    
    cursor: pointer;
    position: absolute;
    right: 0px;
    bottom: -1px;

    background-position: center;
    background-repeat: no-repeat;
    width: 20px;
    height: 12px;

    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAADCAYAAABMFFUxAAAAMUlEQVR4XqWLsQ0AIAgE/x0Fp2MAlsBep2MWbbQ2gasulxzF58YlTPm8j/XtDTnq8wFGWQpHpPdIBwAAAABJRU5ErkJggg==');
    background-size: 15px 3px;

    &:hover {
      background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAAECAYAAACDQW/RAAAAM0lEQVR4Xs2MsQkAIAwE/x0lTpcBskTsdbrMopVgKaTJVQcHR/G58RCmvN7H+m4NCWqPDncaCklIx8+6AAAAAElFTkSuQmCC');
      background-size: 18px 4px;
      background-position: right;
    }

    @media 
    (-webkit-min-device-pixel-ratio: 2), 
    (min-resolution: 192dpi) { 
    /* Retina-specific stuff here */

    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAGCAYAAADUtS5UAAAANUlEQVR4Xu2NsREAIAjE/hkFp3MAl9Aep3MWXYDSo4GUudyFOu3C4YxOz7e1v/SCAHKOa/wAIhEKTRit6mIAAAAASUVORK5CYII=');
    &:hover {
      background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAICAYAAACRbl3VAAAAN0lEQVR4Xu3OMQ0AIAxE0TukFHUIqImygzq0gAHCRrrc23/yaTE2HpY34qL2+aUrSKAhDWko0wE6MApRBAsgaQAAAABJRU5ErkJggg==');
    }

    } 

    //right: 0;
    //bottom: 0;
    //background-color: white;
    display: none;

    &__visible {
      display: inline;
    }
    
  }

}
.MultilineText__window {
  font-weight: normal;
  
  .ModalWindow__main {
    width: 800px;
    //height: 400px;
    height: auto;
  }

  .ModalWindow__body {
    padding: 0;
    min-height: 150px !important;
  }

  .ModalWindow__footer {
    padding-top: 1px;
  }

}