@import '../../styles/utils/variables';

.EditorDialog_instance {
  .ModalWindow__main {
    width: 800px;
    height: 700px !important;
  }

  .ModalWindow__body {
    padding: 1px 18px;
    width: 800px !important;
    height: 850px;
    min-height: 150px !important;
    overflow-y: visible; // added because gray scrollbar was appearing inside Add Issue window, when description was filled with long text;
    // as a result, Discard button was shifted to bottom
  }

  .ModalWindow__footer {
    padding-top: 5px;
    width: 800px;
  }
}

.InstanceDialog {
  &__block_ref {
    margin-top: 6px;
    margin-bottom: 2px;
  }

  &__block_units {
    margin-top: 8px;
    margin-bottom: 2px;
  }

  .DataEditor__block {
    &_name,
    &_description,
    &_type,
    &_platform,
    &_configuration,
    &_reference,
    &_units {
      margin-top: 5px;
      margin-bottom: 5px;

      &__value_right {
        width: calc(80% - 10px);
        float: left;
        line-height: 26px;
      }
    }

    &_order {
      margin-top: 0px;
      margin-bottom: 1px;

      &__value_right {
        width: calc(10% + 10px);
        float: left;
        line-height: 26px;
      }
    }

    &__label {
      display: block;
      float: left;
      padding-right: 10px;
      line-height: 26px;
      width: calc(15% + 30px);
      font-weight: bold;
      font-size: 16px;
    }
  }
}
