@import '../../styles/utils/variables';

.EditorDialog.EditorDialog_topology_chart {
  .ModalWindow__body,
  .ModalWindow__main,
  .ModalWindow__upper,
  .ModalWindow__footer {
    width: 1150px !important;
  }
  .scrollarea {
    width: 1170px;
  }

  div.table {
    max-height: 400px;
    overflow-y: auto;
  }
}

.EditorDialog.EditorDialog_topology_chart.EditorDialog_fullscreen {
  .ModalWindow__body,
  .ModalWindow__main,
  .ModalWindow__upper,
  .ModalWindow__footer {
    width: 100vw !important;
    max-width: none;
  }
  .ModalWindow__main {
    max-height: none;
    height: 100%;
  }
  .scrollarea {
    background-color: white;
  }
  .ModalWindow__body {
    //padding-left: 0;
    //padding-right: 0;
    padding-top: 0;
    padding-bottom: 0;
    box-sizing: content-box;
  }
  .ModalWindow__windowHead {
    margin-bottom: 0;
    border-radius: 0;
    border-bottom: 1px solid $gray;
  }
  .ModalWindow__upper {
    border-radius: 0;
  }
  .scrollarea {
    width: 100vw !important;
    max-width: none;
  }
  .ModalWindow__upper.ModalWindow__flex00.ModalWindow__upper_empty {
    display: none;
  }
}

.EditorDialog_topology_chart__additionalButtons {
  height: 20px;
  margin-bottom: 20px;
  margin-top: 4px;
}

.EditorDialog_topology_chart__headerControlPdf {
  display: inline-block;
  //  float: right;

  position: absolute;
  right: 25px;
  //margin-top: -20px;
  font-size: 18px;
  cursor: pointer;

  a {
    color: $blue-dark;
  }
}
.EditorDialog_topology_chart__headerControlAdditional {
  color: $blue-dark;
  display: inline-block;
  //  float: right;
  margin-right: 60px;
  position: absolute;
  right: 55px;
  //margin-top: -20px;
  font-size: 18px;
  cursor: pointer;

  img {
    margin-right: 4px;
    margin-top: -4px;
    vertical-align: middle;
    width: 16px;
    height: 17px;
  }
}

.TopologyChart__instance {
  min-height: 40px;
  border: 3px solid $yellow;
  border-radius: 8px;
  padding: 4px;
  margin-bottom: 10px;

  &__selected {
    border-color: $yellow-dark;
  }
}
.TopologyChart__header {
  color: white;
  font-size: 16px;
  background-color: $yellow;
  padding: 4px;
  margin-bottom: 6px;
  border-radius: 4px;
}

.TopologyChart__label {
  color: $blue;
}

.TopologyChart__filters.row {
  margin-bottom: 20px !important;
  .col-xs-3 {
    flex-basis: 25%;
    max-width: 25%;
    min-width: auto;
    padding-left: 0;
  }
}

.TopologyEditor {
  .col-xs-3 {
    flex-basis: 25%;
    max-width: 25%;
    min-width: auto;
    padding-left: 0;
  }
}
