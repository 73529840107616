@import '../../styles/utils/variables.scss';

.LocaleSelect {
  position: relative;
  display: inline-block;
  height: 12px;
  vertical-align: top;

  &__input {
    padding-right: 30px;
    cursor: pointer;

    &_short {
      padding-right: 0;
      .LocaleSelect {
        &__value {
          display: inline-block;
          width: auto;
          padding-right: 4px;
        }
        &__toggle {
          display: inline-block;
          position: relative;
          vertical-align: top;

          &_rotated {
            top: 6px;
          }
        }
      }
    }
  }

  &__value {
    display: block;
    width: 100%;
    height: 19px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }


  &__toggle {
    position: absolute;
    top: 0;
    right: 0;
    width: 19px;
    height: 12px;

    img {
      max-height: 100%;
    }

    &_rotated {
      top: 4px;
      transform: rotateZ(180deg);
    }
  }

  &__list {
    box-sizing: border-box;
    position: absolute;
    left: -19px;
    height: 0;
    overflow: hidden;

    &_opened {
      height: auto;
    }
  }

  &__options {
    position: relative;
    z-index: 100;
    width: auto;
    min-width: 140px;
    padding: 5px 15px 5px 18px;
    background-color: $light;
    border: 1px solid;
    box-sizing: border-box;
  }

  &_application {
    .LocaleSelect__options {
      border-color: $yellow;
    }
  }

  &__option {
    position: relative;
    width: auto;
    cursor: pointer;
    color: $blue-dark;
    white-space: nowrap;


    &_selected {
      &:before {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        left: -12px;
        margin-top: -2px;
        width: 6px;
        height: 6px;
        border-radius: 7px;
        background-color: $blue-dark;
      }
    }

    &_empty {
      color: $blue;
    }
  }
}

.LocaleSelectOption__status {
  position: absolute;
  right: -85px;
  white-space: nowrap;
}

