@import '../../styles/utils/variables';

#SQLDesigner__areaBlocker {
  position: absolute;
  width: 100%;
  height: calc(100% - 100px);
  min-height: 800px;
  z-index: 2500;
  //pointer-events: none;
}

.SQLDesigner__root {
  font-family: verdana, sans serif;
  font-size: small;
  padding: 0px;
  margin: 0px;
  background: #fff;
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;

  #area {
    position: relative;
    width: 100%;
    height: 100%;
    background: transparent;
  }

  #controls {
    position: absolute;
    left: 0px;
    top: 0px;
    display: none;
  }

  #bar {
    text-align: center;
    background-color: #ccc;
    position: fixed;
    top: 0px;
    right: 0px;
    width: 150px;
    padding: 0px 5px 5px;
    //-moz-box-shadow: -4px 4px 8px #888; /* FF 3.5+ */
    //-webkit-box-shadow: -4px 4px 8px #888; /* Safari 3.0+, Chrome */
    //box-shadow: -4px 4px 8px #888; /* Opera 10.5, IE 9.0 */
    //filter: progid:DXImageTransform.Microsoft.Shadow(Strength=5, Direction=215, Color='#888888'); /* IE 6, IE 7 */
    //-ms-filter: "progid:DXImageTransform.Microsoft.Shadow(Strength=5, Direction=215, Color='#888888')"; /* IE 8 */
  }

  #bar input {
    display: block;
    width: 90%;
    margin: auto;
  }

  #bar input.small {
    width: 45%;
    display: inline;
  }

  #toggle {
    padding: 5px 0px;
    margin-bottom: 5px;
    cursor: pointer;
    background-position: 50% 5px;
    background-repeat: no-repeat;
    height: 5px;
  }

  #toggle:hover {
    background-color: #bbb;
  }

  #toggle.on {
    //background-image: url(../images/up.gif);
  }

  #toggle.off {
    //background-image: url(../images/down.gif);
  }

  #minimap {
    position: fixed;
    width: 100px;
    height: 100px;
    right: 0px;
    bottom: 0px;
    background-color: #fff;
    border: 1px solid #000;
    opacity: 0.9;
    overflow: hidden;
  }

  .mini {
    position: absolute;
    border: 1px solid #000;
    background-color: #eea;
    font-size: 1px;
    line-height: 1px;
  }

  .mini_selected {
    background-color: #f85;
  }

  .port {
    position: absolute;
    border: 2px solid #f00;
  }

  .table {
    position: absolute;
    border: 2px solid #000;
    //background-color: #ddd;
    cursor: pointer;
    //-moz-box-shadow: 4px 4px 8px #888; /* FF 3.5+ */
    //-webkit-box-shadow: 4px 4px 8px #888; /* Safari 3.0+, Chrome */
    //box-shadow: 4px 4px 8px #888; /* Opera 10.5, IE 9.0 */
    //filter: progid:DXImageTransform.Microsoft.Shadow(Strength=5, Direction=135, Color='#888888'); /* IE 6, IE 7 */
    //-ms-filter: progid:DXImageTransform.Microsoft.Shadow(Strength=5, Direction=135, Color='#888888'); /* IE 8 */
    border-radius: 8px;
    padding: 4px;
    background-color: white;
    &:after {
      position: absolute;
      right: 0px;
      bottom: 0px;
      width: 12px;
      height: 12px;
      content: '';
      //background-color: red;
      cursor: nwse-resize;
      background-color: $blue-light-light;
    }
    //overflow-y: auto;
    overflow-y: hidden;
    max-height: 100%;
  }

  .table_header {
    display: inline-block;
    vertical-align: top;
    padding-top: 2px;
  }

  .table table {
    border-collapse: collapse;
    width: 100%;
  }

  .table.selected {
    //border-width: 3px;
    background-color: $blue-light;
    //overflow-y: auto;
  }

  .table.table_scrolling.selected {
    overflow: scroll;
  }

  .table.table_scrolling:after {
    content: '';
    position: absolute;
    width: 24px;
    //left: 50%;
    margin-left: -12px;
    height: 10px;
    bottom: 5px;
    //background-color: red;
    border-bottom: 5px dotted $blue-light;
    background-color: transparent;
  }
  .table.table_scrolling.selected:after {
    display: none;
  }
  .table.table_scrolling {
    &::-webkit-scrollbar-corner {
      /*
      background-image: url(resources/corner.png);
      background-repeat: no-repeat;
      */
      background-color: $blue-light-light;
      cursor: nwse-resize;
    }
  }

  .table thead tr {
    //background-color: white;
  }

  .table thead .title {
    //text-align: center;
    padding: 3px 30px;
    padding-left: 0;
  }

  td.title {
    padding: 10px;
    background-color: $green-light;
    border-radius: 7px;
    color: white;
    font-weight: bold;
  }

  div.title {
    color: $blue;
  }

  .table tbody {
    //border-top: 1px solid #888;
    tr {
      //background-color: white;
      td {
        .title {
          padding-left: 20px;
          position: relative;
          &.primary.key:before {
            position: absolute;
            left: 0;
            top: 2px;
            content: '';
            width: 16px;
            height: 16px;
            background-size: 16px 16px;
            display: inline-block;
            background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAoCAYAAACM/rhtAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAAEnQAABJ0Ad5mH3gAAAAadEVYdFNvZnR3YXJlAFBhaW50Lk5FVCB2My41LjEwMPRyoQAAAe9JREFUWEftmMFxwyAQRV2CS3AJLiEluIScMzm4hJSQElKCS3AJGp9z8EwKIf9nhAfQXxm0WMkhh+cxsPv3DxIgaRNCeAgvl68dOILz6+UzlLB/HN+q/Ijs9MCC4EOZsmC8ZXTS4QFFDspALch/KjWzhgeIP6uirVAn1c2KLMU7cyXpTE6KtQKxrSqiQOyVMwSOajyFutSXRVuAUNWCYFyRtwdXFUtifFasFYjslHgJ4gYjnyt+UDmE+pOkFiBw91IRxGU3fspoUs4k9WVSLRCQm3DJnEGC8b2Rd5YJtShRC5pQGhGMy6shg2tRghYwMADzWMOY3EdlcC1KcI45kxxTOZPAFpTgPUaTu1QHbXOrygq2ogRrgSlu2AcaVuMRWbgWiJ+UaC+g71vFEKjaB5dCfVm4BiTPngI9gP6yk2Qlc8vO4jXMEdZhPWnCYkVzh1hzYsJiRXPtT9QrmrvNXCRrKNYwB/1lb3Uec8wDNe/F2bFXIjsJEr3mzCeXFmTnXzFHJh0ec6SnOZI1vOZIqteDrAFzTd9UFKleD25/epgjUa8XPz+9zEHnVBbw4jKH3HfwNnJUBby4HttLsUfwb9DLYoO457ovCEWVQZjhdz0e7uQEHrIgFFzF5jc6gvGuZ2srNGh+SEQ/Z+vXzIUQNt8Y4cSxcp/5BwAAAABJRU5ErkJggg==);
          }

          &.key:before {
            position: absolute;
            left: 0;
            top: 2px;
            content: '';
            width: 14px;
            height: 18px;
            background-size: 14px 18px;
            display: inline-block;
            background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAyCAYAAAAus5mQAAAEr0lEQVR4Xs2YW2hcVRSGeyYTxRqbUagatWTES7UinVYRxdqZKlhQ25lGkXohl1bEByFREH006oOKxhlRFIVm4qOonYggoiYTEQWpOqmopV4Yr3hDzzS2VNtE/4fvYXOYnV1yNsMs+B6Slex8WfusvfaZ4O69Xy8jfJMWBZEXGdFt5BqiJiZFWYQ2gYTwHSkxLr4VT4oNolMchDlxvLhSPCH+5OdTrRAsIDYgjor94lFxq7hQrBSXiZ3iBfGNOCL6+b1sVCDpywypXSR+FneJN8gFbPmY2CwaVO0hhJ8VZ4kpsUNM+K5g3pCbFGsicreLT8WdYpW4mO19TXwpLkBuGetkfQqmRJnEq6JPHDDyd7CdJ4kfkLyP5/EKURW9Yqv4EIHdIuVri58SK8SPyJixRjxCU0yIISNXpYK9SGbFNvGxOJN1h+JWMG00xJBZORgWp4hZUw5qYj25U8WbokM8xnoDIp3w0LX/0a1TTfKbkClZFgjF1aJOJV8RL4lfyRcSHppjXlSaJZEPHGuExnN7udguqqybjyuYFf+Ktyz5XUiOMU1sUROjJO4X0whmYncxC31uyT0jvhDdyKYclQxEylgv5Uvwb0vuoLhO/EYFpxaRHGGyzYjfRcLXqOtghNniJ7ElIpmOyI2Ltfyjg6JH/ONDsMFFYL1jgT3iWvELIp8gUmDCDFDtzWKOuZ0UYVzBKhW8Rrhir1gnPhNd4kXxsrhIfM+F4gMEcqxbiys4Ixb4I87gfNsoHhD7xFeiRHVfR2CFuArByWTMGdzPPKfr3DBtxsB2+PeQqCRiyE2JtTTBc8JHrOT+mGSi1BMx5DI80NsZdXGjiyvaaTTfPXTxkuUaNMdHHuR6mB6XkhgSoUXQva3I5bkaxYkTuB/uMY6qnWLSfmF1V+4A59V7whbnU5F9jLgbxXniDLFa3Mb39zMOT2dH+kTZ/k7irtwfos+xrQUmw4lcFM7lyn+ErxOiAwKY4JkL7S9N7sr9JW4S79vM2J4icrNiAuGNTYoxw1ZWRN0igKBbLkRusW3NGnI1LqIhwkuOhEuOGXmLmHas87hYTuWQI2IJuuW2Wi+kwBvbOSQ2eZJD0N4Qc2zr9DEeF0kRGHL+BY2r+SG6lco56aQrvUcy8v4wyIS4WbzrkFrHFWkV27vc0mhFOrnbSHxH9z5IxZ0VHEfuENv6tkNug3iH995tTIHAnAD88boYMOWgVwyTT7sqOG7caG8QM66G4Hw7WZSMCoSR999BxKxBvixy1goiNy/63XIIcl/j9B8F5AxBgsqOMjEaTc7PtOsZPMwix9pYAbheB0pUqBbJhZHqZkTd2sVUcMEitFrcy2d4z/OBz3HkXIxE5aASbSZHk1irkWexh8UO7mmXiIDvewnXLA44aLfwOjhP7nrxNLk6RwPbw/YtPTKRRNUqGPlk6bDxgU8X8rOex1eGSUUs3phJzrHdSHYaObaR67e/GIkkii7BmjhbtCJyYiA6UZyTpIUULdVsC8Fi5NnjNt0eggVmL6FpwqRpB8GMKDcRDttBMCUq5lhjfleXcB/0L4dIr5EoMfpyTWZzrdWCZbMpYBiiMYN0y7Y4J/J+3knaPP4HkPcurC7sGtAAAAAASUVORK5CYII=);
          }
        }
      }
    }
  }

  .table tbody td {
    padding: 3px;
  }

  .table tbody .selected {
    //font-weight: bold;
    //color: #f00;
    //display: inline-block;
    display: none;
  }

  .table tbody .title {
    display: inline-block;
  }

  .primary {
    //font-weight: bold;
  }

  .key {
    //font-style: italic;
  }

  .primary.key {
    //font-style: normal;
  }

  .typehint {
    //font-size: 80%;
    color: gray;
  }

  .comment {
    font-size: 80%;
    font-weight: normal;
    margin-right: 0.5em;
  }

  .adding {
    cursor: crosshair;
  }

  .relation {
    overflow: hidden;
  }

  #background {
    position: absolute;
    background-color: #000;
    opacity: 0.5;
    visibility: hidden;
  }

  #window {
    position: absolute;
    visibility: hidden;
    background-color: #fff;
    border: 6px double #333;
  }

  #windowtitle {
    border-bottom: 2px solid #333;
    padding: 2px;
    padding-left: 1em;
    font-weight: bold;
    background-color: rgb(238, 238, 170);
  }

  #windowcontent {
    padding: 1em;
  }

  #windowok,
  #windowcancel {
    margin: 0em 1em 1em;
  }

  .small {
    font-size: 85%;
  }

  #textarea {
    width: 650px;
    height: 200px;
  }

  #opts {
    width: 700px;
  }

  #keys {
    white-space: nowrap;
    text-align: center;
  }

  legend {
    font-weight: bold;
  }

  label,
  input,
  select {
    vertical-align: middle;
  }

  #throbber {
    position: absolute;
    top: 2px;
    right: 3px;
  }

  #rubberband {
    position: absolute;
    border: 1px solid #000;
    background: #888;
    opacity: 0.5;
    visibility: hidden;
  }

  #textarea,
  #clientload,
  #opts,
  #io,
  #table,
  #keys {
    display: none;
  }

  .ObjectPicture {
    display: inline-block;
    margin-right: 3px;
    font-size: 12px;
    width: 24px;
    height: 24px;
    //font-weight: normal;
    span {
      line-height: 16px;
    }
    pointer-events: none;
  }
}

.SQLDesigner {
  &__hint {
    display: inline-block;
    float: right;
    .SimpleTooltip__tooltip {
      margin-left: -150px;
      &:before {
        left: auto;
        right: 18px;
      }
      &:after {
        left: auto;
        right: 19px;
      }
    }
  }

  &__filters {
    width: 100%;
    //display: none;
    margin-bottom: 15px;
  }
  &__filter {
    display: inline-block;
    width: 25%;
  }
  &__modal {
    .react-contextmenu {
      z-index: 2500;
      min-width: 160px;
      padding: 5px 0;
      margin: 2px 0 0;
      font-size: 16px;
      color: #373a3c;
      text-align: left;
      background-color: #fff;
      background-clip: padding-box;
      border: 1px solid rgba(0, 0, 0, 0.15);
      border-radius: 0.25rem;
      outline: none;
      opacity: 0;
      pointer-events: none;
    }

    .react-contextmenu.react-contextmenu--visible {
      opacity: 1;
      pointer-events: auto;
    }

    .react-contextmenu-item {
      padding: 3px 20px;
      font-weight: 400;
      line-height: 1.5;
      color: #373a3c;
      text-align: inherit;
      white-space: nowrap;
      background: 0 0;
      border: 0;
      cursor: pointer;
    }

    .react-contextmenu-item.react-contextmenu-item--active,
    .react-contextmenu-item:hover {
      color: #fff;
      background-color: #20a0ff;
      border-color: #20a0ff;
      text-decoration: none;
    }

    .react-contextmenu-item.react-contextmenu-item--disabled,
    .react-contextmenu-item.react-contextmenu-item--disabled:hover {
      color: #878a8c;
      background-color: transparent;
      border-color: rgba(0, 0, 0, 0.15);
    }

    .react-contextmenu-item--divider {
      margin-bottom: 3px;
      padding: 2px 0;
      border-bottom: 1px solid rgba(0, 0, 0, 0.15);
      cursor: inherit;
    }
    .react-contextmenu-item--divider:hover {
      background-color: transparent;
      border-color: rgba(0, 0, 0, 0.15);
    }

    .react-contextmenu-item.react-contextmenu-submenu {
      padding: 0;
    }

    .react-contextmenu-item.react-contextmenu-submenu > .react-contextmenu-item:after {
      content: '▶';
      display: inline-block;
      position: absolute;
      right: 7px;
    }

    .example-multiple-targets::after {
      content: attr(data-count);
      display: block;
    }

    &.SQLDesigner__smallModal {
      .ModalWindow__main {
        min-height: 200px;
        height: 250px;

        width: 600px;
      }
    }
  }
  &__link {
    color: $blue-dark;
    min-width: 150px;
    margin-left: 40px;
    padding-top: 2px;
    img {
      margin-top: 4px;
    }
    span {
      display: inline-block;
    }
  }
  &__icon {
    height: 20px;
    margin-left: 20px;
  }

  &__iconButton {
    height: 16px;
  }

  &__pdf {
    display: inline-block;
    //   float: right;
    text-align: right;
    //right: 0;
    //position: absolute;
    //margin-right: 20px;
    margin-left: 10px;
    position: absolute;
    margin-top: 5px;
    font-size: 16px;
    & > span {
      //position: absolute;
      //right: 18px;
      //top: 38px;
      cursor: pointer;
      color: $blue-dark;

      &:before,
      &:after {
        position: relative;
        top: -1px;
        font-weight: bold;
      }
      &:before {
        content: '{';
      }
      &:after {
        content: '}';
      }
      &:hover {
        color: $blue-dark;
        font-weight: bold;
        background-color: transparent;
      }
    }
  }
}

#bottom_area {
  position: relative;
  padding-left: 30px;
  background-color: white;
  .SimpleTooltip__root {
    text-align: left;
    margin-right: 24px;
    //position: absolute;
    //left: 2px;
    .SimpleTooltip__tooltip {
      width: 160px;
    }
  }
  .SimpleTooltip__parent {
    position: absolute;
  }
  .table {
    margin-right: 15px;
    position: static;
    border: none;
    //background-color: #ddd;
    cursor: pointer;
    //-moz-box-shadow: 4px 4px 8px #888; /* FF 3.5+ */
    //-webkit-box-shadow: 4px 4px 8px #888; /* Safari 3.0+, Chrome */
    //box-shadow: 4px 4px 8px #888; /* Opera 10.5, IE 9.0 */
    //filter: progid:DXImageTransform.Microsoft.Shadow(Strength=5, Direction=135, Color='#888888'); /* IE 6, IE 7 */
    //-ms-filter: progid:DXImageTransform.Microsoft.Shadow(Strength=5, Direction=135, Color='#888888'); /* IE 8 */
    border-radius: 8px;
    padding: 4px;
    background-color: white;
    width: auto !important;
    height: 27px !important;
  }

  .table table {
    border-collapse: collapse;
  }

  .table.selected {
    //border-width: 3px;
    background-color: $blue-light;
  }

  .table thead tr {
    //background-color: white;
  }

  .table thead .title {
    //text-align: center;
    padding: 3px 30px;
    padding-left: 0;
  }

  td.title {
    padding: 10px;
    background-color: $green-light;
    border-radius: 7px;
    color: white;
  }

  div.title {
    color: $blue;
  }

  .table tbody {
    display: none;
  }

  .table tbody td {
    padding: 3px;
  }

  .table tbody .selected {
    font-weight: bold;
    color: #f00;
    display: inline-block;
  }

  .table tbody .title {
    display: inline-block;
  }

  .ObjectPicture {
    display: inline-block;
    margin-right: 3px;
    font-size: 12px;
    width: 20px;
    height: 20px;
    //font-weight: normal;
    span {
      //line-height: 16px;
      display: block;
      margin-top: -2px;
    }
    pointer-events: none;
  }
}

#bottom_area.SQLDesigner__bottomArea__view {
  .table {
    height: 0 !important;
  }
  .table table {
    display: none;
  }
}

.SQLDesigner__topRow {
  .KascodeSelect {
    font-size: 16px;
  }
  .KascodeSelect__placeholder {
    text-transform: capitalize;
  }
}

.EditorDialog_view {
  .ModalWindow__main {
    z-index: 3000;
  }
}
