@import '../../styles/utils/variables';

.SchemaView {
  margin: 0;

  &__buttons {
    .btn {
      margin-right: 20px;
    }
  }

  &__block {
    margin-bottom: 20px;
  }

  .Loader {
    margin-left: -80px;
    margin-top: 5px;
    margin-bottom: 5px;
    transform: scale(0.7);

    .Loader__picture {
      width: 120px;
    }
  }
}
