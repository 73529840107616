@import '../../styles/utils/variables';

.FinalizeWindow {
  .ModalWindow__main {
    width: 800px;
    //height: 450px;
  }

  .ModalWindow__body {
    width: 800px;
    //height: 500px;
    min-height: 150px !important;
  }

  .ModalWindow__upper {
    padding-top: 15px;
    padding-left: 2px;
    width: 800px;
  }

  .ModalWindow__footer {
    padding-top: 5px;
    width: 800px;
  }

  &
    > .ModalWindow__root
    > .ModalWindow__background
    > .ModalWindow__main
    > .scrollarea {
    width: 950px;
    height: 300px;
  }

  .row {
    width: 100%;
    flex: none;
    margin-top: 20px;
    margin-left: 5px;
  }

  .col-xs-3 {
    padding-left: 10px;
    padding-top: 4px;
    font-weight: bold;
    font-size: 16px;
    flex-basis: 30%;
    min-width: 30%;
  }

  .col-xs-4 {
    flex-basis: 33.3333%;
    min-width: auto;
    max-width: none;
  }

  .col-xs-9 {
    padding-left: 2px;
    flex-basis: 70%;
    min-width: 70%;
  }

  &__subjectRow {
    button {
      width: 96%;
      min-width: 125px;
      height: 32px;
      border: 0;
      float: left;
      font-size: 16px;
      font-weight: bold;
    }
  }

  &__subjectText {
    width: 100%;
    float: left;
    margin-top: 3px;
    height: 28px;
    padding-left: 40px;
    padding-top: 3px;
    font-size: 18px;
    font-weight: 400;
  }

  &__subjectInput {
    margin-left: 5px;
    margin-top: 4px;
    float: left;
    width: 100%;
    line-height: 26px;

    input[type='text'],
    textarea {
      margin-left: -5px;
      width: 99%;
    }

    .StringText {
      margin-top: 2px;
      font-size: 18px;
    }
  }

  &__issueSelectDiv {
    padding: 18px;
  }

  &__info {
    margin-top: 40px;
    margin-left: 10px;
    padding: 6px 6px;
    font-size: 20px;
  }

  &__version {
    margin-top: 10px;
    margin-left: 10px;
    padding: 130px 6px 10px 6px;
    font-size: 20px;
  }

  &__message {
    margin-left: 7px;
    float: left;
    width: 99%;
    line-height: 26px;

    &__multiLine {
      margin-top: 1px;
    }
  }

  .EditorDialog {
    .TextArea {
      width: 99%;
      min-height: 100px;
      font-size: 16px;
    }

    .EditButton {
      float: right;
    }

    .EditButton__btn {
      line-height: 18px;
    }
  }
}
