@import '../../styles/utils/variables';

.PublicationView {
  //margin-top: 40px;
  //overflow-x: hidden;
  width: 1200px;
  margin: 0 auto;

  //padding-top: 40px;

  /*
  .SystemBlock {
    .ObjectPicture {
      margin-top: 12px !important;
    }
  }


  .ApplicationList__descriptionRight {
    .TreeCol_double {
      .TreeCol_double {
        .TreeCol {
          width: 772px;
        }
      }
    }
    .rightTreeLine:after {
      display: none;
    }
    .ApplicationBlock {
      width: 370px;
      display: inline-block;
      vertical-align: top;
    }
  }




  .PublicationList {
    .TreeCol_double {
      .TreeCol_double {
        .TreeCol {
          width: 772px;
        }
      }
    }
    .PublicationBlock {
      width: 370px;
      display: inline-block;
      vertical-align: top;
    }
  }

  &__searchInput {
    max-width: 100%;
    box-sizing: border-box;
  }
*/

  .MajorObjectBlock {
    .ObjectRating__tooltip {
      flex-grow: 0;
      width: 26px;
    }
    .ObjectPicture {
      flex-grow: 0;
    }
    .MajorObjectLink {
      flex: 1;
    }
  }

  .MajorObjectLink {
    color: $blue-dark;
  }

  .SystemBlock {
    .ObjectPicture {
      margin-top: 12px !important;
    }
  }

  .TreeBlock__linked {
    border-right: 1px solid $blue-deep;
  }
  .DatasetBlock__more,
  .PublicationBlock__more {
    background-color: $blue-deep;
  }

  &__loader {
    text-align: center;
    .Loader {
      width: 100px;
      margin: 0 auto 25px;
    }
  }

  .ApplicationList__descriptionRight {
    .TreeCol_double {
      .TreeCol_double {
        .TreeCol {
          width: 772px;
        }
      }
    }
    .rightTreeLine:after {
      display: none;
    }
    .ApplicationBlock {
      width: 370px;
      display: inline-block;
      vertical-align: top;
    }
  }

  .DatasetList,
  .PublicationList {
    .TreeCol_double {
      .TreeCol_double {
        .TreeCol {
          width: 772px;
        }
      }
    }
    .DatasetBlock,
    .InterfaceBlock,
    .PublicationBlock {
      width: 370px;
      display: inline-block;
      vertical-align: top;
    }
  }

  &__searchInput {
    max-width: 100%;
    box-sizing: border-box;
  }

  &__pdf {
    display: inline-block;
    //   float: right;
    text-align: right;
    //right: 0;
    //position: absolute;
    //margin-right: 20px;
    font-size: 16px;
    & > span {
      //position: absolute;
      //right: 18px;
      //top: 38px;
      cursor: pointer;
      color: $blue-dark;

      &:before,
      &:after {
        position: relative;
        top: -1px;
        font-weight: bold;
      }
      &:before {
        content: '{';
      }
      &:after {
        content: '}';
      }
      &:hover {
        color: $blue-dark;
        font-weight: bold;
        background-color: transparent;
      }
    }
  }

  &__body {
    width: 1600px;
  }
  &__bodyRow.row {
    margin-left: 0 !important;
  }
}

.PublicationView__controls.row {
  //margin-bottom: 20px !important;
  text-align: center;
  font-size: 22px;
}

.PublicationView__titles.row {
  //margin-top: 20px !important;
  margin-bottom: 0;
}

.PublicationView__list.row {
  //margin-top: -40px !important;
}

.PublicationView__root .SelectView__upper {
  background-color: $violet;
  text-align: center;
  color: white;
}
