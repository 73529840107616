@import '../utils/variables';

.KeyIcon {
  display: inline-block;
}

.KeySettings {

  /*
  background-color: $blue;
  position: absolute;
  margin-left: -5px;
  width: 60px;
  height: 36px;
  border-right: 2px solid white;
  border-radius: 2px;
  margin-top: -17px;
  */

  &__buttonIcon {
    left: 20px;
    top: 6px;
    position: relative;
    &:hover {
      outline: none;
    }
  }
  button:hover {
    outline: none;
  }

  .ModalWindow__body {
    padding-left: 0;
    padding-right: 0;
  }

  &__inputBlock {
    display: block;
    margin-top: 5px;
    overflow: auto;
    width: 100%;

    &__multiLine {
      margin-top: 5px;
      height: 60px;
    }
  }

  &__caption {
    display: block;
    position: relative;
    border-radius: 5px;
    border-width: 1px;
    border-color: black;
    background-color: gainsboro;
    font-weight: bold;
    font-size: 16px;
    text-align: center;
    line-height: 28px;
  }


  &__title {
    padding-top: 5px;
    padding-left: 5px;
    padding-right: 5px;
    font-weight: bold;
    font-size: 16px;
    height: 20px;
  }

  &__label {
    display: block;
    font-weight: bold;
    margin-left: 10px;

    &_left {
      float: left;
      padding-right: 10px;
      line-height: 26px;
      width: 12%;
    }
  }

  &__value {
    line-height: 26px;
  }

  .KeyIcon {
    display: inline-block;
    margin-left: 10px;
  }

  &__keyIconCol {
      width: 90px;
  }

  &__keyType {
    font-size: 14px;
  }

  &__input.TextInput {
    display: block;
    float: left;
    width: 83%;
    height: 32px;
  }

  &__textArea {
    width: 100%;
  }

  .TextArea {
    width: 83%;

    min-height: 40px;
    max-height: 60px;
  }

  ::-webkit-input-placeholder {
    font-size: 16px;
  }

  :-moz-placeholder { /* Firefox 18- */
    font-size: 16px;
  }

  ::-moz-placeholder {  /* Firefox 19+ */
    font-size: 16px;
  }

  :-ms-input-placeholder {
    font-size: 16px;
  }

  .LinkedKeyTable {
    margin-top: 2px;
    margin-left: 5px;
    margin-right: 0px;
    width: calc(98% - 1px);
  }

  &__row {
    width: 98%;
    height: 35px;
    padding: 10px 0px 0px 0px;
  }

  .col-xs-1 {
    padding-left: 0px;
    padding-top: 4px;
    flex-basis: 12%;
    font-weight: bold;
    font-size: 16px;
    min-width: calc(12% + 5px);
  }

  .col-xs-6 {
    padding-left: 10px;
    padding-top: 2px;
    flex-basis: 88%;
    font-size: 16px;
    min-width: calc(88% - 7px);
  }

  &__item {
    &Header {
      padding-left: 20px;
      padding-bottom: 20px;
      color: #238A96;
      font-size: 16px;
      display: flex;

      & div {
        margin-right: 5px;
      }
    }

    &Description {
      margin-left: 30px;
    }

  }

  &__block {
    margin-bottom: 18px;
    border-bottom: 1px solid #CBCBCB;
    padding: 0 5px;
  }

  &__select {
    width: 100%;
  }

  &__infoIcon {
    height: 20px;
    margin: 5px;
  }

  &__keyName {
    font-size: 18px;
  }

  &__divButtons {
    width: 100px;
    margin: 0 auto;
    div:first-child {
      margin-left: 20px;
    }
  }

  &__arrowButton1 {
    color: white;
    height: 45px;
    position: relative;
    font-size: 12px;

    &__Text {
      position: absolute;
      top: 12px;
      left: 14px;
    }
  }

  &__arrowButton2 {
    color: white;
    height: 45px;
    position: relative;
    font-size: 12px;

    &__Text {
      position: absolute;
      top: 12px;
      left: 14px;
    }
  }

  &__arrow {
    height: 45px;
    position: absolute;
  }

  &__delete {
    position: absolute;
    top: 8px;
    right: 10px;
    height: 12px;
    color: red;

    img {
      display: block;
      max-height:100%;
    }
  }


  &__keyNameCol {
    flex: 1;
  }

  &__leftButtons {
    text-align: left;
  }

  &__itemDescription .KascodeSelect {
    z-index: 1000;
  }

  &__emptyMessage {
    text-align: center;
  }

}

.KeySettings__Modal {
  text-align: left;
  text-transform: none;

  .ModalWindow__main {

  //  height: 500px;
  }
  .ModalWindow__body {
    //height: 550px;
  }
}


.Modal__body {
  //background-color: red;
  max-height: 300px;
  overflow-y: auto;
}





















