@import '../../styles/utils/variables';

.Table_interface {
  .TableCell_name .MajorObjectBlock {
    color: $blue-dark;

    &:hover {
      font-weight: bold;
    }
  }
}
