.ExperimentalDesign {
  display: flex;

  &__label {
    margin-right: 15px;
  }

  &__input,
  #EXPERIMENTAL_DESIGN {
    width: 20px !important;
    height: 20px !important;
    margin: unset;
  }
}
