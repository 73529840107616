.KanbanCard {
  border-radius: 2px;
  border: 2px solid transparent;
  box-shadow: none;
  box-sizing: border-box;
  padding: 8px;
  min-height: 40px;
  margin-bottom: 8px;
  user-select: none;

  display: grid;
  grid-template-columns: auto 40px;
  grid-gap: 0.25rem;

  &__selected {
    background-color: aliceblue;
  }

  &__list-name {
    user-select: none;
    color: rgb(9, 30, 66);
    font-size: 1rem;
    font-weight: 600;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    margin-top: -0.5rem;
    margin-left: -0.5rem;
    margin-right: -0.5rem;
    padding: 0.5rem 0.5rem 0;
  }

  &__name,
  &__assigned {
    user-select: none;
    color: rgb(9, 30, 66);
    font-size: 0.875rem;
    font-weight: 600;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    grid-column: 1/3;
  }

  &__assigned {
    color: #6b778c;
    font-weight: 400;
    display: grid;
    grid-gap: 0.25rem;
    grid-template-columns: 16px auto;
    align-items: center;
  }

  &__deadline {
    grid-column: 1/2;
    color: #6b778c;
  }

  &__label {
    font-weight: 700;
  }

  &__priority {
    grid-column: 2/3;

    display: grid;
    grid-auto-flow: column;
    grid-gap: 0.5rem;
  }

  &__priorityIcon,
  &__resolutionIcon {
    width: 16px;
    height: 16px;
  }
}
