@import '../utils/variables';


.DraftWindow {
  .ModalWindow__main {
    width: 600px;
  }

  .ModalWindow__body {
    padding: 0;
    min-height: 150px !important;
  }

  .ModalWindow__footer {
    padding-top: 1px;
  }

  &__version {
    width: 180px;
    float: right;
    .col {
      min-width: 50%;
    }
  }

  .row {
    margin-left: 60px;
    margin-top: 1px;
  }

  &__name {
    margin-bottom: 10px;
    font-weight: 600;
  }

  &__description {
    margin-top: 10px;
  }

  &__label {
    padding: 6px 18px;
    margin-bottom: 20px;
    font-weight: 600;
  }

  &__text {
    padding: 6px 18px;
    margin-bottom: 20px;

    .TextArea {
        font-size: 16px;
        
        &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
          color: $gray;
          font-weight: 500;
          font-size: 14px;
        }
        &::-moz-placeholder { /* Firefox 19+ */
          color: $gray;
          font-weight: 500;
          font-size: 14px;
        }
        &:-ms-input-placeholder { /* IE 10+ */
          color: $gray;
          font-weight: 500;
          font-size: 14px;
        }
        &:-moz-placeholder { /* Firefox 18- */
          color: $gray;
          font-weight: 500;
          font-size: 14px;
        }
        input:-ms-input-placeholder {
          color: gray;
          font-weight: 500;
          font-size: 14px;
        }
    }
  }

  .Loader {
    margin-left:100px;
    margin-top:-5px;
    margin-bottom:5px;
    transform: scale(.5);

    .Loader__picture {
        width: 240px;
    }
  }
}

.Versions {
  display: flex;
  width: 200px;
  margin-left: 50px;

  &__current, &__new {
    flex:1;
  }

  &__new {
    display: flex;

    & > * {
      flex:1;
      margin-right: 10px;
    }
  }
}

.Version__label {
  line-height: 30px;
}
