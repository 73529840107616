@import '../../styles/utils/variables';

.EditorDialog_activity .ActivityDialog__map .EditorDialog_outputs {
  &.EditorDialog_fullscreen {
    .ModalWindow__main,
    .ModalWindow__upper,
    .ModalWindow__footer {
      width: 100vw !important;
    }

    .ModalWindow__body {
      padding: 1px 18px;
      width: 100vw !important;
    }
  }

  .ModalWindow__main {
    width: 1150px !important;
    height: 850px !important;
  }

  .ModalWindow__main,
  .ModalWindow__upper,
  .ModalWindow__footer {
    width: 1150px !important;
  }

  .ModalWindow__body {
    padding: 1px 18px;
    width: 1150px !important;
    max-height: none;
    min-height: 150px !important;
    overflow-y: visible; // added because gray scrollbar was appearing inside Add Issue window, when description was filled with long text;
    // as a result, Discard button was shifted to bottom
  }

  .ScrollArea {
    width: 1170px;

    &__container {
      max-width: 1150px;
      width: calc(100% - 20px);
    }
  }

  .SQLDesigner__root .table tbody td {
    padding: 0;
  }

  .TypedTable .MajorObjectBlock {
    min-height: auto;
  }

  .PagedContent__outerTyped .PagedContent__nav {
    position: relative;
    margin-top: 4px;
  }

  .SQLDesigner__root .table thead .title {
    padding-right: 4px;
  }
}

.MapDialog {
  position: relative;

  .col-xs-2 {
    flex-basis: 20%;
    min-width: 20%;
  }

  .col-xs-5 {
    padding-top: 1px;
    flex-basis: 40%;
    min-width: 40%;
  }

  .col-xs-6 {
    padding-top: 1px;
    flex-basis: 50%;
    min-width: 50%;
  }

  &__colHeader {
    font-weight: bold;
    text-align: center;
    padding: 4px;
  }

  &__mapButtons {
    max-width: 180px;
    margin-left: calc(50% - 90px);
    height: 25px;
    margin-right: calc(50% - 90px);

    &--footer {
      margin: 0 10px 0 auto;
      padding-top: 15px;

      .MapDialog__clearbutton {
        user-select: none;
      }
    }
  }

  &__clearButtonRow {
    text-align: center;
    align-content: center;
  }

  .btn_blue {
    min-width: 10px;
    align-content: center;
    width: calc(50% - 6px);
    margin-left: 3px;
    margin-right: 3px;
  }

  &__clearbutton {
    text-align: center;
    align-content: center;
    width: 100px;
    min-width: 100px;

    img {
      margin-right: 4px;
      height: 18px;
      vertical-align: sub;
    }
  }

  &__noLines {
    box-sizing: border-box;
    text-align: left;
    margin-top: 10px;
    margin-right: 10px;
    position: absolute;
    max-width: 150px;
    left: 30px;
  }

  #MapDialog_row {
    margin-top: 10px !important;
  }

  div.table {
    position: relative;
    margin-bottom: 10px;
    border-color: #238995;

    .DatasetViewer__container {
      max-height: 470px;
      padding-right: 4px;

      overflow: auto;
      //overflow: overlay;

      // scrollbar
      scrollbar-color: rgb(18, 18, 219) #f5f5fb;
      scrollbar-width: thin;

      &::-webkit-scrollbar {
        padding: 2px;
        width: 8px;
        height: 8px;
        position: relative;
        z-index: 0;
      }

      &::-webkit-scrollbar-track {
        background: #f5f5fb;
        border-radius: 4px;
      }

      &::-webkit-scrollbar-thumb {
        background: #8f70cb;
        border-radius: 4px;
      }

      &::-webkit-scrollbar-thumb:hover {
        background: rgb(105, 81, 153);
        width: 14px;
      }
    }
  }

  svg {
    position: absolute;
    margin-top: 10px;
    left: 40%;
    width: 40%;
    z-index: 100;
    pointer-events: none;
    overflow: visible;

    path {
      pointer-events: all;
    }
  }

  &__selectedField {
    background: $blue-light;
    font-weight: 700;
  }

  &.SQLDesigner__root div.title {
    color: #6c6c6c;
  }

  .table_1 {
    margin-left: 20px;
  }

  .table_2 {
    margin-left: 40px;
  }

  .table_3 {
    margin-left: 60px;
  }

  .table_4 {
    margin-left: 80px;
  }

  &__label {
    display: block;
    float: left;
    padding-left: 30px;
    padding-right: 10px;
    line-height: 26px;
    width: 10%;
    text-align: left;
    font-weight: bold;
    font-size: 16px;

    a {
      font-weight: normal;
    }
  }

  &__block {
    margin-bottom: 5px;
  }

  &__block_description {
    margin-bottom: 1px;
  }

  .value_right {
    width: 80%;
    float: left;
    line-height: 26px;

    input[type='text'],
    textarea {
      width: 100%;
    }
  }

  .MajorObjectBlock__label {
    max-width: 100%;
    box-sizing: border-box;
    margin-right: 10px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    height: 31px;
  }

  .EditableEntity_typeReference {
    box-sizing: border-box;
    margin-right: 0;
    text-overflow: ellipsis;
    white-space: nowrap;
    height: 31px;
  }
}

#MapDialog_selectInput {
  position: absolute;
  display: none;
  background-color: white;
  z-index: 200;
  width: 125px;
  text-align: left;

  svg {
    width: 40px;
    height: 15px;
    position: relative;
    left: 0;
  }
}

.EditorDialog__editing .MapDialog__icon {
  background-size: 14px 14px !important;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAcCAYAAAByDd+UAAABZUlEQVR4Xq2UsUoDQRCGL8v1lzdIQEuLwxdQewvFB8ilU7TQwtrU2mvpCgo2omAv8Q3uAYSksbI5i3Ti+QUWsiyYmcAM/Pwwszvfzd7eddYur9tMER/nR51MGetXN4/YAcpD6hsd0+OBhG0Am2D9JF2ge2ozZwwbJbBU3hSIKqFeWAN70gJr4FSo/1gD34X6kzO8MBU2WAKboENnCLuNUjVqUBbco02+wyZfoalnQ6WAzdcN/+vTadt2lSe/i6HU97BnAaa/pWz2WNxgAMRHgDGqBZgE1EOpNdgO2hZh8pHqj1eUPKF+UmOgPdQpj7NvBXUKWInVuJeg2ksjwcaoCKlhgCUXSR95snmEetGPuIxgfgFbTAo0S6DyhMBeSeymRe0fBOgY29IAHbAzAdYEmEk4dCGs6TJBaQkskBRdO6AuGkvgpwCb8g5rS+DJkvoM7SOzcDz9S2j6FeV/0RvaUE53qgX+AdBEok/sbCn9AAAAAElFTkSuQmCC');
}

.EditorDialog__editing .EditorDialogItems__button:hover .MapDialog__icon {
  background-size: 16px 16px !important;
  background-repeat: no-repeat;
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAABv0lEQVR4XsWWv0rDUBTG04uDgtA8gbToJErr5iIkUydBn6DpVkcVV4mrjkJ1azrp3M3JvoHpIggOeYQU3Rzid8OFXMLFnGs45MCPM5xDvu/+yeW0tm8nmUOPm8+rs9CxiJ27h2ukC9DWCi9gjG8law5jQHyOdGwoDcA76ruCUTwsxI1sgEc2A+CS0DMQTKv3kDYpvQKwRZMGEmLfisWA/L2QloTWZ647ECH1Kto+YHRsa2CdKD7UCqmhLQKH/7kDAQRcC/EYdMEBOAEe6GLlI5Abk0/xtEK0n1PEEnjyAwRxv+gz08qyjHKm0mRgMqHqHaQ34NqIk49AbhlSpBV6YAFhV7v1Pkip4nQDdBO5MEGcegT044Bgn+ElpO8ETEU8BugmhtIErwGzibiuCWFx9oFBwKeZqP8OBEhTVZhh9YFWc5FeDY9VXKGdGA1AzNO2eaGLK1L1nKYVJux2QAndlyaZFWiXxH2IF6uraUJoo/O0JA4I4gq1I+fWlxDiR8iUWX8kxTlGsgloEXo7XDPhHrHXa3ooTZo2EHMZmFFWjwsYcRkIwfcfPV/gFLCEUNPMPngq1X7AHGxZ/H4xsNqpX1X0x95ZxWpHAAAAAElFTkSuQmCC');
}

.EditorDialog__viewing .MapDialog__icon {
  background-size: 14px 14px !important;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAcCAYAAAByDd+UAAABY0lEQVR4Xq2Uv0oDQRCH75brL2+QPIDFIfZqb6H4ALl0ghaXJzBvEN/AFRRsRMFeYq3FvUHSWNmcRTrJ+RVbLAtmJjA/+DEwszvf7b/L581HnynUzA/yTKmb6ecj4RwXIfWDL+nxQMJWwJaEUZIu8T21tTOGzRJYKg/QVLVQL62BQ2mANXAl1H+tge9C/ckZXpiaMN4CW+ILZwi7jVIt7nAWosf7vMOu2KGpZ0KtgHnGTf7rk/d9v8uX38VQ6qeEZwGmv6VM9oS4wRiIjwAL3AowCaiHUusIx/hIgslbqt9eWfIK9Ss1BtpDnXI7R1ZQp4BVhJboDaDiO6zC1S9DahJgyUXSq0gmz/Aw+hFXEcwD8+mZAs0SqLxCYK8kTtKi9g8CdEE41AAdsKkA6wLMRA5fC2MG4SzNgCWWNLAD6tRZAr8E2IozbC2BV1vqa3yGzeT4+pfQ9DvKb/Ab3lOurtEC/wD1a6RrNgZIjAAAAABJRU5ErkJggg==');
}

.EditorDialog__viewing .EditorDialogItems__button:hover .MapDialog__icon {
  background-size: 16px 16px !important;
  background-repeat: no-repeat;
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAABjElEQVR4XsWXsUrDUBSGU8mgINjNTdpVlNbNRUinTqI+QdOtq7qX+AyCuCVOOndzso9wXQS3PEKKbg76DydQTmP73+ohBz7ogUP/r5eb9rTxjQr4ugEJ8KkxuAI7oKxnMAJ5GNjWBJwCXX3wBvY3DMMTFa7ZAveWAtfETN9KIALbzKAI2FCnQE7OzSwFXom5JyuBDHRWzLyDka/AJhk+mOuLX2aO17kDMWh6hDvQBkfgHETSD0uxUGyWVVcIwC6YyhsVRHhP5pywUKHYrKoUxNJ0KiRa4Kwq/L8ew2F5UkqiKX1eBnLhvAAvIcFMOC/AS5TlmHBegJfIbL6KeYmB9OYCWsJpCUuBuCKg91eJ0CM8VX0ACpF4Ad1SQl475hezUbGTRnPNVIdLaFvd9qaSoAnVEd+qTWamttlCP+fVJ+F/B8YgVeFgMVwdrZa4XOcSnoCEvfUWK9kdaBCzLaud8ICcjepeSvO6BZyVwAP56TMrgQR8Lpn5ABeWf0xycAge1cAXmIA9j+N3vif1AxukZzMPllj6AAAAAElFTkSuQmCC');
}
