@import '../../styles/utils/variables';

.DatasetSubscription {
  .ModalWindow__main {
    width: 800px;
  }

  .ModalWindow__body {
    width: 800px;
    min-height: 150px !important;
  }

  .ModalWindow__upper {
    padding-top: 15px;
    padding-left: 2px;
    width: 800px;
  }

  .ModalWindow__footer {
    width: 800px;
    padding-top: 5px;
  }

  .scrollarea {
    width: 950px;
  }

  &__label {
    padding-top: 6px;
    font-weight: bold;
    line-height: 22px;
    font-size: 16px;
  }

  &__select {
    position: relative;
    margin-bottom: 1px;
    font-size: 16px;
  }

  &__row {
    width: 100%;
    height: 50px;
    padding: 0px 10px 30px 10px;

    &.DatasetSubscription__descriptionRow {
      height: auto;
    }
  }

  &__field {
    width: 100%;
    height: 20px;
    padding: 0 10px 20px 10px;
  }

  .col-xs-1 {
    flex-basis: 14%;
    min-width: 14%;
  }

  .col-xs-3 {
    margin-left: 10px;
    padding: 3px;
    flex-basis: 10%;
    min-width: 10%;
  }

  .col-xs-4 {
    flex-basis: 10%;
    min-width: 10%;
  }

  .col-xs-6 {
    flex-basis: 55%;
    min-width: 55%;
  }

  .col-xs-7 {
    padding-left: 10px;
    flex-basis: 85%;
    min-width: 85%;
  }

  .col-xs-8 {
    flex-basis: 4%;
    min-width: 4%;
  }

  .col-xs-9 {
    flex-basis: 82%;
    min-width: 82%;
  }

  &__subject {
    &Label {
      font-weight: bold;
      font-size: 16px;
      margin-top: 4px;
      margin-right: 6px;
      display: inline;
      width: 170px;
    }

    &Version {
      margin-top: 4px;
      font-size: 16px;
    }

    &Value {
      display: inline;
      font-size: 16px;
    }
  }

  .StringData {
    padding-top: 4px;
  }

  &__infoIcon {
    width: 20px;
    height: 20px;
    margin: 5px;
    background-size: 20px 20px;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADgAAAA4CAYAAACohjseAAABtklEQVR4Xu2aPU6EUBSFdWI/s4PRxB5cgbgCZwfSWomFtVjbYGWLOxhWIO4AeothB7oCPJOc4mWC/CQiOeSSnLxMbjF8OZf73gkc13V9NOdrIQBggAaoLgMUlgGe/DfA+fPrKZYEunYKJRR9Ptzmsg4SzsdSuHCUB70TXrpFt9CypR5LAtK9DZZ1B4C0g2hPm6KFMuCuo/4NJcqAW6j6rchtYicLiJv/whJAmVvg7wvU0zH+1wKvmAzQAC1NdCeJiNPUYyHDBN0QQG+KEizgQfqyqc5topBzkPEoIVjbtZJpUYKt6Njd7IYM27Eg3HyGDOFiLI8jpIhpHSRc2gD30QOu3J9TFVo0cGLPE3SGGw96AOYqLRpSER3hFJ0JIKDyBjd6ASqniWCE508KMJdNE9zs1+KA3e4JAE71/OkD5uqB1xsCqJYmBj1/HEjJXn+dCxcTtWflnHZCpo8bKBVwsBfgiskjPNhKChVAv6O+bEgeb3A1VBkyy4EG3BNOItH7A8Aq6Apwicw2MWAKvkA+4GTyoKuq5RyaQbG7HSgChgcfHJRMDonzDnBk2eszAzRAAzRAAzTAH2HOjflcr9icAAAAAElFTkSuQmCC);

    img {
      width: 20px;
      height: 20px;
    }
  }

  &__infoIcon:hover {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADgAAAA4CAYAAACohjseAAAD2UlEQVR4Xu2bT0hUURTGj4MolBWokRVogwlCi3kuwkWKA0aRq1dBQgsZNy5yI23a5diuFuLGoBY5CkG1iLfqHwkzmdCfxfgWgZA1TqApVDCp4bix+8Eb70PH5/O9e+fi4AcHQWHm/Pjuee+ce68l6+vrJFLpf9lTRBRmodniCOVXhsWULeJ1B8pnSaAAKApKZ9HHos7vx7EYYmEAViUgwOBUlEUbyVECn89A44UD5I7FHMBkgEa8OBrwAAfHUgWEg/BdKXy3HAe5awaLEKmVyUJ362bAJZyGpxyHU6oQckFOIgABFyGiJH/Uq5eVSxK5eQfkcCMkUAtLy3T7xVtqH360ET1PDTLnfpIHjQDSEyCWgGi4mV9/qOeJQZOpH2TXN/b7m8ZLwHuF1NwD8m4kToIF51bW1mg7jX5KkkfFkfNuHDRE19zk9zQt7uDQ4t8lPzVpuALEu0bG0xLLU7JCyN0R0LK5nxSp/mgV+VQ/GJwcjJEk1RyuICcdLCujq6EzJECxvIBonGW2X+eCdXTs0PaQva3NVIO/+1cbWPI5GCWJqigvo8HLHQy0dhN4LT3o1OliYwMJVNTei+ZqL0XFpSD61ZyDOhWfdHsN9lFRiTOVzK6s8uVZfArCwTAVr8IA1Ei6+CQx/P4jpoeNSQL9qURppYUAxCiERtqcX6DNwmSBNu50deXeA0Ti9yc+AIyctJLNynNQxqS+nF2j0c9Jem5+UT75ByQsR9QYh1OsUhIo1NkYc263qq+uIlkS5uDd8YktcKETNS7gKtGnygMUuTRzY0/X2SZ63HUNzTXtpNDJ43tjid5qb6VX0zPU29IMR1xP8RpclgyYEfAkhROITbtlv1U7mEENTpEkTc0tKK0/sMkERF1Kck89IF722CZUWH8cMC7RPdUOxgPWMVRaeP3NK6+/tH3LYqi46o8z5QANEiscqKiuP2MD0FqmCUX1h+kDrZ7I7f0EmBz2ReW+/7ABnKu/19NfMX3QG/bz3vg70fuiHNC6qpGQ6yB3DJPH9bFnDGoCrxORU0UCLNv1ohEWKcknSTgj3DJ5XGhsQD9LAhTJP03wWhwgnwLAbnSjpVkU3AAY8gNySNSiKc4951oc1C+JOlUykbvbeVBnkSEPcrs7doVBPezURXUzyFV3PfDCZh8bwg7HZPw0ic+NQhRGzu4AOSSa8G6vwy8me3tbBscw5d/pOC96D7Qbubq/yiX/roxouJivPRnrA5ocalKFMsiJw3kHtC9XjYVJ6oUcNOQk5b4ormoovImB91xU6rah9QVB3tYV7EJsEHD7V5r3L6UX2b8V/Af5tMOZji/G6QAAAABJRU5ErkJggg==);
  }

  &__block.row {
    border-top: 1px solid #cbcbcb;
    padding-top: 10px;
    margin-top: 10px;
    //    border-bottom: 1px solid #CBCBCB;
  }

  &__button {
    margin: 10px 0;
    //width: 100%;
    width: 33%;
    height: 50px;
    line-height: 50px;
    background-color: $blue;
    color: $light;
    border: 0;
    font-size: 22px;
    border-radius: 5px;
  }

  &__row .DatasetSubscription__button {
    max-width: 33%;
    margin: 0 auto;
  }

  &__blockHeader {
    background-color: #ebebeb;
    border-top: 1px solid #cbcbcb;
    padding-bottom: 10px;
    font-size: 16px;
  }

  &__blockHeader + .DatasetSubscription__row {
    margin-top: 0;
  }

  &__keyCol.col-xs-6 {
    padding: 0;

    .KeyTable:last-child {
      border-left: 0;
    }
  }

  .KeySettings__divButtons {
    margin-left: 0px;

    .KeySettings__arrowButton1 {
      margin-left: 20px;
    }

    .KeySettings__arrowButton2 {
      margin-left: 5px;
    }
  }
}
