.TabbedModalWindow {
  &__tabs {
    display: block;
  }
  &__tab {
    margin-top: 18px;
    box-sizing: border-box;
    display: inline-block;
    padding: 0 15px;
    height: 40px;
    line-height: 40px;
    font-size: 22px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    background-color: transparent;
    color: #218a96;
    text-transform: capitalize;
    &__active {
      background-color: #f0f0f0;
    }
  }
  .ModalWindow__windowHead {
    margin-bottom: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    padding-bottom: 0;
  }
  .ModalWindow__upper {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    min-height: 0;
  }
  .ModalWindow__body {
    padding-top: 0;
  }
}
