@import '../../styles/utils/variables';

.ThButton {
  position: absolute;
  margin-top: -15px;
  margin-left: -5px;
  width: 100px;
  height: 30px;
  background-color: $blue;
  color: $light;
  font-size: 16px;
  border: 0;
  border-right: 4px solid white;
}
