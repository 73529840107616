@import '../../styles/utils/variables.scss';
@import '../../styles/utils/utils';

.IssueMessageTextarea {
  .TextArea {
    border: 0 !important;

    &__input {
      font: 14px PT Sans, Arial, sans-serif;
    }
  }

  .TextArea__suggestions {
    ul {
      border: 1px solid rgba(0, 0, 0, 0.14902);
    }
    li {
      cursor: pointer;
      padding: 5px 15px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.14902);
      &.TextArea__suggestions__item--focused {
        background-color: rgb(206, 228, 229);
      }
      &:nth-child(n + 7) {
        display: none;
      }
    }
  }

  /* prevent Textarea from vertically expanding and breaking the markup */
  .TextArea__highlighter {
    display: none !important;
  }
  .TextArea__input {
    overflow-y: auto !important;
  }
}
