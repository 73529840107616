.ScrollArea {
  overflow-y: auto;
  overflow-y: overlay; // it place our scrollbar like overlay to main container
  overflow-x: unset;
  width: calc(100% + 20px);
  display: flex;
  flex: 1 1 auto;
  max-width: calc(100% + 20px);
  max-height: 80vh;

  // scrollbar
  scrollbar-color: rgb(18, 18, 219) #f5f5fb;
  scrollbar-width: thin;

  &--horizontal {
    overflow-x: auto;
  }

  &__container {
    width: calc(100% - 20px);
    display: flex;
    height: fit-content;
    min-height: 100%;

    & > * {
      height: unset !important;
    }
  }

  &::-webkit-scrollbar {
    padding: 2px;
    width: 8px;
    height: 8px;
    position: relative;
    z-index: 0;
  }

  &::-webkit-scrollbar-track {
    background: #f5f5fb;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: #8f70cb;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: rgb(105, 81, 153);
    width: 14px;
  }
}
