@import '../../styles/utils/variables';

.ObjectCreator {
  &__label,
  &__error {
    display: block;
  }
  &__label {
    font-weight: bold;
    line-height: 26px;
    font-size: 16px;

    &_wide {
      width: 100%;
      font-weight: normal;
    }
  }
  &__columnLabel {
    width: auto;
  }
  &__value {
    width: 85%;
    margin-top: 3px;
    float: left;

    .TextArea {
      min-height: 80px;
    }
  }
  &__comment {
    font-size: 14px;
  }

  .ObjectCreator__name {
    float: left;
    width: 85%;
  }
  &__label.ObjectCreator__columnLabel {
    font-weight: bold;
    //text-align: center;
    //padding-left: 40px;
    width: auto;
    float: none;
    padding-left: 9px;
  }

  &__label.ObjectCreator__columnLabel.ObjectCreator__columnLabelPriority {
    padding-left: 0px;
  }

  &__label.ObjectCreator__columnLabel.ObjectCreator__columnLabelCentered {
    text-align: center;
  }

  &__label.ObjectCreator__columnLabel.ObjectCreator__columnLabelResolution {
    padding-left: 9px;
  }

  &__error {
    margin-top: -5px;
    color: $red;
    width: 100%;
    padding-bottom: 10px;
  }

  .TextInput.ObjectCreator__input {
    margin: 0px 0px 10px 8px;
    padding: 0px 10px;
    line-height: 32px;
    height: 32px;
    width: 99%;
  }
  &__input {
    &__label {
      width: 15%;
      float: left;
      font-weight: bold;
      line-height: 26px;
      font-size: 16px;
      margin-top: 5px;
      margin-left: 8px;
    }
    &__value {
      width: 99%;
      margin-top: 3px;
      margin-left: 8px;
      float: left;

      .TextArea {
        min-height: 80px;
      }
    }
  }

  &__block {
    padding: 10px 0 20px;

    &:first-of-type {
      padding-top: 0;
    }

    .Table .TableHeaderCell {
      background-color: $blue;
      color: white;
    }
  }

  .ModalWindow__body {
    padding-top: 0px;
    min-height: 250px;
  }
  .ModalWindow__upper {
    padding: 10px;
  }

  &__tableTasks {
    &_edit {
      .Table__floatingButtons {
        min-width: 90px;
      }
      .Table__container {
        padding-left: 84px;
      }
    }
    &_create {
      .Table__container {
        padding-left: 54px;
      }
    }
    td {
      padding-top: 6px;
      padding-bottom: 5px;
      padding-right: 0;
    }
    th {
      display: none;
    }
    .Table__row {
      height: auto;
      overflow: hidden;
    }
  }

  .MajorObjectView__collapse {
    &_title {
      position: relative;
      padding-bottom: 3px;
      border-bottom: 2px solid $blue;
      color: $blue-dark;
      font-size: 16px;
    }
    &_opened {
      transform: rotateZ(180deg);
      background-color: $blue;
      right: 5%;
      bottom: 0;
      margin-right: -38px;
    }

    &_closed {
      transform: rotateZ(0deg);
      background-color: $blue;
      right: 5%;
      bottom: 0;
      margin-right: -38px;
    }
  }

  .CheckBox {
    width: 18px;
    height: 18px;
  }
  .CheckBox:not(checked) + label {
    padding-left: 10px;
  }
  .CheckBox:not(checked) + label:before,
  .CheckBox:not(checked) + label:after {
    width: 18px;
    height: 18px;
    top: 4px;
  }
  .CheckBox:not(checked) + label:after {
    top: 6px;
  }

  .StringData {
    min-width: 10px;
  }

  .TableCell_name input:focus {
    &::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      color: $blue-light;
    }
    &::-moz-placeholder {
      /* Firefox 19+ */
      color: $blue-light;
    }
    &:-ms-input-placeholder {
      /* IE 10+ */
      color: $blue-light;
    }
    &:-moz-placeholder {
      /* Firefox 18- */
      color: $blue-light;
    }
  }

  &__issueTextarea {
    max-width: 100%;
    padding-left: 0px;
    padding-right: 10px;
    overflow-x: hidden;
  }

  &__log {
    font-size: 16px;
    padding-left: 50px;
    padding-top: 27px;
  }

  &_issue {
    .Table .TableHeaderCell {
      background-color: $turquoise;
    }
    .EditableTable__header {
      background-color: $turquoise;
    }
  }
  &__track {
    height: 24px;
    display: block;

    .check {
      margin-left: 8px;
    }
  }
  &__addIcon {
    margin-left: 78px;
    margin-top: 30px;
    cursor: pointer;
    height: 24px;
    display: block;
    padding: 2px;
    position: relative;

    &:hover {
      background-color: $blue-light;
    }

    &__icon {
      height: 24px;
    }
  }

  &__docCounter {
    position: absolute;
    bottom: -5px;
    right: 8px;
    font-size: 12px;
  }

  .ObjectSearch__results {
    //z-index: auto;
  }
  .LocaleSelect__options {
    z-index: 200;
  }
}
