@import '../../styles/utils/variables';

.EditorDialog_docs {
  .ModalWindow__main {
    width: 800px;
    height: 700px !important;
  }

  .ModalWindow__body {
    padding: 1px 18px;
    width: 800px;
    height: 850px;
    min-height: 150px;
    overflow-y: visible; // added because gray scrollbar was appearing inside Add Issue window, when description was filled with long text;
    // as a result, Discard button was shifted to bottom
  }

  .ModalWindow__footer {
    padding-top: 5px;
    width: 800px;
  }
}

.DocsDialog {
  .EditorDialogItems {
    .ItemList {
      &_left {
        width: calc(10% + 40px);
      }

      &_right {
        width: calc(80% - 5px);
      }
    }

    &__more {
      &__visible {
        color: $blue-dark;
        cursor: pointer;
        position: absolute;
        margin-left: calc(80%);
        margin-top: 20px;
      }

      img {
        height: 4px;
      }
    }
  }

  &__listItem {
    cursor: pointer;

    img {
      position: relative;
      top: -1px;
      margin-left: 4px;
      height: 14px;
      vertical-align: middle;
    }
  }
}
