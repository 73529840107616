@import '../../styles/utils/variables';

.Header {
  position: fixed;
  left: 56px;
  right: 0;
  background-color: white;
  z-index: 200;

  &__after {
    width: 100%;
    height: 49px;
  }

  .row {
    max-width: unset !important;
    margin: unset !important;
  }

  &__grid {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  // top

  &__topRow {
    height: 40px;
    border-bottom: 1px solid #cbcbcb;
  }

  &__search {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .SearchInput {
      margin-top: 10px;
    }

    input {
      width: 100%;
      box-sizing: border-box;
    }
  }
}
