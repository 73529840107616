@import '../../styles/utils/variables';

.JoinDialog {
  .ModalWindow__main {
    width: 800px;
    height: 100%;
  }

  .ModalWindow__body {
    width: 800px;
    //height: 850px;
    min-height: 150px !important;
  }

  .ModalWindow__upper {
    padding-top: 15px;
    padding-left: 2px;
    width: 800px;
  }

  .ModalWindow__footer {
    padding-top: 5px;
    width: 800px;
  }

  .scrollarea {
    width: 950px;
  }

  .row {
    margin-left: 10px;
    margin-top: 5px;
    width: 100%;
    flex: none;
  }

  .col-xs-12 {
    padding-right: 0px;
    padding-left: 0px;
  }

  .col-xs-3 {
    padding-left: 10px;
    padding-top: 4px;
    flex-basis: 18%;
    font-weight: bold;
    font-size: 16px;
    min-width: 18%;
  }

  .col-xs-9 {
    flex-basis: 82%;
    min-width: 82%;
  }

  .TextInput {
    display: block;
    float: left;

    margin-left: 0px;
    width: 100%;
    height: 32px;
  }

  .btn_big {
    width: 170px;

    line-height: 48px;
    font-size: 26px;
    font-weight: 300;
  }

  &__label {
    margin-top: 5px;
    padding: 6px 6px;
    font-size: 18px;
    font-weight: 600;
  }

  &__info {
    margin-top: 20px;
    padding: 6px 6px;
    font-size: 18px;
  }

  &__acceptRow {
    text-align: left;
  }

  &__innerRow.row {
    border: 0;
  }

  &__subject {
    &Row {
      button {
        font-size: 16px;
        font-weight: bold;
      }
    }

    &Label {
      font-weight: bold;
      font-size: 16px;
      margin-right: 6px;
      display: inline;
      width: 170px;
    }
    &Value {
      display: inline;
      font-size: 16px;
    }
    margin-bottom: 5px;
  }

  &__text {
    padding: 6px 18px;
    margin-bottom: 4px;

    width: 100%;

    .TextArea {
      margin-left: 5px;
      margin-top: 5px;
      width: 98%;
      height: 100px;

      font-size: 16px;

      &::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        color: $gray;
        font-weight: 500;
        font-size: 14px;
      }
      &::-moz-placeholder {
        /* Firefox 19+ */
        color: $gray;
        font-weight: 500;
        font-size: 14px;
      }
      &:-ms-input-placeholder {
        /* IE 10+ */
        color: $gray;
        font-weight: 500;
        font-size: 14px;
      }
      &:-moz-placeholder {
        /* Firefox 18- */
        color: $gray;
        font-weight: 500;
        font-size: 14px;
      }
      input:-ms-input-placeholder {
        color: gray;
        font-weight: 500;
        font-size: 14px;
      }
    }
  }
}
