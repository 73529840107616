@import '../utils/variables';


.PoliciesStructure {
  padding-top: 15px;

  &__width {
    width: 100%;
    min-height: 300px;

  }

  &__head {
    border: 1px solid $gray;
    font-size: 16px;
    color: $gray-dark;
    padding: 10px 15px;
    width: 100%;
    display: flex;
  }

  &__title {
    flex: 1;
  }

  &__edit {
    width: 200px;
    flex: 0;
    color: $blue-dark;
  }



  &__body {
    border: 1px solid $gray;
    border-top: 0;
    padding: 20px 15px;
  }

  &__descriptionHeader {
    font-size: 18px;
    display: inline;
  }

  &__info {
    height: 24px;
    margin: 4px;
  }

}
