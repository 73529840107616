.SearchInput {
  position: relative;
  float: right;

  input {
    background-color: #ebebeb;
    border: 1px solid #CBCBCB;
    color: #6C6C6C;
    border-radius: 3px;
    width: 169px;
    margin-top: -3px;
    padding: 4px;

  }
  .ObjectSearch__results {
    position: absolute;
    z-index: 1000;
  }

  &__pic {
    position: absolute;
    width: 16px;
    height: 16px;
    right: 2px;
    top: 3px;
    pointer-events: none;
  }
  &__systemsView {
    background-color: white;
    display: none;
    &__visible {
      display: block;
      width: 100%;
      height: 100%;
      position: fixed;
      left: 0;
      text-align: left;
      overflow: scroll;


    }
  }
}
