@import '../../styles/utils/variables';

.ObjectDescription {
  margin-top: 4px;
  margin-bottom: 10px;

  &__title,
  &__text {
    font-size: 16px;
  }

  &__title {
    margin-top: 3px;
    display: inline-block;
    &__editable {
      font-size: 18px;
      color: $blue-dark;
      cursor: pointer;
    }
    &:hover {
      //font-size: 16px;
      color: $blue-sea-dark;
    }
  }
  &:hover {
    .ObjectDescription__title {
      font-weight: bold;
    }
  }

  &__control {
    text-align: left;

    button {
      float: right;
    }
  }

  &__input {
    min-height: 100px;
  }

  .EditButton {
    float: right;
    display: none;
    &__btn {
      &:hover {
        font-weight: bold;
        background-color: transparent;
      }
    }
  }
  &:hover {
    .EditButton {
      display: block;
    }
  }
}
