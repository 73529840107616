@import '../../styles/utils/variables';

.EditorDialog_data {
  .ModalWindow__main {
    width: 800px;
  }

  .ModalWindow__body {
    padding: 1px 18px 30px;
    width: 800px !important;
    height: 850px;
    min-height: 400px !important;
    overflow-y: visible; // added because gray scrollbar was appearing inside Add Issue window, when description was filled with long text;
    // as a result, Discard button was shifted to bottom
  }

  .ModalWindow__footer {
    padding-top: 5px;
    width: 800px;
  }

  &.EditorDialog_fullscreen {
    .ModalWindow__main {
      width: 100vw !important;
    }

    .ModalWindow__body {
      width: 100vw !important;
    }

    .ModalWindow__footer {
      width: 100vw;
    }
  }

  .KascodeSelect {
    height: auto !important;
  }

  .KascodeSelect__list_opened {
    top: 0 !important;
    max-height: none !important;
  }
}

.DataDialog {
  .col-xs-3 {
    padding-left: 10px;
    padding-top: 4px;
    flex-basis: 18%;
    font-weight: bold;
    font-size: 16px;
    min-width: 18%;
  }

  .col-xs-9 {
    flex-basis: 82%;
    min-width: 82%;
  }

  &__subject {
    &Row {
      button {
        font-size: 16px;
        font-weight: bold;
      }
    }

    &Label {
      font-weight: bold;
      font-size: 16px;
      margin-right: 6px;
      display: inline;
      width: 170px;
    }
    &Value {
      display: inline;
      font-size: 16px;
    }
    margin-bottom: 5px;
  }

  &__label {
    margin-top: 20px;
    padding: 6px 18px;
    font-size: 18px;
  }

  &__text {
    padding: 6px 18px;
    margin-bottom: 4px;

    .TextArea {
      font-size: 16px;

      &::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        color: $gray;
        font-weight: 500;
        font-size: 14px;
      }
      &::-moz-placeholder {
        /* Firefox 19+ */
        color: $gray;
        font-weight: 500;
        font-size: 14px;
      }
      &:-ms-input-placeholder {
        /* IE 10+ */
        color: $gray;
        font-weight: 500;
        font-size: 14px;
      }
      &:-moz-placeholder {
        /* Firefox 18- */
        color: $gray;
        font-weight: 500;
        font-size: 14px;
      }
      input:-ms-input-placeholder {
        color: gray;
        font-weight: 500;
        font-size: 14px;
      }
    }
  }

  &__license {
    height: 250px;
    display: flex;
  }

  &__infoRow.row {
    margin-top: 10px;
  }

  &__checklabel {
    margin-top: 7px;
    margin-right: 5px;
    font-size: 18px;
    font-weight: 600;
    position: fixed;
  }

  &__licenseHeader {
    margin-left: 20px;
    font-size: 16px;
  }

  .Table .TableHeaderCell {
    background-color: #5a6dc5;
    color: white;
  }

  &__editIndexRow.row {
    margin-bottom: 14px !important;
  }
  &__editIndexRow.row .col:first-child {
    padding-left: 20px;
    vertical-align: middle;
    font-weight: bold;
    width: 100px;
    padding-top: 4px;
  }
  &__editIndexRow.row .col:last-child {
    vertical-align: middle;
    width: 100px;
  }

  .SimpleTable__headerCell {
    background-color: $blue-deep;
    color: $light;
    font-weight: normal;
    padding: 5px;
  }

  .DataDialog__propertyName {
    min-height: 22px;
    &Text {
      //vertical-align: middle;
      display: inline-block;
    }
  }

  .SimpleTooltip__root {
    float: right;
    //display: none;
    opacity: 0;
  }
  tr:hover {
    .SimpleTooltip__root {
      //display: inline-block;
      opacity: 1;
    }
  }

  .SimpleTooltip__tooltip {
    max-width: 300px;
  }

  &__infoIcon {
    vertical-align: middle;
    display: inline-block;
    width: 20px;
    height: 20px;
    position: relative;
    top: 0px;
    margin-right: 0px;
    background-size: 20px 20px;
    background-repeat: no-repeat;
    border: 1px solid #6cd2dc;
    border-radius: 100px;

    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADgAAAA4CAYAAACohjseAAABtklEQVR4Xu2aPU6EUBSFdWI/s4PRxB5cgbgCZwfSWomFtVjbYGWLOxhWIO4AeothB7oCPJOc4mWC/CQiOeSSnLxMbjF8OZf73gkc13V9NOdrIQBggAaoLgMUlgGe/DfA+fPrKZYEunYKJRR9Ptzmsg4SzsdSuHCUB70TXrpFt9CypR5LAtK9DZZ1B4C0g2hPm6KFMuCuo/4NJcqAW6j6rchtYicLiJv/whJAmVvg7wvU0zH+1wKvmAzQAC1NdCeJiNPUYyHDBN0QQG+KEizgQfqyqc5topBzkPEoIVjbtZJpUYKt6Njd7IYM27Eg3HyGDOFiLI8jpIhpHSRc2gD30QOu3J9TFVo0cGLPE3SGGw96AOYqLRpSER3hFJ0JIKDyBjd6ASqniWCE508KMJdNE9zs1+KA3e4JAE71/OkD5uqB1xsCqJYmBj1/HEjJXn+dCxcTtWflnHZCpo8bKBVwsBfgiskjPNhKChVAv6O+bEgeb3A1VBkyy4EG3BNOItH7A8Aq6Apwicw2MWAKvkA+4GTyoKuq5RyaQbG7HSgChgcfHJRMDonzDnBk2eszAzRAAzRAAzTAH2HOjflcr9icAAAAAElFTkSuQmCC);

    &:hover {
      background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAsCAYAAAAehFoBAAACfUlEQVR4XtWZ0Y2bQBCGOeR3OxXEUp4jSAXeEnAFJu+JjlQQu4ON0gCuwFfCUkEgeU0kroNzBc6P9EtYyKvgncE5j/SJB0vDp2XwzC4Pp9MpCo3Pv/6kuBiyBIknQQNa4Dq+v39XR4FB4askF7jkoABvQUg0oOyA/MskwhQtyBxoxBHYjrHi8UjZDJcafNWSJXPmrHkPmTBlLS6H/vHrw9wH3iusJFgCDiTgllGBzFcisVhWnxVwdBhdEk8Ksg3YkepK6QS4USXBOnoUyq7xSJ8GeUtcNleKf0OewrvCfFOlsruhLLEBuR7pNBRm3TKpkNIj8BKYz9KNwgxP59IkF/zlFb3weRfTie2FxmOE+Qs6RjMK5IodbAPB5dlbbsBK2hHpaHth3VgRzSiAffj083cKgR/gHuJDzEd2L2GmFK7Y5faawjPuFKLJ5lvuTJTmkuWMiaYexCkvJpkpPfocom10g4iFsl8gakDL5uAfGZVCssJ7UEJ0e9bFFp4uF70G4Q3I+g7pnXmz11MSlCXOI2C0hRulXM6z1UoUfZtOWOXtxovnplxd0nbCTqmrRTcQdjrC/hyZujAP5p6FwvWF+l0OdjBHaf12rjEFrPYKD2aUHUiF0uV5pysFyRrP/FCDj+ANft9y6zQXzCq9MG9o5eVAmBOULA/HRhMaHKooTGxgLbf/OPVsJbMEneyg03GVw3a25oKo4aoeFDa3Bd1Uj6r2XMkFyATnG96jKq8wpWtxS5VTQdaMHX6MaMaQ0/iaTux7wyld/SdZQ4dRJ/DCmpbXrGgeZoJ13771Ye41ZeUDPM97U7bYo+qxANs27zHdh0WSSJqB4MPi/Xy6/QsYfBdp7FSspgAAAABJRU5ErkJggg==);
    }
  }
}

.DataDialog__propertyNameText {
  .MultilineText__text {
    margin-right: 0;
  }
}
