.EditorDialog_frame {
  .ModalWindow__main {
    width: 800px;
  }

  .ModalWindow__body {
    padding: 1px 18px;
    width: 800px !important;
    height: 850px;
    min-height: 150px !important;
    overflow-y: visible; // added because gray scrollbar was appearing inside Add Issue window, when description was filled with long text;
    // as a result, Discard button was shifted to bottom
  }

  .ModalWindow__footer {
    padding-top: 5px;
    width: 800px;
  }
}

.FrameDialog {
  text-align: left;

  .DataEditor__block {
    &_left,
    &_right,
    &_top,
    &_bottom,
    &_width,
    &_height {
      margin-top: 5px;
      margin-bottom: 5px;

      &__value_right {
        width: calc(50% + 20px);
        float: left;
        line-height: 26px;
      }
    }

    &__label {
      display: block;
      float: left;
      padding-right: 10px;
      line-height: 26px;
      width: calc(25% + 20px);
      font-weight: 500;
      font-size: 16px;
    }
  }

  label {
    display: inline-block;
    float: left;
    padding-right: 4px;
  }

  &__label {
    font-weight: bold;
  }

  &_dataLabel {
    display: block;
    float: left;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 3px;
    line-height: 26px;
    width: 10%;
    font-weight: 500;
    font-size: 16px;
  }

  &_leftLabel {
    display: block;
    float: left;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 3px;
    line-height: 26px;
    width: 25%;
    font-weight: 500;
    font-size: 16px;
    text-align: left;
  }

  &_rightLabel {
    display: block;
    float: left;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 3px;
    line-height: 26px;
    width: 30%;
    font-weight: 500;
    font-size: 16px;
    text-align: left;
  }

  &_widthLabel {
    display: block;
    float: left;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 3px;
    line-height: 26px;
    width: 25%;
    font-weight: 500;
    font-size: 16px;
    text-align: left;
  }

  &__name {
    float: left;
    width: 40%;
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 2px;
    overflow: hidden;
  }

  &__value {
    position: relative;
    top: 1px;
    float: left;
    width: 60%;
    margin-bottom: 2px;
    overflow: hidden;
  }

  .value_right {
    width: 50%;
    float: left;
    line-height: 26px;
  }

  .row {
    width: 100%;
  }

  .col-xs-2 {
    padding-top: 1px;
    flex-basis: 30%;
    min-width: 30%;
    height: 30px;
  }

  .col-xs-5 {
    padding-top: 1px;
    flex-basis: 35%;
    min-width: 35%;
    height: 30px;
  }

  .col-xs-8 {
    padding-top: 1px;
    flex-basis: 35%;
    min-width: 35%;
    height: 30px;
  }

  &_frameLabel {
    padding-top: 10px;
    padding-bottom: 3px;
    font-weight: bold;
    font-size: 16px;
    text-align: left;
  }

  .StyleDialog__padding {
    .TextInput {
      float: right;
    }
  }
}
