.toolbar {
  display: flex;
  justify-content: center;
  height: 55px;
  border-radius: 25px;
  bottom: 50px;
  position: absolute;
  color: #FFFFFF;
  background-color: #6e49cb;
}
