@import '../../styles/utils/variables';

.SystemsView {
  //margin-top: 40px;
  width: 1200px;
  margin: 0 auto;

  &__controls.row {
    margin-bottom: 15px !important;
    text-align: center;
    font-size: 22px;

    a {
      line-height: 36px;
    }
  }

  .col-xs-3 {
    flex-basis: 25%;
    min-width: 25%;
  }

  .col-xs-10 {
    flex-basis: 75%;
    min-width: 75%;
  }

  &__showPDF,
  &__showCSV {
    display: inline-block;
    //    float: right;
    right: 0;
    margin-right: 20px;

    & > span {
      //position: absolute;
      //right: 18px;
      //top: 38px;
      cursor: pointer;
      color: $blue-dark;

      &:before,
      &:after {
        position: relative;
        top: -1px;
        font-weight: bold;
      }

      &:before {
        content: '{';
      }

      &:after {
        content: '}';
      }

      &:hover {
        color: $blue-dark;
        font-weight: bold;
        background-color: transparent;
      }
    }
  }

  &__titles.row {
    margin: 0;
  }

  &__icon {
    position: relative;
    top: 2px;
    height: 18px;
  }

  &__control {
    float: left;
    margin-right: 10px;
    height: 38px;
  }

  &__mainRow > div {
    margin-top: 30px;
  }

  &__mainRowChild {
    margin-top: 30px;
  }

  &__controlOut {
    width: 1200px;

    button,
    input,
    select {
      height: 24px;
      box-sizing: border-box;
    }

    .row {
      height: 28px;
    }

    .btn {
      margin-left: 12px;
    }

    .btn_align_left {
      float: left;
      margin-left: 0;
    }

    select {
      margin-right: 15px;
      width: 100%;
    }

    .row {
      margin-bottom: 5px;
    }

    &Buttons {
      text-align: right;
    }
  }

  &__systemNameDiv {
    margin: 0 0 20px 0;
    text-align: center;
    font-size: 22px;
  }
  //select:hover, button:hover, a:hover {
  //  background-color: $blue-light;
  //}
  .MajorObjectBlock {
    .ObjectRating__tooltip {
      flex-grow: 0;
      width: 26px;
    }

    .ObjectPicture {
      flex-grow: 0;
    }

    .MajorObjectLink {
      flex: 1;
    }
  }

  .MajorObjectLink {
    color: $blue-dark;
  }

  .SystemBlock {
    .ObjectPicture {
      margin-top: 12px !important;
    }
  }

  .TreeBlock__linked {
    border-right: 1px solid $blue-deep;
  }

  .DatasetBlock__more,
  .InterfaceBlock__more,
  .PipelineBlock__more,
  .ViewBlock__more {
    background-color: $blue-deep;
  }

  &__loader {
    text-align: center;

    .Loader {
      width: 100px;
      margin: 0 auto 25px;
    }
  }

  .ApplicationList__descriptionRight {
    .TreeCol_double {
      .TreeCol_double {
        .TreeCol {
          width: 772px;
        }
      }
    }

    .rightTreeLine:after {
      display: none;
    }

    .ApplicationBlock {
      width: 370px;
      display: inline-block;
      vertical-align: top;
    }
  }

  .DatasetList {
    .TreeCol_double {
      .TreeCol_double {
        .TreeCol {
          width: 772px;
        }
      }
    }

    .DatasetBlock,
    .InterfaceBlock,
    .PipelineBlock,
    .ViewBlock {
      width: 370px;
      display: inline-block;
      vertical-align: top;
    }
  }

  &__searchInput {
    max-width: 100%;
    box-sizing: border-box;
  }

  &__pdf {
    display: inline-block;
    //   float: right;
    text-align: right;
    //right: 0;
    //position: absolute;
    //margin-right: 20px;
    font-size: 16px;

    & > span {
      //position: absolute;
      //right: 18px;
      //top: 38px;
      cursor: pointer;
      color: $blue-dark;

      &:before,
      &:after {
        position: relative;
        top: -1px;
        font-weight: bold;
      }

      &:before {
        content: '{';
      }

      &:after {
        content: '}';
      }

      &:hover {
        color: $blue-dark;
        font-weight: bold;
        background-color: transparent;
      }
    }
  }

  &__body {
    width: 1600px;
  }

  &__bodyRow.row {
    margin-left: 0 !important;
  }
}

.SystemsView__root .SelectView__upper {
  background-color: $green-dark;
  text-align: center;
  color: white;
}
