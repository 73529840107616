@import '../utils/variables';

.ApplicationBlock {
  border: 3px solid $yellow;
  height: 41px;

  &.CollapsableList__more {
    position: relative;
    background-color: $yellow;
    color: $light;

    span {
      position: absolute;
      top: 25%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 42px;
    }
  }
}
