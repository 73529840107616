@import '../../styles/utils/variables';

.ActionWindow {
  .ModalWindow__main {
    height: 700px;
  }

  .ModalWindow__body {
    padding-top: 0px;
    height: 435px;
    min-height: 150px !important;
  }

  .btn_big {
    width: 170px;
  }

  .row {
    margin-left: 18px;
    margin-right: 10px;
    margin-top: 0px;
    margin-bottom: 3px;
    width: auto;
    max-width: 1200px;
  }

  .col-xs-3 {
    padding-left: 0px;
    padding-top: 4px;
    flex-basis: 18%;
    font-weight: bold;
    font-size: 16px;
    min-width: 18%;
  }

  .col-xs-9 {
    flex-basis: 82%;
    min-width: 82%;
  }

  .col-xs-4 {
    padding-left: 0px;
    padding-top: 1px;
  }

  .col-xs-6 {
    padding-left: 0px;
    padding-top: 1px;
    margin-bottom: 10px;
    width: 99%;
    max-width: 100%;
    -webkit-flex-basis: 100%;
    flex-basis: 100%;
  }

  &__subject {
    &Row {
      button {
        font-size: 16px;
        font-weight: bold;
      }
    }

    &Label {
      font-weight: bold;
      font-size: 16px;
      margin-right: 6px;
      display: inline;
      width: 170px;
    }
    &Value {
      display: inline;
      font-size: 16px;
    }
    margin-bottom: 5px;
  }

  .LocaleSelect {
    width: 100%;
    border: 1px solid $gray;
    line-height: 24px;
    border-radius: 3px;
    height: 26px;
    box-sizing: border-box;
    padding: 0 5px;
    outline: none;
    &:focus {
      background-color: $blue-light;
      border-color: $blue;
    }
    &__toggle {
      right: 1px;
      top: 2px;
      &_rotated {
        top: 10px;
      }
    }
    &__list {
      left: 0;
      margin-top: 6px;
    }
  }

  &__label {
    margin-top: 20px;
    padding: 6px 18px;
    font-size: 16px;
  }

  &__text {
    padding: 6px 18px;
    margin-bottom: 4px;

    &_col {
      padding-right: 0;
      padding-left: 0;
      width: 99%;
    }

    .TextArea {
      font-size: 16px;

      &::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        color: $gray;
        font-weight: 500;
        font-size: 14px;
      }
      &::-moz-placeholder {
        /* Firefox 19+ */
        color: $gray;
        font-weight: 500;
        font-size: 14px;
      }
      &:-ms-input-placeholder {
        /* IE 10+ */
        color: $gray;
        font-weight: 500;
        font-size: 14px;
      }
      &:-moz-placeholder {
        /* Firefox 18- */
        color: $gray;
        font-weight: 500;
        font-size: 14px;
      }
      input:-ms-input-placeholder {
        color: gray;
        font-weight: 500;
        font-size: 14px;
      }
    }
  }

  &__request {
    color: green;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  &__error {
    color: red;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .Loader {
    margin-left: 180px;
    margin-top: -5px;
    margin-bottom: 5px;
    transform: scale(0.5);

    .Loader__picture {
      width: 240px;
    }
  }

  &__issue {
    display: block;
    font-size: 16px;
  }
  &__apply {
    display: block;
    float: right;
    margin-top: -20px;
    font-size: 16px;
  }
  &__check {
    width: 16px;
    height: 16px;
  }
  &__check + label {
    cursor: pointer;
  }
  &__check:not(checked) {
    position: absolute;
    opacity: 0;
  }
  &__check:not(checked) + label {
    position: relative;
    padding: 5px 0px 0 45px;
  }
  &__check:not(checked) + label:before {
    content: '';
    position: absolute;
    top: -3px;
    left: 0;
    width: 32px;
    height: 32px;
    border: 1px solid #cdd1da;
    border-radius: 5px;
    background: #fff;
  }
  &__check:not(checked) + label:after {
    content: '';
    position: absolute;
    top: 5px;
    left: 9px;
    width: 17px;
    height: 17px;
    border-radius: 5px;
    background: #6cd2dc;
    //box-shadow: inset 0 1px 1px rgba(0,0,0,.5);
    opacity: 0;
    transition: all 0.2s;
  }
  &__check:checked + label:after {
    opacity: 1;
  }
}
