@import '../../styles/utils/variables';

.SimpleTable {
  width: 100%;
  text-align: left;
  border-collapse: collapse;
  position: relative;

  &__row {
    //display: table;
    width: 100%;
    min-height: 49px;
    border-bottom: 1px solid $gray;
    background-clip: padding-box;

    td {
      background-clip: padding-box;
    }

    position: relative;

    .KascodeSelect__list {
      position: relative;
    }

    &_hidden {
      display: none;
    }
  }

  &__row_delimiter.SimpleTable__row {
    border-bottom: 1px solid $gray-dark;
  }

  &__row {
    overflow: hidden;

    &:hover {
      td {
        background-color: #edf9fa;
      }
      //border-bottom: 2px solid $gray;
      .TypedTable__floatingButtonsInner {
        display: flex;
      }

      .TypedTable__floatingButtonsOuter {
        //  display: block;
      }
    }

    &_nobg {
      &:hover {
        background-color: #fff;
      }
    }
  }

  &__selectedRow {
    background: $blue-light;
    font-weight: 700;
    //    position: relative;
  }

  thead,
  tbody {
    // why was this needed in old Table?
    //float: left;
    //width: 100%;
  }

  td,
  th {
    box-sizing: border-box;
    padding-top: 4px;
    padding-right: 4px;
    padding-left: 6px;
    padding-bottom: 4px;
  }

  thead {
    font-size: 16px;
    text-transform: capitalize;
    text-align: left;
  }

  span {
    display: inline-block;
    //height: 100%;
    &:first-child {
      margin-bottom: 1px;
      word-break: break-all;
    }
  }

  a {
    display: inline-block;
    //position: relative;
    top: 50%;
    margin-right: 14px;
    padding: 0;
    height: 30px;
    //transform: translateY(-50%);
    line-height: 30px;
    color: $blue-dark;

    &:hover {
      //background-color: $blue-link-hover;
      font-weight: bold;
    }

    img {
      margin-right: 5px;
      height: 12px;
      vertical-align: -2%;
    }
  }

  .ObjectPicture {
    width: 30px;
    height: 30px;
    line-height: 26px;
    margin-left: 4px;
    margin-top: 2px;

    &__nameUpper {
      line-height: 30px;
    }
  }

  .react-contextmenu {
    z-index: 5500;
    min-width: 160px;
    padding: 5px 0;
    margin: 2px 0 0;
    font-size: 16px;
    color: $blue-sea-dark;
    text-align: left;
    background-color: $gray-light;
    background-clip: padding-box;
    border: 1px solid $gray-dark;
    border-radius: 0;
    outline: none;
    opacity: 0;
    pointer-events: none;
  }

  .react-contextmenu.react-contextmenu--visible {
    opacity: 1;
    pointer-events: auto;
  }

  .react-contextmenu-item {
    padding: 3px 20px;
    font-weight: 400;
    line-height: 1.5;
    color: $blue-sea-dark;
    text-align: inherit;
    white-space: nowrap;
    background: 0 0;
    border: 0;
    cursor: pointer;
  }

  .react-contextmenu-item.react-contextmenu-item--active,
  .react-contextmenu-item:hover {
    //color: #fff;
    background-color: #e0f5f8;
    //border-color: #20a0ff;
    text-decoration: none;
  }

  .react-contextmenu-item.react-contextmenu-item--disabled,
  .react-contextmenu-item.react-contextmenu-item--disabled:hover {
    color: #878a8c;
    background-color: transparent;
    border-color: rgba(0, 0, 0, 0.15);
  }

  .react-contextmenu-item--divider {
    margin-bottom: 3px;
    padding: 2px 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    cursor: inherit;
  }

  .react-contextmenu-item--divider:hover {
    background-color: transparent;
    border-color: rgba(0, 0, 0, 0.15);
  }

  .react-contextmenu-item.react-contextmenu-submenu {
    padding: 0;
  }

  .react-contextmenu-item.react-contextmenu-submenu > .react-contextmenu-item {
  }

  .react-contextmenu-item.react-contextmenu-submenu > .react-contextmenu-item:after {
    content: '▶';
    display: inline-block;
    position: absolute;
    right: 7px;
  }

  .example-multiple-targets::after {
    content: attr(data-count);
    display: block;
  }
}

.SimpleTable__cell {
  position: relative;
  vertical-align: middle;

  &_key {
    text-align: center;
  }

  .ModalWindow__title {
    text-transform: capitalize;
  }

  &_name {
    .MajorObjectLink {
      color: $blue-dark;
    }
  }

  .MultilineText__text {
    text-align: left;
    text-align-last: left !important;
  }

  .EditableEntity {
    width: 100%;
  }
}
.SimpleTable__cell_.EditableEntity {
  width: auto; // for 1st column without name, used for expand button
}

.TypedTable {
  table {
    //table-layout: fixed;
  }

  &__tailsOuter {
    width: 0 !important;
    padding: 0 !important;
    //position: relative;
    overflow: visible;
    vertical-align: top;
    height: 100%;
    //display: none;
    //position: absolute;
    left: 100%;
    display: inline-block;
  }

  &__floatingButtonsOuter {
    width: 0 !important;
    padding: 0 !important;
    //position: relative;
    overflow: visible;
    vertical-align: top;
    height: 100%;
    //display: none;
    position: absolute;
    left: 100%;
    display: inline-block;
  }

  &__floatingButtons {
    position: relative;
    height: 100%;

    &Inner {
      z-index: 100;
      display: none;
      //display: flex;
      position: absolute;
      left: calc(100% - 160px);
      top: 0;
      width: 160px;
      height: 100%;
      border-left: 0;
      background-color: $blue-light;
      text-align: right;
      flex-direction: row;
      align-items: center;

      &_3 {
        left: calc(100% - 220px);
        width: 220px;
      }

      &_closed {
        left: calc(100% - 10px);
        width: 10px;

        span a {
          display: none;
        }
      }

      &:before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 0;
        border-right: 1px solid $blue;
      }

      &:after {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        left: -1px;
        height: 6px;
        width: 6px;
        border-top: 1px solid $blue;
        border-left: 1px solid $blue;
        background-color: $blue-light;
        transform: rotate(-45deg) translateY(-60%);
      }
    }

    span {
      display: inline-block;
      height: 30px;
      padding-right: 5px;
      flex: 1;
      //height: 100%;
      &:first-child {
        margin-bottom: 1px;
      }
    }
  }

  &__HeaderCell {
    font-weight: normal;
  }

  &__bottom {
    //padding-top: 10px;
  }

  &__bottomButton {
    user-select: none;
    min-width: 100px;
    margin-top: 10px;
    margin-bottom: 10px;
    display: inline-block;
    margin-right: 25px;

    &:hover {
      font-weight: bold;
    }
  }

  &__topButton {
    width: 70px;
    text-align: right;
    user-select: none;
    display: inline-block;
    font-weight: normal;

    &:hover {
      font-weight: bold;
    }
  }

  .MajorObjectBlock {
    margin-bottom: 0;

    &__label {
      color: $blue-dark;
      max-width: 100%;

      &:hover {
        font-weight: bold;
      }
    }

    &__label__gray {
      color: $gray;

      &:hover {
        font-weight: bold;
      }
    }
  }

  .Table__filters {
    th {
      padding-bottom: 6px;
    }
  }

  th span {
    width: 100%;
  }

  &__editingRow {
    background-color: transparent !important;

    .TypedTable__floatingButtons {
      display: none;
    }
  }
}

.TypedTable th .TypedTable__sortButton {
  display: inline-block;
  height: 12px;
  width: 16px;
  float: right;
  margin-top: 4px;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAMCAYAAABr5z2BAAAAeklEQVR4Xp2PsQ2AMAwEjWsKNmAHJvIorJCNWIAdYAKQWAAHWUrxQq/4i8gp/nQ/7NdziMgsuZzqj0k+pss0bn6URLnUrsZnrTo96tGRD+Cku3OKRccBEZhC1ONuAJhC1AEAU4g6AGAKUQcAmdLUCQCmgDoD4JRQ/8kLwfIkWRyqjXsAAAAASUVORK5CYII=);
  &__active {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAMCAYAAABr5z2BAAAAdklEQVR4Xp2PMQ2FQBAF56+RX1Dj6QQgAgsXDKGBmoJzgAQg2YTiFS/HdFvsy8xv2vYD+PONFkDhOyWWcViBSj/1+Y08ZqD1qOcPAXAvnZ0pJX8IEkkx6iRBIilGXQYkxajLgKQYdRkwKa+6GZAUUbcDmvKqK1zsLCvqVY7mvAAAAABJRU5ErkJggg==);
  }
  &__desc {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAMCAYAAABr5z2BAAAAb0lEQVR4Xp2PQQqAMAwE2z178CdVP2if0g+pT1Go92huxZAuOBASElpmo4gEj/26s/ZlHHJwQOfx9LZVS2f+gaXY2YKOempWSXfcoFX/4EUBUac3OOoGLwqYOosCok6jgKjTKHE7q+oc4R8ziDqjPA4SOH+byx4NAAAAAElFTkSuQmCC);
  }
  &__desc.TypedTable__sortButton__active {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAMCAYAAABr5z2BAAAAcUlEQVR4Xp2PwQmAMAxF27+KZ6nupTPYHVygGwl6rbNEcyuG9IMPQkJCy/tRRILHet1Z+z4OOTig83h626alM//AUuxsQUc9NaukO27Qqn/wooCo0xscdYMXBUydRQFRp1FA1GmUuJxVdY7wjxlEnVEeMBAw7sjiGJIAAAAASUVORK5CYII=);
  }
}

.PropertyTable {
  &__container {
    margin-left: 20px;
    tbody {
      tr,
      td {
        height: 40px;
      }
    }
  }
  &__expandButton {
    margin-bottom: 5px;
    box-sizing: border-box;
    width: 18px;
    height: 18px;
    background-color: $gray-light;
    color: $blue;
    line-height: 16px;
    font-weight: bold;
    text-align: center;
    border-radius: 3px;
    border: 1px solid;
    cursor: pointer;
    z-index: 50;
    position: relative;

    &__nested {
    }

    &_active {
      &:after {
        content: '';
        display: block;
        position: absolute;
        top: 18px;
        left: 8px;
        width: 24px;
        height: 32px;
        border-left: 1px solid $gray;
        border-bottom: 1px solid $gray;
        z-index: 30;
      }

      // white background for the arrow
      &:before {
        content: '';
        display: block;
        position: absolute;
        background-color: $light;

        top: 29px;
        right: 0px;
        width: 200px;
        height: 42px;
        z-index: 20;
      }
    }
  }

  &__childRow {
    margin-left: 32px;
  }

  &__container .SimpleTable__cell_3 {
    overflow-x: hidden;
  }
}

.StructureTable {
  &__expandButton {
    //margin-bottom: 5px;
    box-sizing: border-box;
    width: 18px;
    height: 18px;
    background-color: $gray-light;
    color: $blue;
    line-height: 16px;
    font-weight: bold;
    text-align: center;
    border-radius: 3px;
    border: 1px solid;
    cursor: pointer;
    z-index: 50;
    position: relative;
    margin-top: -5px;

    &__nested {
    }

    &_active {
      &:after {
        content: '';
        display: block;
        position: absolute;
        top: 17px;
        left: 8px;
        width: 24px;
        height: 34px;
        border-left: 1px solid $gray;
        border-bottom: 1px solid $gray;
        z-index: 30;
      }
      // white background for the arrow
      &:before {
        content: '';
        display: block;
        position: absolute;
        //background-color: $light;
        top: 24px;
        right: 0px;
        width: 200px;
        height: 38px;
        z-index: 20;
      }
    }

    &__enum.StructureTable__expandButton_active {
      &:after {
        width: 16px;
        height: 24px;
      }

      &:before {
        right: -10px;
      }
    }
  }

  &__childRow {
    clear: both;
    margin-left: 40px;

    .LoadableContent {
      margin-bottom: 20px;

      .Loader {
        margin-top: 10px;
        margin-left: 10px;
      }
    }
  }

  .MajorObjectBlock__label {
    color: $blue-dark;

    &:hover {
      font-weight: bold;
    }
  }

  .MajorObjectBlock__label__gray {
    color: $gray;

    &:hover {
      font-weight: bold;
    }
  }

  &__childRowReference {
    padding-top: 20px;

    .StructureTable__expandButton {
      display: inline-block;
      float: left;

      &:after {
        height: 23px;
      }
    }
  }

  &__referenceIdentityLabel {
    display: inline-block;
    float: left;
    margin-left: 10px;
    width: 219px;
  }

  &__referenceIdentityDescription {
    display: inline-block;
    float: left;
    margin-left: 10px;
    width: 333px;
  }

  &__referenceIdentityUsage {
    display: inline-block;
    float: left;
    margin-left: 10px;
    width: 89px;
  }

  &__referenceIdentityType {
    display: inline-block;
    float: left;
    margin-left: 10px;
  }
}

.PagedContent {
  &__outerTyped {
    .PagedContent {
      position: relative;
      &__nav {
        position: absolute;
        right: 0;
        margin-top: 10px;
      }
    }
  }

  &__nav {
    margin-top: 10px;
    width: 340px;
    //text-align: right;
    //margin-left: auto;
    //margin-right: auto;
    float: right;
  }

  &__pageHeader {
    display: inline-block;
    float: right;
    min-width: 60px;
  }

  &__totalPages {
    position: absolute;
    margin-left: 16px;

    input {
      width: 40px;
      border-color: transparent;
      outline: none;
      pointer-events: none;
      background-color: transparent;
    }
  }

  &__page {
    display: inline-block;
    float: right;
    min-width: 20px;
    height: 25px;
    color: $blue;
    cursor: pointer;
    margin: 0 10px;
    background-repeat: no-repeat;

    &:hover {
      color: $blue-dark;
    }

    &_active {
      color: black;
      font-weight: bold;
    }

    &Input {
    }

    &__first {
      background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACUAAAArCAYAAAD/lEFzAAACiUlEQVR4Xs3YPWhTURjG8XtuS8UMOjnpZhdRHBwU0cEGW1ErShGHaoqIgkgHB6VCxUUhRaeKaMBBLFgHu9g6SIW2g4Mfi1QRwTr5MahI20ERaet/OHjh8CZvb96biwce3ulJfpyEc27iogxX7/RMC+M26SbvSdeNza3vltG7xOgjs6TUnDFohBzwhQ2kn5RqdByj7EERKZBrcWNAejzo5j9QksW4gaDv5LICOh10fpFzzggqMMZIMSh8Jrv4Ps2kBHXSmXBG0CPSliWIGbn/CuRXnBOoiTEkgOZJWwIixOUI6hZAHXSeh504BWg144kA+kB2WEBhXArQONkagvwOfTKBdFT+IB1lB7Uw7pMu4TBtp/MqUlZTA0Aj5JAAKtKZZqrLVQGtYTwmW4LCG7IH0BflupFAr5OChpJBE2STACoC+mYF6aj8QTrKCCp40F7hMO2g8zaqYzkbSD/d60IBWue/1BuDwkuyP1eQT0yGBNAL0i6BfAY1kBW1MygskROA5mr0tgvQ8wnIjhoVjomH/mOttsYEVIXOtqxQPWQyKKwnz4C1VuldJMNBZxUZzwLmjM9Jd8lR/dJNv1MRL/CT0Sns2FoyJe0YnQVGiVSEHZukUzTsVA7P3gZULjDLU8IKxgPhPvtKdvv7zArTUbaL1g7TUXZYWfiP4Dc5Qmc0BcoAk3sDAuwPORzANFT+MB2lw+4Iv1B+kH3JYWmG6Sj7TycdZkCZYf2MKwLsOJ1hA8oMu8AoB51F0kPnngHVaJhHGWEVclKAHQQ2VQ/MgNJPcb9jT1PCjsVWFG+6xDgjPMKsJOUavQHGWeFR6rqCMsOald5gRC/sCCgz7Kr/6D6SvmX0bjFOkVmf3r9amdJAGeOiMAAAAABJRU5ErkJggg==');
      &:hover {
        background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACUAAAArCAYAAAD/lEFzAAACXklEQVR4Xs3XPWhTURjG8Z5btYrpIASchBIHq9BBUKwIilSJWyHgUNxEijRORSgUJ0Gkg9ChHw6lQydB0ElRtC5aVAomUjQapBZc3JwEP0jqfwiecs+5ad+8OdwGHp7pSX9cLj0npqONn56J6QxVJgfJb1JYGys+3sLuBXWW1MjYjjaDvpD9jUEXmSK5JpuIWiL9jU0nuRkFArnxg1b+g2zWo4CgOrm6CeiIxzveqQRlqVWSjQ1qJM/79FwIus5m0ihBVbIvAbTYAugO3WG2G8iiwoN2UZ9JT/ylJlfYzG8cmG0EkqMAHaA+kO7Y4C85DeiNEmRRAlCF7PWATgFa1oNsTPogN1EAUIb66gHVyZALcmM0IOF/94tsHtiBEAWol3pH9sQGv8hJQGUFSIiyoBLZ7QEdBfRJARKi0gdZlBKUpSoJB/Igm0e0+BMpQdUEUN6C5DGAjtMvSVds8JMcsyDNcSN/UosJoMMW5OSVDCRHdXsGlwB9a3byeDazFqRHffcM7vPOnGiyK3k2RTaX24XqIz9ig51kCdhAwu4MWfOcDnPtgBnlPalCcp5Dd5jNnAIV9oqrQYWHyVHhYWKU/jzTw1xU+jAXpYa5u9dUvwdwi80NISp9mEWlD3NRAtiK4BeKACZEyX866WEWFR72jDrngU2zuebcPCXhC/5Qh6QHMrvz1NOE28WM4EkFeWJPqHzCfWzEonSw96TXAxsFNtkKTIwSHi9FYDNC2FSkRfFH61Qf+egZ3G6yu0A99GxGLCoMLNpkV6DuxTbGHelhbzdcdca3sBuiFsh6I3f/AXYyutkPG4kiAAAAAElFTkSuQmCC');
      }
      background-size: 18.5px 21.5px;
      // margin-top: 4px;
    }
    &__last {
      background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACUAAAArCAYAAAD/lEFzAAACmUlEQVR4Xs2YP0iVURiH7/d5K3KoCGuwNg36Z7SERA56IXHIpho0ulEQSDg0JCoJUQ0pTZbEHZxu5hAimFZ0jVtjOTREbTY21WKDUdCfJzhD/Dh46Lx9XD94+A0v7+Hh5XDOuTfpf7tcyOVy92EbjE0car6RC3z07CVmYQ9Mw0X6vruymRRuQyPUw3UkRyEJ9F2FfZCHIszQs/F/Sv2UwiDcE7EQ3SJmlroCX6XQFxC7CZ+zEksg5/bVAmyWegkusV9+efZVM/ESdkmpCt30rJqkMhB7ASecWJzUehNL/y6wQJXogC+ePVZGoE6l6Fkm2uGjlDpggZ766EnJxFqJCmyR0jQUEfmRycR0UjKx10SnZ2K9gYkdgw+eiS3Ss9UwKfPEdruJNUlpCTrpWTFI1UBMpEJih4lFaJDSLPTIvWcQU6mwWAtR9YjNwymzmEplLNZIPIODUnoDXfR8MkiZxHa4HhV7BwURE6kai4lUlNh+ouI5LJ86sdUYsQipiFM8QswgZRZ7DEek9B66UpNR+EIe9/W4/XMclqR0AMoiZRIb8JSOrtGzQlwAfQ61WaT0Kip5SvPSoAfrnGdfPwpKGe/GkTX24Sto8uzDYpKR0AM4F/vuSg1CBbeQCpXgbKyQ4UTP9i2frCchkcpUqIV4Djs99+Rper5pT5KtUPjitrw8TxIPYZOUxmDYIhQl5YRmYIMKITNke9r4SWotFJayC7UST2C753Q/L0IBqQghw0+xIHUeoV63kAqNIHQtVsjyr8sZogx6/QwjNJqhkErVXkhJDELtxJxHaBL6ooQcCUI95NQ/CrURldDpHksKdzxCl0VIuRUSskrlpfBn4fFAX94mFF68HyZcYQChSTL0DcIUNMBdGBIh0/cbQOPJWq3gZLYAAAAASUVORK5CYII=');
      &:hover {
        background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACUAAAArCAYAAAD/lEFzAAACdklEQVR4Xs3XP2gUQRTH8d09JEK0EAQrQVDUMxwkghgRERVRtEsX7MRCEwvBQoidCBKwM1ERsbASAoqFRcAgiBJF4QpBg/gnYKNwcIWGJEoSv8wtPBjeOjiTgRn48YrHsJ97LDN7+ZbR8YtZlo2SGnk2e2n4cOZY7DlBeUi6yGfSy75f2SqtglwxoM46BHKaFA7XmAF11lbyCei61UStWI1+8s6CubLJggWjRpTGLgfsLFmOBctJVr5X15X+e9LgfbEAZs8RyiSpWa022c6eVhAqIZigUoMJSmCnKXeV3izZAex3RJigUoHpqHBYP+U5WWO1fpIeYN/8UOGwPZSXCmyO1AXmQKUGK1woHnqPMkiWbS/5qh2WQN9Q9pM/VqubfAC92WNS6sQGKBPKD/lBtsmFHDQxQUWG9VKmyVqrNU92A5vRUfFhOylNBbZA+gRmoRKBCcoTdpLyWDksW+X70vKFFb4oHvqEcowsWa2N5CMAqr3HPLSvg5BVIpsG7TOp4OtFJvaWdFutRXJAJuU/samKiW0gL4xCn1idzFmtLjIlqHDYLW2QRF3lGXVKaa0PR8lVNKy0mv/Ys5cyobS+O1GBd+PByvdQP+nbpJEHgs5Q7iigL6Tu+93lgYr/6ZynBhJUIiBBRQCF3pN5CiAvFKCrlMtK6xWYfSFfEioqIZCgEgIJyhfkcbo3BOSBigCSv2I+KEBjFZfrU0BH44EkhQW6WQGajA+S5BboXAXoeHyQpBYAukAZV0AzpMcTZJIDukE9/5+gIQNynO6+KciQ0ngkIDXXIoAEpYz/AaAB1744IEHdJitl7gMazNxrhCyVjdcWKHj9BXC/xfWFuF2GAAAAAElFTkSuQmCC');
      }
      background-size: 18.5px 21.5px;
      //margin-top: 4px;
    }
    &__next {
      background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAArCAYAAABimUj7AAABzElEQVR4Xq3XvUvDUBQF8CTVoYui1EmdrIOgjoqDHxTp4sfkIoI4iaijQy3uKrgorbgoWEQHHcQWhQzqUgdHcax/gZOLglDqCTx4EK5cmpPA4W7n10tI8+JuvtWmHMcpISnkEMkXhtMNJ6bLQw6QXiSJ5JBjoG6cQPjXrgkIBWwhPwJyCiTBAm4wUJTBqCDJEHSJLOOe1CnAIBMYZaSNQWTAIqMYvoBcIStAfglARcrIgoTogIzcI50MYgEZGcJ4RFIMIgM68oTMAvkmAB6xgI70m8JuBYkAWCSN8SwgVWQGyJcCUMgrkpUQrxkABTWMceQjBIwgPrZsj7CBuEmP2aQvvIm5J58KQCHvSMYgBuAQHxn4DzEAhXSZ52RQQKYVgEaqFuCRMYyXEFD3aMA+H9eCW1E24P9GFID/I3TjLjfgXFCuPgfsyygSYF6nD0iHUq4D5IFAB5o90jBvtEmMO+1QFgHgj5VuxPIzZDUoZ85FWYxbofwEWUd5gznZzWPcIK1KuZpEE+XFwJbK9Q308n0U59jvgyWMcyRBlAuALS8hHlEuAyhfxLwQyvMo343jI/BIKN+25TzQIpTvYfKXKd9ACuZ+7KC86MR4/QGMXuPujDdjTAAAAABJRU5ErkJggg==');
      &:hover {
        background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAArCAYAAABimUj7AAAByUlEQVR4Xq3Xv0tbYRSH8XsTO2QRWuKkQoviIDqLi0ooGbTtYhcRFBcRI06CMf+ACi6KShcFg4SCDmL8gQpJFjPo6h/g7qAuCqKkz4XCgfCGl3vPG3g42/czhCQ3/tfVrSHP8/KUpHXK3S9map6jV4zWqJ0SlKVtUN8lUKtDZwRxAyzQqwHZAYlrAZ88hlKcE0rUQQWa4D35UAH/kQFOkZpdIQII0se5NCB/aRLkTQFYkSL9FiQMYEbO6IsGEcCM9HJKlFQgAoREyvQD5EUB6BEB7Egnp0KtIRABlEiVhkGeLYAKuaG0IFEAQb5xrqjDgggQAWnjVAzILY2APFgAFXJHqQARQIdcUHcjRIDoSAunRD0G5LsFUCPXAuiRfk61DniPqQH5fBwY3NO4g/EuTrnB18iobwHUX4S+83FA+hmMWz8H2h+j8ID8nJ7TZ9u4HdA/EADoxws0FYxrftEGOce2h7IIgP6x0o84vkvTwbjmuSjNOTKM/6FZxmuaJ7tfnEP6FHFcgBDjmzQv42EA+/gqw1nt/4Nxzh7FXYwLION5ijkaF4DxMe6+YTzH+LKLP4EbhvElGdcDTYbxFbH0QIYe6YnmZNzN6x+AfuDgUOhYDQAAAABJRU5ErkJggg==');
      }
      background-size: 12px 21.5px;
      padding-top: 2px;
      background-position: right 0;
      min-width: 20px;
    }
    &__prev {
      background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAArCAYAAABimUj7AAABt0lEQVR4XrWXL0hDURSHPQoGi0kM1tnEKIIIahhMnBgsDploEGHCotpl2hWDFhFMatFnGTL/tIEWFQzONpMPFYtB/PO7MDnjceHu3nve4OPACd833ht791GT4GfxtqJ8BZAHIciSsHwLLNStbygWOXNNAvIWjB0wGxF8ghQJyPdARiMf2+xNlCgG+QdIQ36lFuQob8XYBVMaeRLy8v+CHOWHIG2SK0hI/gpGWc6QgDwEI5Df6QTUoLwNIwDDFnIOyMsZcpQ/qx3kj7yyDEDejnEKBjTyIcgrvLIJsLwI+tzkTLOF/AkM2sgVFJF3YAQ6ee2bV3llE2B5CfT4yxkyyB9AkuX2EOSdmGca+X3td/7ipuabfBSPnAP9mv08y/0DgWZ/gEuXkApkwHlk3wUuEOn2DZDAH5o5UBc5UULJCNk+VNwD5sgbSCFStgr4PdjNkO/RxAR5HK7GEbk0BiSOh/YBfWQbzNlGyOuIzpEJRIqGgFfkC0wicqwNOEQ2QK6RiM/xfR1jyRDhgGDkG8wgss8B+cgPyKqI1EvgCkZBE5mWfI1dxliLrEMOxBN554BcJIexCn5V8w8qIOmYoZU0jQAAAABJRU5ErkJggg==');
      &:hover {
        background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAArCAYAAABimUj7AAABuElEQVR4XrWXPUgjQRxH969gYWMlFjYWYmWrwlUxoEfEiIWFBIloIUICltFe4l17oIUWomClNmosgsSPLqCVYqV4xV1lULGxED+ehUxYBiY7Mxl4/Lr3YIvdWQk8no7fy8LkYQ4qkBbP8hWYrRJcSF3kinPxIG9k1mAqJHiBhHiQb0JKIx/+m8uUpA7yZ0giP2MDsZQ3MRswrpEPIi9/C8RSvgNJk1wF3OUPMKTkCvEgr0Ac+aVOIDXKm5kD6DfI9QH/coVYyv9DDPkNG1gFkLcwh/DDVq4CenkReu3kCokgv4UB5HdsYBVA3soUoEcjjyH/F0Q8EpKXoNtdrhCD/Bp+Knl0BHkbe6SRX0Ec+X3gcBpg1yR3DfRpBDNK7h4oaATbPLpOX4EUHIcE7XBCpMs1IB5eaOZAVWQf4j4jYvFRsQyYI4+QIFK2CFh82C0Cusg6pFwi4nC5GiFyagz4uB5aBLSRVZiOGhGnK7qKjBIpGgJOkVcYI7KnDVhE/kC2lojL9f0XkzNEVMBj5A0miWypgP/IO6S/Ir5+AheYvCYy4fM3dp5ZCgkqhoBz5EkF/EUyzCJ8QPYTRmziw5LBvFkAAAAASUVORK5CYII=');
      }
      background-size: 12px 21.5px;
      padding-top: 2px;
      padding-left: 20px;
    }
  }
}

.Table__customFilterCell span {
  display: inline;
}

.TypedTable__topButtonsCell {
  text-align: right;
  float: right;
  margin-top: 10px;
}

.Table__filteredView {
  .btn_edit:hover {
    font-weight: bold;
  }
}

.TypedTable__customColumn_order {
  margin-top: 12px;
  margin-left: -34px;
  z-index: 30;
  left: 0;
}

.StructureTable__expanded > tbody > tr {
  border-bottom: none;
}

.StructureTable__nested th {
  padding-top: 0px;
}

.DataEditorTable__container .SimpleTable__cell_3 {
  overflow: hidden;
}

.EditorDialog__editing,
.EditorDialog__viewing .EditorDialog__editing {
  .SimpleTable__row {
    overflow: hidden;
    &:hover {
      td {
        background-color: #edf9fa;
      }
    }
  }
  .TypedTable__floatingButtonsInner {
    background-color: $blue-light;
    &:before {
      border-right: 1px solid $blue;
    }

    &:after {
      border-top: 1px solid $blue;
      border-left: 1px solid $blue;
      background-color: $blue-light;
    }
  }
}

.EditorDialog__viewing,
.EditorDialog__editing .EditorDialog__viewing {
  .SimpleTable__row {
    overflow: hidden;
    &:hover {
      td {
        background-color: #f0ecf9;
      }
    }
  }
  .TypedTable__floatingButtonsInner {
    background-color: #e0d8f1;
    &:before {
      border-right: 1px solid #bcaae0;
    }

    &:after {
      border-top: 1px solid #bcaae0;
      border-left: 1px solid #bcaae0;
      background-color: #e0d8f1;
    }
  }
}
