@import '../../styles/utils/variables';

.EditorDialog_applicationReference {
  .ModalWindow__main {
    width: 900px;
    height: 750px !important;
  }

  .ModalWindow__body {
    padding: 1px 18px;
    width: 900px !important;
    height: 850px;
    min-height: 150px !important;
    overflow-y: visible; // added because gray scrollbar was appearing inside Add Issue window, when description was filled with long text;
    // as a result, Discard button was shifted to bottom
  }

  .ModalWindow__footer {
    padding-top: 5px;
  }

  .ModalWindow__body,
  .ModalWindow__main,
  .ModalWindow__upper,
  .ModalWindow__footer {
    width: 900px;
    max-width: 100%;
  }

  .scrollarea {
    width: 920px;
  }
}

.PackageApplicationDialog {
  &_borderGray {
    margin-top: -5px;
    padding-bottom: 0px;
    border-bottom: 1px solid $gray;
  }

  .EditorListList .SimpleTable__headerCell {
    background-color: rgb(62, 137, 193);
  }

  .tabs-navigation {
    box-sizing: border-box;
    padding-left: 20px;
    background-color: $gray-light;
    border-bottom: 2px solid $blue-sea-dark;
  }

  .tabs-menu-item {
    margin-bottom: -4px;

    &.is-active {
      border: 2px solid $blue-sea-dark;
      border-bottom-color: #fff;
    }
  }

  .SimpleTable__cell .EditableEntity {
    width: auto;
  }

  .SimpleTable__cell_3 .EditableEntity {
    margin-top: 1px;
    width: 95%;
  }
}
