.BigButton {
  display: inline-block;
  height: 50px;
  line-height: 50px;
  vertical-align: middle;

  .Loader {
    position: relative;
    transform: scale(0.5);
  }
}
