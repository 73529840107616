@import 'utils/variables';

/* Styles */
body {
  font: 14px 'PT Sans', 'Helvetica', Arial, sans-serif !important;
  line-height: 1.4em !important;
  letter-spacing: unset !important;
  color: $gray-dark;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  font-smoothing: antialiased;
  font-weight: 400;
  background-color: $light !important;
  min-width: 1200px;
  padding: 0;
  margin: 0;
  min-height: 500px;
}

body.overflow_hidden {
  .Header,
  .ObjectHeader__objectName {
    box-sizing: border-box;
    padding-right: 16px;
  }
}

.row {
  margin: 0 auto !important;
  max-width: 1200px !important;
}

h1,
h2,
h3,
h4 {
  font-weight: normal;
}

a,
.link {
  color: $blue;
  text-decoration: none;
  cursor: pointer;
}

.InfoIcon {
  height: 24px;
}

.btn {
  background-color: $light;
  border: 1px solid $gray;
  border-radius: 3px;
  line-height: 20px;
}

.btn_collapse {
  display: inline-block;
  height: 9px;

  img {
    max-height: 100%;
  }
}

.btn_edit {
  background: transparent;
  border: 0;
  color: $blue-dark;
  font-size: 14px;

  &_light {
    color: $blue;
  }

  &:focus {
    outline: 1px dotted;
  }

  &_active {
    color: $orange-red;
  }

  img {
    position: relative;
    top: 2px;
    margin-right: 5px;
    max-height: 16px;
  }
}

.btn_close {
  max-height: 32px;
}

.ButtonBlue {
  box-sizing: border-box;
  display: inline-block;
  min-height: 30px;
  min-width: 170px;
  padding: 0 5px;
  background-color: $blue;
  color: $light;
  line-height: 30px;
  border-radius: 3px;
  border: 0;

  &_big {
    margin: 0 10px;
    height: 50px;
    line-height: 48px;
    font-size: 26px;
  }

  &.ButtonBlue_outline {
    background-color: $light;
    border: 2px solid $blue;
  }
}

.btn_big {
  margin: 0 20px;
  padding: 0 40px;
  height: 50px;
  background-color: $blue;
  border: 0;
  color: $light;
  line-height: 48px;
  font-size: 26px;
  border-radius: 3px;
  &:hover {
    background-color: $blue-hover;
  }
  &.disabled {
    background-color: $gray;
    pointer-events: none;
  }
}

.btn_blue {
  box-sizing: border-box;
  display: inline-block;
  height: 30px;
  min-width: 150px;
  padding: 0 5px;
  background-color: $blue;
  color: $light;
  line-height: 30px;
  border-radius: 3px;
  &:hover {
    background-color: $blue-hover;
  }
  &.disabled {
    background-color: $gray;
    color: #333333;
    pointer-events: none;
  }
}

.btn_finalize {
  margin-top: 1px;
  height: 25px;
  border: 0;
  line-height: 25px;
}

.btn_key {
  position: absolute;
  padding: 0;
  background-color: $blue;
  border: 0;
  width: 60px;
  height: 34px;
  margin-left: -5px;
  margin-top: -17px;
  border-right: 2px solid white;
  border-radius: 2px;

  &:hover {
    background-color: #3dc2d1;
  }

  img {
    width: 20px;
    //width: 100%;
  }

  &__disabled {
    background-color: #a270cb99;
    border-color: #8f70cb;

    &:hover {
      background-color: #a270cb;
    }
  }
}

.select {
  height: 24px;
  border: 1px solid $gray;
  border-radius: 3px;
}

.TextInput {
  box-sizing: border-box;
  padding: 0 5px;
  width: 100%;
  height: 26px;
  border-radius: 3px;
  border: 1px solid $gray;
  line-height: 24px;
  outline: none;
  font: 14px 'PT Sans Regular', Arial, sans-serif;

  &:focus {
    background-color: $blue-light;
    border-color: $blue;
  }
  &.disabled {
    color: #c0c0c0;
  }
}

.TextArea {
  box-sizing: border-box;
  padding: 4px 20px 4px 4px;
  width: 100%;
  min-height: 100px;
  outline: none;
  border: 1px solid $gray;
  font: 14px 'PT Sans Regular', Arial, sans-serif;

  &:focus {
    background-color: $blue-light;
    border-color: $blue;
  }
}

.FileInput {
  &_hidden {
    width: 1px;
    height: 1px;
    overflow: hidden;
    position: absolute;
  }
}

/* used in FilteredTabbedTable, Profile */
.tabs-list {
  .tabs-menu {
    margin: 0;
    padding: 0;
    list-style-type: none;

    &-item {
      margin-top: 18px;
      box-sizing: border-box;
      display: inline-block;
      padding: 0 15px;
      height: 40px;
      line-height: 40px;
      font-size: 22px;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      background-color: transparent;
      text-transform: capitalize;

      &.is-active {
        background-color: $light;
      }

      &:not(.is-active) a span {
        color: $blue-dark !important;
      }
      a {
        color: $blue-dark;
      }
    }
  }

  .tab-panel {
    padding-top: 20px;
  }
}

/* used in FilteredTabbedTable, Profile */
.tabs-list {
  .tabs-menu {
    margin: 0;
    padding: 0;
    list-style-type: none;

    &-item {
      margin-top: 10px;
      box-sizing: border-box;
      display: inline-block;
      padding: 0 15px;
      height: 40px;
      line-height: 40px;
      font-size: 22px;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      background-color: transparent;

      &.is-active {
        background-color: $light;
      }

      a {
        color: $blue-dark;
      }
    }
  }

  .tab-panel {
    padding-top: 20px;

    .Table {
      width: 100%;
      text-align: left;
      border-collapse: collapse;

      thead {
        font-size: 16px;
        text-transform: capitalize;
        text-align: left;

        tr {
          //background-color: $yellow;
        }
        th {
          font-weight: normal;
        }
      }

      thead {
        .Table__filters {
          height: 45px;
          background-color: transparent;
          color: $gray-dark;
        }
      }
    }
  }
}

.filter {
  &__open_flipped {
    transform: rotateZ(0.5turn);
  }
}

.FunctionData {
  &__item {
    margin-right: 10px;
  }
}

.EditableEntity__layoutRow div {
  padding: 0;
}

.CheckBox {
  cursor: pointer;
  position: relative;
  padding: 5px 0px 0 35px;
}
.CheckBox:before {
  content: '';
  position: absolute;
  top: 5px;
  left: 0;
  width: 20px;
  height: 20px;
  border: 1px solid #cdd1da;
  border-radius: 5px;
  background: #fff;
}
.CheckBox:after {
  content: '';
  position: absolute;
  top: 5px;
  left: 0;
  width: 20px;
  height: 20px;
  border-radius: 5px;
  //background: #6CD2DC;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAD4AAAA+CAYAAABzwahEAAAEPElEQVR4Xu3bb2hVdRjA8Z1z7r27DU2ZwlqigbXRSkOQAnuhqP15IfMfSJBvhP0pszkykkhFrN1pBNm62mSucGz1RhOG2gtfmZEVRhTKQmvWC4VCxxrb2r3n3nNP3xcPeHjgwszd7e63c+HDDwaiX579zt+r5ft+yUz82AjDDReGh+GmCcPD8DA8DI+YEtJ0pX8uy4FAwEhy6aN7jJ440ZUs57FJbMAyfp4wduLElbF0YEkgII0nMWZsOHZiFWIS4Ms629g9zrSXsDSjDHYgPIqUkeFEx1i6MV+irUCABQ8fG3Vwk+h21ASi/cDEXXRxVL9oVDi2YjOiatI+PHyP/UZdwDDtxSz7MAuWOqB5uIOdTHvUmHCiHZbjWAAHJSo+jQTRV425ZJXo/ViBCPS0MzhD9FHTrtVfwKuIwVLT9tCH1426VpdL0gTmwlaTzmEQbzHtAWPuzoi2WD5EDSLQ+9pFBy4Yc1sq0fWoRQx6X2dxGe8xbc+YcDyFdxHPs69vooHotDH340x7Dksb5ul9LeswWoi+btoTmL14Wu9r4eIUPjfqCQzTXs9Sh3ieff0bdjNtt+Dh/GNKWV4JBLjsrWMFiH6E5SPMznPHNYQ6ov8p+MNGid6BF7Edr6GZn/cgOoHRD8ip6+HgvhY5/Is2oi9P1lNWB2tRhQosxCJsRHIC4xvxPKKw1MSz+Bptk/2U1cZ8xNXt4Ev4A+/f57SfYXlbP01Rp65mpj08Jc/VA9G2mIVdTL32PqLnsXyKcthqX+cwgu1E35jsFwpp9EL/OQsOynGEgOX/IzrCchCPqYOtL9EpnCD6/KS/SZHLwW5cRBa+etzjoBKfyVH5Xj6bsQUxWCJ46voZLVP2CkmeaDTgJ0i8TF3iUY124svGOe1qltbAqUvfdd3Bm3LXNTXhEn9b4v+UeL1tYliNTqKccZy6OrEQNiwVn8I7RP9QFC8Nie9j2YVBePDVDUQM69Gq4rV9WJ7nzOKil+iuonpbSvw5lj0YRg6++tWPox4v55n2SjlnlwanLLL4HbuL8jUx8Z0sn2AUxEs0YONBfEDksyr6IbkknQMbJSp+UH7FbxVluGjBWWSCt4zqNNdDbJVEOxL9BBxYwhcukjhX1F8MYCopljp8F4i31KXuAhyXi5RGrEMU+pPFN2hl2rlCh0/IVzqJqmD5Cksl1lJXXmlcwuOogKMCPNzEc0T3T5uvgjChv+WWdQCe2rc2SrEalXleBIwgoaKLP1zif2TZETjSl6g9bwt9FM+gF13T9ss/xJ9mOYRRePrqLkC/CGhi2plpGy4O4yRc+Cpa34AMoFHdak7PcCKyLG/gEjLI6WgxhqRsETO+5yYT3Ipf4Um8r/b1tzhs3Bf85Ei/DdcwJLEuhtCHbUx7zLhwif+FZSNO4kucxhdYQ/RfEjAlwv+TYq4wPAwPw00ThofhYXgY/h8utX6K3T3RaAAAAABJRU5ErkJggg==);
  background-size: cover;
  //box-shadow: inset 0 1px 1px rgba(0,0,0,.5);
  opacity: 0;
  transition: all 0.2s;
}
.CheckBox_checked:after {
  opacity: 1;
}

.CheckBox {
  width: 30px;
  height: 30px;
}

.checkmark {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAD4AAAA+CAYAAABzwahEAAAEPElEQVR4Xu3bb2hVdRjA8Z1z7r27DU2ZwlqigbXRSkOQAnuhqP15IfMfSJBvhP0pszkykkhFrN1pBNm62mSucGz1RhOG2gtfmZEVRhTKQmvWC4VCxxrb2r3n3nNP3xcPeHjgwszd7e63c+HDDwaiX579zt+r5ft+yUz82AjDDReGh+GmCcPD8DA8DI+YEtJ0pX8uy4FAwEhy6aN7jJ440ZUs57FJbMAyfp4wduLElbF0YEkgII0nMWZsOHZiFWIS4Ms629g9zrSXsDSjDHYgPIqUkeFEx1i6MV+irUCABQ8fG3Vwk+h21ASi/cDEXXRxVL9oVDi2YjOiatI+PHyP/UZdwDDtxSz7MAuWOqB5uIOdTHvUmHCiHZbjWAAHJSo+jQTRV425ZJXo/ViBCPS0MzhD9FHTrtVfwKuIwVLT9tCH1426VpdL0gTmwlaTzmEQbzHtAWPuzoi2WD5EDSLQ+9pFBy4Yc1sq0fWoRQx6X2dxGe8xbc+YcDyFdxHPs69vooHotDH340x7Dksb5ul9LeswWoi+btoTmL14Wu9r4eIUPjfqCQzTXs9Sh3ieff0bdjNtt+Dh/GNKWV4JBLjsrWMFiH6E5SPMznPHNYQ6ov8p+MNGid6BF7Edr6GZn/cgOoHRD8ip6+HgvhY5/Is2oi9P1lNWB2tRhQosxCJsRHIC4xvxPKKw1MSz+Bptk/2U1cZ8xNXt4Ev4A+/f57SfYXlbP01Rp65mpj08Jc/VA9G2mIVdTL32PqLnsXyKcthqX+cwgu1E35jsFwpp9EL/OQsOynGEgOX/IzrCchCPqYOtL9EpnCD6/KS/SZHLwW5cRBa+etzjoBKfyVH5Xj6bsQUxWCJ46voZLVP2CkmeaDTgJ0i8TF3iUY124svGOe1qltbAqUvfdd3Bm3LXNTXhEn9b4v+UeL1tYliNTqKccZy6OrEQNiwVn8I7RP9QFC8Nie9j2YVBePDVDUQM69Gq4rV9WJ7nzOKil+iuonpbSvw5lj0YRg6++tWPox4v55n2SjlnlwanLLL4HbuL8jUx8Z0sn2AUxEs0YONBfEDksyr6IbkknQMbJSp+UH7FbxVluGjBWWSCt4zqNNdDbJVEOxL9BBxYwhcukjhX1F8MYCopljp8F4i31KXuAhyXi5RGrEMU+pPFN2hl2rlCh0/IVzqJqmD5Cksl1lJXXmlcwuOogKMCPNzEc0T3T5uvgjChv+WWdQCe2rc2SrEalXleBIwgoaKLP1zif2TZETjSl6g9bwt9FM+gF13T9ss/xJ9mOYRRePrqLkC/CGhi2plpGy4O4yRc+Cpa34AMoFHdak7PcCKyLG/gEjLI6WgxhqRsETO+5yYT3Ipf4Um8r/b1tzhs3Bf85Ei/DdcwJLEuhtCHbUx7zLhwif+FZSNO4kucxhdYQ/RfEjAlwv+TYq4wPAwPw00ThofhYXgY/h8utX6K3T3RaAAAAABJRU5ErkJggg==);
  background-size: cover;
  width: 20px;
  height: 20px;
  display: inline-block;
}

.CheckBox__noedit:before {
  border: 0px solid #cdd1da;
  background: none;
}

input:-ms-input-placeholder {
  color: #a9a9a9;
  //opacity: 1;
}

button,
.btn,
.btn_edit,
.btn_big,
.btn_blue,
.btn_close,
.btn_collapse,
.btn_finalize,
.btn_key,
a {
  cursor: pointer;
}

html,
body,
div#app,
div#app > div,
div#app > div > div,
.AppContainer,
.AppChildren {
  height: 100%;
}

.MajorObject__outer {
  min-height: 100%;
}
.MajorObjectView {
  min-height: 100vh;
}

.Alias {
  &__photo {
    height: 32px;
    width: 32px;
    border-radius: 100%;
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: cover;
  }
  &__nameCol {
    padding-left: 5px;
    line-height: 1;
  }
  &__alias {
    font-size: 14px;
  }
}

.StringData.StringData__placeholder {
  color: $gray;
}
.StringData.DatasetName {
  color: $blue;
  cursor: pointer;
  &:hover {
    font-weight: bold;
  }
}

.Input_radio + label {
  cursor: pointer;
}
.Input_radio:not(checked) {
  position: absolute;
  opacity: 0;
}
.Input_radio:not(checked) + label {
  position: relative;
  padding: 0px 0px 0 24px;
}
.Input_radio:not(checked) + label:before {
  content: '';
  position: absolute;
  top: 2px;
  left: 0;
  width: 16px;
  height: 16px;
  border: 1px solid #cdd1da;
  border-radius: 4px;
  background: #fff;
}
.Input_radio:not(checked) + label:after {
  content: '';
  position: absolute;
  top: 6px;
  left: 4px;
  width: 10px;
  height: 10px;
  border-radius: 2px;
  background: #6cd2dc;
  //box-shadow: inset 0 1px 1px rgba(0,0,0,.5);
  opacity: 0;
  transition: all 0.2s;
}
.Input_radio:checked + label:after {
  opacity: 1;
}

.Input__radio {
  width: 15px;
  height: 15px;
}

.EditableEntity_pubSubCounter {
  text-align: center;
}

.Counter {
  display: inline-block;
  min-width: 18px;
  line-height: 18px;
  background-color: $gray;
  text-align: center;
  //border-radius: 50%;
  border-radius: 10px;
  padding: 0 1px;
  color: $light;
}

.Counter_subscriptions {
  background-color: $red-darker;
  color: $light;
}
.Counter_publications {
  //background-color: $blue-deep;
  background-color: $violet;
  color: $light;
}
.MajorObjectView_publication {
  .Counter_publications {
    position: absolute;
    left: 18px;
    top: 6px;
    //background-color: $violet;
  }
}

.boldOnHover:hover {
  font-weight: bold;
}
.Version__Approved {
  color: $green;
}

.SearchFilterInput {
  &__outer {
    padding-right: 8px;
  }
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAoCAYAAACM/rhtAAACLklEQVR4Xs2X0W3CMBCGXRaADaDqc0U2IBvABoQBKrFB0wmaqgMQNkgnqLtBUJ8rNRuUCehZ+h+QleOcwwr5pVNeEufz77uzfXc6nUyInr5/VvRIEXMKXw2FRVTvjw9/JoJEQALb0sPF1ITrSFG4AGhcQIA5p0oRTAbNCbKICgjXXk08fVBkGjdHLXAl4GJqSWFp7InOwXDnGuSWJTfqs+9mKJ6MYmF4HShSONkNEDn3yYEhl0ojCOPkF0D3BJhplri8MOsEcKLoPUvhIF8YgDVaVjgglnbaBkdgiSa5CdK5uGEASuRjsINbZllTc4Xg+lsLwBj/lAGd3Yx7ueecFnKLyfrKggAZlxrMXi8ZZkrmJFpAZefnC4dxcSUCMhu/NfFVtQCkIiAz47onQCMD3laLIQPqHUR19aFGBGReigMoj/kbAmiFvqWXXLG1FnCBU0kU4Ti21AJWOJr7yk085cx1oBIBsd8WjItXQ2KvXxtfuPmFVnHBuPhMkOp8RDcoBVdlQLjIfbAjyELpnKUYM8s70dxJKiRzmxrczGxAQeT+sjKQKbbVYMAJZj0XmmuF8PtcykxQhJQAGUi9NJDyVod8hBP7ngDHuDMnnINSopcY5CZOjoSTsMuzGa6QTZefYQXuKTZqJ+Fg177mXE2989wXnrULvwmjl+4UTgKwB2khPcDBQSb9nahxke+YkxUABws5B+BgIY8AHCAkCgWAt4bkqxiAg4A8AuBw3gf/AZk8GSub2sv7AAAAAElFTkSuQmCC);
  background-repeat: no-repeat;
  background-size: 20px;
  background-position: 4px 4px;
  padding: 0 0 0 28px;
  border: 1px solid #cbcbcb;
  border-radius: 3px;
  line-height: 28px;
  //font-size: 16px;
  font: 16px 'PT Sans';
  width: calc(100% - 28px);
  height: 28px;
}

.TableLoader {
  width: 170px;
  margin: 0 auto;
  padding: 15px 0;
}

.TypeReference {
  a {
    color: $blue-dark;
  }
}

.scrollarea .scrollbar-container {
  z-index: auto !important;
}

.View__StyleFrame {
  img {
    height: 21px;
  }
}

input.selectedFilter {
  border: 2px solid $blue;
}
.selectedFilter {
  .KascodeSelect__input {
    border: 2px solid $blue;
  }
}

.notFoundImage {
  display: block;
  width: 600px;
  margin: 0 auto;
}

.LocaleCreateWindow {
  &__text {
    min-height: 400px;
  }
  .ObjectSearch__result {
    padding: 4px 8px;
  }
}

.ImagePreview {
  max-width: 100px;
  max-height: 40px;
}

.ActivityType {
  width: 36px;
  height: 36px;
  background-size: 36px 36px;
  background-repeat: no-repeat;
  &__operation {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEQAAABECAYAAAA4E5OyAAACGElEQVR4Xu3awW3bMBxG8bboXekEddFzEXuCSBMkmcDSAHXUDbJBDXcAa4Nog9IbEOg5gDYoM4H7DgIIAlUPAUyy4Efg4e+bgR8kCrT89nw+v9HyvRNBmEAEIhCBCEQgAhGIQAQiEIHsdrvhfwN5fyGIK4aha2oLvkJCjOJvGTDWjMljlAriMQxVAUCJewgYLeO4AGUiAExkaTwcDnx+/fI/Ib4eo2d8pxzWC/WgDEluGTAGj5FFFR3n2zcuCBh7xpZyXI/RQWgglylIHR2E+9QyGpoyBKnigoQoG2L6VfRjFxTHftIA8ET10uV7oVqibEA8CqMB5shsQ7DD6YKHxybrswwwHWOv43+I8o3RCSREGRj35IoB+frreUVX/0AZGU0xIGTpNyiG6gUUWxJINc8b+gnKSCu9hvDdkgXlTiC+itYCSZclJ5DwSbahUSAeZaJ7ABoyM4ArEySEMdQA8IE2kQ536eMpZhj7H18+jwswrrQr5IaegGn1lAk7grIXSNgDKEPuL7tX/mwTpe18ZLhjX3E5gmyJou8rBpjao+gPM9dkQVmnAnEZonwk41Higpwox1VRmwLkMVOQgX2kjw7Cl1pGl9mt04PRJf07xPzIu6U1rRK+t+3AGDxALJD0Z5nzXzb3Bgyr43+AobOMpU8eo2wQO18ZLv3b//TVQJyWAIrbVEm/ugtEIAIRiEAEIhCBCEQgWn8Ay/DCZlk1go0AAAAASUVORK5CYII=);
  }
  &__transformation {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEQAAABECAYAAAA4E5OyAAADfUlEQVR4Xu2a4W3bMBCFm6L/7UwQFv1dWJ0g6QR2Jog8QB11AisTlE0HqDJB5AnqTFAH/V3E3sCaIH0EnlEj8FGsIqQ6gQQejIQ6G/pwvns8+ejx8fFVXH/1OiKIQCKQCCQCiUAikAgkAolAOqk3bQP49Ov3EC8ZlEJb6Ozb+3db4drS7UMllOO6de8yhDDm0Ak0gpaAZJ5CI4wxNIAuINu7DKFW+xuE8gAAC+4ZaEIQQly/gCTCxpgS43pXVFk/0obhY8QnvQFCGJa1o+kqCFUhEELgq6sJSxbH56wRtAaUdAfmpQFxYtYIhmuXP14gi6/QjnMNGZIFBmygc+gYN3bkxL/vQj9HS4aEBN5AmceYWbxcBkD5CJDLrmfIOVT5MkOEQWHPZdmiBsZnAUZ3gPBmShosYRFG/cpq6odV02WYxvcSsECwa+E9KhZTdT7kwI0TUri20hFAIxDj/V9zDVUaM6GODLBnnnn+GdHrqMqQHBpIe4FQU897WELvMJBwD3FBSy8uHuZsgJU3nQcSeKq9BZTMY/2XzA7fGmiZh5SBh7kvhFLudZMJNAoEv3CtWQMQCyW8SVNz7D8RLbrscosd+A6fZWSxXhTPTXFadat6QCRb+eYw9AGRrfxNw/B7AYZeIFTZRtxsNksgMeOwl0Gm20Bk2/4Vesvh0DF0BVVSHEC4TPsJ3QJKegBG4boX9IB9q+G5zGav60wBothtciSQ80HVzodUnsPcd0Ipod1U/0THV4Z1BDIcIJ07GMJ1Kz7GnEKG9UM625wyI+YHYCRdabvti7WBN/8v68P19fWqlQzRDoNaIvZMfYYQQs6vjmmhNtzTOVtkTNmoqP5nGPzphNfd3kGWRXfImpEL8EZ7wyVdQChTA+MGtSF92sUAkh1KPCAalTWEhXAqbG8cDCHOte6J8Bikgs5UAiGUQrD7tiZuLbjijKBVd5mi4dR9LQBW33aHLRZqoxuI/PROHiX4r8lUA6EPOT2wlWIv8cRJY8hLvqc+IHSXc89wuXRQBBi+WjHX7FS3AWPHBbQKHlDTv6gzZlQOGRbWiQBnTNUNpi2t+1JfhsgdohQzwG/vJzRsPTnt0mw16BKVAEM/EEIRfnMiqhRgKAciG7UKmgKUMI/ltT0HkkMbwjA7O+4yAeL8ZH800P4IMapbGRKBRCARSAQSgUQgEUgEEtcf4lRkka1IvLUAAAAASUVORK5CYII=);
  }
}

.ColorInput {
  display: inline-block;
  input {
    border: 1px solid $gray;
    width: 90px;
  }
  &__colorPicker {
    display: none;
  }
  img {
    vertical-align: middle;
    border: 1px solid $gray;
    padding: 3px;
    height: 11px;
    margin-left: 12px;
    margin-top: -1px;
    cursor: pointer;
  }
}

.EmptyTableText {
  padding: 20px;
}

.Button_FullScreen,
.Button_Window {
  width: 27px;
  height: 28px;
  background-position: center;
  background-repeat: no-repeat;
  display: inline-block;
  vertical-align: middle;
  margin-right: 3px;
}
.Button_FullScreen {
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAzCAYAAADRlospAAABV0lEQVR4Xu3ZwUnFQBSF4SS8AsQKAhbg0m06yXOfA68TF7cAn51YggW4sALBrZtxBgIHTMDkgpxcmAtDtv/HJExC2mmaUvMPY2Zts2EAXPNl9AZ0jWoIPefLiwAgRxAQGXFau3eVCAAFMPp3QD+XvD5DAgDc5MtrXrd+gD7+XvAQC+IJCBlPQKD4bz9AH/+V18PaOdEFiR/M7I2HnX4H+rL2xC9ObCVgDhpK4J74BYIALWJ7/BJBgBixLX6JIOAQCMYHOomJYLzrdVqPEJzE8okPaFNKdQd0UwEVUAGnaAAAqd5C6qkAAOeyQgLm8OeyiIgBYDxHjugAXDfGX37FixHcgfEvxIx8IkePICAPEc6fcET0KgAR/j+Ij2b2IQQQ4YwnXgYg4t0Trwdw7hzxxwEEiCdAEK8GMD4ggPFRAUP0T8oRwPyDwfEpKN8BIgI8AwEQBARG/ABlw7fol35UYQAAAABJRU5ErkJggg==');
  background-size: 24px 25.5px;
}
a:hover > .Button_FullScreen,
div:hover > .Button_FullScreen {
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADQAAAA2CAYAAACIsLrgAAABWklEQVR4Xu3awWnDQBAFUMm4gJAKBCkgxxw0B3UipwN3khIid5LDFJASUkHA11w2syD44AXhSQj5HmbhM9d9Gml3hdSP41i6Pxiq2tfqjYgsVuafTmDXkQ1VPVg5EYAIUADFQe037n0KlIhUwOzqEHmOls8QIBG5s/JmuXeA6DGPrkUhCAagIBiAbhjz5QDRY86WJ8uJuUNDzZWYSVXfsfkSguoErUyW8zWY5kTRgOhRLaZBAcSL2sa0KIBoUS1mCwUQKQoYx2mbHOXfWCOlL6UEYBB2KEEJSlCCEpSgBCUoQQna3zpARErechdX5FATArRCXmtYUTsRWVwYDKDIOjQ3qBZzvMAQogDqtlAr9gUGYhRAQLl/ggBqIAMB5cTUPKvqB9/GCpRYfXBgFvJl24+hBnkxAUDABAABEwAETBTQFOd9CEv6+vnv9+8x/98hoLifoSAogCKhvgFk/qpbuyFAKAAAAABJRU5ErkJggg==');
  background-size: 27px 28px;
}
.Button_Window {
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAABu0lEQVR4Xu2Y0U3DMBRFiZX/SkzACN2AsAFM0FTiNyKwAN0glfKNmk5AmYCwAQzADu0E4RohXclKoX2heXlSrvRkVf45J32240RN05wNPVmWXWOoUHlZlhgZZwA+xfCMmqBW/rcNAcKvggkvkVNAAUoMzxSYr/oX4BNc/AFfEX5vZl5Cq4UePeQv8LMDAaZKAnyCHeBfUEnMCTUJP+ao5RHwa2yn6VB2IQ/9KYGngH7OBfAUMJJ7wtsTmAN+2TbhjMBX+yZjwUnaHAKAXo06gjeoRQhvqYUi1AMe2NSeADNB1ZQ4jcBbW/UlIT+J2etJj/+EvQsN036hifsDkO9OBhbxKGA3/KxiNLHiF4ckWNy1DQHmNeyGcRFbXwPsTVHYy9qLOOUFW5RItYV+7p1roy1ECbTSFhN3/7Bd1iqLGBI5hjmqay7b6rQClKgo0UvGbTTs3xxDofX+L28hfikuLJ7E/MxtIM4qPAXk8E+DEBDA71BXWJy3Q9hinQA++b588Jy48VJqAoDfHAn/HmyTGy+lJeFQWxk846W0JBzfPmXwoYTKGqCEAD6QUBFolyC8hXMglPhAXRB+uPkCWg/HP4QGmKcAAAAASUVORK5CYII=');
  background-size: 24px 24px;
}
a:hover > .Button_Window,
div:hover > .Button_Window {
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAABmElEQVR4Xu2Y4WmEQBCF18P/V0QasAAhXgfpQK+CmDRwIQ0kHehVkKSCGJgCUoqpwMyPhQeLkF3UzAz4YBgGTngfzt6Mm03T5LSrLMs7Tj1HS0ScoYOgqSbhd28cR47O1/IA3sxTBGQ381wLAFldGKKPNg+9+OcAIKg6hPB1F/McAJRA+FxHwhc5ChUQJeebSOgPjkYTgEswfyWiBmfAlGAeAAbNWwR4gHkoT5icUTsHEWVbzAsiekVp7w088mwoLAMcOQZArAPwNRf/DZE+B9DrleCb8Gu1wTkQrtWSk/i0EGQUBSCiwS2WfAvtAPioN6m9hXaAHSBXcOtWhfPBBgD0GdSZsRbazwB6celuI32IG456yVQXbSH/xX812kKA4FYaubjf4O9y2PYQA6LldHbr63Yu1gUARA8Iu6tEZXOVSL+jd4I3du4QYV61DkbNA8CoeQAkmv/huKgCYPPvCeYrInrmfNbUQmOC+W/MCXfyULIA2IGizIfbZwUIwTMAiBjzkIcChIZtNNZ8CKHjZg6zoPDmR6dcvw1isupH8TzHAAAAAElFTkSuQmCC');
  background-size: 24px 24px;
}

.TailButton {
  position: relative;

  span.TailButtonText {
    display: inline-block;
  }

  .TailButtonIcon {
    position: relative;
    top: 3px;
    background-repeat: no-repeat;
    background-position: center;
    width: 15px;
    height: 18px;
    display: inline-block;

    &.TailButtonIcon_delete {
      background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAOCAYAAAAbvf3sAAAAaUlEQVR4Xu3SQQ2AMAwF0HZBAA4gAR9IQAcCMAF3sIEFfHAACVPw6aEkPS1fAO/y02VNunQKQKJ+3W+Lxst8zVMtgXbLdliOwjmTX2YNPhKv8rmpLnuPplh8WTpLQvsbeAogfLiix/bRvlwsIY+zKEqBAAAAAElFTkSuQmCC');
      background-size: 12px 14px;
    }

    &.TailButtonIcon_edit {
      background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAYAAABWdVznAAAAcUlEQVR4XpXKQQ2FMBCE4TJBAB4QAE6KAyrh6eDEiXNxAA6Q8OoAKUzIXjZN2HaSL3P5m37ZnLFIM50UUBa/8xRhxnoeZqztqIkpoCYmh+JYhuJYgH7UUfqMBWikVT7lsdZKeEhw01/HGmiQcKJLx7kHn40bLLePnXkAAAAASUVORK5CYII=');
      background-size: 12px 12px;
    }

    &.TailButtonIcon_view {
      background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA0AAAAOBAMAAAAGUYvhAAAAG1BMVEUAAAAjipYjipYjipYjipYjipYjipYjipYjipbtrjhFAAAACHRSTlMAryCg8IDQMLE9kxIAAABJSURBVAjXY+gAglYGBoYGII4ogNBhDUCaSaJDCESrdHS0KABpCQYGDgEgDdLQAKKB/AY4HyGvAuQLQPQLNkPMY7AwANvTwdwAAMMdGpXO2y5KAAAAAElFTkSuQmCC');
      background-size: 13px 14px;
    }

    &.TailButtonIcon_download {
      background-image: url('../resources/images/download-2x.png');
      background-size: 13px 14px;
    }

    &.TailButtonIcon_upload {
      background-image: url('../resources/images/download-2x.png');
      background-size: 13px 14px;
      transform: rotateZ(0.5turn);
    }

    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
      /* Retina-specific stuff here */
      &.TailButtonIcon_delete {
        background-size: 12px 14px;
        background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAcCAYAAAB75n/uAAAApElEQVR4Xu3VsQ0CMQyG0S8WfW6DK6CHERiBEULJAPTU0FBBeyMxAEOQCcKliIRA5IQUU/lJKdL4lxVHdiklahan6wYYAM+n7X2/G6gQJlSKZ2cmuPnx0gE3oKetCKwECArFMw8cSoCWtQBL9PRlitQIymYv855oaPwf7i8dyLf0fH69q7+BBViABViABWjJO7lstAfgaSOO+6F77yAAkTZCuTwBFtMtVTLhZMMAAAAASUVORK5CYII=');
      }

      &.TailButtonIcon_edit {
        background-size: 12px 12.5px;
        background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAZCAYAAAArK+5dAAAA2ElEQVR4XrXPsa3CQBCE4WNFbtMB0ivAdAAd4A6gBHeASV8ECSlQgXEHdgfQybkDRrBogsu83pM+aaN/dLO//0uY4N1gBwOcVIQgE8YDZHCADnIOWOPpK34jMmk8HanEJU6VuMQpE5c43cUzDnvxjEMQzzgHPOJKfOIkPnESnziJT5zEJ54O1BpvYbDH04ENnKGEJfS2eDqwhofeUQdbW5wDK71z4PuGhvFxDmz0bhj4iNCNi9OcP/i4QlQlbIFx0wA1evRwhBqCZaCAFzxVpzefcWABEVzeG7l+Ppy8sRb/AAAAAElFTkSuQmCC');
      }

      &.TailButtonIcon_view {
        background-size: 13px 14px;
        background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAcBAMAAACNPbLgAAAAJ1BMVEUAAAAjipYjipYjipYjipYjipYjipYjipYjipYjipYjipYjipYjipb+0dcdAAAADHRSTlMAoNDwcBBQYL+AMECUh672AAAAi0lEQVQY02M4AwMTGIAAzjsF5jFAAMchZB4PUCmCt+fMGSQeVyGMF6Jz5pArA5THZgM08nAClFcDtuE4xEw2qIUJYF4wxKAzpmCeD5R3BMzTgao8BOIhXArhQVRi58EApikIGzBsR7isAdnVZw4i+6jnjACSbxmBkkAeDMgAJRE8kOQZOABKIvGAkgBato/VeSWPjgAAAABJRU5ErkJggg==');
      }

      &.TailButtonIcon_download {
        background-size: 13px 14px;
        background-image: url('../resources/images/download-2x.png');
      }

      &.TailButtonIcon_upload {
        background-size: 13px 14px;
        background-image: url('../resources/images/download-2x.png');
        transform: rotateZ(0.5turn);
      }
    }
  }

  &:hover {
    .TailButtonIcon {
      &.TailButtonIcon_edit {
        background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAASCAYAAAC9+TVUAAAAs0lEQVR4XqXRsQ3CMBAFUHJKT0ZAgh5vQEaACUjLACwBDRW0sAEjwAZOT8MGyQTmW/rSpTxzX/q6xn462VVKaeZNXQKszrcG44Wu0Te6/RwPAzcpBDQjGsQB5MzRu/wNaDbiAZhevADaihfIvyNeIGviBYh4ACJOQBECTTlABFuErODAAmOHjnZAN2nRSOiJ0dkBRQK6x0bdBPqaAKZanq5x8qAPoggBQ2oCPRrZCy5j2vMD5VJiySxeu1EAAAAASUVORK5CYII=');
        background-size: 17px 18px;
      }

      &.TailButtonIcon_delete {
        background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAPAgMAAACpehbnAAAADFBMVEUAAAAjipYjipYjipbAOIw+AAAAA3RSTlMAwLD67aAcAAAAJ0lEQVQI12NgsD/AwFD///8Dhv///39gAAP7//8PMBiftyGaAOsAAKt0IDJJtWVcAAAAAElFTkSuQmCC');
        background-size: 14px 15px;
      }

      &.TailButtonIcon_view {
        background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAASCAYAAAC9+TVUAAABGUlEQVR4Xo2UMWrDMBSGlWK6lSQ3SEjJah/BPUF7g05J2q1L6ZZ0ThbTIaVQqLNk9g3sGyRZQ7q3U23oAfIZXsAokmzBxxMW+vQ/CdwazJeJUuoW6kYBT4fnx1hf8ETQZTG3Ca4X7x3KH3wxzxElVckFKJfAsB4jCs4kDSmktiHW2jHGDykR+BXJN9xBDhunBMErZWZwDyCFFRSWdiSBCBzcw5srSVS5TFObp+kLTG0SX9tg4xJhyEGZSQL1SYQQ3BLZ4CK3tbOHYcMkmU0ygbRBkl8O2RqfWC7qA+rGD6k6RomIHigj+DcIdoIPmYhEci76hCumfbgR+nwLqKEuKv8nW+226+jBHMYi2nliDqDxIFGZYC0v5B8BBgxjLbbTYDwAAAAASUVORK5CYII=');
        background-size: 17px 18px;
      }

      &.TailButtonIcon_download {
        background-image: url('../resources/images/download-2x.png');
        background-size: 17px 18px;
      }

      &.TailButtonIcon_upload {
        background-size: 17px 18px;
        background-image: url('../resources/images/download-2x.png');
        transform: rotateZ(0.5turn);
      }
    }

    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
      /* Retina-specific stuff here */
      .TailButtonIcon {
        &.TailButtonIcon_edit {
          background-size: 17px 17px;
          background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACIAAAAiCAYAAAA6RwvCAAABN0lEQVR4Xs3XMU7DQBSEYRjROzcAJPr4BuxR0lLhhhqoaaCiRNzAN8C5gdNT7BHwCcys9NBE3lDuPv/SyI1lf9pEkXI+z/NZyW5e3je8DNyWm7jXtO+Hu5/j+1ARkWq4Ry4SGDJIBYQS6IuYnSA1EXkffycDN4TqExyuCH1MHVwRqoMrQjVwRagD1oDgAtaASL+yWAMiAeCOsOCLUPBFKPgiFHwRCr4IBV+Egi9CwReh4ItQcEcIYgnxzH3WQSjYaVwZ4o0PeuJ2BFxz+woIQVjQqTBGTOSCTqcEIoe0dh2XUjudfTlEDpm4/x6YMFMBRAa55Rpu5PelPXEqkZe+CMLC4sWX3GCYZbEIwrrgwok/PL1hNlxr99yXQQjSckonEw1lZYjCEDUebbDviSoA2XKHxUt5rdsvW3zMqqMyo1wAAAAASUVORK5CYII=');
        }

        &.TailButtonIcon_delete {
          background-size: 14px 15px;
          background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAeBAMAAADN62OsAAAAFVBMVEUAAAAjipYjipYjipYjipYjipYjipZ729FSAAAABnRSTlMA0BDgoIBdF8A+AAAAMElEQVQY02MAgzQgADPQuYppSECIwQ2ZmwpShwDJqNw0BgIARS0mFygARaNcvGEFAI81ZUSdhkFzAAAAAElFTkSuQmCC');
        }

        &.TailButtonIcon_view {
          background-size: 17px 18px;
          background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACIAAAAkCAYAAADsHujfAAACJUlEQVR4XsWXMUsjQRiGv8RUImyE67Pigddl7xe4xRUHFsZaMLbX5YqrXSy9wthoJ/EXGBs7YfILsldcIXhc8g8SbETE3LvyFku4lf12dpIXXmbCt0Oe+Xa+mZ3K5sl5XUT68LaUpyu48/Dj20RyKgExhJjCsdgpgD32f8EhYXKBzNhf56DC+vjzwkfzFxYtTJXtgBBWwkRGc4EmbCSHquJeTWSqt3QQqk2YhYJM34EJFwnSkWwdZgVqBSoj4J+FcGMuPObi/IxKiVNjIjRHsG+XEZYmbNAdwm1CzKvB2BDABvZZTZF11RCihSbW7L589jfH2pcvIa5hT/Raha8Bs4/2rDAIU9sr6ey5hLuFQAjhib1W4Duslb66algd2//ZxjUVlg58wO/vgDnVZqQj5eu4yD4SZs3SQmtJprnH5M5IQ9yolTsjoK4LVeIaoeQLHCkysljVMmY9wWwUs1TpRVs1Y3GjibZqDNx2sEaMNiNdcSOjykhS65jRgLtrWWtkwD1EfdYcwo9SnqJChx6vBwclfsvGehCKJ+Ye/Cx28mDDw1QNkobZgu8tv+qbWTBVzS0O/oTuDvwHfs1xEd+AQ8K8m5lagWvlLZrEwnvKV4ae2MawSV9h+ZwhRBomTKoofQkfY6AvDsUMpGGEmXqDSUBGPPJvOBsXCrhBTrJgaui0GNylXcnHzIOM19SvzGYzQbBOIFevJ2blZb6mN5BliDB94bL4BxWh1IG6YdYkAAAAAElFTkSuQmCC');
        }
      }
    }
  }
}

// new staff
/*
This .scss loop will create "margin helpers" and "padding helpers" for use in your web projects.
It will generate several classes such as:
.mr-10 which gives margin-right 10 pixels.
.mr-15 gives MARGIN to the RIGHT 15 pixels.
.mt-15 gives MARGIN to the TOP 15 pixels and so on.
.pb-5 gives PADDING to the BOTTOM of 5 pixels
.pl-40 gives PADDING to the LEFT of 40 pixels
The first letter is "m" or "p" for MARGIN or PADDING
Second letter is "t", "b", "l", or "r" for TOP, BOTTOM, LEFT, or RIGHT
Third letter is the number of spacing in pixels. Adjust the amounts generated by editing the $spaceamounts variable below.
*/

$spaceamounts: (5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 75, 100); // Adjust this to include the pixel amounts you need.
$sides: (top, bottom, left, right); // Leave this variable alone

@each $space in $spaceamounts {
  @each $side in $sides {
    .m#{str-slice($side, 0, 1)}-#{$space} {
      margin-#{$side}: #{$space}px !important;
    }

    .p#{str-slice($side, 0, 1)}-#{$space} {
      padding-#{$side}: #{$space}px !important;
    }
  }
}
// new staff

.self-center {
  align-self: center;
}
