@import '../../styles/utils/utils';
@import '../../styles/utils/variables';

.ObjectProperties {
  width: 100%;

  &__button {
    margin-bottom: 10px;

    img {
      margin-right: 4px;
      height: 18px;
      vertical-align: sub;
    }
  }

  .Table__floatingButtons {
    display: none;
  }

  .TableRefExpander {
    &_active {
      width: 30px;
      height: 30px;
    }
  }

  tbody {
    // commented to make tooltips for object property names visible
    //overflow-y: hidden;
  }

  .KascodeSelect__checkbox {
    display: none;
  }

  .KascodeSelect {
    height: auto !important;
  }
  .KascodeSelect__list_opened {
    top: 0 !important;
    max-height: none !important;
  }
  .DataDialog__propertyNameText {
    display: inline-block;
  }
  .SimpleTooltip__root {
    float: right;
    opacity: 0;
  }
  .SimpleTable__row:hover .SimpleTooltip__root {
    display: inline-block;
    opacity: 1;
  }
  .SimpleTooltip__root:hover div {
    display: inline-block;
  }
  .SimpleTooltip__tooltip {
    width: 300px;
  }

  &__title {
    background-color: $blue-light;
    width: 100%;
    padding: 4px 0;
    text-align: center;
    cursor: pointer;
    color: $blue-dark;

    &:hover {
      font-weight: bold;
      background-color: #a8e3ea;
      .ObjectProperties__titleImage {
        background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAoCAYAAACFFRgXAAAB/UlEQVR4Xs2YMU7DMBSGXct7cwOQYG9vUN+A3qDpyNQwMFNWGAgLjCQ3CDcIN0h3hnCD+gTlNXpSKpTEfUnsxy/9stSoyifL/3vPmRwOB8Gh6+f3AJYIHIIvEMCA46O/72/3TQCSCVbDUoAfKthaU/xNtwEoBtgtQrVpDbubtQFIz7BxB6xB2KQLQHqEXcKy6YDVNSwzMAYsscAWNQD/DocYqCYVFlgm4HYFuP4r4BnhGR8whm0ueoizDgcjlsTAR+iKM2Auu56BCyyJK+ezBAKd85IdOAdnUDVyhA1hiU8rDAI7r783giYDLpsCqRzD5vhSqqYt/0ulw8pQkmHtRyaSDmBD3NnpiLAGHB5nZOVydKTLPmuoEcMVg1cjw+5wZwuBkmOFiwhrhF2vTVOcGhwu+nndgZfgPQ5F+k83zLAWlyO25rqoE2FTcHRywYwrE6QG9PWNoOkRQLdioJSHzmVwV5MhoOTQ4YCSE2F/LHc1N8AYrgI8I4Zrjin3Nw9juD4ETSmAhsKBlINw3QFsLBxJdYQrAy969PtMOJTsaAYL4nnVNawHYITVPWbYr7qFupfiDRddCmET+qSFH+48a3L19BbB+kIM1xIvit4lwVt6MwBYJknCtPWJ4SoFo1TfSYtzh1PLeV1bYFnOsCFMWqySeCZ1BVgrtUxabPoFsWHUGjl7OOUAAAAASUVORK5CYII=');
        background-size: 22px 20px;
        width: 22px;
        height: 20px;
        margin-top: -5px;
        margin-right: 4px;
      }
    }

    .ObjectProperties__titleImage {
      display: inline-block;
      width: 20px;
      height: 18px;
      vertical-align: middle;
      margin-right: 6px;
      margin-top: -4px;
      background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAkCAYAAAD7PHgWAAABv0lEQVR4XsWYPU7DQBCFyWp7uAGWoMc3iG+Ac4JsSipMQQ01NE4DZZwTJJyA5AZJT4FvgE8QHtJKzxKOYbQ/XunTFNN8GmbfOowOh8NJrHP5/HqGUgADzm2jBuXH/U3ZJaAiymUoO/BAOQBRYKz8r6MjyRmUxZH2HmSY4JdAMJrcEmKmT0AFlktQSoFc9B18BKcdjYZyQwjyxk67u5QecoJpf3t4wUyu4x4zMUO9RElDTvDzD4H82M0HOzRuwXjICa4g8lPfwMaSgKp9iXSUcO7n2tJ1ahVidyjnTKE9517FaTjAl2atPMmlKBvPcoY56CaXWbkrT3Lz9jOoHOUKlHfB09X09GowgVzhFtSUq1CmAjFjJ52DDCStvNxArHJ8SXgZhH/SGuQQ2NlGZfH8FvMyfArktiClnBwtDN9SsG/z9j6FE2T43goGMONOuaE9h28DMu6bO8pj+O5BQjk/KE/hu+RPR79Hu3yJWO74XwH/qI7wXQj2bUK5MGhx+HLfDPctHKOLpxep3Bbk3LewaLCmXIzwle/g+J/7NqNcPJQgfCs24grWccNXvoMFWPV9dg+JgsQadQaa9pct5YY9335JsdsBVnzqAAAAAElFTkSuQmCC');
      background-size: 20px 18px;
    }
  }

  &.EditorDialog__buttonViewOnly {
    .ObjectProperties__title {
      background-color: #d9cded;
      &:hover {
        background-color: #a270cb99;
      }
    }
  }
}

.ObjectProperty {
  //padding-bottom: 2px;
  padding: 3px;
  height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  border: 1px solid $blue-light;
  border-top: none;

  &__name {
    float: left;
    width: 35%;
    height: 18px;
    font-weight: bold;
    font-size: 16px;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  &__value {
    position: relative;
    top: 1px;
    float: left;
    height: 18px;
    width: 65%;
  }
}
