@import '../../styles/utils/variables';

.EditorDialog_doc {
  .ModalWindow__main {
    width: 800px;
    height: 620px !important;
  }

  .ModalWindow__body {
    padding: 1px 18px;
    width: 800px;
    height: 550px;
    min-height: 150px;
    overflow-y: visible; // added because gray scrollbar was appearing inside Add Issue window, when description was filled with long text;
    // as a result, Discard button was shifted to bottom
  }

  .ModalWindow__footer {
    padding-top: 5px;
    width: 800px;
  }
}

.DocDialog {
  .DataEditor__block {
    &_name,
    &_description,
    &_type,
    &_uri,
    &_content {
      margin-top: 3px;
      margin-bottom: 3px;

      &__value_right {
        width: calc(80% - 10px);
        float: left;
        line-height: 26px;
      }
    }

    &_order {
      margin-top: 0px;
      margin-bottom: 1px;

      &__value_right {
        width: calc(10% + 10px);
        float: left;
        line-height: 26px;
      }
    }

    &__label {
      display: block;
      float: left;
      padding-right: 10px;
      line-height: 26px;
      width: calc(15% + 30px);
      font-weight: bold;
      font-size: 16px;
    }
  }

  .ContentLoader {
    &__previewFrame {
      width: 600px;
      height: 500px;
    }
  }
}
