.InterfaceExampleControl {
  &__checkbox {
    display: inline-block;
    margin-left: 15px;
  }

  &__status {
    display: inline-block;
    margin-left: 10px;
    cursor: pointer;
  }

  &__compare {
    box-sizing: border-box;
    position: absolute;
    top: 100%;
    left: 0;
    width: 1182px;
    z-index: 1000;
    background-color: #fff;
    border-bottom: 1px solid;
  }

  &__header {
    flex: 1;
    width: 100%;
    height: 30px;
  }

  &__close img {
    position: absolute;
    top: 2px;
    right: 8px;
    width: 20px;
    height: 20px;
    cursor: pointer;
  }

  &__body {
    max-width: 1184px;
    max-height: 400px;
    overflow: auto;
  }

  .scrollarea {
    width: 100%;
  }
}
