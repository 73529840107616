.EditorDialog_importParams {
  .ModalWindow__main {
    width: 800px;
  }

  .ModalWindow__body {
    padding: 1px 18px;
    width: 800px !important;
    height: 350px;
    min-height: 150px !important;
    overflow-y: visible; // added because gray scrollbar was appearing inside Add Issue window, when description was filled with long text;
    // as a result, Discard button was shifted to bottom
  }

  .ModalWindow__footer {
    padding-top: 5px;
    width: 800px;
  }
}

.ImportSchemaDialog {
  .DataEditor__block {
    &_datasetName,
    &_datasetDescription,
    &_schemaType {
      margin-top: 5px;
      margin-bottom: 5px;

      &__value_right {
        width: calc(80% - 10px);
        float: left;
        line-height: 26px;
      }
    }

    &__label {
      display: block;
      float: left;
      padding-right: 10px;
      line-height: 26px;
      width: calc(15% + 20px);
      font-weight: bold;
      font-size: 16px;
    }
  }

  &__label {
    display: block;
    float: left;
    padding-right: 10px;
    line-height: 26px;
    width: calc(15% + 20px);
    font-weight: bold;
    font-size: 16px;
  }

  &__block_file {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .value_right {
    width: calc(80%);
    float: left;
    line-height: 26px;

    input[type='text'],
    textarea {
      width: 100%;
    }
  }

  &__fileInputLabel {
    text-align: center;
  }

  #ImportSchemaDialog_fileInput {
    display: none;
  }

  &__selectedFileNameLabel {
    display: inline-block;
    padding-left: 8px;
  }
}
