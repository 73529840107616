@import '../../styles/utils/utils';
@import '../../styles/utils/variables';

.EditorVersion {
  box-sizing: border-box;
  margin-top: 0px;
  padding: 0;
  font-size: 16px;

  &__selectVersion {
    & > div {
      @extend .clearfix;
      width: 100%;
    }

    .EditButton {
      float: left;
      height: 20px;
      overflow: hidden;
      font-size: 16px;

      &__btn {
        padding-right: 0;
        font-size: 16px;
      }
    }

    .EditorButton__edit {
      background-color: transparent;
      color: $blue-dark;

      &:hover {
        color: $blue-dark;
      }
    }

    &__grayButton {
      float: left;
      height: 20px;
      overflow: hidden;
      font-size: 16px;
      font-weight: 600;
      color: darkgray;
    }

    .SelectVersion__content {
      strong {
        display: inline-block;
        width: 60px;
      }

      .LocaleSelect {
        width: 160px;
        padding-top: 1px;
        height: 20px;

        &__input_short {
          padding-top: 1px;
          padding-right: 0;
          height: 22px;
        }

        &__value {
          width: 130px;
        }

        &__toggle {
          top: 2px;
          right: 8px;

          &_rotated {
            top: 8px;
            right: 6px;
          }
        }
      }
    }
  }

  &__row {
    position: relative;
  }
}

.select_transparent.material.root-node {
  display: inline-block;
  width: 100px;
  max-height: 20px;
  line-height: 20px;

  .react-selectize-control {
    height: 24px;
    padding: 0;
    border-bottom: 0;

    &:after {
      background-color: $blue;
    }
  }

  .react-selectize-control .react-selectize-placeholder {
    height: 20px;
    line-height: 20px;
  }

  .react-selectize-control .react-selectize-search-field-and-selected-values {
    min-height: 20px;
    line-height: 20px;
  }
}
