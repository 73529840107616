@import '../../styles/utils/variables';

.Table_dataset {
  .TableCell_name .MajorObjectBlock {
    color: $blue-dark;
  }
}

.plus{
  position: absolute;
  z-index: 999;
  color: white;

  left: -0.1px;
cursor: pointer;
    &:before{left: 1px;
      width: 19px;
      height: 19px;
      top: 50%;
      margin-top: -11px;
      background-color: #218A96;
      content: "";
      display: inline-block;
      position: absolute;
      border-radius: 100%;
      z-index: -1;}
}

.EditableEntity_key {
  text-align: center !important;
}

.SimpleTable__cell_Field {
  overflow-x: hidden;
  &:hover {
    overflow-x: visible;
    .MajorObjectBlock__label {
      //background: white;
    }
  }
}

.ExampleDialog {
  &__label {
    display: block;
    float: left;
    padding-right: 10px;
    line-height: 26px;
    width: 15%;
    font-weight: bold;
    font-size: 16px;
  }

  &__button {
    margin-left: 0 !important;
  }
}

.DatasetHistoryTable {
  .Loader {
    margin: 20px auto;
    width: 150px;
  }

  &__body {
  }

  &__scrollAreaOuter {
    padding-right: 24px;
  }
  &__scrollAreaInner {
    position: relative;
  }

  &__cardBackground {
    position: absolute;
    height: 100%;
    width: 33px;
    background-color: $light;
    z-index: 0;
  }

  &__cardLine {
    position: absolute;
    height: calc(100% - 25px);
    margin-top: 10px;
    width: 1px;
    z-index: 5;
    background-color: $gray;
    margin-left: 10px;
  }

  &__body > .col-xs-4,
  .DatasetHistoryTable__body > .col-xs-8 {
    padding: 0;
  }

  pre {
    margin: 0;
  }

  &__header {
    background-color: $blue-deep;

    color: $light;
    div {
      padding: 4px 0;
      text-align: center;
    }
  }

  &__card {
    border-bottom: 1px solid $gray;

    .col {
      padding: 10px 4px;
      &.DatasetHistoryTable__time {
        padding: 10px 0;
      }
    }

    &__active .col {
      background-color: $gray-light2;
    }
    &__active .col.DatasetHistoryTable__icon {
      background-color: $light;
    }

    &Version {
    }
  }

  &__message {
    display: block;
    background-color: $blue-light;
    border: 1px solid $blue;
    font-size: 16px;
    color: $gray-dark;
    padding: 4px;
    //margin-left: -10px;
    height: 24px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 366px;
    z-index: 50;
    margin-bottom: 4px;

    a {
      color: $blue-dark;
      letter-spacing: 0.05px;
      &:hover {
        font-weight: bold;
        letter-spacing: 0;
      }
    }
  }

  &__icon {
    position: relative;
    &:after {
      content: '';
      display: inline-block;
      position: absolute;
      width: 11px;
      height: 11px;
      top: 50%;
      margin-top: -5px;
      left: 5px;

      background-color: $gray-dark;
      border-radius: 100%;
      z-index: 10;
    }
  

    
  }
  &__name {
    color: $blue-dark;
  }
  &__time {
  }
  &__jsonRow {
    div {
      padding: 4px 8px;
    }
    .DatasetHistoryTable__rowNumber {
      text-align: center;
    }
    &Red {
      background-color: $red-light;
      .DatasetHistoryTable__rowNumber {
        background-color: $red-middle;
      }
    }
    &Blue {
      background-color: $blue-lighter;
      .DatasetHistoryTable__rowNumber {
        background-color: $blue-middle;
      }
    }
  }

  .react-contextmenu {
    z-index: 5500;
    min-width: 160px;
    padding: 5px 0;
    margin: 2px 0 0;
    font-size: 16px;
    color: $blue-sea-dark;
    text-align: left;
    background-color: $gray-light;
    background-clip: padding-box;
    border: 1px solid $gray-dark;
    border-radius: 0;
    outline: none;
    opacity: 0;
    pointer-events: none;
  }

  .react-contextmenu.react-contextmenu--visible {
    opacity: 1;
    pointer-events: auto;
  }

  .react-contextmenu-item {
    padding: 3px 20px;
    font-weight: 400;
    line-height: 1.5;
    color: $blue-sea-dark;
    text-align: inherit;
    white-space: nowrap;
    background: 0 0;
    border: 0;
    cursor: pointer;
  }

  .react-contextmenu-item.react-contextmenu-item--active,
  .react-contextmenu-item:hover {
    //color: #fff;
    background-color: #e0f5f8;
    //border-color: #20a0ff;
    text-decoration: none;
  }

  .react-contextmenu-item.react-contextmenu-item--disabled,
  .react-contextmenu-item.react-contextmenu-item--disabled:hover {
    color: #878a8c;
    background-color: transparent;
    border-color: rgba(0, 0, 0, 0.15);
  }

  .react-contextmenu-item--divider {
    margin-bottom: 3px;
    padding: 2px 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    cursor: inherit;
  }
  .react-contextmenu-item--divider:hover {
    background-color: transparent;
    border-color: rgba(0, 0, 0, 0.15);
  }

  .react-contextmenu-item.react-contextmenu-submenu {
    padding: 0;
  }

  .react-contextmenu-item.react-contextmenu-submenu > .react-contextmenu-item {
  }

  .react-contextmenu-item.react-contextmenu-submenu
    > .react-contextmenu-item:after {
    content: '▶';
    display: inline-block;
    position: absolute;
    right: 7px;
  }
}

.JsonObjectEditor {
  &__controlButton {
    cursor: pointer;
    background-color: $gray;
    color: white;
    padding: 1px 4px;
    border-radius: 5px;
    display: inline-block;
    margin: 3px 14px 3px 0;
    font-weight: bold;
    height: 20px;
    line-height: 20px;
    min-width: 82px;
    text-align: center;
    &__active {
      background-color: $blue;
      &:hover {
        background-color: #3dc2d1;
      }
    }
  }

  &__label {
    display: block;
    float: left;
    padding-right: 10px;
    line-height: 26px;
    width: 15%;
    font-weight: bold;
    font-size: 16px;
  }

  &__name {
    display: block;
    float: left;
    padding-right: 10px;
    line-height: 26px;
    width: 75%;
    font-size: 16px;
  }

  &__type {
    display: block;
    float: left;
    padding-right: 10px;
    line-height: 26px;
    font-size: 16px;
  }

  &__error {
    color: $red;
  }

  &__block {
    margin-bottom: 5px;
  }

  &__block_text {
    width: 100%;
    padding-right: 3px;
    box-sizing: border-box;
  }

  .EditButton {
    float: right;
  }

  .EditButton__edit {
    margin-right: 5px;

    &:hover {
      font-weight: bold;
    }
  }
}
