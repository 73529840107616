@import '../../styles/utils/utils';
@import '../../styles/utils/variables';

.ObjectAdvancedSettings {
  display: inline-block;
  float: left;
  position: relative;

  &__button {
    background-color: $blue-light;
    width: 100%;
    padding: 4px 0;
    text-align: center;
    cursor: pointer;

    &:hover {
      font-weight: bold;
      background-color: #A8E3EA;
      .ObjectAdvancedSettings__buttonImg {
        background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAC4AAAAoCAYAAACB4MgqAAADFUlEQVR4XsWZMW7bQBBFaUJ9cgMpSHoxJwhvIOUEospUYQr3UpsUppukNHUD+QSmThCrDxD6BtIJlBngL0Bwxf3YXVkZYEC42OXDn9n5S+vmdDolofHhx69cHoXkXPLNwIK9ZPnn9kvjsa/ul8ma1RDAKAJYN/3kANhIVgL87AlcSY7xt64/cHAO/FYeteRsAOCIF9cC3HoAT3RNT4i1gebgXI3a0RKPaInWU4xMHk1v3xcIMBijWJURSwGuA1pu0odGrFRth4BFytVI2mBoHsUZ6J3u5xCxUp6RA1o3fSAAewLNIk/scE2flTm4qaOEAk2jTS4fc8ck+2oAUqIEixzlu2RMBXKr+9rnjB/OUpIG+nOLkocE4KyYSbYCrLAHmNy4C2Cc0/T0BKWaegLcS6+XAUYWatvHm/fff2oZGgrL4x1Mx8d9nwLBH1McsKnDCb9JLiV3ZCQyaHtChEc1Qg9Zbgj3yjpGUItK2g53PYuf4wJlDOWga4jasPcwtfV9KXpaAKxo+gACVEF9A50D2pyRv7pOjYJALwKhdzCtJMXtLT8Df/ZWB8P5LDnRtYCpjFmh7baB0HvJNfJeQZEbfaew5hATU8W+7GwEqiACMBjswZ0YbVmgeiR6BgT1FP6jB3TmUHCBSnQreBxQOfOB7ijOgyvOL2Go6u8eNMrvF2kSGajOxgH+gKuoqerStBKB9lA8Tvln4gd55zDnpDX8FY+8ou4dd5oGcz4h0NcF15ID/sUB37n1XRWcww8ZGmJKDOq64ApiYIyhEfjqv4PDuBS26cG7vGAB47o6eN9tx1CyC6+2vyTwxTXBrSvCUBvAeNZkxheh4PHQtpJlB35FDKrCfpHgHDp3QBsbr6m72jM+ezVwlPWJQA/ZeEkMisx4Ah7xD6KdgSYGNQQ/9pnx6YWgN+aSTw2Kz/jaAzwamkwGL/gZmfEcHBtwaL/g7orJRMH5BwKHDoR3wd3hHk/AbXOJh+bwNXHX0lfxA8r4atDMXfH+ygtcwNqBHlxeEtpyV/uLacvB+QHivzzEf7uu4bAT9mvdP2nxtweDuYxEAAAAAElFTkSuQmCC');
        background-size: 23px 20px;
        width: 23px;
        height: 20px;
        margin-top: -5px;
        margin-right: 4px;
      }
    }
  
    .ObjectAdvancedSettings__buttonImg {
      display: inline-block;
      width: 21px;
      height: 18px;
      vertical-align: middle;
      margin-right: 6px;
      margin-top: -4px;
      background-image:url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACoAAAAkCAYAAAD/yagrAAACt0lEQVR4XrWYMbLaMBCGHQ89vsFzkd6+QbgBzglsylTPr0gNfYqYKuXDJ3i+QfxuAH0K3g3MCcgq8xca4V9GG7wzOxoYI31aafdf/Ol6vUah9vnHr0SGWrwSfyKPteLNn+/fjnfMl8pQiCfy/G4MYKEBhC9HAC4GTvwggOeA+bbW5iI1KCALA+ABrAXuoJ4Pc+hAsWsArgnAXnwnkEMAZCXDqw3C5gBDvpiAXMnQkSga2/Ao8vs4AvkhkA1h2MmwXXggzQ+fPQAtIENtNcKzY5HE/Y1iDSRMIFWW8u/4GjHd9bRV0eNsi3vrHnlGkwn3MrsDoJRnOzn+7kGwr4DtEeHyJutxwSvxhBw5s4PZGCvqitP6AnftsnAyO9SWWPgYIBi5Yp3eRPQ3gfiAagwGhqhRS8oKs0YZkGYB2SpHQAvrSHtzH3F/lm4NhcqkPmgkS6mA3AtHHwuMmeDdpQSk+3mFSH+1IA3cm/hPgam9ShRgWOdFOGo76wtEK7NkMSLwqSWtjR0lwA7YxD2QLaTzbCW18bPb1Jg2z+1kjm7J8UkbATAR7wBqoEuV/LqgGoO89Z5u6l/ZAuzROq0L7n8fsl4cKQ0QBStbSMDcUrqTtQFAzg0Kw4IbBgtBSARsACwRhxmOXpHVJwCSflURUa0hKfYEICOd1sygHLb2/OdZI/vnBuWZL96JJ4CtAMu6rWZ+UF6e1hgjC/ZEAJ4hADOD8hqaOUeLcsT7zplB0YAAkh0tMtwH22Cz84AiEm9UjZDdFmyF76kgaED1tRKKQzsuDotEVIBqCroLSWDH7AmRTf4bFEkSAElhNx5B6NWgpEXTSiPUi8NOCUI8GySHfaGCwGH5mxI15DRsM6FeeUhEU8/7phyQSuNSi+owhIBCBm8giaKoYd9HTup8FyhRFkA+3ApEtp2qHn8BkBaA84/NWx4AAAAASUVORK5CYII=');
      background-size: 21px 18px;
    }

  }
  &__addDeleteOuter {
    height: 72px;
  }
  &__addDelete {
    text-align: left;
    padding-left: 18px;
    &__hidden {
      height: 20px;
    }
  }

  .ModalWindow__body {
    .Table__filters {
      height: 0;

    }
  }

  &__title {
    display: inline-block;
    margin-right: 30px;
  }

  .ModalWindow__title {
    .LocaleSelect {
      font-size: 16px;
    }
    .LocaleSelect__value {
      height: 25px;
    }
  }
}

.ObjectAdvancedSetting {
  margin-bottom: 2px;
  overflow: hidden;
  text-align: left;

  &__name {
    float: left;
    width: 50%;
    font-weight: bold;
    font-size: 16px;
  }
  &__value {
    position: relative;
    top: 1px;
    float: left;
    width: 50%;
  }
}

