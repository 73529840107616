@import '../../styles/utils/variables';

.Messages {
  &__newIssueButton {
    position: relative;
    float: right;
    margin-right: 10px;
    top: 28px;
    color: $blue-dark;
    cursor: pointer;
  }

  &__rating {
    .ObjectRating__outer {
      position: relative;
      text-align: left;
      padding-left: 30px;
      div {
        position: absolute;
        display: inline-block;
        top: 10px;
        left: 70px;
      }
    }
  }

  &__buttons {
    display: inline;
    button {
      margin-bottom: 6px;
    }
  }
  &__row {
    position: relative;
    padding: 10px;
  }
  &__userImage {
    max-height: 40px;
  }
  &__leftCol {
    .col {
      margin-right: 15px;
    }
  }

  &__root {
    //padding-top: 110px;
  }
  &__fixed {
    z-index: 150;
    &__root {
      //padding-top: 0;
    }
    margin-top: 58px;
    .tabs-navigation {
      position: fixed;
      bottom: 0;
    }
    .tabs-menu {
      //background-color: white;
      //margin-left: -600px !important;
      //left: 50%;
      //background-color: red;
    }
    .tab-panel {
    }
  }
  &__showMore {
    background-color: transparent;
    border: 2px solid $blue;
    width: 100%;
    padding: 3px;
    color: $blue;
  }
  &__link {
    color: $blue-dark;
  }

  &__scrollArrows {
    position: absolute;
    width: 31px;
    height: 60px;
    z-index: 90;
    margin-left: -34px;
    margin-top: 30px;
    //border: 1px solid red;
  }
  &__arrowFloat {
    width: 21px;
    height: 12px;
    background-repeat: no-repeat;
    background-size: cover;
  }
  &__arrowFixed {
    position: fixed;
    width: 21px;
    height: 12px;
    background-size: cover;
    background-repeat: no-repeat;
    bottom: 8px;
    z-index: 100;
  }
  &__arrowPictureUp {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACoAAAAYCAYAAACMcW/9AAADhklEQVR4Xr3W20+TZwDH8bcnrCCrzk3FQYonXHQ4GIYEoluWeKocHeIhJm7xZsniLpbd7m5/ghdm/8EyFLcGDRMSxIzDBnMDFodRQWTgFtBM5wrS0vq9+D3JkzchobGlySdv8gbCt78+b4Pn/PA9v+M4uciBIwnELpRuW3BW8PX5yP0Aly3Y7GqZ8CqyHMdxBqdRh138ouKzT39rJz7CZ/gSX+AUwl4t+TYa8AnO4WM0o4zY4ApFlqihFtWoxLsogt+rgBxsQBjb9QMRvbvdis3mx71DkUc12nrkIxc+OCY0iRT8WIUQtuIQjqM8G7FE+nQmaxFR5GsIqC2OOROawL+Y1c2U7udhG44oNqPHgEi/lmxEjSLXqCmJGCbwG6a9ujGEXozhOZKKXY0tOGAtuyZDkWHUmgdXS/o11P9q6cRVjHv1FTSKVt28jWdImGUVexAnUUFs7itEergUK/IIdiLfLKnIB7iOKO7QGCfEcYhNKLYFUSs2CY9r2Sbs1bJpL6nIGjSgFCH4tGQM42jHD/iDtnn7YTKxYwptw594ikUrNqzYU6gkNj+NSC+XQhzWmrux1oqcw0N04jKGTKQdamLjXO4iat6RKzaIInyIZsXmLfPpDiOCRrzjipzXkh3uJQ1PKpVa6rutGHWoxy6E4Lfe/RS6cQkDeMoCqSUiC1GjyD1YBz+SWnJSkd9imMiY3eNe1L3smFb9HiNaNq6A1SjEBziJKuQv8eAU4BBqUQr3kn+hW0sOmchlhSp2UU9fm4JN7KI6VuEtvI8mVBMWAnFaUpFassxa0n0mWzBIJPd4pRFqx44jiiv4HY+RMA+YFXtC19fhYCMOKrJc903kC0xqyVb8TOR/S4bojC73uy+Mw6jHe9Y6Dl5gGr1oxSiqcEw/+6YiHevjvqHIfiKfmYC0F7XpIZnEVS17S8vGrWNQgH04i0/RjApF2kuah/A79JnITITax2AK7VpiELNWbBCbsR9NqMR6a8kFRfYgigHzcWcy1F52Gj8q9haeWGc2gLXYhBAC1tF4hJ/MkniOZb/8cNKMTXJmiXU6FJHEXryhMJ8rYB5/K64VPfaSWVnUjtVCHbiCX11n1mOdyX8UeQl9JnJFQhWb0lLX0YJfMKMF49aS/Yq8iSdI/6Wvp0z8E7xB/6xEUIIg5jCKa+hiyRkTsJKLus2gExfxNb7S9Rt04fGrjvESSwReiXtRLSkAAAAASUVORK5CYII=);
  }
  &__arrowPictureDown {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACoAAAAYCAYAAACMcW/9AAADb0lEQVR4Xr2W60tUWxiHZ8+oTebdBCUKTcgSSpLGcyyz6a7R1Y5Cf0NF/0rR574E51NeKyKkRss6B6PseOlkOJYRFVp2MW/ljLsn+G1YDKOSM+MLD4sZNotn/9a75h3Ltm1XLHW+fziJZRNsBAucCsEwBC9vLZ53xVhuiLVKwA8X4JK4CGehCgoh5kqKIUkPSxHsglNQDungUZqZStjSsy9JNryiokhaLMVQbUoa+yVL9A9w63sv9K/00W+AKkn6IENJOmVJMAMqoAF2kmwJeBKeKEm6jeM+qcRMSdtYLcmnK3GXPqcg+5w2CCUy0XVGT1ZCpvGy8zAD41rDThiS3Q51sAc2x//o1ZOQj8BuOCpJM8kwfIP/4BY8hglD1g1pSrYB9rJfOaTEVVQ9uV+S1ZCtpGxDsgf+hqta/4GvMGckmwFlcEYvXap2il2UjfLUi8dgH+RI0pLoFPRCC3TRewHWgD7/K9mQ0bepUKpkD4EP2VXLFtVxF6innCSzDMkwTEIfNME9GNQGI3Bf3z+AzxAyLlgabIN67V+mNlhWomsleVzHngvJxsWZggFohA545txk1jAEEeiEVuhSsmGwFdBqjd6/tH/FYrJJC/yY50GFjuYA5BiSYd3qQSX2EAYXmOdvJGlDSOllay+32mALrJL8d2QH2GtmqUTNJE84PWkmKclncE2J9S00Gn/JwysE2qFZ7fHJ6FmPkt0IdXAEKpH1LirKA1m6OAehBvIlaRmSL6BZx92LiG61iC48ytIN1yU9BnMRbVCiC+aHHbikRhVFMkdjsVZHnhchOQ1DxsX5P+K4l5J9LclWuAPjEbJeKIQTCspnJuuWZKaSPKz4zSRtI8lGST5Bctb1m4XsR/1k3RajUWSL4bTC8uO2xkUlKWKf3qI2ynHPwgi0KQn15LJrTL09rf1rzNOTbBHUS34ax6fWub6gX1OnHtaD10lSksPqyQA8UpIxFwHl6rI6vywFUVrtOVyBZowpPQgpxoPfYcToqe54SaoNxpVsG9yEd/BDks7IzYL0aJNJs5skJanNepBEPL6F7AdNrltwQ20xa/Rt1Mn0Q9NmUpItcFfHzTEkppD9oqERgCZd2rfwHhSOJpMEg4ZAO3RKEvnEFrIT9GyH/hoOQVrEdHNZXKY/dYEiSkmuYCGbrnEaWZM/ARTwUJLFtVeSAAAAAElFTkSuQmCC);
  }

  &__issueTab {
    position: relative;
  }
  &__issuesCount {
    background-color: white;
    border: 2px solid $blue-dark;
    //border-radius: 100%;
    border-radius: 28px;
    min-width: 20px;
    position: absolute;
    margin-top: -56px;
    margin-left: 140px;
    //width: 28px;
    padding: 0 4px;
    height: 28px;
    font-size: 14px;
    line-height: 28px;
    text-align: center;
    color: $blue-dark;
    font-weight: bold;
  }
  &__notificationCount.Messages__issuesCount {
    border-color: $red;
    color: $red;
  }

  &__errorButton.row {
    margin-bottom: 25px;
  }

  &__errors {
    height: 100%;
    overflow-y: auto;
  }

  &__errors.row {
    width: 1200px;
    overflow-y: auto;
    min-height: 100%;
  }
  &__error.row {
    border: 1px solid $gray;
    //margin: 0px;
    margin-bottom: 25px;
    padding: 30px;
    width: 100%;
  }

  &__copyErrorButton {
    float: right;
    margin-right: 10px;
  }
}

.Messages__root {
  margin-top: 10px;
  position: relative;

  .tabs-navigation {
    margin: 0 auto;
    background-color: $gray-light;

    &:after {
      content: '';
      display: block;
      width: 100%;
      height: 0;
      z-index: 40;
      position: relative;
      border-top: 2px solid $blue-dark;
    }
  }
  .tab-panel {
    /*
  div {
    margin: 0 auto;
    max-width: 1200px;

  }
  */
  }
}

.Messages__tabs.tabs-list {
  width: 100%;

  .tabs-menu {
    margin: 0 auto;
    width: 1200px;
    background-color: transparent;
    margin-bottom: -2px;
    &-item {
      position: relative;
      z-index: 20;
      width: 204px;
      text-align: center;
      background-color: white;
      border: 2px solid #ececec;
      border-bottom: none;
      margin-top: 18px;

      a {
        display: inline-block;
        width: 100%;
        text-align: center;
      }

      &.is-active {
        z-index: 50;
        border-color: $blue-dark;
        border-bottom: 2px solid #fff;
        a::after {
          display: none;
        }
      }
    }
  }
  .tab-panel {
    position: relative;
    z-index: 60;
    padding-top: 5px;
    //background-color: $gray-light;
    //width: 100%;
  }
}

.NewIssueSettingsBlock {
  overflow-y: auto;
  overflow-x: hidden;
  margin-bottom: 2px;

  &:first-of-type {
    margin-top: 0;
    padding-top: 0;
    border-top: 0;
  }

  &_borderBlue {
    padding-bottom: 3px;
    border-bottom: 2px solid $blue;
  }
  &_borderGray {
    padding-top: 3px;
    padding-bottom: 6px;
    border-bottom: 1px solid $gray;
  }

  &_collapsable {
    position: relative;
  }
  &__title {
    position: relative;
    padding-top: 3px;
    color: $blue-dark;
    font-size: 16px;
  }
  &__content {
    padding-top: 5px;

    .col-xs-12 {
      padding-right: 0px;
      padding-left: 0px;
    }

    .col-xs-3 {
      padding-top: 10px;

      padding-right: 0px;
      padding-left: 0px;
    }
  }

  .Table__add {
    margin-top: 5px;
    margin-bottom: 10px;
  }

  .TagsList {
    margin-bottom: 3px;

    &__title {
      color: $blue-dark;

      &_pointer {
        cursor: pointer;
      }
    }
  }
}

.IssueUserSearch {
  width: 85%;
  float: left;
}

.Messages__root {
  height: calc(100vh - 125px);
  min-height: 540px;
  //height: 100vh;
  display: flex;
  flex-direction: column;
}

.Messages__tabs.tabs-list {
  flex: 1;
  display: flex;
  flex-direction: column;
  max-height: 100%;
}

.tabs-navigation {
  flex: 0 0 56px;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.tab-panel {
  flex: 1;
  display: flex;
  flex-direction: column;
  max-height: calc(100% - 58px);
}
.tab-panel > div {
  flex: 1;
  display: flex;
  flex-direction: column;
  max-height: 100%;
}

.IssuesView {
  flex: 1;
  display: flex;
  flex-direction: column;
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
  max-height: 100%;
}

.IssuesView__container {
  flex: 1;
  width: 100%;
  display: flex;
  max-height: calc(100% - 34px);
}

.IssuesView__filters.row {
  flex: 0;
}

.IssuesView__main {
  flex: 1;
  display: flex;
  flex-direction: column;
  max-height: 100%;
  float: none;
}

.col-xs-9 {
  flex: 1;
}

.IssuesView__notFound {
  flex: 1;
  display: flex;
  flex-direction: row;
}

.alt-col-xs-3.IssuesList {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.Messages__rowCreate {
  position: absolute;
  width: 100%;
  &Inner {
    max-width: 1200px;
    margin: 0 auto;
  }
}

.Messages__rowCreateFixed {
  //position: fixed;
  width: 100%;
  left: 0;
  bottom: 0;
  //z-index: 100;
  &Inner {
    max-width: 1200px;
    margin: 0 auto;
    .Messages__newIssueButton {
      position: fixed;
      bottom: 5px;
      left: calc(((100vw - 1200px) / 2) + 1200px - 90px);
      width: 100px;
      height: 28px;
      float: none;
      top: auto;
      //top: 0;
      margin-bottom: 10px;
      z-index: 100;
      &:nth-child(2) {
        left: calc(((100vw - 1200px) / 2) + 1200px - 180px);
      }
    }
  }
}

.Issue {
  //flex: 0 0 auto;
}
.IssuesView__messages {
  flex: 1 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  max-height: 100%;
  margin-right: -20px; // its for scrollarea negative padding
}
.IssuesView__messagesInner {
  flex: 1 1;
  overflow: hidden;
  display: flex;
  max-height: 100%;
}

.Messages__reloadIssuesButton {
  position: absolute;
  left: -5px;
  top: 5px;
  width: 30px;
  height: 30px;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size: cover;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADwAAAA8CAYAAAA6/NlyAAAEoklEQVR4Xu2bPW8TSxSGhyXkQ4HrxKBAPiSvAlfQcJ3ulnFKUiHRUoQCKiD+B3FKuoTbQUGKtFylCmU2JZ0dGlACWgsnAcs4LBAl4SvMu+xI+GDJ6z0ziRd4paNBaJOZJ+fMmXNm7SP7+/vChG49eT4ih4w0jHYwJkR9edLy0txgdP67eBajdgFYJyQAJ6RdVnAMedIWpM1JeKdVgAHZEwDmpKWEGRWD378g4d9ygC0mbC4IwwcGYaFUMIeLObkejhq6M9LS4nBUkJaNEupWBFiALrFg+cLcS1iLbg/TveowQE16OxN2b1tNHDEuA9a0t12skQNMYR3WMWNeWJsTBtpiwMYS2mqwZwlsbKB7mgamsHGDDgNMj560iK/SYAgDrIqKSRF/TYIljIdn4k5KWWjhQWvjKZ0AyfY2kf6rW/wjbbCzQ3QdrQ2q6qfPorSzJ1bebfu28+WrbuhpWZTkKLDKyq60hC7Q8b6k+Lf3RGgAwD7eei8Wy1Wd4J40G5UYDenLumDHTyfF9PlUU7AQvJ85lcDPIiJ0Zm2wCQqcE3xh0ezFAvx66oy40n+KTUvZLJWZOf0sDcv5l2WMNfv0UXlL3FktYa4au/tiQzgVr24Iw9tXh/q09NNgVMDQhNCo0u6eD6JAp54WxeLrqv//VKvbO+LhZkVMPSv6z1JhW4xJcA2aUEkLHn7bKlXV391dCGeazREd/h+MIU8mrh4rKLYT3IzMEfU4ooOG+JWBk+zkBVaEdIbBigSlMrLWLTFfKtcAnJOeh/eZygB4JCrsUGeHSioYtUKrQoQmMaZ8D9tRw/j28IDaa0agkcxoNGE+hmwAj0Y5J2+k+snk+qGrHz+L9d2PNKlxgEetSG3I8KCsi9sVgFFoGtZDXR3C9EU8hSGwZqFXP+zUAGBujpo5T1DqNQUBaAgNQdganCp5rI16mD7nFzVhdeTmyhrapUgLArxakAJDdQVFWBAiotlSEmUpkpsZD2PhNLwoMAqHiFKRAOiwzxNYsy/Tfs6gx9mFASBQdISZmz5nHhg3FfSchExDA3b2xXrkLL3MqHtpiKMgMQqNGvtecdMfI2gZwC6j96XnpGrajUADEo0FCpKIcgGcFwwhS5KwhpmAxshtEfMAdrjt3Jo0cv6isdAKjQsCRBNTjhV8WsZjFfkbb2itjcaC1L38upopD6wqSy9w+9f/Nyv1oFGs4N/hbzsvpFR0aJViVMBzgqmlile3hLzU14sLAiSzeh4HnALFsyhkEB0moOfoRbyr4eYSYJxGnZ61ui7kizKcbSP30ij17hdfYaHMRPVOCyxls0iMe5p6WGRVdd8cKSNji2iSBzbjL9PomwgYWjva7sGL67vfX6YVpKlsbPpl2o/QefIyPM4qSNiRRs1DVvw6yjbsloKP882K+GsWLI2AFTS8XIh5KGeb7YczKmvHTB55mxIOGG/MFXTcYLH2sMAUOq+gYwSbZ1zxEOi4wlLgkNB2iyYyrMkGrM7PS9NP6U220NGTNXprGUwwxvY236tjFNaEhyFae2PSxAHu1RnUxofyrZZgYlvaNWlFg6DFYA6bAfvni1q/xVfxvgFxxHOP1g4C1wAAAABJRU5ErkJggg==);
  &:hover {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADwAAAA8CAYAAAA6/NlyAAAE3UlEQVR4Xu2bQWwUZRTHHyPBNts4JZosauxOCpimh3Y94MlKueGth8KZ6tnuXoWD9WC5tuVuexYPvenNBTxJotMeSLXQzEoEmgB2TDcUAqnvvXwhH0+S2Zn3fbUT/CcvkzSbzvfre9+b//tme2B3dxd86ONrq3W8jGPQNTLXEF6uFCPGSMy1dXVsJAYPEsBqSAI8hzHBcDqlGMsYSwjfcgmshew3gDMYNfCjtvn9ywi/BQoFStgZU4aLDOtPNXOPhO6pzXDR0p3DGIX/RisYzSKlHhSAJdAfVbB6jdIaaC2uMyz3aksB6jPb493u7SDHIyZRwPrOdkJrVABLWM5sCPtXIUarG+hAAVtK6CBjzwrY0kD35waWsGWDzgVs2v0olFejxJAFbJuKBpRfDcOSmeE5KLkkizQe0ht/CQ51pOcQjL35BkYIxys9UDn4Gti69/gJ3NzegWsPUoy/YfvpM3Csr9CUzNjAdldOMEJXoJ8OVOF09TB0qw7Cfr/5F3zzx6ZL8BQjIicmS3rCFexUrQrfnhhi2Bzi7E+++xZcPjHEVeFIoWEDCTwDDtSHi6byVYjBvx6OYHrwHdBKsgVWZ66BXlyKF3+7zeVp79NFLNPPflmne9kBjdUN+O7P+/x5Ic72+fffAweqEaOd4XPgUOudRzCNIAYUzv68BovtTf651K/pNixs3IEz19f4s0K8Lc4guFrMaJoW0m/tF1f1QdgHs8M12c2pOvgPplCKjas/MGY71HZkhWTGuTpkiX9+VL2fQ2IN+ChVIeym3JE/qR52uiVmf78NtuphhbOv1DgB16Ggjld6nzeVL/DqEJpNyE8YllzsZc5wVLSMF0YGrb3mHJqbma2PsJrosadQRMAnizxrZ4cjG9YL9L0dtJ2dHdHUKqDQyQDyCzN7FI6hLzbyCc0e29axvl7QKC8w7VkB6xc63urIvgEaHYQcQqsn/HE2NIoGgq49uNTbrx8SGe6Rn2NT060OjF1doXGpyII4g0esBf2AYHfRXaGKLIgqgisjh8iWiuamy7BcuCwvC5gzycahqEwlZEHbf2AB6/llmrR69f4KKMXQF9F0ZIi6tzQn/oFvbr8IbMZC39AMO716q3CXvlLc93bAEnVvMiQ+odljn7+RFD0RuULAiWb2lfbPDO0eoBmWBgs2JAWVEHAMCl3GLint3xiGB2jas9oRMSbglnaci9OONCfCIOihz15f44FCqVZgvi2TgkKXbt2RZ1I0WIhxTu+rlUqJNTAAy9r59dLGv6HnERrNiphwMk47Pxzi6vCgZdtLL9FFuZfZCEhNDVTp2JWa2csyznAGlD5LRoaqwwf0kjyITxycXDLYpGJQF89aVwfybSznyMe5NFu9CzcSeoQoG9VDJ7CSLRA1njo6nqFjV3ne3KU/5o4sHnf6b/Tt1cs0bljmZRqNdrxHpXNa7zx/mWa6sf+XaTZ0LF6Gl1krCFvPGh6aUFpls0hgMF/nm4fya55YMoENNGV5peSl3Mw7D49zhyufUrN2yAVMb8xLCM2wtPbcwAY6FtBlgI0VRzwCutSwEjgbOtqfjYzXFGXBCuOR+wvijX306Gl6PbU0NzilzrY+q6ckrIcMs6T3ppuGe7hX5yxvvLfn0ubGEcYURhv8qW3uESlg//9HrVfiX/H+AeJjcZ6iR9e5AAAAAElFTkSuQmCC);
  }
}
