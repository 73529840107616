@import '../../styles/utils/variables';

.ObjectPathEditor {
  input {
    background-color: #e0f5f8;
  }

  .TextInput {
    margin-bottom: 2px;
  }

  &__Close {
    position: absolute;
    margin-top: -25px;
    right: 40px;
    color: $blue-dark;
    cursor: pointer;

    &:hover {
      font-weight: bold;
    }
  }

  &__results {
    margin-top: -3px;
    background-color: white;
    border: 1px solid #6dd2dc;
  }

  &__result {
    padding: 6px;
    border-bottom: 1px solid #6dd2dc;
    color: #218a96;
    cursor: pointer;

    &:hover {
      background-color: $gray-lighter;
      font-weight: 700;
    }
  }
}
