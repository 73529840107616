@import '../utils/variables';

.KascodeSelect {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  min-width: 100%;
  cursor: pointer;
  line-height: 1.4em;
  font-weight: normal;

  &:focus {
  }

  &__input {
    display: flex;
    padding: 0 0 0 4px;
    border: 1px solid $gray;
    border-radius: 3px;
    line-height: 28px;
  }

  &__values {
    flex: 1;
    box-sizing: border-box;
    height: 24px;
    padding: 2px;
    border: 1px solid;
  }

  &__toggle {
    position: absolute;
    //top: 1.2px;           // was top: 1px and height: 27px, in some scales border was not visible
    top: 2px;
    right: 2px;
    width: 23px;
    height: 26px; // was "height: 28px;" in some scales border below the arrow was not visible
    line-height: 28px;
    text-align: center;
    background-color: transparent;

    img {
      margin-top: 9px;
      width: 18px;
      height: 9px;
    }

    &_rotated {
      transform: rotateZ(180deg);
    }
  }

  &__list {
    box-sizing: border-box;
    position: absolute;
    top: 100%;
    left: 0;
    height: 0;
    z-index: 150;
    overflow: hidden;
    background-color: $light;
    min-width: 100%;

    &_opened {
      border: 1px solid $gray;
      height: auto;
    }
  }

  &__options {
    padding-top: 3px;
    margin-top: -3px;
  }

  &__optionsMany {
    .KascodeSelect__checkbox {
      right: 14px;
    }
  }


  &__option {
    position: relative;
    padding: 2px 4px;
    width: calc(100% - 8px);

    &:hover {
      background-color: $blue-light;
    }
  }

  &__option.KascodeSelect__multiCol {
    display: inline-block;
    width: 32%;
  }

  &__label {
    padding-right: 20px;

    &Subtitle {
      font-weight: lighter;
      opacity: 0.7;
    }
  }

  &__rating-star {
    width: 28px;
  }

  &__checkbox {
    box-sizing: border-box;
    position: absolute;
    height: 14px;
    width: 14px;
    top: 50%;
    right: 4px;
    border: 1px solid $gray;
    transform: translateY(-50%);
  }


  &__check {
    position: absolute;
    top: 3px;
    left: 50%;
    width: 12px;
    height: 12px;
    transform: translate(-50%, -50%);

    img {
      width: 100%;
      height: 100%;
    }
  }

  &__placeholder {
    box-sizing: border-box;
    width: auto;
    min-width: 60px;
    padding-right: 2px;
    overflow: hidden;

    &:hover {
      position: relative;
      z-index: 50;
      background-color: $light;
    }
  }

  &__textdata {
    box-sizing: border-box;
    width: auto;
    min-width: 60px;
    padding-right: 2px;
    overflow: hidden;
    color: black;

    &:hover {
      position: relative;
      z-index: 50;
      background-color: $light;
    }
  }

  &__tooltip {
    .SimpleTooltip__tooltip {
      margin-left: -150px;
      width: 150px !important;
      min-width: 150px !important;
      margin-top: 5px;
      position: fixed;
    }
  }

  .SimpleTooltip__root {
    position: absolute;
    right: 0px;
    top: 0;
    //display: none;
    opacity: 0;
    float: right;
  }

  &__infoIcon {
    vertical-align: middle;
    display: inline-block;
    width: 20px;
    height: 20px;
    position: relative;
    top: 0px;
    margin-right: 12px;
    margin-top: 4px;
    background-size: 20px 20px;
    background-repeat: no-repeat;
    border: 1px solid #6cd2dc;
    border-radius: 100px;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADgAAAA4CAYAAACohjseAAABtklEQVR4Xu2aPU6EUBSFdWI/s4PRxB5cgbgCZwfSWomFtVjbYGWLOxhWIO4AeothB7oCPJOc4mWC/CQiOeSSnLxMbjF8OZf73gkc13V9NOdrIQBggAaoLgMUlgGe/DfA+fPrKZYEunYKJRR9Ptzmsg4SzsdSuHCUB70TXrpFt9CypR5LAtK9DZZ1B4C0g2hPm6KFMuCuo/4NJcqAW6j6rchtYicLiJv/whJAmVvg7wvU0zH+1wKvmAzQAC1NdCeJiNPUYyHDBN0QQG+KEizgQfqyqc5topBzkPEoIVjbtZJpUYKt6Njd7IYM27Eg3HyGDOFiLI8jpIhpHSRc2gD30QOu3J9TFVo0cGLPE3SGGw96AOYqLRpSER3hFJ0JIKDyBjd6ASqniWCE508KMJdNE9zs1+KA3e4JAE71/OkD5uqB1xsCqJYmBj1/HEjJXn+dCxcTtWflnHZCpo8bKBVwsBfgiskjPNhKChVAv6O+bEgeb3A1VBkyy4EG3BNOItH7A8Aq6Apwicw2MWAKvkA+4GTyoKuq5RyaQbG7HSgChgcfHJRMDonzDnBk2eszAzRAAzRAAzTAH2HOjflcr9icAAAAAElFTkSuQmCC);

    &:hover {
      background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAsCAYAAAAehFoBAAACfUlEQVR4XtWZ0Y2bQBCGOeR3OxXEUp4jSAXeEnAFJu+JjlQQu4ON0gCuwFfCUkEgeU0kroNzBc6P9EtYyKvgncE5j/SJB0vDp2XwzC4Pp9MpCo3Pv/6kuBiyBIknQQNa4Dq+v39XR4FB4askF7jkoABvQUg0oOyA/MskwhQtyBxoxBHYjrHi8UjZDJcafNWSJXPmrHkPmTBlLS6H/vHrw9wH3iusJFgCDiTgllGBzFcisVhWnxVwdBhdEk8Ksg3YkepK6QS4USXBOnoUyq7xSJ8GeUtcNleKf0OewrvCfFOlsruhLLEBuR7pNBRm3TKpkNIj8BKYz9KNwgxP59IkF/zlFb3weRfTie2FxmOE+Qs6RjMK5IodbAPB5dlbbsBK2hHpaHth3VgRzSiAffj083cKgR/gHuJDzEd2L2GmFK7Y5faawjPuFKLJ5lvuTJTmkuWMiaYexCkvJpkpPfocom10g4iFsl8gakDL5uAfGZVCssJ7UEJ0e9bFFp4uF70G4Q3I+g7pnXmz11MSlCXOI2C0hRulXM6z1UoUfZtOWOXtxovnplxd0nbCTqmrRTcQdjrC/hyZujAP5p6FwvWF+l0OdjBHaf12rjEFrPYKD2aUHUiF0uV5pysFyRrP/FCDj+ANft9y6zQXzCq9MG9o5eVAmBOULA/HRhMaHKooTGxgLbf/OPVsJbMEneyg03GVw3a25oKo4aoeFDa3Bd1Uj6r2XMkFyATnG96jKq8wpWtxS5VTQdaMHX6MaMaQ0/iaTux7wyld/SdZQ4dRJ/DCmpbXrGgeZoJ13771Ye41ZeUDPM97U7bYo+qxANs27zHdh0WSSJqB4MPi/Xy6/QsYfBdp7FSspgAAAABJRU5ErkJggg==);
    }
  }
}
.KascodeSelect__option:hover {
  .SimpleTooltip__rootKascodeSelect {
    //display: inline-block;
    opacity: 1;
  }
}

.SimpleTooltip {
  &__rootKascodeSelect {
    position: absolute;
    right: 0px;
    top: 0;

    float: right;
    opacity: 0;
  }
}
