@import '../../styles/utils/variables';

.IssueCard {
  box-sizing: border-box;
  background-color: #f3fbfc;
  border: 1px solid #248a96;
  color: #248a96;
  padding: 6px 0px;
  margin-bottom: 10px;
  height: 98px;
  position: relative;
  //width: 274px;         // not needed and too small
  //overflow: hidden;

  &.IssueCard__tracked {
    background-color: #f0ecf9;
    border-color: #8f70cb;
    &.IssueCard__selected {
      background-color: #e0d8f1;
    }
  }

  &__trackIcon {
    height: 16px;
    position: relative;
    top: 2px;
    margin-right: 6px;
  }

  &__link {
    height: 42px;
    .row .row {
      padding: 0 !important;
    }
    pointer-events: none;

    a {
      color: $blue-sea-darker;
    }
  }
  &__linkPart {
    display: inline-block;
  }

  &__priorityIcon {
    height: 16px;
    position: relative;
    margin-right: 4px;
    margin-bottom: 4px;
    vertical-align: middle;
  }

  .row {
    padding: 0 8px;
  }
  &__label {
    font-weight: bold;
  }
  &__name.col {
    color: #6c6c6c;
    font-size: 16px;
    font-weight: bold;
    padding-top: 0px;
    padding-bottom: 6px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  &__date {
    color: #3a3a3a;
    text-align: right;
  }
  &__deadline {
    color: #6c6c6c;
  }
  &__priority {
    color: #6c6c6c;
    font-weight: bold;
    text-align: right;
  }

  .col {
    padding-left: 0;
    padding-right: 0;
    margin: 0;
  }
  &__resolution {
    &Icon {
      background-size: 38px 34px;
      background-repeat: no-repeat;
      height: 16px;
      margin-bottom: 4px;
      vertical-align: middle;
    }
    //border-radius: 2px;
    //padding: 2px;
    //padding-top: 1px;
    display: inline-block;
    width: 20px;
    height: 20px;
    top: 5px;
    position: relative;
    border-radius: 100%;

    &__red {
      background-color: #f1592a;
    }
    &__yellow {
      background-color: #ffc107;
    }
    &__green {
      background-color: #92c850;
    }
  }

  &__edited {
    border-color: $gray;
    background-color: $gray-light;
    &:after {
      content: '';
      position: absolute;
      width: 40px;
      height: 40px;
      top: 32px;
      right: -28px;
      background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADQAAAA0CAYAAADFeBvrAAAD7ElEQVR4Xt2aX0ojQRDGe6MoCJIBRcEXR3wQfJDcQG/g7A0mJ9l4g71BcoONN4jPgoigKAiOCKKoOFERFKV3avgIs1vJWv0nybAfFD4507/+uqurK/NNa608KchiC1HLIsxiWfXXZRZJFodZdBCp8iAAOUFEiG3lpt0s2ghruIoDSAOz3ASMq7bxrATPDkYFFAPkRxZV5V9VejbA4mEChVjrTTGIO1gT7wx9A0XYwJtq9NrEuyMvQLD9F1wZl6oYQ+wK1IDtpRDG0rAFirE5SyWMKTYFiuBMWdUctKcmB2Szli3A29ubStNUPT8/q/f3d/X6+toDmJqaUtPT02p2drYXDmqhIkm+qhQ6Ntns/v5ePTw85CAiAXB+fl4tLi6qiYkJG6g9lFoDgWLTpUYOXF1dAcROBLOysqKCwKo4qMMtBhTAvqqJK0mS/BMAy6oH//n5ORBsbm4uBzNUF9sk/RuoYZLVrq+vKRgA7ZGFhYUcZGZmpu8ee3l5ocno6yr9z9ramukS3Oml8wyIIsgi1ULd3d3p/f39P+Lg4EDf3NxoEz09Penj42P2rNPTU22oFAy9tB0JlxrNKltm5MT6+nq+uQ1ELpIblBjYO+C+VFUwFICEuri46LdEaKlZJ4QwDBkUASHli1QECqT3mdvb2/xsKQ5mdXU1/+sogmLnEmVPqcAQVHp5XAhU1NLSEnPGEYotPUOXtsRAaZoW3SEQ7BlPwjMpdRdEh7UxUE2aDArCIehdbJIeHx9NgGoEFAorgqEDIckU9yStChzGIoUEtGwDhA08LCj2bqGWJ4XkmCVvEpZDHAjZFdBcABqTsKTOz88VF0/fgKFzz7FJggf5FvYi0rVIBAN3HIFgMc967qIqQQRF4IDxDIRzCRoJFC+PBgNdCgtJdj74Fm6v1jA5i9a6o4U6Ojoqlvm4LvhXVgDbvqNTQVdSIrq4sYqYLmy+BUeYYwIdkkMR6Kxcogvax8eHLokicqhjOHvs0Ds7O4NTY1engubCrsEtsy/UyckJu15ICl6aDPp/V4EhrQCgbZiN6C7ESiM60bMlSWADB0lOUoOEJoBgAOVYWoEBXR/Wwhp5G4t3e6xbWYI2VpkbjbyNVawUfoLUSJhZGhDcMLuh0tLd2Nhwgeli7IJWsM9mPQCon+3erOetYFGzvvzizfqK4ophY6mFMcaSajsBVKkFmER6fWhjbZZSGFvb9D7UQjoslTCmlvWv4CVzqm73Kzh36vuYE0UXY2j5+pKkjQ7r3phSc43tGSsgnv22YHt3RK7U8c5kaF9jwfYQm7M7JJAd4ecF7kBQis0ZwrFdT/eZOkAaeIex/rtPNH8DAej3JI0yGHsAAAAASUVORK5CYII=);
      background-size: 25px 25px;
      background-repeat: no-repeat;
      background-position: 0px 5px;
    }
    &:before {
      content: '';
      position: absolute;
      background-color: white;
      border: 1px solid $gray;
      //border-radius: 50%;
      width: 14px;
      height: 28px;
      top: 35px;
      right: -1px;
      border-top-left-radius: 15px;
      border-bottom-left-radius: 15px;
      border-right: 0;
    }
  }
}
.IssueCard__selected {
  background-color: #cdeff3;
  //border: 2px solid #248A96;
  //margin-bottom: 9px;
  box-shadow: inset 0 0 0 1px #248a96;
  box-sizing: border-box;

  .IssueCard__link {
    pointer-events: auto;
  }
}
.IssueCard__selected.IssueCard__edited {
  &:after,
  &:before {
    //  display: none;
  }
}

.alt-col {
  float: left;
}
.clear {
  clear: both;
}
