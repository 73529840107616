.org {
  &__center {
    display: flex;
    justify-content: center;
  }

  &__head {
    display: flex;
    justify-content: space-between;
  }
}
