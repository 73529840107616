@import '../utils/variables';

.ProfileView {
  padding: 10px 0;
  background-color: $gray-light;
  border-radius: 10px 10px 10px 10px;

  //min-height: 400px;
  min-height: 65vh;

  &__noTabs {
    margin-top: 20px;
    border-top-left-radius: 10px;
  }

  &__photo {
    width: 130px;
    height: 130px;
    background-color: $blue;
    border-radius: 100%;
    overflow: hidden;
    position: relative;
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: cover;
    margin: 0 0 4px;

    img {
       //transform: translate(50%, 50%);
      max-width: 100%;
      max-height: 100%;
      width: auto;
      height: auto;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      //margin-left: -50%;
      border: 1px solid #CCCCCC;
      //border-radius: 50%;

    }
  }

  input {
    box-sizing: border-box;
    margin-bottom: 19px;
    padding: 0 15px;
    width: 100%;
    height: 52px !important;
    border-radius: 5px;
    border: 1px solid $gray;
    line-height: 50px;
    font-size: 16px;
  }
  .selected-flag {
    height: 52px !important;
  }

  & > .row {
    padding: 20px 5px 10px 5px;
    border-bottom: 1px solid $light;

    &:last-child {
      border-bottom: 0;
    }
  }

  &__button {
    margin: 10px 10px;
    width: 160px;
    height: 50px;
    line-height: 50px;
    background-color: $blue;
    color: $light;
    border: 0;
    font-size: 22px;
    border-radius: 5px;
    outline: none;
    &__disabled {
      pointer-events: none;
      background-color: $gray-light;
    }
  }
  &__button:hover {
    background-color: #3DC2D1;
    outline: none;
  }

  &__topText {
    //font-weight: bold;
    font-size: 18px;
    text-align: center;
    margin-bottom: 20px;
  }
}

.Profile__tabs-list.tabs-list {
  .tabs-menu {
    &-item {
      &.is-active {
        background-color: $gray-light ;
      }
    }
  }
  .tab-panel {
    padding-top: 0px;
  }
}

.Profile__preferencesTabs-list {
  background-color: #F0F0F0;
  .tabs-navigation {
    float: left;
    width: 200px;
    border-right: 2px solid #CBCBCB;
    background-color: #F0F0F0;
    border-bottom-left-radius: 10px;
  }
  .tabs-menu {
    //background-color: #F0F0F0;
    .tabs-menu-item {
      text-align: center;
      //width: 100%;
      width: 160px;
      height: 110px;
      padding: 10px;
      border-radius: 20px;
      margin: 10px 20px;
      &.is-active {
          background-color: #6CD2DC;
          a {
            color: white;
          }
        img {
          // todo: this is hack
          filter: brightness(1500%);
          -webkit-filter: brightness(1500%);
        }
      }
      img {
        //background-color: #F0F0F0;
        //clear: both;
        height: 48px;
        //display: block;
        //margin: 0 auto;
      }
    }
  }
  .tab-panel {
    height: 520px;
    //padding: 25px;
  }
}

.ProfileView {
  .Profile__radio + label {
    cursor: pointer;
  }

  .Profile__radio:not(checked) {
    position: absolute;
    opacity: 0;
  }

  .Profile__radio:not(checked) + label {
    position: relative;
    padding: 5px 0px 0 45px;
  }

  .Profile__radio:not(checked) + label:before {
    content: '';
    position: absolute;
    top: -3px;
    left: 0;
    width: 32px;
    height: 32px;
    border: 1px solid #CDD1DA;
    border-radius: 5px;
    background: #FFF;
  }

  .Profile__radio:not(checked) + label:after {
    content: '';
    position: absolute;
    top: 5px;
    left: 9px;
    width: 17px;
    height: 17px;
    border-radius: 5px;
    background: #6CD2DC;
    //box-shadow: inset 0 1px 1px rgba(0,0,0,.5);
    opacity: 0;
    transition: all .2s;
  }

  .Profile__radio:checked + label:after {
    opacity: 1;
  }

  .Profile__radio {
    width: 30px;
    height: 30px;
  }


  .Profile__check + label {
    cursor: pointer;
  }

  .Profile__check:not(checked) {
    position: absolute;
    opacity: 0;
  }

  .Profile__check:not(checked) + label {
    position: relative;
    padding: 5px 0px 0 45px;
  }

  .Profile__check:not(checked) + label:before {
    content: '';
    position: absolute;
    top: -3px;
    left: 0;
    width: 32px;
    height: 32px;
    border: 1px solid #CDD1DA;
    border-radius: 5px;
    background: #FFF;
  }

  .Profile__check:not(checked) + label:after {
    content: '';
    position: absolute;
    top: 5px;
    left: 9px;
    width: 17px;
    height: 17px;
    border-radius: 5px;
    background: #6CD2DC;
    //box-shadow: inset 0 1px 1px rgba(0,0,0,.5);
    opacity: 0;
    transition: all .2s;
  }

  .Profile__check:checked + label:after {
    opacity: 1;
  }

  .Profile__check {
    width: 30px;
    height: 30px;
  }

  .Profile__infoIcon {
    height: 20px;
    margin: 5px;
  }

  &__dropZoneOuter {
    padding-top: 0px !important;
  }

  .ContentLoader {
    &__emptyFrame {
      margin-top: 2px;
      width: 170px;
      height: 160px;
    }

    &__previewFrame {
      margin-top: 2px;
      width: 170px;
      height: 160px;
    }
  }

  &__loadIcon {
    cursor: pointer;
    position: absolute;
    left: 120px;
    top: 115px;
    height: 22px;
    //border: 1px solid red;
    bottom: 0;
    color: #238A96;
    font-weight: bold;
    font-size: 10px;

    img {
      height: 10px;
      margin-right: 3px;
    }
  }
}
.Profile__selectView {
  font-size: 1.2em;
  padding: 30px;
}

.Profile__header {
  color: #6DD2DC;
  font-size: 20px;
  text-align: center;
  margin: 20px 0;
}

.Dialog {
  margin-top: 25px;
  text-align: center;

  input {
    text-align: center;

    &.LoginPage__textInput {
      text-align: left;
    }
  }
}

.Dialog button {
  margin: 10px 0;
  width: 100%;
  height: 50px;
  line-height: 50px;
  background-color: $blue;
  color: $light;
  border: 0;
  font-size: 22px;
  border-radius: 5px;

  &:disabled {
    background-color: $gray;
    &:hover {
      background-color: $gray;
    }
  }

  &:hover {
    background-color: $blue-hover;
  }
}

.Profile__error {
  color: red;
}
.Profile__saved {
  text-align: center;
}

.Profile__tabsOuter {
  position: relative;
}

.Profile__close {
  position: absolute;
  right: 0;
  top: 25px;
  img {
    height: 25px;
  }
}

.Profile__aliasInput {
  margin-top: 0px;
}

.LoginPage__Logo {
  height: 39px;
}

.Profile__passwordDisplay {
  //background-color: red;
  width: 50px;
  height: 10px;
  &:before {
    content: 'show';
    display: inline-block;
  }
}

.Profile__passwordHide {
  //background-color: blue;
  width: 50px;
  height: 10px;
  &:before {
    content: 'hide';
    display: inline-block;
  }

}

.Profile__passwordStrength {
  color: $red;
  margin-bottom: 20px;
}

.Profile__error {
  text-align: center;
}

.Profile__inputOuter {
  display: inline-block;
  position: relative;
  width: 100%;
  height: 70px;
}
.Profile__inputPlaceholder {
  position: absolute;
  left: 16px;
  bottom: 20px;
  font-size: 14px;
  color: $gray;
}




.EditorDialog_profile {
  &__tabs {
    display: block;
  }
  &__tab {
    margin-top: 18px;
    box-sizing: border-box;
    display: inline-block;
    padding: 0 15px;
    height: 40px;
    line-height: 40px;
    font-size: 22px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    background-color: transparent;
    color: #218a96;
    text-transform: capitalize;
    &__active {
      background-color: #f0f0f0;
    }
  }
  /*
  .ModalWindow__windowHead {
    margin-bottom:  0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    padding-bottom: 0;
  }
  .ModalWindow__upper {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    min-height: 0;
  }
  .ModalWindow__body {
    padding-top: 0;
  }
  */
  .col-xs-3 {
    -webkit-flex-basis: 25% !important;
    -ms-flex-preferred-size: 25% !important;
    flex-basis: 25% !important;
    max-width: 25% !important;
    padding-left: inherit;
    min-width: auto;
  }
}

.Profile__changePasswordButton.btn_big {
  width: 300px;
}



.ProfileView__changePassword.ProfileView {
  min-height: 300px;
}















