.ErrorWindow {
  .row {
    margin: 0;
    button {
      width: 100%;
    }
  }

  .ModalWindow__background {
    background-color: rgba(255, 0, 0, 0.5);
    z-index: 3000;
  }

  .ModalWindow__main {
    z-index: 9999;
  }
}
