@import '../../styles/utils/variables';

.DataSelector {
  width: 100%;
  user-select: none;
  outline: none;

  &:active {
    outline: none;
  }

  .col-xs-2 {
    flex-basis: 16%;
    min-width: 16%;
  }

  .col-xs-5 {
    padding-left: 2px;
    padding-right: 0px;
    flex-basis: 42%;
    min-width: 42%;
  }

  .col-xs-9 {
    padding-left: 2px;
    padding-right: 0px;
    flex-basis: 42%;
    min-width: 42%;
  }

  &__divButtons {
    width: 100px;
    margin: 0 auto;

    div:first-child {
      margin-left: 20px;
    }
  }

  &__arrowButton1 {
    color: white;
    height: 45px;
    position: relative;
    font-size: 12px;

    &__Text {
      position: absolute;
      top: 50%;
      left: 14px;
      transform: translate(0, -60%);
    }
  }

  &__arrowButton2 {
    color: white;
    height: 45px;
    position: relative;
    font-size: 12px;

    &__Text {
      position: absolute;
      top: 50%;
      left: 18px;
      transform: translate(0, -60%);
    }
  }

  &__arrow {
    height: 45px;
    position: absolute;
  }

  .DataSelectorTable {
    .scrollarea {
      max-height: 300px;
      width: 100% !important;
      //max-width: none;
    }
  }
}
