.EditorDialog_style {
  .ModalWindow__main {
    width: 800px;
  }

  .ModalWindow__body {
    padding: 1px 18px;
    width: 800px !important;
    height: 850px;
    min-height: 150px !important;
    overflow-y: visible; // added because gray scrollbar was appearing inside Add Issue window, when description was filled with long text;
    // as a result, Discard button was shifted to bottom
  }

  .ModalWindow__footer {
    padding-top: 5px;
    width: 800px;
  }
}

.StyleDialog {
  text-align: left;

  .DataEditor__block {
    &_font,
    &_background,
    &_borderLeft,
    &_borderRight,
    &_borderTop,
    &_borderBottom {
      margin-top: 5px;
      margin-bottom: 5px;

      &__value_right {
        width: calc(97% + 0px);
        float: left;
        line-height: 26px;
      }
    }

    &__label {
      display: block;
      float: left;
      padding-right: 10px;
      line-height: 26px;
      width: calc(35% + 20px);
      font-weight: bold;
      font-size: 16px;
    }
  }

  .row {
    width: 100%;
  }

  .col-xs-4 {
    padding-top: 1px;
    flex-basis: 50%;
    min-width: 50%;
  }

  &__exampleOuter {
    width: 200px;
    height: 120px;
  }

  &__example {
  }

  &__name {
    float: left;
    width: 40%;
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 2px;
    overflow: hidden;
  }

  &__border__label {
    display: block;
    float: left;
    padding-right: 20px;
    line-height: 26px;
    width: 25%;
    font-weight: bold;
    font-size: 16px;
  }

  &__border__value {
    position: relative;
    top: 4px;
    left: 20px;
    line-height: 24px;
    float: left;
    width: 60%;
    margin-bottom: 2px;
    overflow: hidden;
  }

  .Radio_container {
    display: inline-block;
  }

  .Input_radio:not(checked) + label {
    position: relative;
    padding: 3px 10px 1px 20px;
  }

  &__border__name {
    float: left;
    width: calc(40% - 15px);
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 2px;
    margin-left: 15px;
    overflow: hidden;
  }

  &__value {
    position: relative;
    top: 1px;
    float: left;
    width: 60%;
    margin-bottom: 2px;
    overflow: hidden;
  }

  .Border__inputLineStyle input {
    width: 100px;
  }

  .Border__inputWidth input {
    //width: 60px;
  }
}

.EditableEntity {
  text-align: left;

  &_color_label,
  &_style_label,
  &_width_label,
  &_font_label {
    display: block;
    float: left;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 3px;
    line-height: 26px;
    width: 30%;
    font-weight: 500;
    font-size: 16px;
  }

  .col-xs-2 {
    padding-top: 1px;
    flex-basis: 30%;
    min-width: 30%;
  }

  .col-xs-12 {
    padding-bottom: 7px;
    padding-right: 10px;
    flex-basis: 100%;
    min-width: 100%;
  }

  .col-xs-5 {
    padding-top: 1px;
    flex-basis: 50%;
    min-width: 50%;
  }

  &_color_value_right {
    width: 60%;
    float: left;
    line-height: 26px;

    input {
      width: 60px;
      margin-right: 5px;
    }
  }

  &_style_value_right,
  &_width_value_right,
  &_font_value_right {
    width: calc(60% - 15px);
    float: left;
    line-height: 26px;
  }

  &_style_value_size,
  &_width_value_size {
    margin-left: 7px;
    width: 5%;
    float: left;
    line-height: 26px;
  }
}
