@import '../../styles/utils/variables';

.EditorDialog_draft {
  .ModalWindow__main {
    width: 800px;
    height: 470px !important;
  }

  .ModalWindow__body {
    padding: 1px 18px;
    width: 800px !important;
    height: 350px;
    min-height: 150px !important;
    overflow-y: visible; // added because gray scrollbar was appearing inside Add Issue window, when description was filled with long text;
    // as a result, Discard button was shifted to bottom
  }

  .ModalWindow__footer {
    padding-top: 5px;
    width: 800px;
  }

  &.EditorDialog_fullscreen {
    .ModalWindow__main {
      width: 100vw !important;
    }

    .ModalWindow__body {
      width: 100vw !important;
    }

    .ModalWindow__footer {
      width: 100vw;
    }
  }
}

.DraftDialog {
  .col-xs-8 {
    padding-left: 5px;
    padding-right: 35px;
    flex-basis: 33.3%;
    min-width: 33.3%;
  }

  &__version {
    width: 180px;
    float: right;

    .col {
      min-width: 50%;
    }
  }

  .row {
    margin-left: 60px;
    margin-top: 1px;
  }

  &__name {
    margin-bottom: 10px;
    margin-right: 10px;
    font-weight: 600;
  }

  &__description {
    margin-top: 10px;
  }

  &__label {
    padding: 6px 18px;
    margin-bottom: 20px;
    font-weight: 600;
  }

  &__text {
    padding: 6px 18px;
    margin-bottom: 20px;

    .TextArea {
      font-size: 16px;

      &::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        color: $gray;
        font-weight: 500;
        font-size: 14px;
      }

      &::-moz-placeholder {
        /* Firefox 19+ */
        color: $gray;
        font-weight: 500;
        font-size: 14px;
      }

      &:-ms-input-placeholder {
        /* IE 10+ */
        color: $gray;
        font-weight: 500;
        font-size: 14px;
      }

      &:-moz-placeholder {
        /* Firefox 18- */
        color: $gray;
        font-weight: 500;
        font-size: 14px;
      }

      input:-ms-input-placeholder {
        color: gray;
        font-weight: 500;
        font-size: 14px;
      }
    }
  }
}

.Versions {
  display: flex;
  width: 200px;
  margin-left: 50px;

  &__current,
  &__new {
    flex: 1;
  }

  &__new {
    display: flex;

    & > * {
      flex: 1;
      margin-right: 10px;
    }
  }
}

.Version__label {
  line-height: 30px;
}
