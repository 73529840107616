@import '../utils/variables';

.SystemBlock {
  position: relative;
  box-sizing: border-box;
  //margin-bottom: 40px;
  height: 60px;
  border: 3px solid $green;

  &__label {
    text-align: center;
    line-height: 56px;
  }

  &__info {
    position: absolute;
    top: 50%;
    right: 5px;
    width: 36px;
    transform: translateY(-50%);
  }

  .ObjectRating__star {
    display: inline-block;
    margin: 11px 4px;
    max-height: 28px;
  }

  .InfoIcon {
    margin: 12px 0 0 0;
    width: 32px;
    height: 32px;
    max-height: 32px;
  }

  .ObjectPicture {
    margin-top: 8px;
    margin-left: 8px;
    width: 38px;
    height: 38px;
    line-height: 36px;
  }
}
