@import '../../styles/utils/variables';

.VersionedObjectReferenceList {
  margin: 0;

  .tabs-navigation {
    box-sizing: border-box;
    padding-left: 20px;
    background-color: $gray-light;
    border-bottom: 2px solid $blue-sea-dark;
  }

  .tabs-menu-item {
    margin-bottom: -4px;

    &.is-active {
      border: 2px solid $blue-sea-dark;
      border-bottom-color: #fff;
    }
  }

  &__buttons {
    .btn {
      margin-right: 20px;
    }
  }

  &__block {
    margin-bottom: 20px;
  }
  &__version {
    &Available {
      color: $blue-dark;
      cursor: pointer;
    }
    &NotAdded {
    }
    &TwoLines {
      height: 50px;
    }
  }
  &__deployed {
    &TwoLines {
      height: 50px;
    }
  }
}

.VersionedObjectReferenceList__deploymentVersion {
  .CheckBox {
    width: auto;
    height: 15px;
  }
  &Prev {
    margin-left: 34px;
  }
}

.VersionedObjectReferenceList {
  .SimpleTable__cell .EditableEntity {
    width: auto;
  }
}

.VersionedObjectReferenceListList {
  text-align: left;

  .ObjectSearch,
  .ObjectSearch__result {
    text-align: left;
  }

  .SimpleTable__headerCell {
    background-color: $blue-deep;
    color: $light;
    font-weight: normal;
    padding: 5px;
  }

  .EditableEntity_string {
    max-width: 100%;
    box-sizing: border-box;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    //height: 31px;
  }

  .EditableEntity .StringData {
    display: inline-block;
  }
}
