.EditorDialog_parameter {
  .ModalWindow__body {
    padding: 1px 18px;
  }

  .FieldView {
    margin-bottom: 0;
    min-height: inherit;
  }

  &__bgBlock,
  .EditorDialog__block {
    padding: 14px;
  }

  .EditorDialog__block {
    label,
    span {
      display: inline-block;
      margin-right: 4px;
      line-height: 26px;
      vertical-align: middle;
    }
  }

  .TextInput {
    display: inline-block;
    margin-left: 0;
    width: 100%;
  }

  .HeaderBadge {
    margin-right: 4px;
    cursor: pointer;

    &_add {
      //width: 14px;
      text-align: center;
      font-size: 200%;
    }
  }
}

.ParameterDialog {
  padding: 0 10px;

  &__block {
    margin-top: 10px;
    height: 80px;
  }

  .col-xs-3 {
    padding-left: 10px;
    padding-top: 40px;
    font-weight: bold;
    font-size: 16px;
    min-width: 10%;
    max-width: 90px;
  }

  .col-xs-9 {
    padding-top: 35px;
    font-size: 16px;
    min-width: 15%;
    max-width: 145px;
  }

  .col-xs-12 {
    padding-top: 38px;
    font-size: 18px;
    min-width: 15%;
    max-width: 145px;
  }
}
