.GenerateParamsDialog {
  .row {
    padding-left: 80px;
  }

  &__label {
    display: block;
    float: left;
    padding-right: 10px;
    line-height: 26px;
    width: 15%;
    font-weight: bold;
    font-size: 16px;
  }

  &__block {
    margin-bottom: 5px;
  }

  &__block_description {
    margin-bottom: 1px;
  }

  &__block_platform {
    margin-bottom: 10px;
  }

  &__block_unit {
    margin-bottom: 16px;
  }

  &__block_value_right {
    width: 150px;
    float: left;
    line-height: 26px;
  }

  label {
    width: 400px;
    float: left;
  }

  .btn_big {
    width: 200px;
    margin-top: 7px;
  }
}
