@import '../../styles/utils/variables';

.UserDialog {
  .ModalWindow__main {
    width: 800px;
    //height: 500px;
  }

  .ModalWindow__body {
    //min-height: 150px !important;
    overflow: visible;
  }

  .ModalWindow__footer {
    width: 800px;
    padding-top: 5px;
  }

  .btn_big {
    width: 170px;
    line-height: 48px;
    font-size: 26px;
    font-weight: 300;
  }

  .row {
    margin-left: 60px;
    margin-top: 20px;
  }

  &__label {
    margin-top: 5px;
    padding: 7px 1px;
    font-size: 18px;
    font-weight: 600;
  }

  &__userSearch {
    padding-top: 25px;
  }

  &__role {
    margin-bottom: 25px;

    .Input_radio:not(checked) + label {
      position: relative;
      padding: 3px 0px 0 34px;
    }
  }

  .DataDialog__propertyName {
    display: inline-block;

    .SimpleTooltip__parent:hover .SimpleTooltip__tooltip {
      display: block;
      width: 200px;
    }
  }

  .DataDialog__propertyNameText {
    display: inline-block;
    width: 170px;

    .MultilineText__text {
      margin-right: 0;
      font-size: 18px;
    }
  }

  .SimpleTooltip__root {
    opacity: 1 !important;
    position: absolute;
    left: 125px;
  }

  .SimpleTooltip__parent {
  }
}
