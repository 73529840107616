@import '../../styles/utils/variables';

.UserSearch {
  &__inputBlock {
    position: relative;
  }

  .UserSearch__inputBlock {
    .UserSearch__TextInput {
      float: none;
      box-sizing: border-box;
      margin-left: 0;
      width: 100%;
      height: 40px;
      line-height: 38px;
    }
  }

  &__suggestions {
    box-sizing: border-box;
    margin-top: -7px;
    padding-top: 5px;
    width: 100%;
    border-right: 1px solid $gray;
    border-bottom: 1px solid $gray;
    border-left: 1px solid $gray;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
  }

  &__suggestionsTitle {
    margin-top: 6px;
    padding-left: 10px;
    font-weight: bold;
  }

  &__suggestionsList {
    margin-top: 4px;
    margin-bottom: 0;
    padding-left: 0;
    list-style: none;
    border-top: 1px solid $gray;
  }

  &__suggestedUser {
    position: relative;
    box-sizing: border-box;
    padding: 4px 6px;
    width: 100%;
    min-height: 48px;
    line-height: 20px;
    border-bottom: 1px solid $gray-light;

    &:last-of-type {
      border-bottom: none;
    }

    &:hover {
      background-color: $blue-light;
    }
  }

  &__suggestedPicture {
    position: absolute;
    top: 4px;
    left: 6px;
    width: 40px;
    height: 40px;
    vertical-align: top;
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: cover;
    border-radius: 100%;
  }

  &__close {
    position: absolute;
    right: 6px;
    top: 50%;
    transform: translateY(-50%);
    height: 32px;
    line-height: 32px;

    a {
      color: $blue-dark;
      font-size: 16px;
      margin-right: 4px;
      margin-top: 4px;
    }

    &:hover {
      font-weight: bold;
    }
  }
}

.UserSearchResult {
  &__content {
    padding-left: 50px;
  }
  &__col1,
  &__col2 {
    float: left;
  }
  &__col1 {
    width: 50%;
  }
  &__col2 {
    width: 50%;
  }
}

.UserSearch__user {
  position: relative;
  height: 48px;
  font-size: 16px;

  &Picture {
    position: absolute;
    left: 6px;
    width: 40px;

    img {
      max-width: 100%;
    }
  }
  &Identity {
    box-sizing: border-box;
    float: left;
    padding-left: 80px;
    width: 50%;
    height: 40px;
  }
  &Name {
    color: $blue-dark;
  }
  &Email {
    box-sizing: border-box;
    width: 50%;
    float: left;
    height: 40px;
  }
  &Change {
    position: absolute;
    right: 6px;
    color: $blue-dark;
    cursor: pointer;

    &:hover {
      font-weight: bold;
    }
  }
}
