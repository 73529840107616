@import '../../styles/utils/variables';

.Table {
  width: 100%;
  text-align: left;
  border-collapse: collapse;
  position: relative;

  &__edit-control {
    padding-bottom: 2px;
    text-align: right;
    &:hover {
      font-weight: bold;
    }
  }

  &__add {
    margin-top: 20px;
    margin-bottom: 40px;
    .btn_edit {
      margin-right: 30px;
      min-width: 110px;
      &:hover {
        font-weight: bold;
      }
    }
    .btn_save {
      color: $red;
    }

    .error {
      color: $red;
    }
  }

  .react-contextmenu-wrapper:nth-child(10n) .Table__row {
    border-bottom: 1px solid $gray-dark;
  }

  &__row {
    //display: table;
    width: 100%;
    min-height: 49px;
    border-bottom: 1px solid $gray;

    position: relative;

    .KascodeSelect__list {
      position: relative;
    }

    &_hidden {
      display: none;
    }
  }

  &__row {
    overflow: hidden;
    &:hover {
      background-color: #edf9fa;

      .Table__floatingButtonsInner {
        display: block;
      }
      .Table__floatingButtonsOuter {
        //  display: block;
      }
    }

    &_nobg {
      &:hover {
        background-color: #fff;
      }
    }
  }
  .Table__row.Table__expandRow:hover {
    background-color: transparent;
  }

  &__expandableRow {
    position: relative;
    padding-bottom: 20px;

    &_active,
    &_last {
      border-bottom: 0;
    }

    .MajorObjectBlock {
      display: inline;
      & .row {
        display: inline;
      }
      .ObjectPicture {
        display: inline-block;
      }
    }
    .MajorObjectBlock__label {
      display: inline;
    }
    .TableRefExpander {
      display: inline;
      float: left;
    }
  }

  &__expandRow {
    & > .TableCell {
      padding: 0;
    }

    tbody:last-child > tr {
      border-bottom: 0;
    }
  }

  &__expandReferenceRow {
    height: 44px;
    border-bottom: 0 !important;
  }

  thead,
  tbody {
    float: left;
    width: 100%;
  }

  td {
    padding-top: 6px;
    padding-right: 12px;
  }

  thead {
    font-size: 16px;
    text-transform: capitalize;
    text-align: left;
  }

  .TableHeaderCell {
    background-color: $yellow;
  }

  thead {
    .Table__filters {
      height: 60px;
      background-color: transparent;

      th {
        padding: 0 1px;
      }

      .KascodeSelect {
        margin-left: -2px;
      }
    }
  }

  &_dataset,
  &_interface {
    .TableHeaderCell {
      background-color: $blue-deep;
      color: $light;
    }
  }
  &_organization,
  &_issue {
    .TableHeaderCell {
      background-color: $turquoise;
      color: $light;
    }
  }
  &_layout {
    .TableHeaderCell {
      background-color: $blue-deep;
      color: $light;
    }
  }

  &_publication {
    .TableHeaderCell {
      background-color: $violet;
      color: $light;
    }
    .StringData.DatasetName {
      color: #218a96;
    }
  }

  &_subscription {
    .TableHeaderCell {
      background-color: $red-darker;
      color: $light;
    }
    .StringData.DatasetName {
      color: #218a96;
    }
  }

  &_system {
    .TableHeaderCell {
      background-color: $green;
      color: $light;
    }
  }

  tbody {
    // commented to remove scroll in rows in Organization List
    //overflow: auto;
  }

  &__deleteButton {
    position: absolute;
    margin-left: -24px;
    img {
      height: 14px;
    }
  }
  &__floatingButtonsOuterFull {
    .Table__floatingButtonsInner {
      //width: 70px !important;
    }
  }
  &__floatingButtonsOuter {
    width: 0 !important;
    padding: 0 !important;
    //position: relative;
    overflow: visible;
    vertical-align: top;
    height: 100%;
    //display: none;
    position: absolute;
    left: 100%;
  }

  &__floatingButtons {
    position: relative;
    height: 100%;

    &Inner {
      z-index: 100;
      display: none;
      position: absolute;
      left: calc(100% - 160px);
      top: 0;
      width: 160px;
      height: 100%;
      border-left: 0;
      background-color: $blue-light;
      text-align: right;

      &_3 {
        left: calc(100% - 220px);
        width: 220px;
      }

      &_closed {
        left: calc(100% - 10px);
        width: 10px;

        span a {
          display: none;
        }
      }

      &:before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 0;
        border-right: 1px solid $blue;
      }

      &:after {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        left: -1px;
        height: 6px;
        width: 6px;
        border-top: 1px solid $blue;
        border-left: 1px solid $blue;
        background-color: $blue-light;
        transform: rotate(-45deg) translateY(-60%);
      }
    }

    span {
      display: inline-block;
      height: 100%;
      &:first-child {
        margin-bottom: 1px;
      }
    }

    //vertical-align: middle;
    a {
      display: inline-block;
      position: relative;
      top: 50%;
      margin-right: 14px;
      padding: 0;
      height: 30px;
      transform: translateY(-50%);
      line-height: 30px;
      color: $blue-dark;

      &:hover {
        //background-color: $blue-link-hover;
        font-weight: bold;
      }

      img {
        margin-right: 5px;
        height: 12px;
      }
    }
  }

  .Table__row:hover > .Table__floatingButtons {
    //display: flex;
    $var1: 100%;
    $var2: 1px;
    height: unquote(
      'calc( #{$var1} - #{$var2} )'
    ); // this is sass hack for "height: calc ( 100% - 1px ); "
    // -1px is needed for Edge, otherwise the box overlaps bottom border of table row
  }

  .ObjectPicture {
    width: 30px;
    height: 30px;
    line-height: 26px;
    margin-left: 4px;
    margin-top: 2px;
    &__nameUpper {
      line-height: 30px;
    }
  }

  &__customFilterCell {
    display: inline-block;
    box-sizing: border-box;
    padding: 0 2px;
  }
}

.TableHeaderCell {
  font-weight: normal;

  &.TableHeaderCell_key {
    background-color: $turquoise;
    border-right: 3px solid $light;
    text-align: center;
    border-radius: 3px;
    &:hover {
      background-color: #3dc2d1;
    }
  }
}

.TableCell {
  position: relative;
  vertical-align: middle;

  &_key {
    text-align: center;
  }

  .ModalWindow__title {
    text-transform: capitalize;
  }

  &_name {
    .MajorObjectLink {
      color: $blue-dark;
    }
  }
}

.TableCell_subscriptions,
td[data-column='subscriptions'] {
  text-align: center;

  span {
    display: inline-block;
    width: 18px;
    line-height: 18px;
    background-color: $green;
    border-radius: 50%;
    color: $light;

    &.zero {
      background-color: $gray;
    }
  }
}

.TableRefExpander {
  position: relative;
  display: inline-block;
  margin-top: -10px;

  &_active {
    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 18px;
      left: 9px;
      width: 31px;
      height: 38px;
      border-left: 1px solid $gray;
      border-bottom: 1px solid $gray;
      z-index: 900;
    }
  }

  &__btn {
    margin-bottom: 5px;
    box-sizing: border-box;
    width: 18px;
    height: 18px;
    background-color: $gray-light;
    color: $blue;
    line-height: 16px;
    font-weight: bold;
    text-align: center;
    border-radius: 3px;
    border: 1px solid;
    cursor: pointer;
  }
}

.TableCell.TableCell_expandButton {
  padding-left: 0;
  padding-right: 0;
}

th a.Table__clearFilter {
  //background-color: red;
  font-weight: normal !important;
  &:hover {
    font-weight: bold !important;
  }
}

.Table__expandableRowLoader {
  overflow-x: hidden;
  .Loader {
    margin-left: 20px;
    margin-top: 5px;
    margin-bottom: 5px;
    transform: scale(0.5);

    .Loader__picture {
      width: 100px;
    }
  }
}

.TableHeaderCellExpander {
  float: right;
  width: 22px;
}

.Table__container {
  .react-contextmenu {
    z-index: 5500;
    min-width: 160px;
    padding: 5px 0;
    margin: 2px 0 0;
    font-size: 16px;
    color: $blue-sea-dark;
    text-align: left;
    background-color: $gray-light;
    background-clip: padding-box;
    border: 1px solid $gray-dark;
    border-radius: 0;
    outline: none;
    opacity: 0;
    pointer-events: none;
  }

  .react-contextmenu.react-contextmenu--visible {
    opacity: 1;
    pointer-events: auto;
  }

  .react-contextmenu-item {
    padding: 3px 20px;
    font-weight: 400;
    line-height: 1.5;
    color: $blue-sea-dark;
    text-align: inherit;
    white-space: nowrap;
    background: 0 0;
    border: 0;
    cursor: pointer;
  }

  .react-contextmenu-item.react-contextmenu-item--active,
  .react-contextmenu-item:hover {
    //color: #fff;
    background-color: #e0f5f8;
    //border-color: #20a0ff;
    text-decoration: none;
  }

  .react-contextmenu-item.react-contextmenu-item--disabled,
  .react-contextmenu-item.react-contextmenu-item--disabled:hover {
    color: #878a8c;
    background-color: transparent;
    border-color: rgba(0, 0, 0, 0.15);
  }

  .react-contextmenu-item--divider {
    margin-bottom: 3px;
    padding: 2px 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    cursor: inherit;
  }
  .react-contextmenu-item--divider:hover {
    background-color: transparent;
    border-color: rgba(0, 0, 0, 0.15);
  }

  .react-contextmenu-item.react-contextmenu-submenu {
    padding: 0;
  }

  .react-contextmenu-item.react-contextmenu-submenu > .react-contextmenu-item {
  }

  .react-contextmenu-item.react-contextmenu-submenu
    > .react-contextmenu-item:after {
    content: '▶';
    display: inline-block;
    position: absolute;
    right: 7px;
  }

  .example-multiple-targets::after {
    content: attr(data-count);
    display: block;
  }
}

.Table__deleteModal {
  /*
  .ModalWindow__body, .ModalWindow__upper, .ModalWindow__main, .ModalWindow__footer {
    width: 400px;
  }
  .ModalWindow__body, .ModalWindow__upper, .ModalWindow__footer {
    border: 2px solid red;
  }
  .ModalWindow__upper {
    border-bottom: none;
  }
  .ModalWindow__footer {
    border-top: none;
    width: 396px;
  }
  .ModalWindow__body {
    border-bottom: none;
    border-top: none;

  }
  .btn_big {
    padding: 0 10px;
    margin: 0 10px;
    width: 140px !important;
  }
  */
  .OrganizationList__deleteMessage {
    text-align: center;
  }
  p {
    //float: left;
    display: inline-block;
  }
  p img {
    height: 30px !important;
    margin-right: 15px;
    vertical-align: middle;
  }
}

.Table__typeReference a {
  color: $blue-dark;
}

.TableCell {
  .MultilineText__text {
    text-align: left;
    text-align-last: left !important;
  }
}
