@import '../../styles/utils/variables';

.DocumentationView {
  margin-top: 12px;

  .MajorObject__expandStructureButton,
  .StructureTable__expandButton {
    display: none;
  }

  .DocumentationView__body.row .col-xs-3 {
    padding-top: 10px;
  }

  .DocumentationView__body.row .col-xs-9 {
    border-left: 1px solid $gray;
    padding: 0;
  }

  &__objectName {
    //border: 2px solid $blue-deep;
    padding: 4px;
    padding-left: 0;
    margin-left: -4px;
    font-size: 16px;
    font-weight: bold;
  }
  &__objectDescription {
    margin-left: 2px;
  }

  h3 {
    font-weight: bold;
  }

  &__tree {
    position: fixed;
    height: 100%;
    width: 280px;
    overflow-y: auto;
    color: $blue;
  }
  &__tree_system {
  }
  &__tree_system > a {
    color: $blue-sea-dark;
    font-size: 16px;
    font-weight: bold;
  }
  &__tree_application {
    margin-left: 25px;
  }
  &__tree_application > a {
    color: $blue-sea-dark;
    font-size: 16px;
    font-weight: bold;
  }
  &__tree_dataset {
    margin-left: 25px;
  }
  &__tree_dataset > a {
    color: $blue-sea-dark;
    font-weight: bold;
  }
  &__download {
    height: 30px;
    a {
      color: $blue-sea-dark;
      position: fixed;
      border: 1px solid $blue;
      padding: 3px 12px;
      img {
        height: 13px;
        margin-left: 6px;
        position: relative;
        top: 1px;
      }
    }
  }

  .ObjectPicture {
    display: inline-block;
    vertical-align: middle;
    margin-right: 8px;
    width: 32px !important;
    height: 32px !important;
  }
  &__datasetDataHeader {
    font-size: 16px;
    font-weight: bold;
    padding-top: 15px;
    padding-bottom: 5px;
  }

  &__operation {
    margin-bottom: 20px;
    &Name {
      display: inline-block;
      margin-bottom: 5px;
    }
  }

  &__headerSmallest {
    font-size: 16px;
    font-weight: bold;
    padding: 20px 0;
  }

  &__blockHeader {
    width: 230px;
    height: 20px;
    margin-top: 30px;
    padding: 0px 10px;
    text-transform: uppercase;
    font-size: 16px;
    letter-spacing: 1px;
    position: relative;
    box-sizing: border-box;
    &:after {
      background-color: transparent;
      content: '';
      position: absolute;
      width: 0;
      height: 0;
      right: 0;
      top: 0;
      border-style: solid;
      border-width: 0 20px 20px 0;
      border-color: transparent white transparent transparent;
    }
  }

  &__systemBlock {
    .DocumentationView__blockHeader {
      background-color: $green-dark;
      color: $light;
      margin-bottom: 5px;
    }
  }

  &__applicationBlock {
    .DocumentationView__blockHeader {
      background-color: $yellow;
      color: $gray-dark;
      margin-bottom: 5px;
    }
  }

  &__datasetsBlock,
  &__interfacesBlock {
    .DocumentationView__blockHeader {
      background-color: $blue-deep;
      color: $light;
    }
  }

  &__datasetBlock,
  &__interfaceBlock {
    border-bottom: 1px solid $blue-deep;
    //margin-top: 20px;
    padding-bottom: 30px;
    margin-bottom: 10px;
    padding-left: 10px;
  }
}
