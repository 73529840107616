@import '../../styles/utils/variables';

.OrganizationList {
  .SimpleTable__headerCell {
    background-color: $blue;
    color: $light;
    font-weight: normal;
    padding-bottom: 3px;
  }

  &__upper {
    background-color: $blue;
    color: white;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 6px;
    font-size: 16px;
    height: 20px !important;
    position: fixed;
    width: 100%;
    left: 0;
    top: 105px;
    z-index: 100;
  }

  &__content {
    padding: 58px 0;
  }

  &__buttonRow {
    margin-top: 30px;
    //width: 100%;
    background-color: $blue;
    border-top: 6px solid $turquoise-dark;
    padding: 24px 15px;
    text-align: center;
    border-bottom: 6px solid #238a96;

    button {
      background-color: #238a96;
      padding: 10px 40px;
      border: 0;
      border-radius: 4px;
      color: white;
      font-size: 18px;
      margin: 4px 15px;
    }
  }

  &__subscriptionStatusOuter {
    position: relative;
    width: 150px;
    //height: 40px;

    .col:first-child {
      width: 120px;
    }
  }

  &__subscriptionStatus {
    color: $blue;
    display: block;
    font-size: 16px;
    //line-height: 24px;
    height: auto !important;
  }

  &__subscriptionDaysRemaining {
    display: block !important;
    height: auto !important;
    color: black;
    font-size: 12px;
  }

  &__subscriptionStatusBlock {
    position: absolute;
    top: 50%;
    margin-top: -11px;
    float: right;
    display: inline-block;
    width: 24px;
    height: 22px;
    color: white;
    border-radius: 2px;
    text-align: center;
    font-weight: bold;
    font-size: 20px;
    padding-top: 2px;
    &.green {
      background-color: #92c850;
    }
    &.yellow {
      background-color: #ffc107;
    }
    &.red {
      background-color: #f1592a;
    }
  }

  &__modal {
    .ModalWindow__body {
      padding: 0;
    }
    .ModalWindow__upper {
      padding: 0;
    }
    .row {
      width: 100%;
      flex: none;
      padding: 4px 10px;
      //padding: 12px 10px;
      //border-bottom: 1px solid #cbcbcb;
    }

    textarea {
      height: auto;
    }
  }

  &__subscriptionBlock {
    label {
      color: $blue;
    }
    &__active {
      label {
        font-weight: bold;
      }
    }
  }

  &__acceptRow {
    text-align: left;
  }

  &__innerRow.row {
    border: 0;
  }

  &__subscriptionCost {
    margin-top: 10px;
  }

  &__subscriptionDescription {
    margin-top: 12px;
    font-size: 12px;
  }

  &__error {
    color: red;
    text-transform: capitalize;
  }

  .Table__deleteButton {
    margin-top: 10px;
    margin-left: -8px;
    img {
      height: 20px;
    }
  }

  &__defaultOrganizationName {
    font-weight: bold;
  }

  .SimpleTable__headerCell_isDefault {
    //text-align: center;
  }

  .SimpleTable__headerCell_Role,
  .SimpleTable__cell_Role,
  .SimpleTable__headerCell_Status,
  .SimpleTable__cell_Status,
  .SimpleTable__headerCell_Default,
  .SimpleTable__cell_Default {
    //text-align: center;
    .CheckBox {
      margin-left: 0px;
    }
    .EditableEntity_data {
      //text-align: center;
    }
  }

  .SimpleTable__cell_4 {
    padding-left: 20px;
  }

  .Table__row {
    min-height: 60px;
  }

  .ObjectPicture {
    display: inline-block;
    margin-right: 10px;
    vertical-align: top;
    &__picture {
      width: 28px;
      height: 28px;
    }
  }

  .currentOrgLabel {
    //margin-left: 35px;
  }

  .btn_edit,
  .btn_edit:focus {
    outline: none;
  }
  button,
  button:focus {
    outline: none;
  }
  &__modal {
    .btn_edit,
    .btn_edit:focus {
      outline: none;
    }
    button,
    button:focus {
      outline: none;
    }
  }

  &__additionalButtons {
    display: inline-block;
    button.btn_edit {
      display: inline-block;
      min-width: 140px;
    }
  }

  &__statusPending {
    color: $red;
  }

  &__statusArchitect {
    color: $blue-dark;
  }

  &__deleteModal {
    .ModalWindow__body,
    .ModalWindow__upper,
    .ModalWindow__footer,
    .ModalWindow__windowHead,
    .ModalWindow__main {
      width: 600px;
    }

    .ScrollArea {
      width: 620px !important;

      &__container {
        max-width: 600px;
        width: calc(100% - 20px);
      }
    }

    .ModalWindow__body {
      padding-top: 0;
      min-height: 120px;
      font-size: 18px;
    }
    .ModalWindow__windowHead {
      background-color: $orange-red;
      color: white;
    }

    .ModalWindow__footer {
      padding-top: 30px;
      padding-bottom: 30px;
      border-top: 1px solid $gray;
    }
    .btn_big {
      width: 170px;
    }
  }

  &__deleteMessage {
    border: 1px solid $gray;
    border-radius: 5px;
    padding: 15px 0;
    text-align: center;
    p {
      img {
        height: 55px;
      }
    }
  }

  .loader_center {
    position: relative;
    margin: 10px 0;
    width: 100%;
    height: 20px;

    .Loader {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .Table__floatingButtons {
    //min-width: 45px;
  }

  &__departmentIcon {
    &Department {
      &:after {
        content: '';
        display: block;
        position: absolute;
        top: -22px;
        left: 14px;
        width: 30px;
        height: 48px;
        border-left: 1px solid $gray;
        border-bottom: 1px solid $gray;
        z-index: 100;
      }
      &:before {
        content: '';
        display: block;
        position: absolute;
        background-color: white;
        top: -22px;
        left: -8px;
        width: 34px;
        height: 48px;
        z-index: 50;
      }
    }

    &Parent {
      position: absolute;
      margin-bottom: 5px;
      box-sizing: border-box;
      width: 18px;
      height: 18px;
      background-color: $gray-light;
      color: $blue;
      line-height: 16px;
      font-weight: bold;
      text-align: center;
      border-radius: 3px;
      border: 1px solid;
      cursor: pointer;
      margin-top: -10px;
      z-index: 200;
    }
    &None {
    }
  }

  &__name {
    &_department {
      display: inline-block;
      margin-left: 20px;
    }
  }

  .TypedTable__bottom {
    z-index: 100;
    // position: absolute;
  }

  .StructureTable__expandButton_active:after {
    height: 42px;
  }
}

.SubscriptionType {
  border: 1px solid $gray;
  color: #3d3d3d;
  margin: 0 2% 0 0;
  flex-basis: 23.5% !important;
  max-width: 23.5% !important;

  &:last-child {
    margin-right: 0;
  }

  &__title {
    padding: 10px 0;
    border-bottom: 1px solid $gray;
    text-transform: uppercase;
    font-size: 24px;
    text-align: center;
  }

  &__price {
    padding: 15px 0;
    height: 25px;
    text-align: center;
    color: $blue-dark;
    font-size: 26px;
    border-bottom: 1px solid $gray;

    &Per {
      font-size: 14px;
      color: $gray-dark;
    }
  }

  &__details {
    padding: 15px 0;
    //min-height: 100px;
    border-bottom: 1px solid $gray;
  }
  &__detail {
    img {
      margin-right: 10px;
      margin-bottom: 4px;
      width: 20px;
    }
    span {
      line-height: 20px;
      vertical-align: top;
      font-size: 16px;
    }
  }

  &__control {
    padding: 10px 0;

    .btn {
      margin: 0;
      padding: 0;
      width: 100%;
      height: 40px;
      line-height: 40px;
      font-size: 16px;
    }
  }

  &__selected {
    width: 24px;
  }

  &__unselected {
    display: inline-block;
    width: 20px;
    height: 16px;
  }
}

.OrganizationList__nameColumnLabel {
  padding-left: 40px;
}

.OrganizationList__table {
  position: relative;
}

/*
.OrganizationList__table::before {
  content: '';
  background: white;
  position: absolute;
  z-index: 50;
  width: 40px;
  height: 100%;
}

*/
