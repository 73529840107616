.EditorDialog_operation {
  .ModalWindow__main {
    width: 800px;
    height: 700px;
  }

  .ModalWindow__body {
    padding: 1px 18px;
    width: 800px;
    min-height: 150px !important;
  }
  .ModalWindow__footer {
    padding-top: 5px;
    width: 800px;
  }
}

.OperationDialog {
  &__label {
    display: block;
    float: left;
    padding-right: 10px;
    line-height: 26px;
    width: 15%;
    font-weight: bold;
    font-size: 16px;
  }

  .LocaleSelect_classic {
    margin-top: 4px;
    height: 19px;
    padding: 4px;
    border: 1px solid;
    border-radius: 4px;

    .LocaleSelect__value {
      line-height: 19px;
    }

    .LocaleSelect__toggle {
      top: 2px;
      right: 4px;

      &_rotated {
        top: 12px;
      }
    }
  }
}
