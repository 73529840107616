@import '../utils/variables';


.Loader {

  $opacity_small: 0.45;



  &__picture {
    @keyframes move {
      /*
      0% {
        transform: scale(0.5);
      }
      50% {
        transform: scale(0.5);
      }
      75% {
        transform: scale(1.0);
      }
      100% {
        transform: scale(0.5);
      }
      */
      0% {
        transform: scale(0.5);
        opacity: $opacity_small;
      }
      12% {
        transform: scale(0.5);
        opacity: $opacity_small;
      }
      25% {
        transform: scale(0.5);
        opacity: $opacity_small;
      }
      37% {
        transform: scale(0.5);
        opacity: $opacity_small;
      }
      50% {
        transform: scale(0.5);
        opacity: $opacity_small;
      }
/*      62% {
        transform: scale(0.75);
      }
      */
      75% {
        transform: scale(1.0);
        opacity: 1.0;
      }
/*      82% {
        transform: scale(0.75);
      }
      */
      100% {
        transform: scale(0.5);
        opacity: $opacity_small;
      }
    }

    $period: 2s;

    .ball-cell {
      width: 20px;
      height: 20px;
      display: inline-block;
    }
    .ball {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      display: inline-block;
      animation: move $period infinite ease-in-out; // cubic-bezier(0.4, 0, 1, 1);
      animation-direction: normal;
      text-align: center;
      vertical-align: middle;
      transform: translate3d(0,0,0);
      -webkit-transform: translate3d(0,0,0);

      background-color: #81D7E0;
    }
    .ball-cell:nth-child(1) .ball {
      //background-color: #6CD2DC;
      animation-delay: -5*$period/8;
    }
    .ball-cell:nth-child(2) .ball  {
      //background-color: #449D61;
      animation-delay: -4*$period/8;
    }
    .ball-cell:nth-child(3) .ball  {
      //background-color: #FFC107;
      animation-delay: -3*$period/8;
    }
    .ball-cell:nth-child(4) .ball  {
      //background-color: #8F70CB;
      animation-delay: -2*$period/8;
    }
    .ball-cell:nth-child(5) .ball  {
      //background-color: #EF5074;
      animation-delay: -$period/8;
    }
    //z-index: 2000;
    width: 100px;
    height: 20px;
    position: relative;

    /*
    left: 50%;
    margin-left: -85px;
    */

  }

  &__background {
   /* position: absolute;
    background-color: white;
    right: 0;
    bottom: 0;
    left: 0;
    top: 0;
    opacity: 0.5;
    z-index: 1000;
  */
  }



}
