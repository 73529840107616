@import '../../styles/utils/variables';

.EditorDialog.EditorDialog_pipeline_chart {
  .ModalWindow__body,
  .ModalWindow__main,
  .ModalWindow__upper,
  .ModalWindow__footer {
    width: 1150px !important;
  }

  .ScrollArea {
    width: 1170px;

    &__container {
      max-width: 1150px;
      width: calc(100% - 20px);
    }
  }

  div.table {
    max-height: 400px;
    overflow-y: auto;
  }
}

.EditorDialog.EditorDialog_pipeline_chart_fullscreen {
  .ModalWindow__body,
  .ModalWindow__main,
  .ModalWindow__upper,
  .ModalWindow__footer {
    width: 100vw !important;
    max-width: none;
  }
  .ModalWindow__main {
    max-height: none;
    height: 100%;
  }
  .scrollarea {
    background-color: white;
  }
  .ModalWindow__body {
    //padding-left: 0;
    //padding-right: 0;
    padding-top: 0;
    padding-bottom: 0;
    box-sizing: content-box;
  }
  .ModalWindow__windowHead {
    margin-bottom: 0;
    border-radius: 0;
    border-bottom: 1px solid $gray;
  }
  .ModalWindow__upper {
    border-radius: 0;
  }
  .scrollarea {
    width: 100vw !important;
    max-width: none;
  }
  .ModalWindow__upper.ModalWindow__flex00.ModalWindow__upper_empty {
    display: none;
  }
}

.TransformationChart {
  &__block {
    margin-bottom: 5px;
  }

  &__label {
    display: block;
    float: left;
    padding-right: 10px;
    line-height: 26px;
    width: 15%;
    font-weight: bold;
    font-size: 16px;
  }

  &__name {
    float: left;
    width: 40%;
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 2px;
    overflow: hidden;
  }

  &__value {
    position: relative;
    top: 1px;
    float: left;
    width: 60%;
    margin-bottom: 2px;
    overflow: hidden;
  }

  &__outer {
    display: block;

    svg {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
    }
  }

  &__rows {
    width: 100%;
  }

  &__row {
    height: 150px;
  }

  &__rowBody {
    position: relative;
    border: 2px solid $blue-dark;
    border-radius: 5px;
    background-color: white;
    padding: 4px;
    text-align: center;
    height: 40px;
    z-index: 100;

    &__empty {
      border-color: white;
      height: 50px;
    }

    &__active {
      border-color: $blue;
    }
  }

  &__arrow {
    position: absolute;
    z-index: 50;
    border-left: 2px solid $blue;
    text-align: left;
    margin-left: 15px;
    padding-left: 5px;
    width: 150px;
    line-height: 100px;

    &Down {
      &:before {
        content: '';
        width: 0;
        height: 0;
        position: absolute;
        border-style: solid;
        border-width: 0 9px 9px 9px;
        border-color: transparent transparent $blue transparent;
        top: 0;
        left: -10px;
      }
    }

    &Up {
      &:before {
        content: '';
        width: 0;
        height: 0;
        position: absolute;
        border-style: solid;
        border-width: 9px 9px 0 9px;
        border-color: $blue transparent transparent transparent;
        bottom: 0;
        left: -10px;
      }
    }
  }

  &__active {
    border-left: 2px solid $blue-deep;
  }

  &__footer {
    text-align: left;
    padding-left: 10px;
  }
}

.EditorDialog_pipeline_chart {
  div.table table td:first-child .title {
    //display: none !important;
  }
  .SQLDesigner__root .table:after {
    // display: none;
  }

  .SQLDesigner__root .table_header {
    display: inline;
  }

  .react-contextmenu {
    z-index: 2500;
    min-width: 160px;
    padding: 5px 0;
    margin: 2px 0 0;
    font-size: 16px;
    color: #373a3c;
    text-align: left;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 0.25rem;
    outline: none;
    opacity: 0;
    pointer-events: none;
  }

  .react-contextmenu.react-contextmenu--visible {
    opacity: 1;
    pointer-events: auto;
  }

  .react-contextmenu-item {
    padding: 3px 20px;
    font-weight: 400;
    line-height: 1.5;
    color: #373a3c;
    text-align: inherit;
    white-space: nowrap;
    background: 0 0;
    border: 0;
    cursor: pointer;
  }

  .react-contextmenu-item.react-contextmenu-item--active,
  .react-contextmenu-item:hover {
    color: #fff;
    background-color: #20a0ff;
    border-color: #20a0ff;
    text-decoration: none;
  }

  .react-contextmenu-item.react-contextmenu-item--disabled,
  .react-contextmenu-item.react-contextmenu-item--disabled:hover {
    color: #878a8c;
    background-color: transparent;
    border-color: rgba(0, 0, 0, 0.15);
  }

  .react-contextmenu-item--divider {
    margin-bottom: 3px;
    padding: 2px 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    cursor: inherit;
  }
  .react-contextmenu-item--divider:hover {
    background-color: transparent;
    border-color: rgba(0, 0, 0, 0.15);
  }

  .react-contextmenu-item.react-contextmenu-submenu {
    padding: 0;
  }

  .react-contextmenu-item.react-contextmenu-submenu > .react-contextmenu-item {
  }

  .react-contextmenu-item.react-contextmenu-submenu
    > .react-contextmenu-item:after {
    content: '▶';
    display: inline-block;
    position: absolute;
    right: 7px;
  }

  .example-multiple-targets::after {
    content: attr(data-count);
    display: block;
  }

  &.SQLDesigner__smallModal {
    .ModalWindow__main {
      min-height: 200px;
      height: 250px;

      width: 600px;
    }
  }
}
