@import '../../styles/utils/variables';

.LayoutInstanceList {
  margin: 0;

  .tabs-navigation {
    box-sizing: border-box;
    padding-left: 20px;
    background-color: $gray-light;
    border-bottom: 2px solid $blue-sea-dark;
  }

  .tabs-menu-item {
    margin-bottom: -4px;

    &.is-active {
      border: 2px solid $blue-sea-dark;
      border-bottom-color: #fff;
    }
  }

  &__buttons {
    .btn {
      margin-right: 20px;
    }
  }

  &__block {
    margin-bottom: 20px;
  }

  &__version {
    .SimpleTable__cell .EditableEntity {
      width: 200px;
      background-color: red;
    }

    &Available {
      color: $blue-dark;
      cursor: pointer;
    }

    &NotAdded {
      margin-top: 5px;
    }

    &TwoLines {
      margin-top: 1px;
      height: 50px;
    }
  }

  &__deployed {
    &TwoLines {
      height: 50px;
    }
  }
}
