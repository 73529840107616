@import '../../styles/utils/variables';

.EditorDialog.EditorDialog_deployment_chart {
  .ModalWindow__body,
  .ModalWindow__main,
  .ModalWindow__upper,
  .ModalWindow__footer {
    width: 1150px !important;
  }
  .scrollarea {
    width: 1170px;
  }

  .ScrollArea {
    width: 1170px;

    &__container {
      max-width: 1150px;
      width: calc(100% - 20px);
    }
  }

  div.table {
    max-height: 400px;
    overflow-y: auto;
  }
}

.EditorDialog.EditorDialog_deployment_chart_fullscreen {
  .ModalWindow__body,
  .ModalWindow__main,
  .ModalWindow__upper,
  .ModalWindow__footer {
    width: 100vw !important;
    max-width: none;
  }
  .ModalWindow__main {
    max-height: none;
    height: 100%;
  }
  .scrollarea {
    background-color: white;
  }
  .ModalWindow__body {
    //padding-left: 0;
    //padding-right: 0;
    padding-top: 0;
    padding-bottom: 0;
    box-sizing: content-box;
  }
  .ModalWindow__windowHead {
    margin-bottom: 0;
    border-radius: 0;
    border-bottom: 1px solid $gray;
  }
  .ModalWindow__upper {
    border-radius: 0;
  }
  .scrollarea {
    width: 100vw !important;
    max-width: none;
  }
  .ModalWindow__upper.ModalWindow__flex00.ModalWindow__upper_empty {
    display: none;
  }
}

.EditorDialog_deployment_chart__additionalButtons {
  height: 20px;
  margin-bottom: 20px;
  margin-top: 4px;
}

.EditorDialog_deployment_chart__headerControlPdf {
  display: inline-block;
  //  float: right;

  position: absolute;
  right: 25px;
  //margin-top: -20px;
  font-size: 18px;
  cursor: pointer;

  a {
    color: $blue-dark;
  }
}
.EditorDialog_deployment_chart__headerControlAdditional {
  color: $blue-dark;
  display: inline-block;
  //  float: right;
  margin-right: 60px;
  position: absolute;
  right: 55px;
  //margin-top: -20px;
  font-size: 18px;
  cursor: pointer;

  img {
    margin-right: 4px;
    margin-top: -4px;
    vertical-align: middle;
    width: 16px;
    height: 17px;
  }
}

.deploymentChart__instance {
  min-height: 40px;
  border: 3px solid $yellow;
  border-radius: 8px;
  padding: 4px;

  &__selected {
    border-color: $yellow-dark;
  }
}
.deploymentChart__header {
  color: white;
  font-size: 16px;
  background-color: $yellow;
  padding: 4px;
  margin-bottom: 6px;
  border-radius: 4px;
}

.deploymentChart__label {
  color: $blue;
}

.deploymentChart__filters.row {
  margin-bottom: 20px !important;
}

.DeploymentChart {
  &__deploymentRow {
    display: block;
  }
  &__deploymentBlock {
    width: 200px;
    margin: 0 auto;
    border: 2px solid $green;
    border-radius: 8px;
    padding: 6px;
    text-align: center;
    font-weight: bold;
  }
  &__packageRow {
    display: block;
    clear: both;
    margin-top: 20px;
    &Clear {
      clear: both;
    }
  }
  &__packageBlock {
    border: 2px solid $green;
    border-radius: 8px;
    padding: 6px;
    width: 200px;
    float: left;
    margin-right: 20px;
    &__selected {
      border-color: $blue-dark;
    }
    &Title {
      background-color: $green;
      color: $light;
      text-align: center;
      font-weight: bold;
    }
    &Body {
    }
  }
  &__packageApplicationRow {
    border-bottom: 1px solid $blue;
    cursor: pointer;
    &__selected {
      //border-color: $violet;
      font-weight: bold;
    }
  }
  &__applicationRow {
    margin-top: 20px;
    display: block;
    clear: both;
    &Package__empty {
      margin-top: 20px;
      width: 200px;
      height: 20px;
    }
    &Package__full {
      width: 200px;
    }
  }
  &__applicationBlock {
    border: 2px solid $violet;
    border-radius: 8px;
    padding: 6px;
    width: 200px;
    &Title {
      background-color: $violet;
      color: $light;
      text-align: center;
      font-weight: bold;
    }
  }
}
