@import '../../styles/utils/variables';

.EditorDialog_deploymentReference {
  .ModalWindow__main {
    width: 800px;
  }

  .ModalWindow__body {
    padding: 1px 18px;
    // width: 800px !important;
    height: 450px;
    min-height: 150px;
    overflow-y: visible; // added because gray scrollbar was appearing inside Add Issue window, when description was filled with long text;
    // as a result, Discard button was shifted to bottom
  }

  .ModalWindow__footer {
    padding-top: 5px;
    width: 800px;
  }
}

.DeploymentDialog {
  .DataEditor__block {
    &_packages {
      margin-top: 5px;
      margin-bottom: 5px;

      &__value_right {
        width: calc(82% - 20px);
        float: left;
        line-height: 26px;
        font-size: 16px !important;

        &__warning {
          color: red;
          margin-bottom: 5px;
          font-size: 14px;
        }
      }
    }

    &__label {
      display: block;
      float: left;
      padding-top: 5px;
      padding-right: 10px;
      line-height: 23px !important;
      width: calc(15% + 20px);
      font-weight: bold;
      font-size: 18px;
    }
  }

  .SimpleTooltip__parent:hover .SimpleTooltip__tooltip {
    display: block;
    width: 250px;
  }
}
