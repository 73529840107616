.AuthPageLocal {
  background: #cdeff3;

  /* MetUCat styles */

  .ProfileView {
    background-color: white;
    border-radius: 10px 10px 10px;
    padding: 10px 0px;
    border-color: #b2d0d4;
    min-height: 350px;
    margin-bottom: 50px;
  }

  .ProfileView input {
    border: 1px solid rgb(203, 203, 203);
    border-radius: 5px;
    box-sizing: border-box;
    height: 52px;
    line-height: 50px;
    margin-bottom: 19px;
    padding: 0px 15px;
    width: 100%;
    outline: none;
  }

  .Dialog {
    margin-top: 25px;
    text-align: center;
  }

  .Dialog input {
    text-align: center;
  }

  .Dialog input.LoginPage__textInput {
    text-align: left;
  }

  .Dialog button {
    background-color: rgb(108, 210, 220);
    border: 0px;
    border-radius: 5px;
    color: rgb(253, 253, 253);
    font-size: 22px;
    height: 50px;
    line-height: 50px;
    margin: 10px 0px;
    width: 100%;
    outline: none;
  }

  .Dialog button:disabled {
    background-color: rgb(203, 203, 203);
  }

  .Dialog button:hover {
    background-color: #3dc2d1;
  }

  .LoginPage__Logo {
    height: 39px;
  }

  .row {
    -webkit-box-direction: normal;
    -webkit-box-flex: 0;
    -webkit-box-orient: horizontal;
    box-sizing: border-box;
    display: flex;
    flex: 0 1 auto;
    flex-flow: row wrap;
    margin-left: -0.5rem;
    margin-right: -0.5rem;
  }

  .col-xs,
  .col-xs-1,
  .col-xs-10,
  .col-xs-11,
  .col-xs-12,
  .col-xs-2,
  .col-xs-3,
  .col-xs-4,
  .col-xs-5,
  .col-xs-6,
  .col-xs-7,
  .col-xs-8,
  .col-xs-9,
  .col-xs-offset-1,
  .col-xs-offset-10,
  .col-xs-offset-11,
  .col-xs-offset-12,
  .col-xs-offset-2,
  .col-xs-offset-3,
  .col-xs-offset-4,
  .col-xs-offset-5,
  .col-xs-offset-6,
  .col-xs-offset-7,
  .col-xs-offset-8,
  .col-xs-offset-9 {
    -webkit-box-flex: 0;
    box-sizing: border-box;
    flex: 0 0 auto;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  .col-xs-4 {
    flex-basis: 33.333%;
    max-width: 33.333%;
  }

  .col-xs-12 {
    flex-basis: 100%;
    max-width: 100%;
  }

  .col-xs-offset-4 {
    margin-left: 33.333%;
  }

  body {
    -webkit-font-smoothing: antialiased;
    background-color: rgb(253, 253, 253);
    color: rgb(108, 108, 108);
    font-family: 'PT Sans', Arial, sans-serif;
    font-size: 14px;
    font-stretch: normal;
    font-style: normal;
    font-variant: normal;
    font-weight: 400;
    line-height: 1.4em;
    margin: 0px auto;
    min-width: 1200px;
  }

  .row {
    margin: 0px auto;
    max-width: 1200px;
  }

  h1,
  h2,
  h3,
  h4 {
    font-weight: normal;
  }

  #aTempPassword,
  #aAutoLogin {
    font-size: 90%;
    color: rgb(108, 209, 219);
  }

  #aTempPassword:hover,
  #aAutoLogin:hover {
    color: rgb(108, 209, 219);
    text-decoration: none;
  }

  .page-login {
    height: 369px;
  }

  form .col-xs-12 {
    padding: 0 10px;
  }

  .LoginPage__root.ProfileView.Dialog {
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.5);
  }

  #btnRegister {
    margin-top: 25px;
  }

  .auth_left img,
  .auth_right img {
    height: 39px;
    margin-top: 12px;
    margin-left: 8px;
    display: inline-block;
  }

  .auth_left {
    color: #6c6c6c;
  }

  .auth_left h2,
  .auth_right h2 {
    font-size: 20px;
    display: inline-block;
  }
  .auth_left button {
    background: #f1f8f8;
    cursor: pointer;
    color: #238a96;
    border-radius: 3px;
    border: 1px solid #6dd2dc;
    outline: none;
  }
  .auth_left button:hover {
    background: #ffffff;
  }
  .auth_right {
    padding-top: 24px;
    padding-left: 60px;
  }
  .auth_left_col {
    padding-top: 14px;
  }

  .nav > li > a {
    padding: 7px 0;
  }
}
