@import '../../styles/utils/variables';

.EditorDialog_finalize {
  .ModalWindow__main {
    width: 800px;
    height: 650px !important;
  }

  .ModalWindow__body {
    padding: 1px 18px;
    width: 800px !important;
    height: 350px;
    min-height: 150px !important;
    overflow-y: visible; // added because gray scrollbar was appearing inside Add Issue window, when description was filled with long text;
    // as a result, Discard button was shifted to bottom
  }

  .ModalWindow__footer {
    padding-top: 5px;
    width: 800px;
  }

  &.EditorDialog_fullscreen {
    .ModalWindow__main {
      width: 100vw !important;
    }

    .ModalWindow__body {
      width: 100vw !important;
    }

    .ModalWindow__footer {
      width: 100vw;
    }
  }
}

.FinalizeDialog {
  .row {
    width: 100%;
    flex: none;
    margin-top: 20px;
    margin-left: 5px;
  }

  &__subjectRow {
    .col-xs-7 {
      padding-left: 10px;
      padding-top: 4px;
      font-weight: bold;
      font-size: 16px;
      flex-basis: 30%;
      min-width: 30%;
    }

    .col-xs-9 {
      padding-left: 2px;
      flex-basis: 70%;
      min-width: 70%;
    }

    button {
      width: 96%;
      min-width: 125px;
      height: 32px;
      border: 0;
      float: left;
      font-size: 16px;
      font-weight: bold;
    }
  }

  &__subjectText {
    width: 100%;
    float: left;
    margin-top: 7px;
    height: 28px;
    padding-left: 40px;
    padding-top: 3px;
    font-size: 20px;
    font-weight: 500;
  }

  &__subjectInput {
    margin-left: 5px;
    margin-top: 4px;
    float: left;
    width: 100%;

    input[type='text'],
    textarea {
      margin-left: -5px;
      width: 100%;
      height: 32px;
      line-height: 28px;
      font-size: 18px;
    }

    .StringText {
      margin-top: 2px;
      font-size: 18px;
    }
  }

  &__issueSelectDiv {
    padding: 18px;
  }

  &__info {
    margin-top: 40px;
    margin-left: 10px;
    padding: 6px 6px;
    font-size: 20px;
  }

  &__version {
    margin-top: 10px;
    margin-left: 10px;
    padding: 130px 6px 10px 6px;
    font-size: 20px;
  }

  .col-xs-8 {
    padding-left: 5px;
    padding-right: 35px;
    flex-basis: 33.3%;
    min-width: 33.3%;
  }

  &__message {
    margin-left: 7px;
    float: left;
    width: 99%;

    &__multiLine {
      margin-top: 1px;
    }

    input[type='text'],
    textarea,
    .TextArea {
      margin-left: -5px;
      width: 100%;
      height: 32px;
      line-height: 28px;
      font-size: 18px;
    }
  }
}
