.TreeCol {
  float: left;
  width: 370px;
  margin-right: 32px;

  &:last-child {
    margin-right: 0;
  }

  &_double {
    float: left;
    width: 772px;
    margin-right: 32px;
  }
  &_half {
    float: left;
    width: 185px;
  }
  &_fourth {
    float: left;
    width: 97px;
  }
}

.leftTreeClearLine {
  position: relative;

  // why was this needed? I removed and full view has not changed
  // and view with all hidden datasets is now better
  /*
  &:before {
    position: absolute;
    content: "";
    background: white;
    width: 30px;
    height: 30px;
    left: -32px;
    top: 45px;
  }
  */
}

.leftTreeLine {
  position: relative;
  &:before {
    position: absolute;
    content: "";
    left: -16px;
    top: 15px;
    width: 16px;
    height: 100%;
    border-top: 1px solid #6C6C6C;
    border-left: 1px solid #6C6C6C;
    background: transparent;

  }


}

.leftTreeLine.leftTreeLineLast {
  &:before {
    height: 0px;
  }
}

.rightTreeLine {
  position: relative;
  &:after {
    position: absolute;
    content: "";
    width: 19px;
    height: 10px;
    border-top: 1px solid #6C6C6C;
    right: -19px;
    top: 15px;

  }
}

.PublicationBlock.PublicationBlock__more, .ApplicationBlock.MajorObjectBlock__more, .DatasetBlock.MajorObjectBlock__more {
  height: 22px;
  line-height: 16px;
  border-radius: 4px;
}


























